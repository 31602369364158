import { Alert, Avatar, Button, Upload } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { DialogSupport } from "../../../shared/dialogs/Dialogs";
import { EditUserPhotoViewModel } from "./EditUserPhotoViewModel";
import { AvatarEditorDialog } from "./avatarEditorDialog/AvatarEditorDialog";

interface EditUserDataViewProps {
  model: EditUserPhotoViewModel;
}

export const EditUserPhotoView: React.FC<EditUserDataViewProps> = observer(({ model }: EditUserDataViewProps) => {
  const [imageUrl, setImageUrl] = React.useState<string>();

  React.useEffect(() => {
    if (model.photo) {
      const url = URL.createObjectURL(model.photo);
      setImageUrl(url);
    } else {
      setImageUrl(undefined);
    }
  }, [model.photo]);

  const handleNewImage = (file: File | undefined) => {
    if (!file) return;
    model.uploadFile(file);
  };

  return (
    <>
      <div className="EditUserView">
        <div className="EditUserView__Title">
          <SvgIcon iconName="picture" />
          Profile photo
        </div>

        <div className="EditUserView__Content">
          {imageUrl ? (
            <div className="EditUserView__AvatarWrapper">
              <Avatar className="EditUserView__Avatar" src={imageUrl} />
              <Button
                className="EditUserView__Avatar-delete"
                icon={<SvgIcon iconName="delete" />}
                onClick={() => {
                  DialogSupport.showConfirmDialog({
                    title: "Are you sure you want to delete the photo?",
                    okText: "Delete",
                    cancelText: "Cancel",
                    onOk: model.removeUserPhoto,
                  });
                }}
              />
            </div>
          ) : (
            <Avatar
              className="EditUserView__Avatar EditUserView__Avatar--no-picture"
              icon={<SvgIcon iconName="avatar" />}
            />
          )}

          <div className="EditUserView__InfoText">Allowed extensions: *.jpg, *.jpeg, *.jfif, *.png. Max size 2MB</div>
          {model.isInvalid && <Alert type="error" message={model.errorMessage} />}
        </div>

        <div className="EditUserView__submit">
          <Upload
            accept=".png,.jpg,.jpeg,.jfif"
            onChange={e => handleNewImage(e.file.originFileObj)}
            showUploadList={false}
            multiple={false}
          >
            <Button type="primary" size="large">
              Upload photo
            </Button>
          </Upload>
        </div>
      </div>
      <AvatarEditorDialog model={model.avatarEditorDialogViewModel} />
    </>
  );
});
