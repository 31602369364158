import { observable, onBecomeObserved, untracked } from "mobx";
import { command, Command, Deferred, isLoaded } from "react-mvvm";
import { LoadMoreList } from "../../../shared/List/LoadMoreList";
import { Url } from "../../../shared/Url";
import { IErrorService } from "../../../shared/services/ErrorService";
import { RootStore } from "../../../shared/stories/RootStore";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { WorkspaceOverview } from "../../../shared/stories/workspace/Workspace";
import { WorkspaceStore } from "../../../shared/stories/workspace/WorkspaceStore";
import { GetWorkspacesListSortType } from "../../../types/initiatives/dto/GetWorkspacesListSortType";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { navigateTo } from "../../../useRouter";
import { BasePage } from "../../BasePage";
import { CreateWorkspaceDialogViewModel } from "./createWorkspaceDialog/CreateWorkspaceDialogViewModel";
import { SuccessDialogViewModel } from "./successDialog/SuccessWorkspaceDialogViewModel";

export interface AddNewItem {
  id: "addNewItem";
  name: "addNewItem";
}

export function isAddNewItem<T>(item: T | AddNewItem): item is AddNewItem {
  const { id, name } = item as AddNewItem;

  return id === "addNewItem" && name === "addNewItem";
}

export enum WorkspacesOrderBy {
  Name,
  NumberOfInitiatives,
  NumberOfMembers,
}

const defaultOrderBy = WorkspacesOrderBy.Name;

export class WorkspacePaginatedList extends LoadMoreList<AddNewItem | WorkspaceOverview> {
  @observable orderBy = defaultOrderBy;

  constructor(
    private readonly workspaceStore: WorkspaceStore,
    public readonly loggedUser?: UserDto,
    readonly pageSize = 12
  ) {
    super(pageSize);
  }

  protected async load(options: { page: number; results?: number }) {
    const currentPageNo = untracked(() => this.paginatedList.currentPageNo);
    if (currentPageNo === 1) {
      const result = await this.getWorkspaces({ page: options.page, results: options.results });

      return {
        items: [
          {
            id: "addNewItem",
            name: "addNewItem",
          } as AddNewItem,
          ...result.items,
        ],
        hasNextPage: result.hasNextPage,
      };
    }

    const workspaces = await this.getWorkspaces({ ...options });

    return workspaces;
  }

  private async getWorkspaces(options: { page: number; results?: number }) {
    const { workspaces, totalCount, hasNextPage } = await this.workspaceStore.getWorkspaces(
      { ...options },
      this.mapToSortOption(this.orderBy)
    );
    this.totalCount = totalCount ?? 0;
    return { items: workspaces, hasNextPage };
  }

  // eslint-disable-next-line class-methods-use-this
  private mapToSortOption(orderBy: WorkspacesOrderBy) {
    switch (orderBy) {
      case WorkspacesOrderBy.NumberOfInitiatives:
        return { sortDirection: SortDirection.Descending, sortType: GetWorkspacesListSortType.Name };
      case WorkspacesOrderBy.NumberOfMembers:
        return { sortDirection: SortDirection.Descending, sortType: GetWorkspacesListSortType.NumberOfMembers };
      case WorkspacesOrderBy.Name:
      default:
        return { sortDirection: SortDirection.Descending, sortType: GetWorkspacesListSortType.NumberOfInitiatives };
    }
  }
}

class Loaded {
  @observable workspaceList: WorkspacePaginatedList;

  constructor(readonly workspaceStore: WorkspaceStore, public readonly loggedUser: UserDto) {
    this.workspaceList = new WorkspacePaginatedList(workspaceStore, loggedUser);
  }

  async refreshLists() {
    await this.workspaceList.paginatedList.loadFirstPage();
  }
}

export class WorkspaceListViewModel extends BasePage {
  @observable.ref state: Deferred<Loaded> = "Loading";

  @observable hideLoading = false;

  createWorkspaceDialogViewModel: CreateWorkspaceDialogViewModel;

  successDialogViewModel: SuccessDialogViewModel;

  showCreateWorkspaceDialog: Command;

  showWorkspace: Command<string, void>;

  private showSuccessDialog: Command<string, void>;

  constructor(
    public readonly userStore: UserStore,
    public readonly workspaceStore: WorkspaceStore,
    public readonly errorService: IErrorService,
    public readonly rootStore: RootStore
  ) {
    super(errorService, false, userStore);
    onBecomeObserved(this, "state", async () => {
      await this.loadData();
    });

    this.showWorkspace = command(id => navigateTo(Url.toWorkspace({ workspaceId: id })));
    this.showCreateWorkspaceDialog = command(() => {
      this.createWorkspaceDialogViewModel.show();
    });

    this.successDialogViewModel = new SuccessDialogViewModel(this.showWorkspace);
    this.createWorkspaceDialogViewModel = new CreateWorkspaceDialogViewModel(
      this.workspaceStore,
      this.userStore,
      async workspaceId => {
        this.createWorkspaceDialogViewModel.hide();
        this.showSuccessDialog.execute(workspaceId);
        isLoaded(this.state) && this.state.refreshLists();
      }
    );

    this.showSuccessDialog = command(id => {
      this.successDialogViewModel.setWorkspaceId(id);
      this.successDialogViewModel.show();
    });
  }

  protected async loadData() {
    if (isLoaded(this.state)) {
      this.hideLoading = true;
      await this.state.refreshLists();
    } else {
      await this.userStore.updateUser();
      this.state = new Loaded(this.workspaceStore, this.userStore.loggedUser);
    }

    super.loadData();
  }
}
