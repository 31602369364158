type LoadingItem = { isLoading?: boolean };

export function isItemLoaded<T>(item: T | LoadingItem): item is T {
  const { isLoading } = item as LoadingItem;

  return isLoading === undefined || !isLoading;
}

// interface LoadMoreListItemProps<T> extends ListProps<T> {
//   className?: string;
//   renderItem
// }

// export const LoadMoreListItem = observer(
//   <T extends { id: string | number } | LoadingItem>(props: LoadMoreListItemProps<T>) => {
//     return (

//     );
//   }
// );
