/* eslint-disable import/no-cycle */
import { Button, Typography } from "antd";
import classNames from "classnames";
import * as React from "react";
import Tour, { ReactourStep } from "reactour";
import { IconType, SvgIcon } from "../SvgIcon/SvgIcon";
import { InitialWalkTourSelectors } from "./InitialWalkTour";

const { Title } = Typography;

interface IWalkTour {
  fixed: boolean;
  steps: ReactourStep[];
  lastStepNextButton?: React.ReactNode;
}

interface IWalkTourPopover {
  title: string;
  icon: IconType;
  children: React.ReactNode;
}

export const WalkTour = ({ fixed, steps, lastStepNextButton }: IWalkTour) => {
  const [isOpen, setOpen] = React.useState(false);
  const isOneStepTour = steps.length > 1;
  return (
    <>
      <Button
        data-tour={InitialWalkTourSelectors.Help}
        className={classNames("WalkTourBtn", fixed && "WalkTourBtn--fixed")}
        shape="circle"
        size={fixed ? "large" : "small"}
        onClick={() => setOpen(true)}
      >
        <SvgIcon iconName="question" />
      </Button>
      <Tour
        steps={steps}
        isOpen={isOpen}
        rounded={8}
        showNavigationNumber={false}
        startAt={0}
        showNumber={false}
        onRequestClose={() => {
          setOpen(false);
        }}
        showNavigation={isOneStepTour}
        showButtons={isOneStepTour}
        lastStepNextButton={lastStepNextButton}
      />
    </>
  );
};

export const WalkTourPopover = ({ title, icon, children }: IWalkTourPopover) => {
  return (
    <>
      <Title level={1} className="WalTourPopoverTitle">
        <SvgIcon iconName={icon} /> {title}
      </Title>
      {children}
    </>
  );
};
