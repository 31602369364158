import _ from "lodash";
import { observable, computed } from "mobx";
import moment from "moment";
import { command, Command } from "react-mvvm";
import { AttachmentDto } from "src/types/initiatives/dto/AttachmentDto";
import { IdeaDto } from "src/types/initiatives/dto/IdeaDto";
import { IdeaEditUserDto } from "src/types/initiatives/dto/IdeaEditUserDto";
import { IdeaListItemDto } from "../../../types/initiatives/dto/IdeaListItemDto";
import { IdeaStateDto } from "../../../types/initiatives/dto/IdeaStateDto";
import { IdeaStateNameDto } from "../../../types/initiatives/dto/IdeaStateNameDto";
import { InitiativeStateDto } from "../../../types/initiatives/dto/InitiativeStateDto";
import { InitiativeStateNameDto } from "../../../types/initiatives/dto/InitiativeStateNameDto";
import { UserDto } from "../../../types/shared/dto/UserDto";
// eslint-disable-next-line import/no-cycle
import { Initiative } from "../initiative/Initiative";
// eslint-disable-next-line import/no-cycle
import { IdeaStore } from "./IdeaStore";

export class IdeaDetail {
  @observable id = "";

  @observable initiativeId = "";

  @observable initiativeTitle = "";

  @observable submittedBy: UserDto;

  @observable state: IdeaStateDto = {
    name: IdeaStateNameDto.Submitted,
    isFinal: false,
    createdAt: new Date().toISOString(),
  };

  @observable initiativeState: InitiativeStateDto = {
    name: InitiativeStateNameDto.Collecting,
    isFinal: false,
  };

  @observable title = "";

  @observable description = "";

  @observable votesCount = 0;

  @observable userVotesCount = 0;

  @observable commentsCount = 0;

  @observable attachmentsCount = 0;

  @observable attachments: AttachmentDto[] = [];

  @observable createdAt = new Date().toISOString();

  @observable workspaceId = "";

  @observable workspaceName = "";

  @observable isPersist = false;

  @observable lastEditedBy: IdeaEditUserDto = {
    submittedBy: {
      id: "",
      name: "",
      email: "",
    },
    editedAt: "",
  };

  @observable isCurrentUserSubscribed = false;

  remove: Command;

  addVote: Command<number>;

  remoteVote: Command<number>;

  eraseVotes: Command<void, Promise<void>>;

  release: Command;

  editSubscription: Command;

  updateVote = _.debounce(async () => {
    await this.store.updateVote(this.id, this.userVotesCount);
  }, 800);

  constructor(public store: IdeaStore, submittedBy: UserDto) {
    this.submittedBy = submittedBy;

    this.remove = new Command(async () => {
      await this.store.removeIdea(this);

      this.state = {
        name: IdeaStateNameDto.Deleted,
        isFinal: false,
        createdAt: this.state.createdAt,
      };
    });

    this.eraseVotes = command(
      async () => {
        this.votesCount = 0;
        this.userVotesCount = 0;
        await this.updateVote();
      },
      () => {
        return true;
      }
    );

    this.release = new Command(async () => {
      await this.store.releaseIdea(this);

      this.state = {
        name: IdeaStateNameDto.Released,
        isFinal: false,
        createdAt: this.state.createdAt,
      };
    });

    this.addVote = new Command(async (num = 1) => {
      this.votesCount += num;
      this.userVotesCount += num;
      await this.updateVote();

      // automatically subscribed when voted, no need to send request
      this.subscribe();
    });

    this.remoteVote = new Command(async (num = 1) => {
      this.votesCount -= num;
      this.userVotesCount -= num;
      await this.updateVote();
    });

    this.editSubscription = new Command(
      async () => {
        await this.store.editSubscription(this.id, !this.isCurrentUserSubscribed);
        this.isCurrentUserSubscribed = !this.isCurrentUserSubscribed;
      },
      () => !this.initiativeState.isFinal && !this.state.isFinal && !this.isArchived
    );
  }

  @computed get isDeleted() {
    return this.state.name === IdeaStateNameDto.Deleted;
  }

  @computed get isReleased() {
    return this.state.name === IdeaStateNameDto.Released;
  }

  @computed get isArchived() {
    return this.state.name === IdeaStateNameDto.Archived;
  }

  updateFromDto(dto: IdeaDto) {
    this.id = dto.id;
    this.title = dto.title;
    this.description = dto.description;
    this.submittedBy = dto.submittedBy;
    this.createdAt = dto.createdAt;
    this.state = dto.state;
    this.initiativeState = dto.initiativeState;
    this.commentsCount = dto.commentsCount;
    this.attachmentsCount = dto.attachmentsCount;
    this.votesCount = dto.votesCount;
    this.userVotesCount = dto.currentUserVotesCount;
    this.attachments = dto.attachments;
    this.isPersist = true;
    this.votesCount = dto.votesCount;
    this.lastEditedBy = dto.lastEditedBy;
    this.isCurrentUserSubscribed = dto.isCurrentUserSubscribed;
    this.initiativeId = dto.initiativeId;
    this.initiativeTitle = dto.initiativeTitle;
    this.workspaceId = dto.workspaceId;
    this.workspaceName = dto.workspaceName;
  }

  async save() {
    if (this.isPersist) {
      await this.store.initiativeService.editIdea({
        id: this.id,
        description: this.description,
        title: this.title,
        attachments: this.attachments,
      });
    } else {
      await this.store.initiativeService.createIdea({
        id: this.id,
        initiativeId: this.initiativeId,
        description: this.description,
        title: this.title,
        attachments: this.attachments,
      });
      this.isPersist = true;
    }
  }

  async move(initiativeId: string) {
    await this.store.moveIdea(this.id, initiativeId);
  }

  subscribe() {
    this.isCurrentUserSubscribed = true;
  }
}

// Model
export class IdeaListItem {
  @observable initiativeId = this.initiative.id;

  @observable submittedBy: UserDto;

  @observable state: IdeaStateDto = {
    name: IdeaStateNameDto.Submitted,
    isFinal: false,
    createdAt: new Date().toISOString(),
  };

  @observable initiativeState: InitiativeStateDto = {
    name: InitiativeStateNameDto.Collecting,
    isFinal: false,
  };

  @observable title = "";

  @observable attachments: AttachmentDto[];

  @observable description = "";

  @observable votesCount = 0;

  @observable userVotesCount = 0;

  @observable commentsCount = 0;

  @observable attachmentsCount = 0;

  @observable isCurrentUserSubscribed = false;

  @observable createdAt = new Date().toISOString();

  @observable workspaceId = "";

  @observable workspaceName = "";

  @observable initiativeTitle = "";

  @observable lastEditedBy: IdeaEditUserDto = {
    submittedBy: {
      id: "",
      name: "",
      email: "",
    },
    editedAt: "",
  };

  @observable private _isPersist = false;

  remove: Command;

  release: Command;

  addVote: Command<number>;

  remoteVote: Command<number>;

  eraseVotes: Command<void, Promise<void>>;

  editSubscription: Command;

  updateVote = _.debounce(async () => {
    await this.store.updateVote(this.id, this.userVotesCount);
  }, 800);

  constructor(public store: IdeaStore, public initiative: Initiative, submittedBy: UserDto, public id: string) {
    this.submittedBy = submittedBy;
    this.attachments = [];

    this.remove = new Command(async () => {
      await this.store.removeIdea(this);

      this.state = {
        name: IdeaStateNameDto.Deleted,
        isFinal: false,
        createdAt: this.state.createdAt,
      };
    });

    this.eraseVotes = command(
      async () => {
        this.votesCount = 0;
        this.userVotesCount = 0;
        await this.updateVote();
      },
      () => {
        return this.canUserRemove;
      }
    );

    this.release = new Command(async () => {
      await this.store.releaseIdea(this);

      this.state = {
        name: IdeaStateNameDto.Released,
        isFinal: false,
        createdAt: this.state.createdAt,
      };
    });

    this.addVote = new Command(async (num = 1) => {
      this.votesCount += num;
      this.userVotesCount += num;
      await this.updateVote();

      // automatically subscribed when voted, no need to send request
      this.subscribe();
    });

    this.remoteVote = new Command(async (num = 1) => {
      this.votesCount -= num;
      this.userVotesCount -= num;
      await this.updateVote();
    });

    this.editSubscription = new Command(async () => {
      await this.store.editSubscription(this.id, !this.isCurrentUserSubscribed);
      this.isCurrentUserSubscribed = !this.isCurrentUserSubscribed;
    });
  }

  @computed get isDeleted() {
    return this.state.name === IdeaStateNameDto.Deleted;
  }

  @computed get isReleased() {
    return this.state.name === IdeaStateNameDto.Released;
  }

  @computed get isArchived() {
    return this.state.name === IdeaStateNameDto.Archived;
  }

  @computed get isPersist() {
    return this._isPersist;
  }

  @computed get canUserEdit() {
    return (
      this.initiative.userStore.loggedUser.id === this.submittedBy.id &&
      !this.initiative.hasSubmissionEnded &&
      !this.isDeleted &&
      !this.isReleased &&
      !this.isArchived &&
      !this.initiative.state.isFinal
    );
  }

  @computed get canSetAsReleased() {
    return (
      (this.initiative.userStore.loggedUser.id === this.initiative.ownerId ||
        this.initiative.coOwnersIds.indexOf(this.initiative.userStore.loggedUser.id) > -1) &&
      !this.isDeleted &&
      !this.isReleased &&
      !this.isArchived &&
      !this.initiative.state.isFinal
    );
  }

  @computed get isRemovable() {
    return moment().isBetween(this.initiative.submissionStartDate, this.initiative.votingEndDate, "day", "[]");
  }

  @computed get canUserRemove() {
    return (
      !this.isDeleted &&
      (this.initiative.userStore.loggedUser.id === this.submittedBy.id ||
        this.initiative.userStore.loggedUser.id === this.initiative.ownerId ||
        this.initiative.coOwnersIds.indexOf(this.initiative.userStore.loggedUser.id) > -1) &&
      this.isRemovable &&
      !this.isReleased &&
      !this.isArchived &&
      !this.initiative.state.isFinal
    );
  }

  @computed get isAnonymous() {
    return this.initiative.hideIdeaSubmitter && this.initiative.userStore.loggedUser.id !== this.submittedBy.id;
  }

  updateFromDto(dto: IdeaListItemDto) {
    this.id = dto.id;
    this.title = dto.title;
    this.description = dto.description;
    this.submittedBy = dto.submittedBy;
    this.createdAt = dto.createdAt;
    this.state = dto.state;
    this.initiativeState = dto.initiativeState;
    this.commentsCount = dto.commentsCount;
    this.attachmentsCount = dto.attachmentsCount;
    this.votesCount = dto.votesCount;
    this.userVotesCount = dto.currentUserVotesCount;
    this.lastEditedBy = dto.lastEditedBy;
    this.isCurrentUserSubscribed = dto.isCurrentUserSubscribed;
    this.initiativeId = dto.initiativeId;
    this._isPersist = true;
  }

  async updateAttachments(dto?: IdeaDto) {
    if (dto) this.attachments = dto.attachments;
    else this.attachments = await this.store.updateAttachments(this);
  }

  async save() {
    await this.store.initiativeService.editIdea({
      id: this.id,
      description: this.description,
      title: this.title,
      attachments: this.attachments,
    });
    this.attachmentsCount = this.attachments.length;
  }

  async move(initiativeId: string) {
    await this.store.moveIdea(this.id, initiativeId);
  }

  subscribe() {
    this.isCurrentUserSubscribed = true;
  }
}
