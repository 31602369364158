import { observable } from "mobx";
import { GetWorkspaceMemberListSortType } from "src/types/initiatives/dto/GetWorkspaceMemberListSortType";
import { v4 as uuid } from "uuid";
import { GetWorkspacesListSortType } from "../../../types/initiatives/dto/GetWorkspacesListSortType";
import { WorkspaceDto } from "../../../types/initiatives/dto/WorkspaceDto";
import { WorkspaceListItemDto } from "../../../types/initiatives/dto/WorkspaceListItemDto";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { IWorkspaceService } from "../../api/BackendApi";
import { UserStore } from "../user/UserStore";
import { Workspace, WorkspaceOverview } from "./Workspace";
import { WorkspaceMember } from "./WorkspaceMember";

export class WorkspaceStore {
  @observable private workspaces: Workspace[] = [];

  private constructor(public readonly workspaceService: IWorkspaceService, private readonly userStore: UserStore) {}

  static async create(workspaceService: IWorkspaceService, userStore: UserStore) {
    const store = new WorkspaceStore(workspaceService, userStore);

    return store;
  }

  createWorkspace(id: string = uuid()) {
    const workspace = new Workspace(this, this.userStore, id);
    this.workspaces.push(workspace);
    return workspace;
  }

  removeWorkspace(workspace: Workspace) {
    this.workspaces = this.workspaces.filter(i => i.id !== workspace.id);
  }

  async getWorkspaces(
    options: { page: number; results?: number },
    sortOption: { sortDirection: SortDirection; sortType: GetWorkspacesListSortType }
  ) {
    const workspaceList = await this.workspaceService.getWorkspaceList({ ...options, ...sortOption });

    return {
      workspaces: workspaceList.items.map(workspaceOverview => this.updateWorkspaceOverview(workspaceOverview)),
      totalCount: workspaceList.totalResults,
      hasNextPage: workspaceList.hasNextPage,
    };
  }

  async getWorkspace(workspaceId: string): Promise<Workspace> {
    const workspaceDto = await this.workspaceService.getWorkspace({ id: workspaceId });

    return this.updateWorkspaceFromServer(workspaceDto);
  }

  async getWorkspaceMembers(
    workspaceId: string,
    options: { page: number; results?: number },
    sortOption: { sortDirection: SortDirection; sortType: GetWorkspaceMemberListSortType }
  ) {
    const workspaceMembers = await this.workspaceService.getWorkspaceMembers({
      workspaceId,
      ...options,
      ...sortOption,
    });

    return {
      members: workspaceMembers.items.map(dto => {
        const workspaceMember = new WorkspaceMember();
        workspaceMember.update(dto);
        return workspaceMember;
      }),
      totalCount: workspaceMembers.totalResults,
      hasNextPage: workspaceMembers.hasNextPage,
    };
  }

  validateWorkspaceName(name: string) {
    return this.workspaceService.validateWorkspaceName({ name });
  }

  private updateWorkspaceOverview(dto: WorkspaceListItemDto) {
    const workspaceOverview = new WorkspaceOverview();
    workspaceOverview.updateListFromServer(dto);

    return workspaceOverview;
  }

  private updateWorkspaceFromServer(dto: WorkspaceDto) {
    let workspace = this.workspaces.find(x => x.id === dto.id);
    if (!workspace) {
      workspace = new Workspace(this, this.userStore, dto.id);
      this.workspaces.push(workspace);
    }
    workspace.updateFromDto(dto);

    return workspace;
  }
}
