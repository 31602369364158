import { List, Skeleton } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Command } from "react-mvvm";
import { VotingMethodDto } from "src/types/shared/dto/VotingMethodDto";
import { LoadMoreListView } from "../../../../shared/List/LoadMoreListView";
import { IdeaListItem as IdeaListItemModel } from "../../../../shared/stories/idea/Idea";
import { IdeaListViewModel } from "../InitiativeViewModel";
import { IdeaListItem } from "./IdeaListItem";

interface IdeaListViewProps {
  ideaList: IdeaListViewModel;
  showIdea: Command<string>;
  showEditIdeaDialog: Command<IdeaListItemModel, Promise<void>>;
  showMoveIdeaDialog: Command<IdeaListItemModel, Promise<void>>;
  votingMethod?: VotingMethodDto;
}

export const IdeaListView = observer(
  ({ ideaList, showIdea, showEditIdeaDialog, votingMethod, showMoveIdeaDialog }: IdeaListViewProps) => {
    return (
      <div className="IdeaList">
        <LoadMoreListView
          className="IdeaList__ideasList"
          loadMoreList={ideaList}
          skeleton={
            <List.Item className="IdeaList__ideasListItem">
              <Skeleton title loading active />
            </List.Item>
          }
          renderItem={item => {
            return (
              <IdeaListItem
                item={item}
                showIdea={showIdea}
                showEditIdeaDialog={showEditIdeaDialog}
                votingMethod={votingMethod}
                showMoveIdeaDialog={showMoveIdeaDialog}
              />
            );
          }}
        />
      </div>
    );
  }
);
