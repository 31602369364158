import { Dropdown, Input, Menu, Spin } from "antd";
import classNames from "classnames";
import { debounce } from "lodash";
import React, { FC, useCallback, useState } from "react";

const TagOptionMenuItemId = "tag-option-menu-item";

export interface SearchOption<T> {
  id: string;
  value: T;
  render?: (value: T) => React.ReactNode;
}

interface SearchInputProps<T> {
  options: SearchOption<T>[];
  onSelect: (value: T) => void;
  onSearch: (searchText: string) => void;
  inputValue: string;
  onInputValueChange: (value: string) => void;
  loading?: boolean;
  clearOnSelect?: boolean;
  renderOption: (value: T) => React.ReactNode;
  getOptionInputValue: (value: T) => string;
}

export const SearchInput = <T,>(props: SearchInputProps<T>) => {
  const { options, onSelect, onSearch, loading, inputValue, onInputValueChange, clearOnSelect, renderOption, getOptionInputValue } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [cursor, setCursor] = useState(0);

  const handleMenuItemClick = (value: T) => {
    setIsDropdownVisible(false);
    onSelect(value);
    if (clearOnSelect) onInputValueChange("");
  };

  const debouncedSearch = useCallback(
    debounce(query => {
      onSearch(query);
      setCursor(0);
    }, 600),
    []
  );

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      setCursor(c => {
        let newVal = c + (e.key === "ArrowDown" ? 1 : -1);
        if (newVal === -1) newVal = options.length - 1;
        if (newVal === options.length) newVal = 0;
        const node = document.getElementById(`${TagOptionMenuItemId}-${newVal}`);
        node?.scrollIntoView({ behavior: "smooth", block: "center" });
        onInputValueChange(getOptionInputValue(options[newVal].value));
        return newVal;
      });
    } else if (e.key === "Enter" && cursor >= 0) {
      handleMenuItemClick(options[cursor].value);
    }
  };

  const handleInputChange = (val: string) => {
    onInputValueChange(val);
    debouncedSearch(val);
    setCursor(0);
  };

  const handleVisibleChange = (flag: boolean) => {
    setIsDropdownVisible(flag);
    setCursor(0);
  };

  const menu = (
    <Menu className="SearchInput__dropdown-menu">
      {loading && (
        <Menu.Item key="spin-loader">
          <div className="spin-wrapper">
            <Spin size="default" />
          </div>
        </Menu.Item>
      )}
      {!loading &&
        options.map((option, i) => (
          <Menu.Item
            id={`${TagOptionMenuItemId}-${i}`}
            className={classNames("menu-item", cursor === i && "menu-item-active")}
            key={option.id}
            onClick={() => handleMenuItemClick(option.value)}
          >
            {option.render ? option.render(option.value) : renderOption(option.value)}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className="SearchInput">
      <Dropdown
        className="SearchInput__dropdown"
        placement="bottomLeft"
        overlay={menu}
        getPopupContainer={trigger => trigger.parentElement ?? trigger}
        trigger={["click"]}
        visible={isDropdownVisible && options.length > 0}
        onVisibleChange={handleVisibleChange}
      >
        <Input value={inputValue} onChange={e => handleInputChange(e.target.value)} onKeyDown={handleKeyDownInput} />
      </Dropdown>
    </div>
  );
};
