import { List } from "antd";
import * as React from "react";
import { AttachmentDto } from "../../types/initiatives/dto/AttachmentDto";
import { AttachmentType } from "../../types/ly/application/AttachmentType";
import { FileInput, IFileInputProps } from "../FileInput/FileInput";
import { UrlAttachment } from "../attachment/Attachment";

export const AttachmentInput: React.FunctionComponent<IFileInputProps> = props => {
  const attachmentRender: (
    rootNode: React.ReactElement,
    attachment: AttachmentDto,
    actions: {
      remove: () => void;
    }
  ) => React.ReactElement = (rootNode, attachment, actions) => {
    if (attachment.type === AttachmentType.Url) {
      return (
        <List.Item key={attachment.id}>
          <UrlAttachment attachment={attachment} onRemove={actions.remove} />
        </List.Item>
      );
    }
    return rootNode;
  };

  return <FileInput {...props} itemRender={attachmentRender} />;
};
