import { Card } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import React from "react";
import { SvgIcon } from "../../../../../shared/SvgIcon/SvgIcon";
import { ActivityIndicator } from "../../../../../shared/activityIndicator/ActivityIndicator";
import { Button } from "../../../../../shared/button/Button";
import { UserAvatar } from "../../../../../shared/userAvatar/UserAvatar";
import { WorkspaceMembersViewModel } from "./WorkspaceMembersViewModel";

export interface WorkspaceMembersProps {
  model: WorkspaceMembersViewModel;
}

export const WorkspaceMembers = observer(({ model }: WorkspaceMembersProps) => {
  React.useEffect(() => {
    model.loadData();
  }, []);

  return (
    <Card className="Workspace__members">
      <ActivityIndicator isLoading={model.isLoading}>
        <div className="Workspace__members--content">
          <div className="Workspace__members--header">
            <SvgIcon iconName="avatar" />
            <Title level={4}>{model.title}</Title>
          </div>
          <div className="Workspace__members--list">
            {model.members.map(member => {
              return (
                <div className="Workspace__members--item">
                  <UserAvatar userName={member.displayName} size={48} avatarId={member.avatarId} />
                  <div className="Workspace__members--itemName">
                    <div className="Workspace__members--userName">
                      <span>{member.displayName}</span>
                    </div>
                    <div className="Workspace__members--userRole">
                      <span>{member.workspaceRole}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="Workspace__members--footer">
            <Button
              type="text"
              onClick={model.showManageWorkspaceMembersDialogClick}
              className="Workspace__members--seeAllButton"
            >
              {model.canUserEdit ? "Manage all" : "See all"}
              <SvgIcon iconName="chevronRight" className="customicon--textPrimary" />
            </Button>
          </div>
        </div>
      </ActivityIndicator>
    </Card>
  );
});
