import { List } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Comment } from "../../../../shared/stories/comment/Comment";
import { IdeaDetail } from "../../../../shared/stories/idea/Idea";
import { CommentType } from "../../../../types/ly/application/CommentType";
import { CommentView } from "./CommentView";
import { SystemCommentView } from "./SystemCommentView";

interface ICommentListViewProps {
  comments: Comment[];
  idea: IdeaDetail;
}

export const CommentListView: React.FunctionComponent<ICommentListViewProps> = observer(props => {
  const { comments, idea } = props;

  React.useEffect(() => {
    const list = document.getElementById("CommentList");
    if (list != null) setTimeout(() => list.scrollTo({ top: 100000, behavior: "smooth" }), 0);
  }, [comments.length]);

  return (
    <List
      className="CommentList"
      id="CommentList"
      dataSource={comments}
      renderItem={comment =>
        comment.commentType === CommentType.UserComment ? (
          <CommentView className="CommentList__item" comment={comment} idea={idea} />
        ) : (
          <SystemCommentView className="CommentList__systemItem" comment={comment} />
        )
      }
    />
  );
});
