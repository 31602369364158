import { autorun, observable, runInAction, untracked } from "mobx";
import { asyncCommand } from "../commands";
import { List } from "./List";

const PAGE_SIZE: number = 50;

export class PaginatedList<T extends { id: string | number }> extends List<T> {
  @observable private _currentPageNo: number = 1;
  get currentPageNo() {
    return this._currentPageNo;
  }

  @observable private _hasNextPage: boolean = false;
  get hasNextPage() {
    return this._hasNextPage;
  }

  @observable private _isLoadingFirstPage = 0;
  get isLoadingFirstPage() {
    return this._isLoadingFirstPage > 0;
  }

  @observable private _isLoadingNextPage = 0;
  get isLoadingNextPage() {
    return this._isLoadingNextPage > 0;
  }

  get isLoading() {
    return this.isLoadingFirstPage || this.isLoadingNextPage;
  }

  readonly loadNextPage = asyncCommand<void, void>(
    () => this._loadNextPage(),
    () => this.hasNextPage
  );

  constructor(
    private load: (options: {
      page: number;
      resultsPerPage: number;
    }) => Promise<{ items: ReadonlyArray<T>; hasNextPage: boolean }>,
    private pageSize = PAGE_SIZE
  ) {
    super();
    autorun(this.loadFirstPage, { delay: 500 });
  }

  loadFirstPage = async (): Promise<void> => {
    const isLoadingFirstPage = untracked(() => this._isLoadingFirstPage);


      this._isLoadingFirstPage = isLoadingFirstPage + 1;
      try {
        this._currentPageNo = 1;
        await this.loadMoreItems([], 1);
      } finally {
        this._isLoadingFirstPage--;
      }
    
  };

  private async _loadNextPage(): Promise<void> {
    const isLoadingNextPage = untracked(() => this._isLoadingNextPage);

    this._isLoadingNextPage = isLoadingNextPage + 1;
    try {
      this._currentPageNo++;
      await this.loadMoreItems(this.items, this.currentPageNo);
    } finally {
      this._isLoadingNextPage--;
    }
  }

  private async loadMoreItems(currentItems: ReadonlyArray<T & { isNew?: boolean }>, pageToLoad: number): Promise<void> {
    let { items: newItems, hasNextPage } = await this.load({
      page: pageToLoad,
      resultsPerPage: this.pageSize,
    });

    runInAction(() => {
      this._hasNextPage = hasNextPage;
      this.items = currentItems.concat(newItems);
    });
  }
}
