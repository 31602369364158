import { Radio } from "antd";
import * as React from "react";

export interface IRadioButtonGroupTypeProps<T> {
  value: T | undefined;
  onChange: (value: T) => void;
  defaultValue: T;
  className?: string;
  children: React.ReactNode;
}

export const RadioButtonGroup = <T,>({
  defaultValue,
  className,
  onChange,
  value,
  children,
}: IRadioButtonGroupTypeProps<T>) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      defaultValue={defaultValue}
      className={className}
      onChange={e => onChange(e.target.value)}
      value={value}
    >
      {children}
    </Radio.Group>
  );
};
