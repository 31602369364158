import { observable } from "mobx";
import { GetIdeaListSortType } from "src/types/initiatives/dto/GetIdeaListSortType";
import { IdeaDto } from "src/types/initiatives/dto/IdeaDto";
import { IdeaListItemDto } from "src/types/initiatives/dto/IdeaListItemDto";
import { IdeaStateNameDto } from "src/types/initiatives/dto/IdeaStateNameDto";
import { SubscriptionTargetTypeDto } from "src/types/notifications/dto/SubscriptionTargetTypeDto";
import { SortDirection } from "src/types/shared/dto/SortDirection";
import { IInitiativeService, IVoteService, INotificationService } from "../../api/BackendApi";
// eslint-disable-next-line import/no-cycle
import { Initiative } from "../initiative/Initiative";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { IdeaDetail, IdeaListItem } from "./Idea";

export class IdeaStore {
  @observable ideas: IdeaListItem[] = [];

  private constructor(
    public readonly initiativeService: IInitiativeService,
    private readonly userStore: UserStore,
    public readonly voteService: IVoteService,
    public readonly notificationService: INotificationService
  ) {}

  static async create(
    initiativeService: IInitiativeService,
    userStore: UserStore,
    voteService: IVoteService,
    notificationService: INotificationService
  ) {
    const store = new IdeaStore(initiativeService, userStore, voteService, notificationService);

    return store;
  }

  async getIdea(id: string) {
    const idea = await this.initiativeService.getIdea({ id });
    return this.updateIdeaDetailFromServer(idea);
  }

  async getIdeas(
    initiative: Initiative,
    options: { page: number; results?: number },
    filter: { initiativeId: string; userId: string },
    sortOption: { sortDirection: SortDirection; sortType: GetIdeaListSortType },
    status: string
  ) {
    const ideaListResult = await this.initiativeService.getIdeaList({
      ...options,
      ...sortOption,
      ...filter,
      state: status,
    });
    return {
      ideas: ideaListResult.items.map((ideaListItemDto: IdeaListItemDto) =>
        this.updateIdeaOverviewFromServer(ideaListItemDto, initiative)
      ),
      totalCount: ideaListResult.totalResults,
      hasNextPage: ideaListResult.hasNextPage,
    };
  }

  createIdea() {
    const idea = new IdeaDetail(this, this.userStore.loggedUser);
    return idea;
  }

  async removeIdea(idea: IdeaListItem | IdeaDetail) {
    await this.initiativeService.removeIdea({ id: idea.id });
  }

  async releaseIdea(idea: IdeaListItem | IdeaDetail) {
    await this.initiativeService.releaseIdea({ id: idea.id });
  }

  async updateVote(ideaId: string, amount: number) {
    await this.voteService.updateVote({ pollOptionTargetId: ideaId, amount });
  }

  async updateAttachments(idea: IdeaListItem | IdeaDetail) {
    const response = await this.initiativeService.getIdea({ id: idea.id });
    return response.attachments;
  }

  async moveIdea(ideaId: string, initiativeId: string) {
    await this.initiativeService.moveIdea({ ideaId, newInitiativeId: initiativeId });
  }

  async editSubscription(ideaId: string, subscribe: boolean) {
    if (subscribe) {
      return await this.notificationService.subscribeForNotification({
        targetId: ideaId,
        targetType: SubscriptionTargetTypeDto.Idea,
      });
    }

    return await this.notificationService.unsubscribeForNotification({
      targetId: ideaId,
      targetType: SubscriptionTargetTypeDto.Idea,
    });
  }

  private updateIdeaOverviewFromServer(dto: IdeaListItemDto, initiative: Initiative) {
    let idea = this.ideas.find(b => b.id === dto.id);
    if (!idea) {
      idea = new IdeaListItem(this, initiative, dto.submittedBy, dto.id);
      this.ideas.push(idea);
    }
    idea.updateFromDto(dto);

    return idea;
  }

  // eslint-disable-next-line class-methods-use-this
  private updateIdeaDetailFromServer(dto: IdeaDto) {
    const idea = new IdeaDetail(this, dto.submittedBy);
    idea.updateFromDto(dto);
    return idea;
  }
}
