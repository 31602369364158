import React, { FC } from "react";
import { Command } from "react-mvvm/dist";
import { SvgIcon } from "../../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../../shared/button/Button";

interface SubscribeButtonProps {
  command: Command;
  isSubscribed: boolean;
}

export const SubscribeButton: FC<SubscribeButtonProps> = ({ command, isSubscribed }) => {
  return (
    <div className="SubscribeButton">
      {isSubscribed ? (
        <CommandButton
          command={command}
          icon={<SvgIcon iconName="tick" />}
          size="large"
          type="primary"
          className="SubscribeButton__PrimaryBtn"
        >
          Unubscribe
        </CommandButton>
      ) : (
        <CommandButton
          command={command}
          icon={<SvgIcon iconName="bellOutlined" />}
          size="large"
          type="ghost"
          className="SubscribeButton__OutlinedBtn"
        >
          Subscribe
        </CommandButton>
      )}
    </div>
  );
};
