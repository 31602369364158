import { HubConnection } from "@microsoft/signalr";
import { observable } from "mobx";
import { AppNotificationDto } from "../../../types/notifications/dto/AppNotificationDto";
import { NotificationAreaDto } from "../../../types/notifications/dto/NotificationAreaDto";
import { UnreadNotificationTotalsDto } from "../../../types/notifications/dto/UnreadNotificationTotalsDto";
import { INotificationService as INotificationApiService } from "../../api/BackendApi";
import { NEW_NOTIFICATION_METHOD_NAME } from "../../api/hubConnections";
import { INotificationService as IShowNotificationService } from "../../services/NotificationService";
import { UserStore } from "../user/UserStore";
import { AppNotificationList } from "./AppNotificationList";

export class NotificationStore {
  @observable public totalUnread = 0;

  @observable public initiativeNotifications: AppNotificationList;

  @observable public ideaNotifications: AppNotificationList;

  @observable public directMentionNotifications: AppNotificationList;

  private constructor(
    public readonly notificationHubConncetion: HubConnection,
    public readonly notificationApiService: INotificationApiService,
    private readonly notificationService: IShowNotificationService,
    private readonly userStore: UserStore
  ) {
    this.initiativeNotifications = new AppNotificationList(this, NotificationAreaDto.Initiative);
    this.ideaNotifications = new AppNotificationList(this, NotificationAreaDto.Idea);
    this.directMentionNotifications = new AppNotificationList(this, NotificationAreaDto.DirectMention);
  }

  static create = async (
    notificationHubConncetion: HubConnection,
    notificationApiService: INotificationApiService,
    notificationService: IShowNotificationService,
    userStore: UserStore
  ) => {
    const store = new NotificationStore(
      notificationHubConncetion,
      notificationApiService,
      notificationService,
      userStore
    );
    return store;
  };

  getUser(id: string) {
    return this.userStore.users.get(id);
  }

  async loadUser(id: string) {
    await this.userStore.getUser(id);
  }

  public readAll = async () => {
    await this.notificationApiService.markAllNotificationsAsRead();
    this.updateUnreadTotals();
    this.initiativeNotifications.markAllAsRead();
    this.ideaNotifications.markAllAsRead();
    this.directMentionNotifications.markAllAsRead();
  };

  public updateUnreadTotals = async () => {
    const totals = await this.notificationApiService.getUnreadNotificationsTotals();
    this.updateUnreadTotalsFromDto(totals);
  };

  public startNotificationHubConnection = () => {
    this.notificationHubConncetion.on(
      NEW_NOTIFICATION_METHOD_NAME,
      (notification: AppNotificationDto, totals: UnreadNotificationTotalsDto) => {
        this.updateUnreadTotalsFromDto(totals);
        // eslint-disable-next-line default-case
        switch (notification.notificationArea) {
          case NotificationAreaDto.Initiative:
            this.initiativeNotifications.pushFirst(notification);
            break;
          case NotificationAreaDto.Idea:
            this.ideaNotifications.pushFirst(notification);
            break;
          case NotificationAreaDto.DirectMention:
            this.directMentionNotifications.pushFirst(notification);
            break;
        }
        this.notificationService.showNotification("open", notification.targetTitle, notification.title);
      }
    );
    this.notificationHubConncetion.start().catch(err => console.log(err));
  };

  public async readNotification(id: string) {
    await this.notificationApiService.markNotificationAsRead({ notificationId: id });
    this.updateUnreadTotals();
  }

  public getInitiativeNotifications = async (page: number, results?: number) =>
    await this.notificationApiService.getInitiativeNotifications({ page, results });

  public getIdeaNotifications = async (page: number, results?: number) =>
    await this.notificationApiService.getIdeaNotifications({ page, results });

  public getDirectMentionNotifications = async (page: number, results?: number) =>
    await this.notificationApiService.getDirectMentionNotifications({ page, results });

  public async initLoadAll() {
    await Promise.all([
      this.initiativeNotifications.initLoad(),
      this.ideaNotifications.initLoad(),
      this.directMentionNotifications.initLoad(),
    ]);
  }

  private updateUnreadTotalsFromDto = (totals: UnreadNotificationTotalsDto) => {
    this.totalUnread = totals.all;
    this.initiativeNotifications.totalUnread = totals.initiative;
    this.ideaNotifications.totalUnread = totals.idea;
    this.directMentionNotifications.totalUnread = totals.directMention;
  };
}
