import { Divider } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../shared/button/Button";
import { Field, Form } from "../../../shared/form/Form";
import { TextInput } from "../../../shared/textInput/TextInput";
import { EditUserDataViewModel } from "./EditUserDataViewModel";

interface EditUserDataViewProps {
  model: EditUserDataViewModel;
}

export const EditUserDataView: React.FC<EditUserDataViewProps> = observer(({ model }: EditUserDataViewProps) => {
  return (
    <div className="EditUserView">
      <div className="EditUserView__Title">
        <SvgIcon iconName="avatar" />
        General information
      </div>
      <Form className="EditUserView__Content EditUserView__Form" layout="vertical" form={model.form}>
        {fields => (
          <>
            <Field field={fields.name} label="Full name">
              {props => <TextInput {...props} />}
            </Field>
            <Field field={fields.jobTitle} label="Job title">
              {props => <TextInput placeholder="Job title" {...props} />}
            </Field>
            <Divider />
            <Field field={fields.email} label="E-mail address">
              {props => <TextInput disabled value={props.value} />}
            </Field>
          </>
        )}
      </Form>
      <div className="EditUserView__submit">
        <CommandButton command={model.submit} type="primary" size="large">
          Save changes
        </CommandButton>
      </div>
    </div>
  );
});
