import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { BreadcrumbView } from "../../shared/breadcrumb/BreadcrumbView";
import { UserProfileViewModel } from "./UserProfileViewModel";
import { EditUserDataView } from "./editUserData/EditUserDataView";
import { EditUserPhotoView } from "./editUserPhoto/EditUserPhotoView";

export const UserProfileView = observer(({ model }: { model: UserProfileViewModel }) => {
  return (
    <>
      <div className="UserProfileView__header">
        <BreadcrumbView items={[{ title: "Profile" }]} />
      </div>
      <Row gutter={[48, 0]}>
        <Col flex="1 0 60%">
          {model.editUserDataViewModel && <EditUserDataView model={model.editUserDataViewModel} />}
        </Col>
        <Col flex="1 0 40%">
          {model.editUserPhotoViewModel && <EditUserPhotoView model={model.editUserPhotoViewModel} />}
        </Col>
      </Row>
    </>
  );
});
