import { notification } from "antd";
import { NotificationInstance } from "antd/lib/notification";

export interface INotificationService {
  showNotification: (type: keyof NotificationInstance, message: string, description?: string) => void;
}

export class NotificationService implements INotificationService {
  // eslint-disable-next-line class-methods-use-this
  showNotification(type: keyof NotificationInstance, message: string, description?: string) {
    notification[type]({ message, description });
  }
}
