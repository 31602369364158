import { Card, Result, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../shared/button/Button";
import { Field, Form } from "../../shared/form/Form";
import { TextInput } from "../../shared/textInput/TextInput";
import { LoginViewModel } from "./LoginViewModel";

export const LoginView = observer(({ model }: { model: LoginViewModel }) => {
  const renderInfo = (isLoading: boolean, isError: boolean, isNewUser: boolean, isSettingUserName: boolean) => {
    if (isError) {
      return (
        <Result
          status="403"
          title="Oops! Something went wrong."
          subTitle="Your token is not valid or has been expired."
        />
      );
    }
    if (isLoading) {
      return (
        <>
          <p>Please wait, we are trying to log you in... </p>
          <div>
            <Spin size="large" />
          </div>
        </>
      );
    }
    if (isNewUser && !isSettingUserName) {
      return (
        <>
          <p>First time here? Please introduce yourself</p>
          <Form layout="vertical" className="UsernameForm" form={model.form}>
            {fields => (
              <>
                <Field field={fields.name} label="Username">
                  {props => <TextInput placeholder="Type username..." {...props} />}
                </Field>
              </>
            )}
          </Form>
          <CommandButton command={model.submit} type="primary" size="large">
            Let me in
          </CommandButton>
        </>
      );
    }
    return (
      <>
        <p>Please wait, we are trying to log you in... </p>
        <div>
          <Spin size="large" />
        </div>
      </>
    );
  };

  return (
    <>
      <nav className="SigInNav">
        <Title level={1}>
          <SvgIcon iconName="bulb" /> Ideamate
        </Title>
      </nav>
      <div className="SignInContainer">
        <Card>
          <div className="SignInContainer__card">
            {renderInfo(model.isLoading, model.isError, model.userWithoutName, model.isSettingUserName)}
          </div>
        </Card>
      </div>
    </>
  );
});
