import { Col, Divider, Row } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Comment } from "../../../../shared/stories/comment/Comment";

interface ISystemCommentViewProps {
  comment: Comment;
  className?: string;
}

export const SystemCommentView: React.FunctionComponent<ISystemCommentViewProps> = observer(props => {
  const {
    comment: { text },
  } = props;

  return (
    <div className="SystemCommentView">
      <Row wrap={false} gutter={[8, 0]}>
        <Col flex="1 1 50px" className="flex-item"><Divider /></Col>
        <Col className="flex-item text">{text}</Col>
        <Col flex="1 1 50px" className="flex-item">       <Divider/></Col>
      </Row>

    </div>
  )
  

});
