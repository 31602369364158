import { route } from "react-mvvm";
import { App } from "./pages/App";
import { addInitiativeListRouters } from "./pages/Initiatives/addInitiativeRouters";
import { addAccessRequestRouter } from "./pages/accessRequest/addRequestAccessRouters";
import { addLoginRouter, addSignRouter } from "./pages/login/addLoginRouters";
import { addNotificationsRouters } from "./pages/notifications/addNotificationsRouter";
import { addUserProfileRouter } from "./pages/userProfile/addUserProfileRouter";
import { addWorkspaceRouter } from "./pages/workspaces/addWorkspaceRouters";
import { BackendApi } from "./shared/api/BackendApi";
import { notificationHubConnection } from "./shared/api/hubConnections";
import { ErrorService } from "./shared/services/ErrorService";
import { NavigationService } from "./shared/services/NavigationService";
import { NotificationService } from "./shared/services/NotificationService";
import { UrlService } from "./shared/services/UrlService";
import { RootStore } from "./shared/stories/RootStore";

export const appRoute = route(async () => {
  const notification = new NotificationService();
  const errorService = new ErrorService(notification);
  const navigationService = new NavigationService(UrlService);
  const backendApi = new BackendApi(navigationService, errorService);
  const rootStore = await RootStore.create(backendApi, notificationHubConnection, notification);

  const app = new App(navigationService, rootStore, errorService);
  backendApi.initErrorInterceptors(errorService);
  return app;
});

addInitiativeListRouters(appRoute);
addLoginRouter(appRoute);
addSignRouter(appRoute);
addNotificationsRouters(appRoute);
addUserProfileRouter(appRoute);
addWorkspaceRouter(appRoute);
addAccessRequestRouter(appRoute);
