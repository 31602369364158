import { computed, observable } from "mobx";
import { WorkspaceMemberDto } from "src/types/initiatives/dto/WorkspaceMemberDto";
import { WorkspaceRole } from "src/types/ly/application/WorkspaceRole";
import { isNullOrWhitespace } from "../../utils";

export interface IWorkspaceMember {
  id: string;
  name: string;
  email: string;
  jobTitle?: string;
  photoId?: string;
  avatarId?: string;
  workspaceRole: WorkspaceRole;
}

export class WorkspaceMember implements IWorkspaceMember {
  @observable id = "";

  @observable name = "";

  @observable email = "";

  @observable jobTitle?: string = "";

  @observable photoId?: string = "";

  @observable avatarId?: string = "";

  @observable workspaceRole: WorkspaceRole = WorkspaceRole.Member;

  @computed get displayName() {
    return isNullOrWhitespace(this.name) ? this.email : this.name;
  }

  update(dto: WorkspaceMemberDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.email = dto.email;
    this.jobTitle = dto.jobTitle;
    this.photoId = dto.photoId;
    this.avatarId = dto.avatarId;
    this.workspaceRole = dto.workspaceRole;
  }
}
