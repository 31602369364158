import { action, computed, observable } from "mobx";
import { bindableForm, Command, command, RequiredFieldValidator } from "react-mvvm";
import { IdeaDetail } from "src/shared/stories/idea/Idea";
import { IdeaStore } from "src/shared/stories/idea/IdeaStore";
import { v4 as uuid } from "uuid";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { Initiative } from "../../../../shared/stories/initiative/Initiative";
import { MAX_IDEA_TITLE_LENGTH, MAX_DESCRIPTION_LENGTH } from "../../../../shared/validators/LengthValidatorsConst";
import { MaxLengthValidator } from "../../../../shared/validators/MaxLengthValidator";
import { RichTextLengthValidator } from "../../../../shared/validators/RichTextLengthValidator";
import { combineValidators } from "../../../../shared/validators/combineValidators";
import { AttachmentDto } from "../../../../types/initiatives/dto/AttachmentDto";
import { CreateIdeaModel } from "./CreateIdeaModel";

type CreateIdeaModelOnSuccessMethod = (idea: IdeaDetail) => Promise<void>;

export class CreateIdeaDialogViewModel extends DialogViewModel<Initiative> {
  @observable.ref createIdeaModel = {
    title: "",
    description: "",
    attachments: [] as AttachmentDto[],
  };

  cancel: Command;

  submit: Command<void, Promise<void>>;

  initiative?: Initiative;

  form = bindableForm<CreateIdeaModel>()
    .addField("title", {
      validator: v => combineValidators([RequiredFieldValidator(v), MaxLengthValidator(v, MAX_IDEA_TITLE_LENGTH)]),
    })
    .addField("description", {
      validator: v => combineValidators([RichTextLengthValidator(v, MAX_DESCRIPTION_LENGTH)]),
    })
    .addField("attachments")
    .bindTo(() => this.createIdeaModel);

  constructor(private onSuccess: CreateIdeaModelOnSuccessMethod, ideaStore: IdeaStore) {
    super();

    this.cancel = command(() => {
      this.hide();
    });
    this.submit = command(async () => {
      if (!(await this.form.validateAndCommit())) {
        return;
      }

      if (!this.initiative) {
        return;
      }

      const idea = ideaStore.createIdea();
      idea.title = this.createIdeaModel.title;
      idea.description = this.createIdeaModel.description;
      idea.attachments = this.createIdeaModel.attachments;
      idea.id = uuid();
      idea.initiativeId = this.initiative.id;

      await this.onSuccess(idea);
      await this.initiative.updateInitiative();
    });
  }

  @computed get getIdeaAttachements() {
    return this.createIdeaModel.attachments;
  }

  @action addToIdeaAttachments = (file: AttachmentDto) => {
    // TODO Check for duplication
    this.form.fields.attachments.value = this.form.fields.attachments.value?.concat(file);
  };

  async show(model: Initiative) {
    this.createIdeaModel = {
      title: "",
      description: "",
      attachments: [],
    };
    this.form.reset();
    this.initiative = model;
    await super.show();
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}
}
