import { computed, observable } from "mobx";
import moment from "moment";
import { Command } from "react-mvvm";
import { AttachmentDto } from "src/types/initiatives/dto/AttachmentDto";
import { InitiativeListItemDto } from "src/types/initiatives/dto/InitiativeListItemDto";
import { InitiativeStateDto } from "src/types/initiatives/dto/InitiativeStateDto";
import { InitiativeStateNameDto } from "src/types/initiatives/dto/InitiativeStateNameDto";
import { InitiativeDto } from "../../../types/initiatives/dto/InitiativeDto";
import { VotingMethodDto } from "../../../types/shared/dto/VotingMethodDto";
import { DEFAULT_WORKSPACE_COLOR } from "../../Constants";
// eslint-disable-next-line import/no-cycle
import { IdeaDetail, IdeaListItem } from "../idea/Idea";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { InitiativeStore } from "./InitiativeStore";

const { htmlToText } = require("html-to-text");

// remove when frontend support workspaces
const PUBLIC_DEFAULT_WORKSPACE_ID = "64656661-756c-7477-6f72-6b7370616365";

export class InitiativeOverview {
  @observable id = "";

  @observable ownerId = "";

  @observable ownerName = "";

  @observable ownerEmail = "";

  @observable title = "";

  @observable description = "";

  @observable initiativeOwner = "";

  @observable submissionStartDate = new Date().toISOString();

  @observable submissionEndDate = new Date().toISOString();

  @observable votingStartDate = new Date().toISOString();

  @observable votingEndDate = new Date().toISOString();

  @observable createdAt = new Date().toISOString();

  @observable state: InitiativeStateDto = {
    name: InitiativeStateNameDto.Planning,
    isFinal: false,
  };

  @observable votingMethod: VotingMethodDto = VotingMethodDto.Dots;

  @observable participantsCount = 0;

  @observable ideasCount = 0;

  @observable commentsCount = 0;

  @observable isCurrentUserSubscribed = false;

  @observable themeColor = DEFAULT_WORKSPACE_COLOR;

  @observable private _isPersist = false;

  editSubscription: Command;

  constructor(public store: InitiativeStore, public userStore: UserStore) {
    this.editSubscription = new Command(async () => {
      await this.store.editSubscription(this.id, !this.isCurrentUserSubscribed);

      this.isCurrentUserSubscribed = !this.isCurrentUserSubscribed;
    });
  }

  @computed get hasVotingStarted() {
    return moment(this.votingStartDate).isSameOrBefore(moment());
  }

  @computed get hasSubmissionStarted() {
    return moment(this.submissionStartDate).isSameOrBefore(moment());
  }

  @computed get getOwnerName() {
    return this.ownerName ? this.ownerName : this.ownerEmail;
  }

  @computed get isArchived() {
    return this.state.name === InitiativeStateNameDto.Archived;
  }

  @computed get descriptionPlainText() {
    return htmlToText(this.description, {
      selectors: [{ selector: "img", format: "skip" }],
    });
  }

  updateListFromServer(dto: InitiativeListItemDto) {
    this.id = dto.id;
    this.ownerName = dto.ownerName;
    this.ownerEmail = dto.ownerEmail;
    this.ownerId = dto.ownerId;
    this.title = dto.title;
    this.description = dto.description;
    this.submissionStartDate = dto.submissionStartDate;
    this.submissionEndDate = dto.submissionEndDate;
    this.votingStartDate = dto.votingStartDate;
    this.votingEndDate = dto.votingEndDate;
    this.votingMethod = dto.votingMethod;
    this.ideasCount = dto.ideasCount;
    this.participantsCount = dto.participantsCount;
    this.commentsCount = dto.commentsCount;
    this.state = dto.state;
    this.isCurrentUserSubscribed = dto.isCurrentUserSubscribed;
    this.createdAt = dto.createdAt;
    this._isPersist = true;
  }
}

export class Initiative {
  @observable title = "";

  @observable description = "";

  @observable ownerId;

  @observable submissionStartDate = new Date().toISOString();

  @observable submissionEndDate = new Date().toISOString();

  @observable votingStartDate = new Date().toISOString();

  @observable votingEndDate = new Date().toISOString();

  @observable votingMethod = VotingMethodDto.Dots;

  @observable votesPerPoll = 12;

  @observable votesLeftPerCurrentUserCount = 0;

  @observable maxVotesPerPollOption = 3;

  @observable userMaxVotedForPoll = 0;

  @observable userMaxVotedForPollOption = 0;

  @observable participantsCount = 0;

  @observable ideasCount = 0;

  @observable hideIdeaSubmitter = false;

  @observable state: InitiativeStateDto = {
    name: InitiativeStateNameDto.Planning,
    isFinal: false,
  };

  @observable ideas: IdeaDetail[] = [];

  @observable attachments: AttachmentDto[] = [];

  @observable isCurrentUserSubscribed = false;

  @observable tags: string[] = [];

  @observable workspaceId = "";

  @observable workspaceName = "";

  @observable totalVotesCount = 0;

  @observable themeColor = DEFAULT_WORKSPACE_COLOR;

  @observable private _isPersist = false;

  coOwnersIds: string[] = [];

  createdAt = new Date().toISOString();

  closeInitiative: Command;

  resetInitiative: Command;

  archiveInitiative: Command;

  unarchiveInitiative: Command;

  setImplementation: Command;

  editSubscription: Command;

  constructor(public store: InitiativeStore, public userStore: UserStore, public id: string) {
    this.ownerId = userStore.loggedUser.id;

    this.setImplementation = new Command(async () => {
      await this.store.setImplementation(this.id);

      this.state = {
        name: InitiativeStateNameDto.Implementation,
        isFinal: true,
      };
    });

    this.closeInitiative = new Command(async () => {
      await this.store.closeInitiative(this.id);

      this.state = {
        name: InitiativeStateNameDto.Closed,
        isFinal: true,
      };
    });

    this.resetInitiative = new Command(async () => {
      await this.store.resetInitiative(this);
      await this.store.getInitiative(this.id);
    });

    this.archiveInitiative = new Command(async () => {
      await this.store.archiveInitiative(this);
      await this.store.getInitiative(this.id);
    });

    this.unarchiveInitiative = new Command(async () => {
      await this.store.unarchiveInitiative(this);
      await this.store.getInitiative(this.id);
    });

    this.editSubscription = new Command(async () => {
      await this.store.editSubscription(this.id, !this.isCurrentUserSubscribed);
      this.isCurrentUserSubscribed = !this.isCurrentUserSubscribed;
    });
  }

  @computed get isPersist() {
    return this._isPersist;
  }

  @computed get isArchived() {
    return this.state.name === InitiativeStateNameDto.Archived;
  }

  @computed get isInFinalState() {
    return this.state.isFinal;
  }

  @computed get canUserEdit() {
    return this.ownerId === this.userStore.loggedUser.id || this.coOwnersIds.indexOf(this.userStore.loggedUser.id) > -1;
  }

  @computed get canUseMoveIdeas() {
    return this.ownerId === this.userStore.loggedUser.id || this.coOwnersIds.indexOf(this.userStore.loggedUser.id) > -1;
  }

  @computed get canUserReset() {
    return this.canUserEdit && !this.state.isFinal && !this.isArchived;
  }

  @computed get canUserArchive() {
    return this.canUserEdit && !this.state.isFinal && !this.isArchived;
  }

  @computed get canUserUnarchive() {
    return this.canUserEdit && this.isArchived;
  }

  @computed get canAddIdeas() {
    return this.hasSubmissionStarted && !this.hasSubmissionEnded && !this.isArchived && !this.isInFinalState;
  }

  @computed get hasVotingStarted() {
    return moment(this.votingStartDate).isSameOrBefore(moment(), "dates");
  }

  @computed get hasVotingEnded() {
    return moment(this.votingEndDate).isBefore(moment(), "dates");
  }

  @computed get hasSubmissionStarted() {
    return moment(this.submissionStartDate).isSameOrBefore(moment(), "dates");
  }

  @computed get hasSubmissionEnded() {
    return moment(this.submissionEndDate).isBefore(moment(), "dates");
  }

  @computed get isVotingPeriod() {
    return moment().isBetween(this.votingStartDate, this.votingEndDate, undefined, "[]");
  }

  async save() {
    if (this._isPersist) {
      await this.store.initiativeService.editInitiative({
        id: this.id,
        title: this.title,
        description: this.description,
        submissionStartDate: this.submissionStartDate,
        submissionEndDate: this.submissionEndDate,
        votingStartDate: this.votingStartDate,
        votingEndDate: this.votingEndDate,
        votesPerPoll: this.votesPerPoll,
        maxVotesPerPollOption: this.maxVotesPerPollOption,
        attachments: this.attachments,
        tags: this.tags,
      });
      await this.store.getInitiative(this.id);
    } else {
      await this.store.initiativeService.createInitiative({
        id: this.id,
        workspaceId: this.workspaceId,
        title: this.title,
        description: this.description,
        submissionStartDate: this.submissionStartDate,
        submissionEndDate: this.submissionEndDate,
        votingStartDate: this.votingStartDate,
        votingEndDate: this.votingEndDate,
        votingMethod: this.votingMethod,
        votesPerPoll: this.votesPerPoll,
        maxVotesPerPollOption: this.maxVotesPerPollOption,
        attachments: this.attachments,
        hideIdeaSubmitter: this.hideIdeaSubmitter,
        tags: this.tags,
      });
      this._isPersist = true;
    }
  }

  async removeIdea(idea: IdeaDetail | IdeaListItem) {
    if (idea.isPersist) {
      await this.store.initiativeService.removeIdea({ id: idea.id });
    } else {
      this.ideas = this.ideas.filter(i => i.id !== idea.id);
    }
  }

  async updateInitiative() {
    await this.store.getInitiative(this.id);
  }

  updateFromDto(dto: InitiativeDto) {
    this.id = dto.id;
    this.ownerId = dto.owner.id;
    this.participantsCount = dto.participantsCount;
    this.title = dto.title;
    this.description = dto.description;
    this.votesLeftPerCurrentUserCount = dto.votesLeftPerCurrentUserCount;
    this.submissionStartDate = dto.submissionStartDate;
    this.submissionEndDate = dto.submissionEndDate;
    this.votingStartDate = dto.votingStartDate;
    this.votingEndDate = dto.votingEndDate;
    this.createdAt = dto.createdAt;
    this.attachments = dto.attachments;
    this.votingMethod = dto.votingMethod;
    this.ideasCount = dto.ideasCount;
    this._isPersist = true;
    this.maxVotesPerPollOption = dto.maxVotesPerIdea;
    this.votesPerPoll = dto.maxVotesPerInitiative;
    this.userMaxVotedForPoll = dto.userMaxVotedForInitiative;
    this.userMaxVotedForPollOption = dto.userMaxVotedForIdea;
    this.coOwnersIds = dto.coOwnerIds;
    this.state = dto.state;
    this.isCurrentUserSubscribed = dto.isCurrentUserSubscribed;
    this.hideIdeaSubmitter = dto.hideIdeaSubmitter;
    this.tags = dto.tags;
    this.workspaceId = dto.workspaceId;
    this.workspaceName = dto.workspaceName;
    this.themeColor = dto.themeColor ?? DEFAULT_WORKSPACE_COLOR;
    this.totalVotesCount = dto.totalVotesCount;
  }
}
