import { Empty, List, ListProps } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { CommandButton } from "../button/Button";
import { isItemLoaded } from "./ListItem";
import { LoadMoreList } from "./LoadMoreList";

interface LoadMoreListViewProps<T extends { id: string | number }> extends ListProps<T> {
  loadMoreList: LoadMoreList<T>;
  skeleton?: React.ReactNode;
  className?: string;
  dataTour?: string;
  hideDefaultLoadMoreBtn?: boolean;
  emptyText?: string;
  hideLoading?: boolean;
}

export const LoadMoreListView = observer(
  <T extends { id: string | number }>({
    loadMoreList,
    renderItem,
    skeleton,
    className,
    grid,
    dataTour,
    hideDefaultLoadMoreBtn,
    emptyText,
    hideLoading
  }: LoadMoreListViewProps<T>) => {
    return (
      <List
        grid={grid}
        loading={!hideLoading && loadMoreList.paginatedList.isLoading}
        className={className}
        dataSource={loadMoreList.paginatedList.items.concat()}
        data-tour={dataTour}
        loadMore={
          !hideDefaultLoadMoreBtn && (
            <CommandButton
              type="link"
              command={loadMoreList.paginatedList.loadNextPage}
              className="LoadMoreList__loadMoreButton"
            >
              Show more
            </CommandButton>
          )
        }
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText ?? "No data"} /> }}
        renderItem={(item, index) => {
          return isItemLoaded(item) ? renderItem && renderItem(item as T, index) : skeleton;
        }}
      />
    );
  }
);
