import { IRoute } from "react-mvvm";
import { App } from "../App";
import { IdeaViewModel } from "./idea/IdeaViewModel";
import { InitiativeViewModel } from "./initiative/InitiativeViewModel";

export type InitiativeParams = { initiativeId: string };
export type IdeaParams = { ideaId: string };

export const addInitiativeListRouters = (route: IRoute<App, never, never>) => {
  // ** IMPORTANT NOTE **: The Board page is no longer available when the workspaces feature is enabled because we are not using the initiatives page.
  //    The Board page worked in the same way as the initiatives page.
  //    We will consider using this page in the future.

  // initiativeListRoute.addRoute<BoardViewModel>("/board", BoardViewModel, appPage => {
  //   const board = new BoardViewModel(
  //     appPage.initiativeStore,
  //     appPage.voteStore,
  //     appPage.userStore,
  //     appPage.commentStore,
  //     appPage.errorService,
  //     appPage.ideaStore,
  //     appPage.showInitiative,
  //   );
  //   return appPage.showChildPage(board);
  // });

  const initiativeRoute = route.addRoute<InitiativeViewModel, InitiativeParams>(
    "/initiatives/:initiativeId",
    InitiativeViewModel,
    (app, p) => {
      const initiative = new InitiativeViewModel(
        p.initiativeId,
        app.rootStore.initiativeStore,
        app.rootStore.voteStore,
        app.rootStore.userStore,
        app.rootStore.commentStore,
        app.errorService,
        app.rootStore.ideaStore,
        app.rootStore.fileStore,
        app.navigationService
      );

      return app.showChildPage(initiative);
    },
    page => ({ initiativeId: page.initiativeId })
  );

  initiativeRoute.addRoute<IdeaViewModel, IdeaParams>(
    "/ideas/:ideaId",
    IdeaViewModel,
    (initiativeListPage, p) => {
      const idea = new IdeaViewModel(
        p.ideaId,
        initiativeListPage.initiativeId,
        initiativeListPage.initiativeStore,
        initiativeListPage.voteStore,
        initiativeListPage.commentStore,
        initiativeListPage.userStore,
        initiativeListPage.errorService,
        initiativeListPage.ideaStore,
        initiativeListPage.fileStore,
        initiativeListPage.navigationService
      );

      return initiativeListPage.showChildPage(idea);
    },
    page => ({ ideaId: page.ideaId })
  );
};

export const getIdeaPath = (initiativeId: string, ideaId: string) => `/initiatives/${initiativeId}/ideas/${ideaId}`;
