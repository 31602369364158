import { Button, Select, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React, { FC, useMemo, useState } from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { SearchInput, SearchOption } from "../../../../shared/searchInput/SearchInput";
import { IWorkspaceMember } from "../../../../shared/stories/workspace/WorkspaceMember";
import { UserAvatar } from "../../../../shared/userAvatar/UserAvatar";
import { WorkspaceRole } from "../../../../types/ly/application/WorkspaceRole";
import { UserDto } from "../../../../types/shared/dto/UserDto";

interface WorkspaceMemberListProps {
  workspaceMembers: IWorkspaceMember[];
  roleChanged?: (value: WorkspaceRole, target: IWorkspaceMember) => void;
  onChange?: (members: IWorkspaceMember[]) => void;
  isPrivate?: boolean;
  findUsers?: (text: string) => Promise<UserDto[]>;
  currentUser?: UserDto;
  isReadOnly?: boolean;
}

interface WorkspaceMemberProps {
  workspaceMember: IWorkspaceMember;
  key?: string;
  onChange?: (value: WorkspaceRole, target: IWorkspaceMember) => void;
  isPrivateWorkspace: boolean;
  onRemove: () => void;
  canEdit: boolean;
}

function RoleChangeDisabledToReason(userRole: WorkspaceRole, isPrivateWorkspace: boolean, canUserEdit: boolean) {
  if (!canUserEdit) return "You don't have permission to edit this user's role.";

  if (userRole === WorkspaceRole.Owner) return "You cannot change the workspace owner.";

  if (!isPrivateWorkspace)
    return 'In Public Workspaces, you can only assign explicitly Co-owners, as by default everyone has "Read" access.';

  return undefined;
}

const WorkspaceMember: FC<WorkspaceMemberProps> = ({
  workspaceMember,
  onChange,
  isPrivateWorkspace,
  onRemove,
  canEdit,
}) => {
  const [workspaceRole, setWorkspaceRole] = React.useState(workspaceMember.workspaceRole);

  const changeDisabledReason = RoleChangeDisabledToReason(workspaceMember.workspaceRole, isPrivateWorkspace, canEdit);
  const isChangeDisabled = !!changeDisabledReason;

  return (
    <div className="WorkspaceMemberList__userCard">
      <UserAvatar userName={workspaceMember.name ?? workspaceMember.email} avatarId={workspaceMember.avatarId} />
      <Text>{workspaceMember.name ?? workspaceMember.email}</Text>

      <Tooltip placement="bottom" title={changeDisabledReason}>
        <Select
          options={[{ value: WorkspaceRole.Member }, { value: WorkspaceRole.CoOwner, label: "Co-Owner" }]}
          value={workspaceRole}
          disabled={isChangeDisabled}
          onChange={x => {
            setWorkspaceRole(x);
            onChange && onChange(x, workspaceMember);
          }}
          bordered={false}
        />
      </Tooltip>

      {workspaceMember.workspaceRole !== WorkspaceRole.Owner && canEdit && (
        <Button icon={<SvgIcon iconName="x" />} onClick={onRemove} />
      )}
    </div>
  );
};

export const WorkspaceMemberList: FC<WorkspaceMemberListProps> = ({
  workspaceMembers,
  isPrivate = false,
  roleChanged,
  onChange,
  findUsers,
  currentUser,
  isReadOnly = true,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [options, setOptions] = React.useState<IWorkspaceMember[]>([]);
  const [selected, setSelected] = React.useState<IWorkspaceMember[]>(workspaceMembers ?? []);
  const inviteUserOptionId = "inviteUserOption";

  if (!isPrivate)
    workspaceMembers.forEach(x => {
      // eslint-disable-next-line no-param-reassign
      x.workspaceRole = x.workspaceRole === WorkspaceRole.Member ? WorkspaceRole.CoOwner : x.workspaceRole;
    });

  React.useEffect(() => setSelected(workspaceMembers), [workspaceMembers]);

  const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

  const dropdownOptions = useMemo(() => {
    const showInviteUserOption =
      isValidEmail(searchText) &&
      !options
        .filter((x): x is IWorkspaceMember => true)
        .map(x => x.email)
        .includes(searchText) &&
      !selected.map(x => x.email).includes(searchText);
    let result: SearchOption<IWorkspaceMember>[] = options
      .filter((o): o is IWorkspaceMember => true)
      .filter(o => !selected.map(x => x.email).includes(o.email))
      .map(o => ({ value: o, id: o.id }));
    if (showInviteUserOption)
      result = [
        {
          value: {
            id: inviteUserOptionId,
            email: searchText,
            name: searchText,
            workspaceRole: isPrivate ? WorkspaceRole.Member : WorkspaceRole.CoOwner,
          },
          render: (s: IWorkspaceMember) => `Invite: ${s.email}`,
          id: searchText,
        },
        ...result,
      ];
    return result;
  }, [options, selected]);

  return (
    <div className="WorkspaceMemberList">
      {!isReadOnly && (
        <SearchInput
          options={dropdownOptions}
          onSelect={(user: IWorkspaceMember) => {
            if (user.id === inviteUserOptionId) {
              DialogSupport.showConfirmDialog({
                title: "Are you sure?",
                content: "The user will get also access to all Public Workspaces",
                okText: "Share",
                onOk: () => onChange && onChange([...selected, { ...user, id: user.email }]),
              });
            } else if (!selected.includes(user)) onChange && onChange([...selected, user]);
          }}
          loading={isSearching}
          onSearch={async text => {
            if (!findUsers) return;

            setIsSearching(true);

            setOptions(
              (await findUsers(text)).map(x => ({
                ...x,
                workspaceRole: WorkspaceRole.Member,
              }))
            );

            setIsSearching(false);
          }}
          inputValue={searchText}
          onInputValueChange={v => setSearchText(v)}
          renderOption={v => (
            <div>
              <UserAvatar userName={v.name ?? v.email} size="default" avatarId={v.avatarId} />
              <Text>{v.name ?? v.email}</Text>
            </div>
          )}
          getOptionInputValue={v => v.email}
          clearOnSelect
        />
      )}
      <div className="CreateWorkspaceDialog__selectedMembers">
        {selected.map(x => (
          <WorkspaceMember
            workspaceMember={x}
            key={x.id}
            onChange={roleChanged}
            isPrivateWorkspace={isPrivate}
            onRemove={() => onChange && onChange(selected.filter(s => s !== x))}
            canEdit={!isReadOnly && !!currentUser && x.id !== currentUser.id}
          />
        ))}
      </div>
    </div>
  );
};
