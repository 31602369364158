import { Typography } from "antd";
import * as React from "react";
import { INT_32_MAX_VALUE } from "../../../../../../shared/Constants";
import { DateFormatter } from "../../../../../../shared/DateFormatter";
import { VotingMethodIcon } from "../../../../../../shared/VotingMethodIcon/VotingMethodIcon";
import { RichTextViewer } from "../../../../../../shared/richText/RichTextViewer";
import { VotingMethodDto } from "../../../../../../types/shared/dto/VotingMethodDto";
import { TagList } from "../../../../shared/tags/Tags";
import { CreateInitiativeSummaryStepViewModel } from "./CreateInitiativeSummaryStepViewModel";

interface ICreateInitiativeSummaryStepProps {
  model: CreateInitiativeSummaryStepViewModel;
  className?: string;
}

const { Title, Text } = Typography;

export const CreateInitiativeSummaryStep: React.FunctionComponent<ICreateInitiativeSummaryStepProps> = ({ model }) => {
  const { createInitiativeModel } = model;

  const formatDate = (date?: string) => {
    return date ? DateFormatter.format(date, "MMM DD, YYYY") : "";
  };

  return (
    <div className="CreateInitiativeDialog__summary">
      <Title level={2}>{createInitiativeModel.title}</Title>
      <Title level={3}>Description</Title>
      <RichTextViewer content={createInitiativeModel.description} />
      <div className="CreateInitiativeDialog__tags">
        <TagList tags={createInitiativeModel.tags} />
      </div>
      <Title level={3}>Timeframe</Title>
      <div className="CreateInitiativeDialog__summaryDates">
        <Text>
          Collecting ideas:
          <br />
          <strong>
            {` ${formatDate(createInitiativeModel.submissionDateRange?.startDate)} - ${formatDate(
              createInitiativeModel.submissionDateRange?.endDate
            )}`}
          </strong>
        </Text>
        <Text>
          Voting:
          <br />
          <strong>
            {` ${formatDate(createInitiativeModel.votingDateRange?.startDate)} - ${formatDate(
              createInitiativeModel.votingDateRange?.endDate
            )}`}
          </strong>
        </Text>
      </div>
      <div className="CreateInitiativeDialog__summaryVotes">
        <Title level={3}>
          Voting with{" "}
          <VotingMethodIcon
            isVoted={false}
            votingMethod={createInitiativeModel.votingMethod}
            className="CreateInitiativeDialog__icon"
          />
        </Title>
        <Text>
          <strong>
            {createInitiativeModel.votesPerInitiative !== INT_32_MAX_VALUE
              ? createInitiativeModel.votesPerInitiative
              : "Unlimited"}
          </strong>{" "}
          {createInitiativeModel.votingMethod} per initiative
          {createInitiativeModel.votingMethod !== VotingMethodDto.Likes && (
            <>
              , <strong>{createInitiativeModel.maxVotesPerIdea}</strong> {createInitiativeModel.votingMethod} per idea
            </>
          )}
        </Text>
      </div>
    </div>
  );
};
