import { action, computed, observable } from "mobx";
import moment from "moment";
import { command } from "react-mvvm";
import { CommentStore } from "src/shared/stories/comment/CommentStore";
import { Initiative } from "src/shared/stories/initiative/Initiative";
import { IdeaDetail } from "../../../shared/stories/idea/Idea";
import { IdeaStateNameDto } from "../../../types/initiatives/dto/IdeaStateNameDto";

export class IdeaDetailViewModel {
  @observable initiative: Initiative;

  @observable investment = this.loggedUserVoteAmount;

  id = this.idea.id;

  submittedById = this.idea.submittedBy.id;

  createdAt = this.idea.createdAt;

  addVotes = command<number, Promise<void>>(
    async value => {
      this.initiative.votesLeftPerCurrentUserCount -= value;
      await this.idea.addVote.execute(value);
      await this.initiative.store.getInitiative(this.initiative.id);
    },
    () => this.canAddVote
  );

  removeVotes = command<number, Promise<void>>(
    async value => {
      this.initiative.votesLeftPerCurrentUserCount += value;
      await this.idea.remoteVote.execute(value);
    },
    () => this.canRemoveVote
  );

  removeIdea = command<void, Promise<void>>(
    async () => {
      this.initiative.votesLeftPerCurrentUserCount += this.loggedUserVoteAmount;
      this.initiative.ideasCount -= 1;
      await this.idea.remove.execute();
    },
    () => this.canUserRemove
  );

  realizeIdea = command<void, Promise<void>>(
    async () => {
      await this.idea.release.execute();
      this.initiative.votesLeftPerCurrentUserCount += this.loggedUserVoteAmount;
    },
    () => this.canSetAsReleased
  );

  constructor(public idea: IdeaDetail, private commentStore: CommentStore, initiative: Initiative) {
    this.initiative = initiative;
  }

  @computed get title() {
    return this.idea.title;
  }

  @computed get description() {
    return this.idea.description;
  }

  @computed get submittedBy() {
    return this.idea.submittedBy;
  }

  @computed get submittedByName() {
    return this.submittedBy.name ? this.submittedBy.name : this.submittedBy.email;
  }

  @computed get isDeleted() {
    return this.idea.state.name === IdeaStateNameDto.Deleted;
  }

  @computed get canRemoveVote() {
    return (
      this.idea.userVotesCount > 0 &&
      !this.idea.isDeleted &&
      !this.idea.isReleased &&
      this.initiative.isVotingPeriod &&
      !this.idea.isArchived
    );
  }

  @computed get canAddVote() {
    return (
      this.idea.userVotesCount < this.initiative.maxVotesPerPollOption &&
      this.initiative.votesLeftPerCurrentUserCount > 0 &&
      !this.idea.isDeleted &&
      !this.idea.isReleased &&
      this.initiative.isVotingPeriod &&
      !this.idea.isArchived
    );
  }

  @computed get isReleased() {
    return this.idea.state.name === IdeaStateNameDto.Released;
  }

  @computed get releasedDate() {
    return this.idea.state.createdAt;
  }

  @computed get isArchived() {
    return this.idea.isArchived;
  }

  @computed get totalVoteAmount() {
    return this.idea.votesCount;
  }

  @computed get loggedUserVoteAmount() {
    return this.idea.userVotesCount;
  }

  @computed get isUserAddedAnyVotes() {
    return this.idea.userVotesCount > 0;
  }

  @computed get amountOfComment() {
    return this.idea.commentsCount;
  }

  @computed get votingMethod() {
    return this.initiative.votingMethod;
  }

  @computed get isRemovable() {
    return moment().isBetween(this.initiative.submissionStartDate, this.initiative.votingEndDate, "day", "[]");
  }

  @computed get isInvestAvailable() {
    return !this.addVotes.isEnabled && !this.removeVotes.isEnabled;
  }

  @computed get isInvestBtnAvailable() {
    return this.investment === this.loggedUserVoteAmount || (!this.addVotes.isEnabled && !this.removeVotes.isEnabled);
  }

  @computed get canUserEdit() {
    return (
      (this.initiative.userStore.loggedUser.id === this.submittedBy.id || this.initiative.canUserEdit) &&
      !this.initiative.hasSubmissionEnded &&
      !this.isDeleted &&
      !this.isReleased &&
      !this.idea.isArchived
    );
  }

  @computed get isAnonymous() {
    return this.initiative.hideIdeaSubmitter && this.initiative.userStore.loggedUser.id !== this.submittedBy.id;
  }

  @computed get canSetAsReleased() {
    return (
      (this.initiative.userStore.loggedUser.id === this.initiative.ownerId ||
        this.initiative.coOwnersIds.indexOf(this.initiative.userStore.loggedUser.id) > -1) &&
      !this.isDeleted &&
      !this.isReleased &&
      !this.idea.isArchived
    );
  }

  @computed get hasExtraActions() {
    return (
      (this.canUserEdit || this.canUserRemove || this.canSetAsReleased) &&
      !this.initiative.isArchived &&
      !this.initiative.isInFinalState
    );
  }

  @computed get canUserRemove() {
    return (
      !this.isDeleted &&
      (this.initiative.userStore.loggedUser.id === this.submittedBy.id ||
        this.initiative.userStore.loggedUser.id === this.initiative.ownerId ||
        this.initiative.coOwnersIds.indexOf(this.initiative.userStore.loggedUser.id) > -1) &&
      !this.isReleased &&
      this.isRemovable &&
      !this.idea.isArchived
    );
  }

  @computed get canUserMove() {
    return this.initiative.canUseMoveIdeas;
  }

  @computed get hasAnotherEditor() {
    return this.lastEditedById !== undefined && this.idea?.submittedBy?.id !== this.lastEditedById;
  }

  @computed get lastEditedById() {
    return this.idea.lastEditedBy?.submittedBy?.id;
  }

  @computed get lastEditedByName() {
    if (!this.idea.lastEditedBy) return "";
    return this.idea.lastEditedBy.submittedBy.name;
  }

  @computed get lastEditedByAvatarId() {
    if (!this.idea.lastEditedBy) return null;
    return this.idea.lastEditedBy.submittedBy.avatarId;
  }

  @computed get totalVotes() {
    return this.idea.votesCount || 0;
  }

  @computed get maxVotesPerIdea() {
    return this.initiative.maxVotesPerPollOption;
  }

  @computed get votesLeft() {
    return this.initiative.votesLeftPerCurrentUserCount;
  }

  @action.bound updateInvestment = (newValue?: number | string | null) => {
    newValue && !isNaN(+newValue) ? this.setInvestment(+newValue) : this.setInvestment(0);
  };

  @action.bound setInvestment = (newValue: number) => {
    this.investment = newValue;
  };

  @action.bound investmentButtonText = (newValue: number) => {
    if (newValue > this.loggedUserVoteAmount) return "+$";
    if (newValue < this.loggedUserVoteAmount) return "-$";
    return " $";
  };
}
