import { IFileService } from "src/shared/api/BackendApi";
import { FileDto } from "../../../types/filestores/dto/FileDto";

export class FileStore {
  private constructor(public readonly fileService: IFileService) {}

  static async create(fileService: IFileService) {
    const store = new FileStore(fileService);

    return store;
  }

  uploadFile(
    file: File,
    onFulfilled?: ((value: FileDto) => void) | undefined | null,
    onRejected?: ((reson: any) => void) | undefined | null
  ) {
    const promise = this.fileService.uploadFile(file);
    promise.then(reponse => (onFulfilled ? onFulfilled(reponse.data) : null), onRejected);
  }

  async downloadFile(id: string) {
    const file = await this.fileService.downloadFile(id);
    return file;
  }
}
