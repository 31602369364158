import { observable, untracked } from "mobx";
import { PaginatedList } from "../../../react-mvvm/dist";

export abstract class LoadMoreList<TItem extends { id: string | number }> {
  @observable public paginatedList: PaginatedList<TItem | { isLoading?: boolean; id: number }>;

  @observable public totalCount = 0;

  constructor(public pageSize: number) {
    this.paginatedList = new PaginatedList<TItem | { isLoading?: boolean; id: number }>(async options => {
      const loadingItems = [...new Array(this.pageSize)].map(() => ({ isLoading: true, id: 0 }));

      untracked(() => {
        this.paginatedList.items = [...this.paginatedList.items, ...loadingItems];
        this.totalCount += pageSize;
      });

      const result = await this.load({ page: options.page, results: options.resultsPerPage });

      untracked(() => this.paginatedList.removeItems(loadingItems));

      return result;
    }, pageSize);
  }

  public async reload(options: { page: number; results: number }) {
    await this.load(options);
  }

  protected abstract load(options: {
    page: number;
    results: number;
  }): Promise<{ items: TItem[]; hasNextPage: boolean }>;
}
