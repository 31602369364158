import { IRoute } from "react-mvvm";
import { App } from "../App";
import { AccessRequestReviewPageViewModel } from "./AccessRequestReviewPageViewModel";

export type RequestAccessParams = { resourceId: string };

export const addAccessRequestRouter = (route: IRoute<App, never, never>) => {
  route.addRoute<AccessRequestReviewPageViewModel, RequestAccessParams>(
    "/access-request/:resourceId",
    AccessRequestReviewPageViewModel,
    (appPage, params) => {
      const requestAccessPage = new AccessRequestReviewPageViewModel(
        appPage.errorService,
        appPage.rootStore.userStore,
        appPage.navigationService,
        params.resourceId
      );
      return appPage.showChildPage(requestAccessPage);
    }
  );
};
