import { Row, Col, Button, InputNumber } from "antd";
import * as React from "react";
import { INT_32_MAX_VALUE } from "../Constants";
import { SvgIcon } from "../SvgIcon/SvgIcon";

interface INumberInputProps {
  value: number;
  maxValue: number;
  minValue: number;
  onChange: (newValue: number) => void;
  step: number;
  postfix?: string;
  disabled?: boolean;
}

export const NumberInput: React.FunctionComponent<INumberInputProps> = ({
  value,
  maxValue,
  minValue,
  onChange,
  step,
  postfix,
  disabled = false,
}) => {
  const [InputValue, setValue] = React.useState(value);
  const helper = (newValue?: number | string | null) => {
    if (newValue && !isNaN(+newValue)) {
      setValue(+newValue - (+newValue % step));
      onChange(+newValue - (+newValue % step));
    } else {
      setValue(minValue);
      onChange(minValue);
    }
  };

  return (
    <>
      <Row justify="space-between" className="NumberInputContainer" align="middle">
        <Button
          type="primary"
          shape="circle"
          disabled={value <= minValue || disabled}
          onClick={() => {
            onChange(value - step);
            setValue(InputValue - step);
          }}
        >
          <SvgIcon iconName="minusOutlined" />
        </Button>
        <Col className="ValueContainer">
          <InputNumber
            min={minValue}
            max={maxValue}
            value={value}
            onBlur={e => helper(Number(e.target.value.replace(postfix ?? "", "")))}
            className="NumberInput"
            formatter={info => `${info && info >= INT_32_MAX_VALUE ? "∞" : info}${postfix && ` ${postfix}`}`}
            size="small"
            step={step}
            disabled={disabled}
          />
        </Col>
        <Button
          type="primary"
          shape="circle"
          disabled={value >= maxValue || disabled}
          onClick={() => {
            onChange(value + step);
            setValue(InputValue + step);
          }}
        >
          <SvgIcon iconName="plusOutlined" />
        </Button>
      </Row>
    </>
  );
};
