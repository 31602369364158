import { Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { WorkspaceMemberList } from "../../../../components/workspaceMemberList/WorkspaceMemberList";
import { CreateWorkspaceSummaryStepViewModel } from "./CreateWorkspaceSummaryStepViewModel";

interface ICreateWorkspaceSummaryStepProps {
  model: CreateWorkspaceSummaryStepViewModel;
  className?: string;
}

const { Title, Text } = Typography;

export const CreateWorkspaceSummaryStep: React.FunctionComponent<ICreateWorkspaceSummaryStepProps> = observer(
  ({ model, className }) => {
    const { createWorkspaceModel } = model;
    return (
      <div className={classNames("CreateWorkspaceDialog__summaryStep", className)}>
        <Title level={2}>{createWorkspaceModel.name}</Title>
        <Text>{createWorkspaceModel.description}</Text>
        <WorkspaceMemberList workspaceMembers={createWorkspaceModel.members} isReadOnly />
      </div>
    );
  }
);
