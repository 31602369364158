import { Spin } from "antd";
import classNames from "classnames";
import * as React from "react";

interface ActivityIndicatorProps {
  children: React.ReactNode;
  isLoading: boolean;
  className?: string;
}

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({ isLoading, className, children }) => {
  if (isLoading) {
    return (
      <div className={classNames("ActivityIndicator", className)}>
        <Spin size="large" />
      </div>
    );
  }
  return <>{children}</>;
};
