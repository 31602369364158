import { observable } from "mobx";
import { IViewModel } from "mobx-utils";
import { Command, command } from "react-mvvm";
import { InitiativeStore } from "src/shared/stories/initiative/InitiativeStore";
import { DateFormatter } from "../../../../shared/DateFormatter";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { FileStore } from "../../../../shared/stories/file/FileStore";
import { Initiative } from "../../../../shared/stories/initiative/Initiative";
import { VoteStore } from "../../../../shared/stories/vote/VoteStore";
import { EditInitiativeModel } from "./EditInitiativeModel";
import { EditInitiativeSteps } from "./EditInitiativeSteps";
import { EditInitiativeWizard } from "./editInitiativeWIzard/EditInitiativeWizard";

export class EditInitiativeDialogViewModel extends DialogViewModel<Initiative> {
  @observable.ref wizard?: EditInitiativeWizard;

  cancel: Command;

  constructor(
    private onSuccess: (initiative: Initiative & IViewModel<Initiative>) => Promise<void>,
    readonly initiative: Initiative & IViewModel<Initiative>,
    readonly fileStore: FileStore,
    private readonly initiativeStore: InitiativeStore,
    private readonly voteStore: VoteStore
  ) {
    super();

    this.cancel = command(() => {
      this.hide();
    });
  }

  async show(model: Initiative & IViewModel<Initiative>, editType: EditInitiativeSteps = "Basic") {
    const votingConfig = await this.voteStore.getVotingConfig();

    this.wizard = new EditInitiativeWizard(
      async editInitiativeModel => await this.editInitiative(editInitiativeModel),
      votingConfig,
      this.fileStore,
      this.initiativeStore,
      model,
      editType
    );

    await super.show();
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}

  private async editInitiative(editInitiativeModel: EditInitiativeModel): Promise<void> {
    this.initiative.description = editInitiativeModel.description;
    this.initiative.title = editInitiativeModel.title;
    this.initiative.submissionStartDate = DateFormatter.toStartTimeOfDayUTC(
      editInitiativeModel.submissionDateRange.startDate
    );
    this.initiative.submissionEndDate = DateFormatter.toEndTimeOfDayUTC(
      editInitiativeModel.submissionDateRange.endDate
    );
    this.initiative.votingStartDate = DateFormatter.toStartTimeOfDayUTC(editInitiativeModel.votingDateRange?.startDate);
    this.initiative.votingEndDate = DateFormatter.toEndTimeOfDayUTC(editInitiativeModel.votingDateRange?.endDate);
    this.initiative.votesPerPoll = editInitiativeModel.votesPerInitiative;
    this.initiative.maxVotesPerPollOption = editInitiativeModel.maxVotesPerIdea;
    this.initiative.votingMethod = editInitiativeModel.votingMethod;
    this.initiative.attachments = editInitiativeModel.attachments;
    this.initiative.hideIdeaSubmitter = editInitiativeModel.hideIdeaSubmitter;
    this.initiative.tags = editInitiativeModel.tags;

    try {
      await this.onSuccess(this.initiative);
    } catch {
      await this.initiativeStore.getInitiative(this.initiative.id);
    }
  }
}
