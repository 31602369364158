import { Tooltip } from "antd";
import * as React from "react";
import { IRadioButtonProps, RadioButton } from "../../../../../../../shared/RadioButton/RadioButton";
import { SvgIcon, IconType } from "../../../../../../../shared/SvgIcon/SvgIcon";

export interface IRadioButtonWithIconPros<T> extends IRadioButtonProps<T> {
  icon: IconType;
  label: string;
  dataTour?: string;
  tooltip?: string;
}

export const VotingTypeRadioButton = <T,>({
  value,
  className,
  disabled,
  icon,
  label,
  dataTour = "",
  tooltip,
}: IRadioButtonWithIconPros<T>) => {
  return (
    <div className="VotingTypeRadioButton" data-tour={dataTour}>
      <Tooltip placement="left" title={tooltip}>
        <div>
          <RadioButton value={value} disabled={disabled} className={className}>
            <SvgIcon iconName={icon} />
          </RadioButton>
        </div>
      </Tooltip>

      <span id={`${value}`} className="RadioButton__label">
        {label}
      </span>
    </div>
  );
};
