import React, { FC } from "react";
import { Command } from "react-mvvm/dist";
import { SvgIcon } from "../../../../../shared/SvgIcon/SvgIcon";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";

interface SubscribeIconButtonProps {
  command: Command;
  isSubscribed: boolean;
  tooltipPlacement: TooltipPlacement;
}

export const SubscribeIconButton: FC<SubscribeIconButtonProps> = ({ command, isSubscribed, tooltipPlacement }) => {
  return (
    <Tooltip placement={tooltipPlacement} title={isSubscribed ? "Unsubscribe" : "Subscribe"}>
      <div
        className="SubscribeIconButton"
        onClick={e => {
          command.execute();
          e.stopPropagation();
        }}
      >
        {isSubscribed ? <SvgIcon iconName="bellFilled" /> : <SvgIcon iconName="bellOutlined" />}
      </div>
    </Tooltip>
  );
};
