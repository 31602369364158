import { Tag } from "antd";
import classNames from "classnames";
import React, { FC, useMemo, useState } from "react";
import { SearchInput, SearchOption } from "../../../../shared/searchInput/SearchInput";

interface TagChipProps {
  tagText: string;
  closeable?: boolean;
  onClose?: () => void;
  clickable?: boolean;
  onClick?: () => void;
  selected?: boolean;
}

interface TagEditableListProps {
  options: string[];
  selected: string[];
  onSelectedChange: (selected: string[]) => void;
  onSearch: (searchText: string) => void;
  tagOptionsLoading: boolean;
}

interface TagListProps {
  tags: string[];
  className?: string;
}

export const TagChip: FC<TagChipProps> = ({ tagText, closeable, onClose, clickable, onClick, selected }) => {
  return (
    <div className={classNames("TagChip", selected && "TagChip--selected", clickable && "TagChip--clickable")}>
      <Tag closable={closeable} onClose={onClose} onClick={onClick}>
        #{tagText}
      </Tag>
    </div>
  );
};

export const TagList: FC<TagListProps> = ({ tags, className }) => {
  return (
    <div className={classNames("TagList", className)}>
      {tags.map(tag => (
        <TagChip key={tag} tagText={tag} />
      ))}
    </div>
  );
};

export const TagEditableList: FC<TagEditableListProps> = ({
  options,
  selected,
  onSelectedChange,
  onSearch,
  tagOptionsLoading,
}) => {
  const [searchText, setSearchText] = useState("");

  const dropdownOptions = useMemo(() => {
    const showCrateNewTagOption =
      searchText.length > 1 && !options.includes(searchText) && !selected.includes(searchText);
    let result: SearchOption<string>[] = options.filter(o => !selected.includes(o)).map(o => ({ value: o, id: o }));
    if (showCrateNewTagOption)
      result = [{ value: searchText, render: (s: string) => `Create: #${s}`, id: searchText }, ...result];
    return result;
  }, [options, selected]);

  const handleSelect = (tag: string) => {
    if (!selected.includes(tag)) onSelectedChange([...selected, tag]);
  };

  return (
    <div className="TagEditableList">
      <SearchInput
        options={dropdownOptions}
        onSelect={handleSelect}
        loading={tagOptionsLoading}
        onSearch={onSearch}
        inputValue={searchText}
        onInputValueChange={v => setSearchText(v)}
        renderOption={v => v}
        getOptionInputValue={v => v}
        clearOnSelect
      />
      <div className="selected-section">
        {selected.map(tag => (
          <TagChip
            key={tag}
            tagText={tag}
            closeable
            onClose={() => onSelectedChange(selected.filter(s => s !== tag))}
          />
        ))}
      </div>
    </div>
  );
};
