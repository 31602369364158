import { message } from "antd";
import { action, computed, observable, onBecomeObserved } from "mobx";
import { createViewModel } from "mobx-utils";
import { command, Command, Deferred, isLoaded } from "react-mvvm";
import { INavigationService } from "../../../shared/services/NavigationService";
import { FileStore } from "../../../shared/stories/file/FileStore";
import { InitiativeStateNameDto } from "../../../types/initiatives/dto/InitiativeStateNameDto";
import { CurrentUserDto } from "../../../types/shared/dto/CurrentUserDto";
import { VotingMethodDto } from "../../../types/shared/dto/VotingMethodDto";
import { NIL } from "uuid";
import { LoadMoreList } from "../../../shared/List/LoadMoreList";
import { Breadcrumb } from "../../../shared/breadcrumb/utils";
import { DialogSupport } from "../../../shared/dialogs/Dialogs";
import { IErrorService } from "../../../shared/services/ErrorService";
import { CommentStore } from "../../../shared/stories/comment/CommentStore";
import { IdeaListItem } from "../../../shared/stories/idea/Idea";
import { IdeaStore } from "../../../shared/stories/idea/IdeaStore";
import { Initiative } from "../../../shared/stories/initiative/Initiative";
import { InitiativeStore } from "../../../shared/stories/initiative/InitiativeStore";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { Poll, PollStatus } from "../../../shared/stories/vote/Poll";
import { VoteStore } from "../../../shared/stories/vote/VoteStore";
import { InitiativeWalkTour } from "../../../shared/walkTour/InitiativeWalkTour";
import { GetIdeaListSortType } from "../../../types/initiatives/dto/GetIdeaListSortType";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { BasePage } from "../../BasePage";
import { IdeaViewModel } from "../idea/IdeaViewModel";
import { EditInitiativeDialogViewModel } from "../initiativeList/editInitiativeDialog/EditInitiativeDialogViewModel";
import { EditInitiativeSteps } from "../initiativeList/editInitiativeDialog/EditInitiativeSteps";
import { IdeaListItemViewModel } from "../shared/IdeaListItemViewModel";
import { AddCoOwnerViewModel } from "../shared/addCoOwner/AddCoOwnerModel";
import { CreateIdeaDialogViewModel } from "../shared/createIdeaDialog/CreateIdeaDialogViewModel";
import { EditIdeaDialogViewModel } from "../shared/editIdeaDialog/EditIdeaDialogViewModel";
import { MoveIdeaDialogViewModel } from "../shared/moveIdeaDialog/MoveIdeaDialogViewModel";

export enum IdeasOrderBy {
  DateAsc,
  DateDesc,
  VotesCountDesc,
}

export class IdeaListViewModel extends LoadMoreList<IdeaListItemViewModel> {
  @observable orderBy = this.poll?.status === PollStatus.Closed ? IdeasOrderBy.VotesCountDesc : IdeasOrderBy.DateDesc;

  @observable filter = { userId: NIL, initiativeId: this.initiative.id };

  @observable statusFilter = { status: "" };

  constructor(
    private readonly initiative: Initiative,
    public readonly ideaStore: IdeaStore,
    public readonly commentStore: CommentStore,
    readonly pageSize = 20,
    private readonly navigationService: INavigationService,
    private readonly poll?: Poll
  ) {
    super(pageSize);
  }

  @computed get votingMethod() {
    return this.poll?.votingConfiguration.votingMethodDto || VotingMethodDto.Dots;
  }

  @action removeIdea(ideaId: string) {
    this.paginatedList.removeItems(this.paginatedList.items.filter(i => i.id === ideaId));
  }

  protected async load(options: { page: number; results?: number }) {
    const { ideas, totalCount, hasNextPage } = await this.ideaStore.getIdeas(
      this.initiative,
      { ...options },
      this.filter,
      this.mapToSortOption(this.orderBy),
      this.statusFilter.status
    );

    await Promise.all(ideas.map(idea => idea.updateAttachments()));

    this.totalCount = totalCount ?? 0;
    const ideasWithVotes = ideas.map(i => {
      return new IdeaListItemViewModel(i, this.initiative);
    });

    return { items: ideasWithVotes, hasNextPage };
  }

  // eslint-disable-next-line class-methods-use-this
  private mapToSortOption(orderBy: IdeasOrderBy) {
    switch (orderBy) {
      case IdeasOrderBy.DateAsc:
        return { sortDirection: SortDirection.Ascending, sortType: GetIdeaListSortType.CreateAt };
      case IdeasOrderBy.DateDesc:
        return { sortDirection: SortDirection.Descending, sortType: GetIdeaListSortType.CreateAt };
      case IdeasOrderBy.VotesCountDesc:
        return { sortDirection: SortDirection.Descending, sortType: GetIdeaListSortType.Vote };
      default:
        return { sortDirection: SortDirection.Descending, sortType: GetIdeaListSortType.CreateAt };
    }
  }
}

class Loaded {
  @observable filter: {
    userId: string;
    initiativeId: string;
  };

  @observable order: IdeasOrderBy;

  @observable ideaList: IdeaListViewModel;

  @observable initiative: Initiative;

  @observable poll?: Poll;

  @observable loggedUser: CurrentUserDto;

  createIdeaDialogViewModel: CreateIdeaDialogViewModel;

  addCoOwner: AddCoOwnerViewModel;

  editInitiativeDialogViewModel: EditInitiativeDialogViewModel;

  editIdeaDialogViewModel: EditIdeaDialogViewModel;

  moveIdeaDialogViewModel: MoveIdeaDialogViewModel;

  showCreateIdeaDialog: Command;

  shareInitiative: Command;

  showEditInitiativeDialog: Command<EditInitiativeSteps>;

  showAddCoOwnerDialog: Command;

  closeInitiative: Command;

  resetInitiative: Command<void, Promise<void>>;

  archiveInitiative: Command<void, Promise<void>>;

  unarchiveInitiative: Command<void, Promise<void>>;

  setImplementation: Command;

  editSubscription: Command;

  showEditIdeaDialog: Command<IdeaListItem, Promise<void>>;

  showMoveIdeaDialog: Command<IdeaListItem, Promise<void>>;

  private ideaLimit = 20;

  constructor(
    initiative: Initiative,
    public owner: Deferred<UserDto>,
    loggedUser: CurrentUserDto,
    commentStore: CommentStore,
    ideaStore: IdeaStore,
    fileStore: FileStore,
    initiativeStore: InitiativeStore,
    voteStore: VoteStore,
    navigationService: INavigationService,
    poll?: Poll
  ) {
    this.initiative = initiative;
    this.loggedUser = loggedUser;
    this.poll = poll;

    this.filter = { userId: NIL, initiativeId: this.initiative.id };

    this.order = this.initiative.state.isFinal === true ? IdeasOrderBy.VotesCountDesc : IdeasOrderBy.DateDesc;

    this.ideaList = new IdeaListViewModel(
      this.initiative,
      ideaStore,
      commentStore,
      this.ideaLimit,
      navigationService,
      this.poll
    );

    this.closeInitiative = command(
      () => {
        this.initiative.closeInitiative.execute();
      },
      () => this.canUserClose
    );

    this.resetInitiative = command(
      async () => {
        DialogSupport.showConfirmDialog({
          title: "Do you want to reset the initiative and all ideas inside?",
          content: "You will not be able to undo this action.",
          okText: "Reset initiative",
          onOk: async () => {
            await this.initiative.resetInitiative.execute();
            await this.ideaList.reload({ page: 1, results: this.ideaLimit });
            DialogSupport.showSuccessDialog({
              title: "Initiative has been reset successfully",
              okText: "Go to initiative",
            });
          },
        });
      },
      () => this.canUserReset
    );

    this.archiveInitiative = command(
      async () => {
        DialogSupport.showConfirmDialog({
          title: "Do you really want to archive this initiative?",
          content: "You will not be able to edit your initiative. The initiative will no logner appear in the toolbar.",
          okText: "Archive initiative",
          onOk: async () => {
            await this.initiative.archiveInitiative.execute();
            await this.ideaList.reload({ page: 1, results: this.ideaLimit });
            DialogSupport.showSuccessDialog({
              title: "Initiative has been archived successfully",
              content: "We hope you will come back to us to create a new initiative!",
              okText: "Go to initiative",
            });
          },
        });
      },
      () => this.canUserArchive
    );

    this.unarchiveInitiative = command(
      async () => {
        DialogSupport.showConfirmDialog({
          title: "Do you really want to restore this initiative?",
          okText: "Restore initiative",
          onOk: async () => {
            await this.initiative.unarchiveInitiative.execute();
            await this.ideaList.reload({ page: 1, results: this.ideaLimit });
            DialogSupport.showSuccessDialog({
              title: "Initiative has been restored successfully",
              okText: "Go to initiative",
            });
          },
        });
      },
      () => this.canUserUnarchive
    );

    this.setImplementation = command(
      () => {
        this.initiative.setImplementation.execute();
      },
      () => this.canUserSetImplementation
    );

    this.shareInitiative = command(() => {
      navigator.clipboard.writeText(window.location.href);
      message.info({
        content: "Link to initiative copied to a clipboard",
      });
    });

    this.showAddCoOwnerDialog = command(
      () => {
        this.addCoOwner.show();
      },
      () => this.initiative.canUserEdit
    );

    this.showCreateIdeaDialog = command(
      () => {
        this.createIdeaDialogViewModel.show(this.initiative);
      },
      () => this.initiative.canAddIdeas
    );

    this.showEditInitiativeDialog = command(
      type => {
        this.editInitiativeDialogViewModel.show(createViewModel(this.initiative), type);
      },
      () => this.initiative.canUserEdit
    );

    this.showEditIdeaDialog = command(async idea => {
      await this.editIdeaDialogViewModel.show(createViewModel(idea));
    });

    this.showMoveIdeaDialog = command(async idea => {
      await this.moveIdeaDialogViewModel.show(idea);
    });

    this.addCoOwner = new AddCoOwnerViewModel(this.initiative, async initiativeCoOwnersIds => {
      try {
        await this.initiative.store.setInitiativeCoOwners(this.initiative.id, initiativeCoOwnersIds);
        this.initiative.coOwnersIds = initiativeCoOwnersIds;
        this.addCoOwner.hide();
      } catch {
        throw new Error("Add user do not work correctly");
      }
    });

    this.createIdeaDialogViewModel = new CreateIdeaDialogViewModel(async idea => {
      try {
        await idea.save();
        await poll?.reload();
        this.createIdeaDialogViewModel.hide();
        this.ideaList.paginatedList.loadFirstPage();
        this.initiative.ideasCount += 1;
      } catch {
        this.initiative.removeIdea(idea);
      }
    }, ideaStore);

    this.editIdeaDialogViewModel = new EditIdeaDialogViewModel(
      async idea => {
        await idea.save();
        this.editIdeaDialogViewModel.hide();
        idea.submit();
        // this.ideaList.paginatedList.patchItem(idea as never, idea);
      },
      ideaStore,
      loggedUser
    );

    this.moveIdeaDialogViewModel = new MoveIdeaDialogViewModel(initiativeStore, navigationService, idea => {
      this.ideaList.removeIdea(idea.id);
      this.initiative.ideasCount -= 1;
    });

    this.editInitiativeDialogViewModel = new EditInitiativeDialogViewModel(
      async editedInitiative => {
        await editedInitiative.save();
        editedInitiative.submit();
        poll?.editVotingConfiguration({
          startDate: editedInitiative.votingStartDate,
          endDate: editedInitiative.votingEndDate,
        });
        this.editInitiativeDialogViewModel.hide();
      },
      createViewModel(this.initiative),
      fileStore,
      initiativeStore,
      voteStore
    );

    this.editSubscription = command(
      () => {
        this.initiative.editSubscription.execute();
      },
      () => !this.isInFinalState && !this.isArchived
    );
  }

  @computed get isVotingPeriod() {
    return this.initiative.isVotingPeriod;
  }

  @computed get isArchived() {
    return this.initiative.isArchived;
  }

  @computed get isInFinalState() {
    return this.initiative.isInFinalState;
  }

  @computed get totalContributorCount() {
    return this.initiative.participantsCount;
  }

  @computed get totalVotesCount() {
    return this.initiative.totalVotesCount;
  }

  @computed get description() {
    return this.initiative.description;
  }

  @computed get createdAt() {
    return this.initiative.createdAt;
  }

  @computed get title() {
    return this.initiative.title;
  }

  @computed get votingMethod() {
    return this.initiative.votingMethod;
  }

  @computed get maxVotesPerIdea() {
    return this.initiative.maxVotesPerPollOption;
  }

  @computed get maxVotesPerInitiative() {
    return this.initiative.votesPerPoll;
  }

  @computed get votesLeftForInitiative() {
    return this.initiative.votesLeftPerCurrentUserCount;
  }

  @computed get hasAttachments() {
    return this.initiative.attachments.length > 0;
  }

  @computed get attachments() {
    return this.initiative.attachments;
  }

  @computed get canUserEdit() {
    return this.initiative.canUserEdit && !this.initiative.state.isFinal && !this.initiative.isArchived;
  }

  @computed get canUserReset() {
    return this.initiative.canUserReset;
  }

  @computed get canUserArchive() {
    return this.initiative.canUserArchive;
  }

  @computed get canUserUnarchive() {
    return this.initiative.canUserUnarchive;
  }

  @computed get canUserSetImplementation() {
    return (
      this.initiative.state.name !== InitiativeStateNameDto.Implementation &&
      this.initiative.canUserEdit &&
      !this.initiative.isArchived
    );
  }

  @computed get canUserClose() {
    return (
      this.initiative.state.name !== InitiativeStateNameDto.Closed &&
      this.initiative.canUserEdit &&
      !this.initiative.isArchived
    );
  }

  @computed get canUserOpen() {
    return (
      this.initiative.state.name === InitiativeStateNameDto.Closed &&
      this.initiative.canUserEdit &&
      !this.initiative.isArchived
    );
  }

  @computed get ideasCount() {
    return this.initiative.ideasCount;
  }

  @computed get submissionStartDate() {
    return this.initiative.submissionStartDate;
  }

  @computed get submissionEndDate() {
    return this.initiative.submissionEndDate;
  }

  @computed get votingStartDate() {
    return this.initiative.votingStartDate;
  }

  @computed get votingEndDate() {
    return this.initiative.votingEndDate;
  }

  @computed get ownerName() {
    if (!isLoaded(this.owner)) return "";
    return this.owner.name ? this.owner.name : this.owner.email;
  }

  @computed get ownerAvatarId() {
    if (!isLoaded(this.owner)) return null;
    return this.owner.avatarId;
  }

  @computed get hasExtraActions() {
    return (
      this.canUserEdit ||
      this.canUserClose ||
      this.canUserOpen ||
      this.canUserArchive ||
      this.canUserUnarchive ||
      this.canUserSetImplementation ||
      this.canUserReset
    );
  }

  @computed get breadcrumbItems() {
    return [
      Breadcrumb.createWorkspaceItem(this.initiative.workspaceId, this.initiative.workspaceName),
      Breadcrumb.createInitiativeItem(this.initiative.id, this.initiative.title),
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  private mapToSortOption(orderBy: IdeasOrderBy) {
    switch (orderBy) {
      case IdeasOrderBy.DateAsc:
        return { sortDirection: SortDirection.Ascending, sortType: GetIdeaListSortType.CreateAt };
      case IdeasOrderBy.DateDesc:
        return { sortDirection: SortDirection.Descending, sortType: GetIdeaListSortType.CreateAt };
      case IdeasOrderBy.VotesCountDesc:
        return { sortDirection: SortDirection.Descending, sortType: GetIdeaListSortType.Vote };
      default:
        return { sortDirection: SortDirection.Descending, sortType: GetIdeaListSortType.CreateAt };
    }
  }
}

export class InitiativeViewModel extends BasePage {
  @observable state: Deferred<Loaded> = "Loading";

  @observable isDrawerVisible = false;

  @observable isFilterVisible = false;

  @observable isPopoverVisible = false;

  showIdea: Command<string, void>;

  steps = InitiativeWalkTour.getSteps();

  constructor(
    public initiativeId: string,
    public initiativeStore: InitiativeStore,
    public voteStore: VoteStore,
    public userStore: UserStore,
    public commentStore: CommentStore,
    public errorService: IErrorService,
    public ideaStore: IdeaStore,
    public fileStore: FileStore,
    public readonly navigationService: INavigationService
  ) {
    super(errorService, false, userStore);

    onBecomeObserved(this, "state", async () => {
      await this.loadData();
    });

    this.showIdea = command(id => {
      this.showChildPage(
        new IdeaViewModel(
          id,
          this.initiativeId,
          this.initiativeStore,
          this.voteStore,
          this.commentStore,
          this.userStore,
          this.errorService,
          this.ideaStore,
          this.fileStore,
          this.navigationService
        )
      );
    });
  }

  @action.bound togglePopover = () => {
    this.isPopoverVisible = !this.isPopoverVisible;
  };

  @action.bound toggleFilters = () => {
    this.isFilterVisible = !this.isFilterVisible;
  };

  @action.bound toggleDrawer = () => {
    this.isDrawerVisible = !this.isDrawerVisible;
  };

  protected async loadData() {
    const initiative = await this.initiativeStore.getInitiative(this.initiativeId);
    this.userStore.updateUser();
    const owner = await this.userStore.getUser(initiative.ownerId);
    this.state = new Loaded(
      initiative,
      owner,
      this.userStore.loggedUser,
      this.commentStore,
      this.ideaStore,
      this.fileStore,
      this.initiativeStore,
      this.voteStore,
      this.navigationService
    );

    super.loadData();
  }
}
