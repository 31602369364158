import { REDIRECT_URI_PARAM_NAME, RETURN_URL_PARAM_NAME } from "../Constants";

export interface IUrlService {
  getUrlQueryParam(paramName: string): string | null;
  getReturnUrlParam(): string | null;
  getRedirectUriParam(): string | null;
  getLoginUrl(): string;
}

export const UrlService: IUrlService = {
  getUrlQueryParam(paramName: string) {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramName);
  },

  getReturnUrlParam() {
    return this.getUrlQueryParam(RETURN_URL_PARAM_NAME);
  },
  getRedirectUriParam() {
    return this.getUrlQueryParam(REDIRECT_URI_PARAM_NAME);
  },

  getLoginUrl() {
    const returnUrlParam = this.getReturnUrlParam();
    return `/login${returnUrlParam ? `?${RETURN_URL_PARAM_NAME}=${encodeURIComponent(returnUrlParam)}` : ""}`;
  },
};
