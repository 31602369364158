import { observable } from "mobx";
import { Command } from "react-mvvm/dist";
import { FileStore } from "src/shared/stories/file/FileStore";
import { IErrorService } from "../../shared/services/ErrorService";
import { UserStore } from "../../shared/stories/user/UserStore";
import { BasePage } from "../BasePage";
import { EditUserDataViewModel } from "./editUserData/EditUserDataViewModel";
import { EditUserPhotoViewModel } from "./editUserPhoto/EditUserPhotoViewModel";

export class UserProfileViewModel extends BasePage {
  @observable editUserDataViewModel: EditUserDataViewModel | undefined;

  @observable editUserPhotoViewModel: EditUserPhotoViewModel | undefined;

  uploadPhoto: Command<File, void>;

  constructor(
    public errorService: IErrorService,
    private readonly userStore: UserStore,
    private readonly fileStore: FileStore
  ) {
    super(errorService, true, userStore);

    this.uploadPhoto = new Command(async file => {
      await this.userStore.uploadUserPhoto(file);
    });
  }

  async onActivated() {
    await this.loadData();
    super.onActivated();
  }

  protected async loadData() {
    super.loadData();
    await this.userStore.updateUser();
    this.editUserDataViewModel = new EditUserDataViewModel(this.userStore);
    this.editUserPhotoViewModel = new EditUserPhotoViewModel(this.userStore, this.fileStore, this.userStore.loggedUser);
  }
}
