import { action, observable } from "mobx";
import { bindableForm, Command, command } from "react-mvvm";
import { DialogViewModel } from "../dialog/DialogViewModel";
import { AddUsersModel } from "./AddUsersModel";

type AddUsersSuccessMethod = (emailsList: string[]) => Promise<void>;

export class AddUsersViewModel extends DialogViewModel<never> {
  @observable addUserModel: AddUsersModel = {
    emails: "",
    emailsList: [],
  };

  @observable.ref errorEmailList: string[] = [];

  cancel: Command;

  submit: Command<void, Promise<void>>;

  form;

  constructor(private onSuccess: AddUsersSuccessMethod) {
    super();

    this.form = bindableForm<AddUsersModel>()
      .addField("emails")
      .bindTo(() => this.addUserModel);

    this.cancel = command(() => {
      this.hide();
    });

    this.submit = command(async () => {
      if (!(await this.form.validateAndCommit())) {
        return;
      }
      this.addUserModel.emailsList = this.concatEmails(this.addUserModel.emails);
      this.addUserModel.emailsList.length > 0 && (await onSuccess(this.addUserModel.emailsList));
    });
  }

  @action.bound isValidEmail = (email: string) => {
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegEx.test(email.toLowerCase());
  };

  showError(list: string[]) {
    this.errorEmailList = list;
  }

  async show() {
    await super.show();
    this.addUserModel.emails = "";
    this.addUserModel.emailsList = [];
    this.errorEmailList = [];
    this.form.reset();
  }

  concatEmails(val: string) {
    return val.split(",").filter(this.isValidEmail);
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}
}
