import React, { FC } from "react";
import { SvgIcon } from "../SvgIcon/SvgIcon";

interface AppLogoProps {
  size: "small" | "medium" | "large";
  includeText?: boolean;
  variant: "primary" | "secondary" | "contrast";
  className?: string
}

export const AppLogo: FC<AppLogoProps> = ({ size, includeText, variant, className }) => {
  return (
    <div className={`AppLogo--${size} AppLogo--${variant} ${className}`}>
      <SvgIcon iconName="bulb" /> {includeText && "Ideamate"}
    </div>
  );
};
