import classNames from "classnames";
import * as React from "react";
import { VotingMethodDto } from "../../types/shared/dto/VotingMethodDto";
import { SvgIcon } from "../SvgIcon/SvgIcon";

interface IVotingMethodIcon {
  isVoted: boolean;
  votingMethod: VotingMethodDto;
  isSummaryIcon?: boolean;
  className?: string;
}

export const VotingMethodIcon = ({ isVoted, votingMethod, className, isSummaryIcon }: IVotingMethodIcon) => {
  switch (votingMethod) {
    case VotingMethodDto.Dots:
      return (
        <SvgIcon
          iconName={isVoted ? "dotsVoted" : "dots"}
          className={classNames(className, { voted: isVoted }, { summaryIcon: isSummaryIcon })}
        />
      );
    case VotingMethodDto.Investment:
      return (
        <SvgIcon
          iconName="dollar"
          className={classNames(className, { voted: isVoted }, { summaryIcon: isSummaryIcon })}
        />
      );
    case VotingMethodDto.Likes:
      return (
        <SvgIcon
          iconName="thumbUp"
          className={classNames(className, { voted: isVoted }, { summaryIcon: isSummaryIcon })}
        />
      );
    default:
      return <SvgIcon iconName={isVoted ? "dotsVoted" : "dots"} className={className} />;
  }
};
