import classNames from "classnames";
import React from "react";
import { Command } from "react-mvvm/dist";
import { LoadMoreListView } from "../../../../../shared/List/LoadMoreListView";
import { CommandButton } from "../../../../../shared/button/Button";
import { TagChip } from "../Tags";
import { LoadMoreTagList } from "./LoadMoreTagList";

interface LoadMoreTagsViewProps {
  list: LoadMoreTagList;
  selected?: string[];
  onClick?: (tag: string) => void;
  onReset?: Command;
  className?: string;
}

export const TagFilterListView: React.FC<LoadMoreTagsViewProps> = ({ list, selected, onClick, className }) => {
  return (
    <>
      <LoadMoreListView
        className={classNames("LoadMoreTagsView", className)}
        loadMoreList={list}
        skeleton={<></>}
        renderItem={(item, index) => {
          return (
            <>
              <TagChip
                clickable
                selected={selected?.includes(item.id)}
                tagText={item.text}
                onClick={() => onClick && onClick(item.id)}
              />
              {index === list.totalCount - 1 && (
                <>
                  {selected
                    ?.filter(s => !list.paginatedList.items.find(x => x.id === s))
                    .map(tag => (
                      <TagChip key={tag} clickable selected tagText={tag} onClick={() => onClick && onClick(tag)} />
                    ))}

                  <CommandButton
                    type="link"
                    command={list.paginatedList.loadNextPage}
                    className="LoadMoreTagsView__ShowMoreBtn"
                  >
                    Show more tags
                  </CommandButton>
                </>
              )}
            </>
          );
        }}
        hideDefaultLoadMoreBtn
        emptyText="No tags found"
      />
    </>
  );
};
