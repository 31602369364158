import { Button, Divider, Input, Modal, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { UserAvatar } from "../../../../shared/userAvatar/UserAvatar";
import { AddCoOwnerViewModel } from "./AddCoOwnerModel";

const { Title } = Typography;

interface IAddCoOwnerDialogViewProps {
  model: AddCoOwnerViewModel;
}

export const AddCoOwner: React.FunctionComponent<IAddCoOwnerDialogViewProps> = observer(
  ({ model }: IAddCoOwnerDialogViewProps) => {
    const { isVisible, cancel, submit } = model;

    return (
      <Modal
        destroyOnClose
        visible={isVisible}
        title={
          <>
            <Title level={2} className="Title--withIcon">
              <SvgIcon iconName="addUser" /> Add co-owner to Initiative
            </Title>
          </>
        }
        onCancel={() => cancel.execute()}
        closable
        closeIcon={<SvgIcon iconName="close" />}
        footer={
          <CommandButton type="primary" size="large" command={submit} withLoader>
            Set Co-owners
          </CommandButton>
        }
        className="AddCoOwner"
        width="100%"
      >
        <Input.Search
          onSearch={model.search}
          className="SearchUserInput"
          onChange={e => {
            // eslint-disable-next-line no-param-reassign
            model.searchValue = e.target.value;
          }}
          value={model.searchValue}
          placeholder="Search for names"
        />
        {model.isEmptySearch && <p>We do not found any user for this search</p>}
        <ul className="SearchUserResultList">
          {model.searchResult.map(item => (
            <li className="SearchUserResultList__SearchItem" key={item.id}>
              <UserAvatar
                userName={item.name ? item.name : item.email}
                size={64}
                className={classNames(
                  "SearchUserResultList__SearchItem__UserAvatar",
                  model.selectedUsers.includes(item) && "SearchUserResultList__SearchItem__UserAvatar--selected"
                )}
              />
              <span
                className={classNames(
                  "SearchUserResultList__SearchItem__UserName",
                  model.selectedUsers.includes(item) && "SearchUserResultList__SearchItem__UserName--selected"
                )}
              >
                {item.name ? item.name : item.email}
              </span>
              <Button
                type="link"
                className="SearchUserResultList__SearchItem__buttonOverlay"
                onClick={() => {
                  model.selectUser(item);
                }}
              >
                {" "}
              </Button>
            </li>
          ))}
          {!model.isNoMore && (
            <li className="SearchUserResultList__SearchItem">
              <Button shape="circle" type="primary" size="large" onClick={model.loadMore}>
                +
              </Button>
            </li>
          )}
        </ul>
        <Divider />
        <Title level={2} className="Title--withIcon">
          <SvgIcon iconName="avatar" /> Initiative co-owners
        </Title>
        <ul className="SelectedUsers">
          {model.selectedUsers.length !== 0 &&
            model.selectedUsers.map(item => (
              <li className="SelectedUsers_ListItem" key={item.id}>
                <UserAvatar
                  userName={item.name ? item.name : item.email}
                  size={64}
                  className="SelectedUsers_ListItem__UserAvatar SelectedUsers_ListItem__UserAvatar--selected"
                />
                <span className="SelectedUsers_ListItem__UserName SelectedUsers_ListItem__UserName--selected">
                  {item.name ? item.name : item.email}
                </span>
                <Button
                  type="link"
                  className="SelectedUsers_ListItem__buttonOverlay"
                  onClick={() => model.selectUser(item)}
                >
                  {" "}
                </Button>
              </li>
            ))}
          {model.selectedUsers.length === 0 && model.isNoMoreCoOwners && <p>This initiative does not have co-owners</p>}
          {!model.isNoMoreCoOwners && (
            <li className="SelectedUsers_ListItem">
              <Button shape="circle" type="primary" size="large" onClick={model.loadMoreCoOwners}>
                +
              </Button>
            </li>
          )}
        </ul>
      </Modal>
    );
  }
);
