import { observable } from "mobx";
import { Page } from "react-mvvm";
import { UserStore } from "src/shared/stories/user/UserStore";
import { IErrorService } from "../shared/services/ErrorService";

// This is a base page for all the views that are children of the app (root page of the application).
export abstract class BasePage<TDialog = never> extends Page<TDialog> {
  @observable isLoading = true;

  constructor(public errorService: IErrorService, isAuthRequired: boolean, userStore: UserStore) {
    super();
    isAuthRequired && userStore.updateUser();
  }

  async onActivated() {
    await super.onActivated();
  }

  async onInvalidRoute() {
    this.errorService.showErrorSplash(404);
  }

  protected async loadData(): Promise<void> {
    this.isLoading = false;
  }
}
