import { AbstractFormStep } from "../../../../../shared/stepWizard/Step";
import { CreateWorkspaceModel } from "../CreateWorkspaceModel";

export type CreateWorkspaceStepName = "Basic" | "Members" | "Summary";

export abstract class CreateWorkspaceStep<T extends keyof CreateWorkspaceModel> extends AbstractFormStep<
  T,
  CreateWorkspaceModel,
  CreateWorkspaceStepName
> {}
