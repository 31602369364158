import { computed } from "mobx";
import { bindableForm } from "react-mvvm";
import { Step } from "../../../../../../shared/stepWizard/Step";
import { UserStore } from "../../../../../../shared/stories/user/UserStore";
import { SortDirection } from "../../../../../../types/shared/dto/SortDirection";
import { UserDto } from "../../../../../../types/shared/dto/UserDto";
import { GetUsersSortType } from "../../../../../../types/users/dto/GetUsersSortType";
import { CreateWorkspaceModel } from "../../CreateWorkspaceModel";
import { CreateWorkspaceStep, CreateWorkspaceStepName } from "../CreateWorkspaceStep";

export function isCreateWorkspaceMembersStep(
  step: Step<CreateWorkspaceStepName>
): step is CreateWorkspaceMembersStepViewModel {
  return step.name === "Members";
}

export class CreateWorkspaceMembersStepViewModel extends CreateWorkspaceStep<"members"> {
  readonly name = "Members";

  form = bindableForm<CreateWorkspaceModel>()
    .addField("members")
    .bindTo(() => this.createWorkspaceModel);

  constructor(private readonly createWorkspaceModel: CreateWorkspaceModel, private readonly userStore: UserStore) {
    super();
  }

  @computed get isPrivate() {
    return this.createWorkspaceModel.isPrivate;
  }

  readonly findUsers = async (text: string): Promise<UserDto[]> => {
    return (
      await this.userStore.getUsers({
        query: text,
        ids: [],
        excludeIds: [],
        page: 0,
        limit: 3,
        sortType: GetUsersSortType.Name,
        sortDirection: SortDirection.Descending,
      })
    ).items;
  };

  canGotoNextStep = () => true;
}
