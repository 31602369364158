import { observable, reaction } from "mobx";
import { FileStore } from "../../../shared/stories/file/FileStore";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { CurrentUserDto } from "src/types/shared/dto/CurrentUserDto";
import { AvatarEditorDialogViewModel } from "./avatarEditorDialog/AvatarEditorDialogViewModel";


export class EditUserPhotoViewModel {
  @observable photo: File | undefined;

  @observable isInvalid = false;

  @observable errorMessage = "";

  avatarEditorDialogViewModel: AvatarEditorDialogViewModel;

  constructor(
    private readonly userStore: UserStore,
    private readonly fileStore: FileStore,
    loggedUser: CurrentUserDto
  ) {
    this.avatarEditorDialogViewModel = new AvatarEditorDialogViewModel(userStore);

    this.downloadUserPhoto(loggedUser.photoId);
    reaction(
      () => this.userStore.loggedUser.photoId,
      id => this.downloadUserPhoto(id)
    );
    this.removeUserPhoto = this.removeUserPhoto.bind(this);
  }

  uploadFile(file: File) {
    if (this.validateFile(file)) {
      this.avatarEditorDialogViewModel.show({ img: file });
    }
  }

  async removeUserPhoto() {
    await this.userStore.removeUserPhoto();
    await this.userStore.updateUser(true);
  }

  private validateFile(file: File) {
    this.isInvalid = false;
    this.errorMessage = "";

    if (file.size > 2097152) {
      this.isInvalid = true;
      this.errorMessage = "Selected file is too large (max size 2MB).";
      return false;
    }
    return true;
  }

  private downloadUserPhoto(id?: string) {
    if (!id) {
      this.photo = undefined;
      return;
    }
    this.fileStore.fileService.downloadFile(id).then(file => {
      this.photo = file;
    });
  }
}
