import { BindableForm } from "react-mvvm";

export interface Step<StepType> {
  name: StepType;
  canGotoNextStep: () => boolean;
  goToNextStep: () => Promise<boolean>;
  commitStep?: () => Promise<void>;
  isValid?: () => boolean;
}

export interface FormStep<K, T extends keyof K, L> extends Step<L> {
  form: BindableForm<K, Pick<K, T>>;
}

export abstract class AbstractFormStep<T extends keyof ModelType, ModelType, StepNameType>
  implements FormStep<ModelType, T, StepNameType> {
  abstract name: StepNameType;

  abstract form: BindableForm<ModelType, Pick<ModelType, T>>;

  canGotoNextStep() {
    return this.form.isDirty;
  }

  goToNextStep = async () => {
    return await this.form.validateAndCommit();
  };

  commitStep = async () => {
    await this.form.validateAndCommit();
  };

  isValid = () => this.form.isValid;
}
