import { Modal, Result, Typography } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { Button } from "../../../../shared/button/Button";
import { SuccessDialogViewModel } from "./SuccessDialogViewModel";

interface ISuccessDialogViewProps {
  model: SuccessDialogViewModel;
}

const { Paragraph } = Typography;

export const SuccessDialog: React.FunctionComponent<ISuccessDialogViewProps> = observer(
  ({ model }: ISuccessDialogViewProps) => {
    const { isVisible, cancel, showInitiative, initiativeId } = model;

    return (
      <Modal
        visible={isVisible}
        onOk={() => initiativeId && showInitiative.execute(initiativeId)}
        onCancel={() => cancel.execute()}
        closable
        closeIcon={<SvgIcon iconName="close" />}
        className="SuccessDialog"
        footer={[
          <Button
            key="submit"
            type="primary"
            size="large"
            onClick={() => initiativeId && showInitiative.execute(initiativeId)}
          >
            Go to initiative
          </Button>,
        ]}
        width="100%"
      >
        <Result
          className="SuccessDialog__message"
          title="Your initiative was successfully created"
          status="success"
          icon={<SvgIcon iconName="success" />}
          subTitle={
            <>
              <Paragraph>
                You can edit your initiative whenever you need, change the timeframe or invite new people.
                <br />
                New initiative will be visible on your dashboard and workspace.
              </Paragraph>
            </>
          }
        />
      </Modal>
    );
  }
);
