import { Select } from "antd";
import * as React from "react";

type DropdownOption<T> = {
  value: T;
  text: string;
};

interface IDropdown<T> {
  defaultValue: T;
  onChange: (value: T) => void;
  options: DropdownOption<T>[];
  className: string;
  label: React.ReactNode;
}

export const Dropdown = <T extends string | number>({
  defaultValue,
  onChange,
  options,
  className,
  label,
}: IDropdown<T>) => {
  return (
    <div className="Dropdown">
      {label}
      <Select
        defaultValue={defaultValue}
        onChange={value => onChange(value)}
        bordered={false}
        className={className}
        showArrow={false}
      >
        {options.map(({ value, text }, index) => (
          <Select.Option value={value} key={`${value} ${index}`}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
