import { Modal, Steps, Row, Col } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { CreateInitiativeDialogViewModel } from "./CreateInitiativeDialogViewModel";
import { CreateInitiativeStep } from "./createInitiativeWizard/CreateInitiativeStep";
import { CreateInitiativeBasicStep } from "./createInitiativeWizard/createInitiativeBasicStep/CreateInitiativeBasicStep";
import { isCreateInitiativeBasicStep } from "./createInitiativeWizard/createInitiativeBasicStep/CreateInitiativeBasicStepViewModel";
import { CreateInitiativeDateStep } from "./createInitiativeWizard/createInitiativeDateStep/CreateInitiativeDateStep";
import { isCreateInitiativeDateStep } from "./createInitiativeWizard/createInitiativeDateStep/CreateInitiativeDateStepViewModel";
import { CreateInitiativeSummaryStep } from "./createInitiativeWizard/createInitiativeSummaryStep/CreateInitiativeSummaryStep";
import { isCreateInitiativeSummaryStep } from "./createInitiativeWizard/createInitiativeSummaryStep/CreateInitiativeSummaryStepViewModel";
import { CreateInitiativeVotingSystem } from "./createInitiativeWizard/createInitiativeVotingSystem/CreateInitiativeVotingSystem";
import { isCreateInitiativeVotingSystem } from "./createInitiativeWizard/createInitiativeVotingSystem/CreateInitiativeVotingSystemModel";

interface ICreateInitiativeDialogViewProps {
  model: CreateInitiativeDialogViewModel;
}

const { Step } = Steps;

const CreateInitiativeSteps = ({ currentStep }: { currentStep: number }) => {
  return (
    <Steps progressDot current={currentStep}>
      <Step />
      <Step />
      <Step />
    </Steps>
  );
};

export const CreateInitiativeDialog: React.FunctionComponent<ICreateInitiativeDialogViewProps> = observer(
  ({ model }: ICreateInitiativeDialogViewProps) => {
    const { isVisible, cancel, wizard } = model;

    if (!wizard) {
      return null;
    }

    const {
      currentStep,
      currentStepOrder,
      isGoToNextStepVisible,
      goToNextStep,
      isGoToPreviousStepVisible,
      goToPreviousStep,
      isSubmitVisible,
      submit,
    } = wizard;

    return (
      <Modal
        visible={isVisible}
        title={
          <Row justify="space-between">
            <Col>
              <Title level={2} className="Title--withIcon">
                <SvgIcon iconName="diamond" /> New Initiative
              </Title>
            </Col>
            <Col className="CreateInitiativeDialog__steps">
              <CreateInitiativeSteps currentStep={currentStepOrder} />
            </Col>
          </Row>
        }
        onCancel={() => {
          if (
            (currentStep instanceof CreateInitiativeStep && currentStep.form.isDirty) ||
            !isCreateInitiativeBasicStep(currentStep)
          )
            DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        width="100%"
        closeIcon={<SvgIcon iconName="close" />}
        footer={
          <div className="CreateInitiativeDialog__footer">
            {isGoToNextStepVisible && (
              <CommandButton command={goToNextStep} type="primary" size="large">
                Next
              </CommandButton>
            )}
            {isSubmitVisible && (
              <CommandButton withLoader command={submit} type="primary" size="large">
                Publish
              </CommandButton>
            )}
            {isGoToPreviousStepVisible && (
              <CommandButton command={goToPreviousStep} type="link" icon={<SvgIcon iconName="chevronLeft" />}>
                Back
              </CommandButton>
            )}
          </div>
        }
        className="CreateInitiativeDialog"
      >
        {isCreateInitiativeBasicStep(currentStep) && (
          <CreateInitiativeBasicStep
            className="CreateInitiativeDialog__form"
            model={currentStep}
            fileStore={model.fileStore}
          />
        )}
        {isCreateInitiativeDateStep(currentStep) && (
          <CreateInitiativeDateStep
            className="CreateInitiativeDialog__form CreateInitiativeDialog__dateStep"
            model={currentStep}
          />
        )}
        {isCreateInitiativeVotingSystem(currentStep) && (
          <CreateInitiativeVotingSystem
            className="CreateInitiativeDialog__form CreateInitiativeDialog__votingSystem"
            model={currentStep}
          />
        )}
        {isCreateInitiativeSummaryStep(currentStep) && (
          <CreateInitiativeSummaryStep className="CreateInitiativeDialog__summary" model={currentStep} />
        )}
      </Modal>
    );
  }
);
