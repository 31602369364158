import { bindableForm } from "react-mvvm";
import { RangePickerValue } from "../../../../../../shared/rangePicker/RangePicker";
import { Step } from "../../../../../../shared/stepWizard/Step";
import { DateBeforeValidator } from "../../../../../../shared/validators/DateBeforeValidator";
import { ModalsWalkTour } from "../../../../../../shared/walkTour/ModalsWalkTour";
import { CreateInitiativeModel } from "../../CreateInitiativeModel";
import { CreateInitiativeStep, CreateInitiativeStepName } from "../CreateInitiativeStep";

export function isCreateInitiativeDateStep(
  step: Step<CreateInitiativeStepName>
): step is CreateInitiativeDateStepViewModel {
  return step.name === "Date";
}

export class CreateInitiativeDateStepViewModel extends CreateInitiativeStep<"submissionDateRange" | "votingDateRange"> {
  readonly name = "Date";

  form = bindableForm<CreateInitiativeModel>()
    .addField("submissionDateRange", {
      validator: v => {
        if (this.form.fields.votingDateRange.value === undefined) return true;

        const votingRange: RangePickerValue = this.form.fields.votingDateRange.value;
        const value = (v as unknown) as RangePickerValue;
        return DateBeforeValidator(
          value.endDate,
          votingRange.endDate,
          "The collecting ideas time frame should end before the voting time frame ends."
        );
      },
    })
    .addField("votingDateRange", {
      validator: v => {
        if (this.form.fields.submissionDateRange.value === undefined) return true;

        const submissionRange: RangePickerValue = this.form.fields.submissionDateRange.value;
        const value = (v as unknown) as RangePickerValue;
        return DateBeforeValidator(
          submissionRange.startDate,
          value.startDate,
          "The voting time frame cannot start before the collection of ideas time frame starts."
        );
      },
    })
    .bindTo(() => this.createInitiativeModel);

  steps = ModalsWalkTour.getCreateInitiativeDataSteps();

  constructor(protected createInitiativeModel: CreateInitiativeModel) {
    super();
  }

  canGotoNextStep() {
    return (
      this.form.isDirty ||
      !!(this.createInitiativeModel.submissionDateRange && this.createInitiativeModel.votingDateRange)
    );
  }
}
