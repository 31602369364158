import { createBrowserHistory } from "history";
import { computed, observable, onBecomeObserved } from "mobx";
import { createViewModel } from "mobx-utils";
import { command, Command, Deferred, isLoaded } from "react-mvvm";
import { FileStore } from "src/shared/stories/file/FileStore";
import { IdeaDetail } from "src/shared/stories/idea/Idea";
import { IdeaStore } from "src/shared/stories/idea/IdeaStore";
import { AttachmentDto } from "src/types/initiatives/dto/AttachmentDto";
import { Breadcrumb } from "../../../shared/breadcrumb/utils";
import { INavigationService } from "../../../shared/services/NavigationService";
import { IdeaStateNameDto } from "../../../types/initiatives/dto/IdeaStateNameDto";
import { IErrorService } from "../../../shared/services/ErrorService";
import { CommentStore } from "../../../shared/stories/comment/CommentStore";
import { Initiative } from "../../../shared/stories/initiative/Initiative";
import { InitiativeStore } from "../../../shared/stories/initiative/InitiativeStore";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { Poll } from "../../../shared/stories/vote/Poll";
import { VoteStore } from "../../../shared/stories/vote/VoteStore";
import { IdeaPageWalkTour } from "../../../shared/walkTour/IdeaPageWalkTour";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { BasePage } from "../../BasePage";
import { getIdeaPath } from "../addInitiativeRouters";
import { IdeaDetailViewModel } from "../shared/IdeaDetailViewModel";
import { EditIdeaDialogViewModel } from "../shared/editIdeaDialog/EditIdeaDialogViewModel";
import { MoveIdeaDialogViewModel } from "../shared/moveIdeaDialog/MoveIdeaDialogViewModel";
import { DEFAULT_WORKSPACE_COLOR } from "../../../shared/Constants";

interface ICreateComment {
  text?: string;
  attachments?: AttachmentDto[];
}

class Loaded {
  createComment: Command<ICreateComment, void>;

  editIdeaDialogViewModel: EditIdeaDialogViewModel;

  showEditIdeaDialog: Command<IdeaDetail, Promise<void>>;

  moveIdeaViewModel: MoveIdeaDialogViewModel;

  showMoveIdeaDialog: Command<IdeaDetail, Promise<void>>;

  constructor(
    public readonly initiative: Initiative,
    private readonly loggedUser: UserDto,
    private readonly commentStore: CommentStore,
    private readonly navigationService: INavigationService,
    initiativeStore: InitiativeStore,
    public readonly idea: IdeaDetail,
    public readonly poll?: Poll
  ) {
    this.createComment = new Command<ICreateComment>(async comment => {
      if (!comment.text && !comment.attachments) return;

      const newComment = await this.commentStore.createComment(idea.id);
      comment.text && newComment.updateText(comment.text);
      comment.attachments && comment.attachments.length !== 0 && newComment.updateAttachments(comment.attachments);
      newComment.owner = loggedUser;
      await newComment.save();

      this.idea.subscribe();
    });

    this.editIdeaDialogViewModel = new EditIdeaDialogViewModel(
      async editedIdea => {
        await editedIdea.save();
        this.editIdeaDialogViewModel.hide();
        editedIdea.submit();
      },
      idea.store,
      loggedUser
    );

    this.showEditIdeaDialog = command(async ideaToEdit => {
      await this.editIdeaDialogViewModel.show(createViewModel(ideaToEdit));
    });

    this.moveIdeaViewModel = new MoveIdeaDialogViewModel(initiativeStore, navigationService);
    this.showMoveIdeaDialog = command(async dialogIdea => {
      await this.moveIdeaViewModel.show(dialogIdea);
    });

    onBecomeObserved(this, "comments", async () => {
      await this.commentStore.getComments(idea.id);
    });
  }

  @computed get ideaWithPollOption() {
    return new IdeaDetailViewModel(this.idea, this.commentStore, this.initiative);
  }

  @computed get isVotingPossible() {
    return (
      !this.ideaWithPollOption.isDeleted &&
      !this.ideaWithPollOption.isReleased &&
      this.poll?.isInProgress &&
      !this.idea.state.isFinal
    );
  }

  @computed get ideaOwnerName() {
    if (!isLoaded(this.ideaWithPollOption.submittedBy)) return "";
    return this.ideaWithPollOption.submittedBy.name
      ? this.ideaWithPollOption.submittedBy.name
      : this.ideaWithPollOption.submittedBy.email;
  }

  @computed get ideaOwnerAvatarId() {
    return isLoaded(this.ideaWithPollOption.submittedBy) ? this.ideaWithPollOption.submittedBy.avatarId : null;
  }

  @computed get ideaOwner() {
    return isLoaded(this.ideaWithPollOption.submittedBy) ? this.ideaWithPollOption.submittedBy.name : "";
  }

  @computed get hasVotingStarted() {
    return this.initiative.hasVotingStarted;
  }

  @computed get hasVotingEnded() {
    return this.initiative.hasVotingEnded;
  }

  @computed get createdAt() {
    return isLoaded(this.ideaWithPollOption.submittedBy) ? this.ideaWithPollOption.createdAt : "";
  }

  @computed get lastEditedByName() {
    return this.ideaWithPollOption.lastEditedByName;
  }

  @computed get lastEditedByAvatarId() {
    return this.ideaWithPollOption.lastEditedByAvatarId;
  }

  @computed get hasAnotherEditor() {
    return this.ideaWithPollOption.hasAnotherEditor;
  }

  @computed get ideaTitle() {
    return this.ideaWithPollOption.title;
  }

  @computed get canUserAddComment() {
    return !this.ideaWithPollOption.isDeleted && !this.ideaWithPollOption.isReleased && !this.isArchived;
  }

  @computed get ideaDescription() {
    return this.ideaWithPollOption.description;
  }

  @computed get hasExtraActions() {
    return this.ideaWithPollOption.hasExtraActions;
  }

  @computed get isDeleted() {
    return this.idea.state.name === IdeaStateNameDto.Deleted;
  }

  @computed get isReleased() {
    return this.idea.state.name === IdeaStateNameDto.Released;
  }

  @computed get isArchived() {
    return this.idea.isArchived;
  }

  @computed get canUserVote() {
    return !this.isDeleted && !this.isReleased && this.isVotingPeriod && !this.isArchived;
  }

  @computed get hasAttachments() {
    return this.idea.attachments.length > 0;
  }

  @computed get attachments() {
    return this.idea.attachments;
  }

  @computed get votingMethod() {
    return this.initiative.votingMethod;
  }

  @computed get comments() {
    return this.commentStore.comments.filter(c => c.targetId === this.ideaWithPollOption.id);
  }

  @computed get initiativeTitle() {
    return this.initiative.title;
  }

  @computed get leftVotesToDistribute() {
    return this.maxVotesPerIdea - this.idea.userVotesCount;
  }

  @computed get maxVotesPerIdea() {
    return this.initiative.maxVotesPerPollOption;
  }

  @computed get maxVotesPerInitiative() {
    return this.initiative.votesPerPoll;
  }

  @computed get votesLeftForInitiative() {
    return this.initiative.votesLeftPerCurrentUserCount;
  }

  @computed get isVotingPeriod() {
    return this.initiative.isVotingPeriod;
  }

  @computed get isAnonymous() {
    return this.initiative.hideIdeaSubmitter && this.idea.submittedBy.id !== this.loggedUser.id;
  }

  @computed get breadcrumbItems() {
    return [
      Breadcrumb.createWorkspaceItem(this.idea.workspaceId, this.idea.workspaceName),
      Breadcrumb.createInitiativeItem(this.idea.initiativeId, this.idea.initiativeTitle),
      Breadcrumb.createIdeaItem(this.idea.id, this.idea.initiativeId, this.idea.title),
    ];
  }

  @computed get themeColor() {
    return this.initiative.themeColor ?? DEFAULT_WORKSPACE_COLOR;
  }
}

export class IdeaViewModel extends BasePage {
  @observable state: Deferred<Loaded> = "Loading";

  steps = IdeaPageWalkTour.getSteps();

  constructor(
    public ideaId: string,
    public initiativeId: string,
    public initiativeStore: InitiativeStore,
    public voteStore: VoteStore,
    public commentStore: CommentStore,
    public userStore: UserStore,
    public errorService: IErrorService,
    public ideaStore: IdeaStore,
    public fileStore: FileStore,
    private readonly navigationService: INavigationService
  ) {
    super(errorService, false, userStore);

    onBecomeObserved(this, "state", async () => {
      await this.loadData();
    });
  }

  protected async loadData() {
    const idea = await this.ideaStore.getIdea(this.ideaId);

    if (!idea) {
      this.errorService.showErrorSplash(404);
      return;
    }

    const initiative = await this.initiativeStore.getInitiative(idea.initiativeId);

    if (this.initiativeId !== idea.initiativeId) {
      const history = createBrowserHistory();
      history.replace(getIdeaPath(idea.initiativeId, idea.id));
    }

    this.state = new Loaded(
      initiative,
      this.userStore.loggedUser,
      this.commentStore,
      this.navigationService,
      this.initiativeStore,
      idea
    );

    await this.userStore.updateUser();
    super.loadData();
  }
}
