import { Typography } from "antd";
import React from "react";
import { ReactourStep } from "reactour";
import { InitialWalkTour } from "./InitialWalkTour";
import { WalkTourPopover } from "./WalkTour";

const { Text } = Typography;

export enum IdeaPageWalkTourSelectors {
  None = "None",
  VotingMethod = "VotingMethod",
  Comment = "Comment",
  Vote = "Vote",
}

export class IdeaPageWalkTour {
  static getSteps() {
    const initialSteps = InitialWalkTour.getSteps();
    const steps: ReactourStep[] = [
      {
        selector: `[data-tour=${IdeaPageWalkTourSelectors.VotingMethod}]`,
        content: () => (
          <WalkTourPopover title="Voting Methods" icon="dots">
            <Text>
              Remember that each initiative has its own voting methods, specifying not only how you vote but also the
              maximum number of votes you can cast for an idea and the maximum number of votes you can spend on an
              initiative.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${IdeaPageWalkTourSelectors.Comment}]`,
        content: () => (
          <WalkTourPopover title="Comments" icon="chat">
            <Text>
              Do you have questions about this solution? Write a comment and start a discussion on this topic.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${IdeaPageWalkTourSelectors.Vote}]`,
        content: () => (
          <WalkTourPopover title="Voting" icon="dots">
            <Text>
              Have you found this idea suitable for initiative? Nothing simpler. Show your support for it by your vote.
              Remember that you can only vote for ideas that have not yet been implemented and deleted. You can withdraw
              your support at any time by revoking your vote.
            </Text>
          </WalkTourPopover>
        ),
      },
    ];
    return [...initialSteps, ...steps];
  }
}
