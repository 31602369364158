import moment from "moment";
import * as React from "react";
import { Field, Form } from "../../../../../../shared/form/Form";
import { RangePicker } from "../../../../../../shared/rangePicker/RangePicker";
import { WalkTour } from "../../../../../../shared/walkTour/WalkTour";
import { CreateInitiativeDateStepViewModel } from "./CreateInitiativeDateStepViewModel";

interface ICreateInitiativeDateStepProps {
  model: CreateInitiativeDateStepViewModel;
  className?: string;
}

export const CreateInitiativeDateStep: React.FunctionComponent<ICreateInitiativeDateStepProps> = ({
  model,
  className,
}) => {
  return (
    <>
      <Form className={className} layout="vertical" form={model.form}>
        {fields => (
          <>
            <Field
              field={fields.submissionDateRange}
              label={
                <>
                  Collecting ideas
                  <WalkTour fixed={false} steps={model.steps} />
                </>
              }
            >
              {props => (
                <RangePicker
                  value={props.value}
                  onChange={value => {
                    value.startDate &&
                      value.endDate &&
                      props.onChange({ endDate: value.endDate, startDate: value.startDate });
                    model.form.validate();
                  }}
                />
              )}
            </Field>
            <Field field={fields.votingDateRange} label="Voting timeframe">
              {props => (
                <RangePicker
                  disabledDate={date => date && date < moment(fields.submissionDateRange.value?.startDate)}
                  value={props.value}
                  onChange={value => {
                    value.startDate &&
                      value.endDate &&
                      props.onChange({ endDate: value.endDate, startDate: value.startDate });
                    model.form.validate();
                  }}
                />
              )}
            </Field>
          </>
        )}
      </Form>
    </>
  );
};
