import { Col, Divider, Empty, Row, Spin } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Command } from "react-mvvm/dist";
import { IconType, SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { RichTextViewer } from "../../../shared/richText/RichTextViewer";
import { AppNotification } from "../../../shared/stories/notification/AppNotification";
import { UserAvatar } from "../../../shared/userAvatar/UserAvatar";
import { NotificationAreaDto } from "../../../types/notifications/dto/NotificationAreaDto";

interface NotificationListProps {
  notifications: AppNotification[];
  isLoading: boolean;
  title: string;
  icon: React.ReactNode;
  onClickCommand: Command<AppNotification, void>;
  listItemsClassName?: string;
  loadMore: () => Promise<void>;
}

interface NotificationListItemProps {
  notification: AppNotification;
  onClickCommand: Command<AppNotification, void>;
}

const notificationCategoryIconMapping: {
  [key: string]: IconType;
} = {
  IdeaCommentCreatedCategory: "speechBubble",
  IdeaCreatedCategory: "speechBubble",
  InitiativeStateSetToClosedCategory: "edit",
  InitiativeStateSetToImplementationCategory: "edit",
  InitiativeStateSetToCalculationCategory: "edit",
  IdeaNewVoteReceivedCategory: "thumbUp",
  InitiativeCollectionPeriodStartingSoonCategory: "clock",
  InitiativeCollectionPeriodEndingSoonCategory: "clock",
  InitiativeVotingPeriodStartingSoonCategory: "clock",
  InitiativeVotingPeriodEndingSoonCategory: "clock",
  InitiativeCollectionPeriodStartedCategory: "clock",
  InitiativeCollectionPeriodEndedCategory: "clock",
  InitiativeVotingPeriodStartedCategory: "clock",
  InitiativeVotingPeriodEndedCategory: "clock",
  InitiativeResetCategory: "refresh",
  IdeaReleasedCategory: "vote",
  IdeaRemovedCategory: "delete",
};

const NotificationListItem: React.FC<NotificationListItemProps> = observer(({ notification, onClickCommand }) => {
  return (
    <div
      className={classNames("NotificationListItem", notification.isRead ? "" : "NotificationListItem--unread")}
      onClick={() => onClickCommand.execute(notification)}
      aria-hidden="true"
    >
      {notification.notificationArea !== NotificationAreaDto.DirectMention && (
        <div className="NotificationListItem__title">
          <SvgIcon
            iconName={notificationCategoryIconMapping[notification.notificationCategory] ?? "bellOutlined"}
            className="icon"
          />
          <span>{notification.title}</span>
        </div>
      )}
      {notification.notificationArea === NotificationAreaDto.DirectMention && (
        <div className="NotificationListItem__title--mention">
          <Row>
            <Col>
              <UserAvatar
                userName={notification.triggeredByName}
                className="userAvatar"
                avatarId={notification.triggeredBy?.avatarId}
              />
            </Col>
            <Col>
              <div className="userName">{notification.triggeredByName}</div>
              <div>{notification.title}</div>
            </Col>
          </Row>
        </div>
      )}
      {notification.targetTitle && (
        <div className="NotificationListItem__targetTitle">
          <div className="targetAccent targetAccent--Initiative" />
          <span className="titleText">{notification.targetTitle}</span>
        </div>
      )}
      {notification.content && (
        <RichTextViewer
          className="NotificationListItem__content"
          maxLength={100}
          content={notification.content}
          disableExpand
        />
      )}
    </div>
  );
});

export const NotificationList: React.FC<NotificationListProps> = observer(
  ({ notifications, isLoading, title, icon, onClickCommand, listItemsClassName, loadMore }) => {
    // implemented infinite scroll with IntersectionObserver API
    const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

    const intersectionObserver = useRef(
      new IntersectionObserver(entries => {
        const first = entries[0];
        if (first.isIntersecting) {
          loadMore();
        }
      })
    );

    useEffect(() => {
      const currentElement = lastElement;
      const currentObserver = intersectionObserver.current;
      if (currentElement) {
        currentObserver.observe(currentElement);
      }
      return () => {
        if (currentElement) {
          currentObserver.unobserve(currentElement);
        }
      };
    }, [lastElement]);

    return (
      <div className="NotificationList">
        <div className="NotificationList__header">
          <div className="NotificationList__headerIcon">{icon}</div>
          <div className="NotificationList__headerTitle">{title}</div>
        </div>
        <div className={`NotificationList__items ${listItemsClassName}`}>
          {notifications.map(notification => (
            <div ref={setLastElement} key={notification.id}>
              <NotificationListItem key={notification.id} notification={notification} onClickCommand={onClickCommand} />
              <div className="NotificationList__dividerWrapper">
                <Divider
                  className={notification.isRead ? "NotificationList__divider" : "NotificationList__divider--unread"}
                />
              </div>
            </div>
          ))}
          {!isLoading && notifications.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {isLoading && (
            <div className="NotificationList__SpinWrapper">
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    );
  }
);
