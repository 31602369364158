import { observer } from "mobx-react";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

export interface IWorkspaceShadowProps {
  color: string;
}

export const WorkspaceShadow = observer(({ color }: IWorkspaceShadowProps) => {
  const footer: React.RefObject<HTMLDivElement> = useRef(null);
  const [sizes, setSizes] = useState({
    rect1Width: 0,
    rect2Width: 0,
    rect3Width: 0,
  });

  const calculateShadowSize = useCallback(() => {
    if (footer.current) {
      const footerSizes = footer.current.getBoundingClientRect();
      setSizes({
        rect1Width: footerSizes.width - 48,
        rect2Width: footerSizes.width - 32,
        rect3Width: footerSizes.width - 16,
      });
    }
  }, []);

  useEffect(() => {
    calculateShadowSize();
  }, []);

  useLayoutEffect(() => {
    calculateShadowSize();
    window.addEventListener("resize", calculateShadowSize);
    return () => window.removeEventListener("resize", calculateShadowSize);
  }, []);

  return (
    <div className="WorkspaceCard__Shadow" style={{ fill: color }} ref={footer}>
      <svg fill="current" xmlns="http://www.w3.org/2000/svg" width="100%">
        <rect opacity="0.2" x="24" y="0" rx="8" height="24" width={sizes.rect1Width} />
        <rect opacity="0.5" x="16" y="0" rx="8" height="16" width={sizes.rect2Width} />
        <rect opacity="1" x="8" y="-8" rx="8" height="16" width={sizes.rect3Width} />
      </svg>
    </div>
  );
});
