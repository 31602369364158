import * as React from "react";
import { AppLogo } from "../../shared/logo/AppLogo";
import Bulb from "../../static/images/img-bulb.png";
import Orange from "../../static/images/img-orange.png";

interface SignInPageProps {
  className?: string;
  hideImages?: boolean;
}

export const SignInPage: React.FC<SignInPageProps> = ({ className, children, hideImages }) => {
  return (
    <div>
      <nav className="SignInNav">
        <AppLogo size="large" variant="contrast" includeText />
      </nav>
      <main className="SignInPage">
        <div className="SignInPage__FormContainer">
          <div className={className}>{children}</div>
          {!hideImages && (
            <img
              src={Orange}
              alt="register page background orange"
              className="SignInPage__FormContainer--firstBackground"
            />
          )}
          {!hideImages && (
            <img src={Bulb} alt="page background bulb" className="SignInPage__FormContainer--secondBackground" />
          )}
        </div>
      </main>
    </div>
  );
};
