import { observable } from "mobx";
import { Command, command } from "react-mvvm";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { UserStore } from "../../../../shared/stories/user/UserStore";
import { WorkspaceMember } from "../../../../shared/stories/workspace/WorkspaceMember";
import { WorkspaceStore } from "../../../../shared/stories/workspace/WorkspaceStore";
import { CreateWorkspaceModel } from "./CreateWorkspaceModel";
import { CreateWorkspaceWizard } from "./createWorkspaceWizard/CreateWorkspaceWizard";

export class CreateWorkspaceDialogViewModel extends DialogViewModel<never> {
  @observable.ref wizard?: CreateWorkspaceWizard;

  cancel: Command;

  constructor(
    private readonly workspaceStore: WorkspaceStore,
    readonly userStore: UserStore,
    private onSuccess: (newWorkspaceId: string) => Promise<void>
  ) {
    super();
    this.cancel = command(() => this.hide());
  }

  async createWorkspace(createWorkspaceModel: CreateWorkspaceModel) {
    const workspace = this.workspaceStore.createWorkspace(createWorkspaceModel.id);

    workspace.name = createWorkspaceModel.name;
    workspace.description = createWorkspaceModel.description;
    workspace.themeColor = createWorkspaceModel.themeColor;
    workspace.isPrivate = createWorkspaceModel.isPrivate;
    workspace.members = createWorkspaceModel.members.map(x => {
      const member = new WorkspaceMember();
      member.id = x.id;
      member.email = x.email;
      member.name = x.name;
      member.jobTitle = x.jobTitle;
      member.photoId = x.photoId;
      member.workspaceRole = x.workspaceRole;
      return member;
    });
    workspace.numberOfMembers = workspace.members.length;

    try {
      await workspace.save();
      await workspace.updateWorkspace();

      await this.onSuccess(createWorkspaceModel.id);
    } catch {
      this.workspaceStore.removeWorkspace(workspace);
    }
  }

  protected async onShow(): Promise<void> {
    const currentUser = await this.userStore.getCurrentUser();

    this.wizard = new CreateWorkspaceWizard(
      async createWorkspaceModel => await this.createWorkspace(createWorkspaceModel),
      this.userStore,
      currentUser,
      this.workspaceStore
    );
  }
}
