import { Card, Col, List, Row, Skeleton, Typography } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { ContentView, isLoaded } from "react-mvvm";
import { DEFAULT_WORKSPACE_COLOR } from "../../../shared/Constants";
import { LoadMoreListView } from "../../../shared/List/LoadMoreListView";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { Url } from "../../../shared/Url";
import { ActivityIndicator } from "../../../shared/activityIndicator/ActivityIndicator";
import { Link } from "../../../shared/link/Link";
import WorkspaceCardFooter from "../../../static/images/workspace-card-footer.svg";
import { isAddNewItem } from "../../Initiatives/initiativeList/InitiativeListViewModel";
import { WorkspaceListViewModel } from "./WorkspaceListViewModel";
import { CreateWorkspaceDialog } from "./createWorkspaceDialog/CreateWorkspaceDialog";
import { SuccessWorkspaceDialog } from "./successDialog/SuccessWorkspaceDialog";
import { WorkspaceListCard } from "./workspaceListCard/WorkspaceListCard";
import { WorkspaceShadow } from "../components/workspaceCard/WorkspaceShadow";

const { Title } = Typography;

export const WorkspaceListView = observer(({ model }: { model: WorkspaceListViewModel }) => {
  if (model.childPage) return <ContentView content={model.childPage} />;

  const { isLoading, state, createWorkspaceDialogViewModel, showCreateWorkspaceDialog, successDialogViewModel } = model;

  return (
    <div className="WorkspaceList__root">
      <ActivityIndicator isLoading={isLoading}>
        {isLoaded(state) && (
          <>
            <Row>
              <Col md={15} lg={12}>
                <Col className="WorkspaceList__col">
                  <Row align="middle" justify="space-between" className="WorkspaceList__workspacesHeader">
                    <Title level={2} className="Title--withIcon">
                      <SvgIcon iconName="box" /> Workspaces ({state.workspaceList.totalCount})
                    </Title>
                  </Row>
                  <LoadMoreListView
                    grid={{
                      gutter: 32,
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 4,
                      xxl: 4,
                    }}
                    className="WorkspaceList__items"
                    loadMoreList={state.workspaceList}
                    hideLoading={model.hideLoading}
                    skeleton={
                      model.hideLoading === false && (
                        <List.Item>
                          <Card className="WorkspaceCard__skeleton" hoverable size="small">
                            <Skeleton title loading active />
                          </Card>
                        </List.Item>
                      )
                    }
                    renderItem={item => {
                      if (isAddNewItem(item)) {
                        return (
                          <List.Item onClick={() => showCreateWorkspaceDialog.execute()}>
                            <Card
                              className="AddWorkspaceCard"
                              hoverable
                              onClick={() => showCreateWorkspaceDialog.execute()}
                            >
                              <SvgIcon iconName="plusCircle" />
                              <Title level={3}>Add workspace</Title>
                              <div className="WorkspaceCard__FooterIcon" style={{ fill: DEFAULT_WORKSPACE_COLOR }}>
                                <WorkspaceShadow color={DEFAULT_WORKSPACE_COLOR} />
                              </div>
                            </Card>
                          </List.Item>
                        );
                      }
                      return (
                        <List.Item>
                          <Link to={Url.toWorkspace({ workspaceId: item.id })}>
                            <WorkspaceListCard
                              model={item}
                              currentUserIsOwner={model.userStore.loggedUser.id === item.ownerId}
                            />
                          </Link>
                        </List.Item>
                      );
                    }}
                  />
                </Col>
              </Col>
            </Row>
            <CreateWorkspaceDialog model={createWorkspaceDialogViewModel} />
            <SuccessWorkspaceDialog model={successDialogViewModel} />
          </>
        )}
      </ActivityIndicator>
    </div>
  );
});
