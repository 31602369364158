import { Modal, ModalFuncProps } from "antd";
import classNames from "classnames";
import React from "react";
import { SvgIcon } from "../SvgIcon/SvgIcon";

interface IDialogProps {
  title: string;
  content?: string | React.ReactNode;
  onOk?: () => void;
  icon?: React.ReactNode;
  okText?: string;
  className?: string;
  onCancel?: () => void;
  maskClosable?: boolean;
  closable?: boolean;
  afterClose?: () => void;
}

interface IConfirmDialogProps extends IDialogProps {
  onCancel?: () => void;
  cancelText?: string;
  cancelButtonVisible?: boolean;
}

function populateCommonProps(props: IDialogProps): ModalFuncProps {
  return {
    ...props,
    closable: props?.closable ?? true,  
    closeIcon: <SvgIcon iconName="close" className="closeButton" />,
    className: classNames(props.className, "Dialogs"),
    onOk: () => {
      props.onOk && props.onOk();
    },
  };
}

function showConfirmDialog(props: IConfirmDialogProps) {
  const commonProps = populateCommonProps(props);

  return Modal.confirm({
    ...commonProps,
    cancelButtonProps: { hidden: !(props.cancelButtonVisible ?? true) },
    icon: props.icon ?? <SvgIcon iconName="questionCircled" />,
    className: classNames(commonProps.className, "Dialogs--AccentNegative"),
    closable: false,
  });
}

function showInfoDialog(props: IDialogProps) {
  return Modal.info({ ...populateCommonProps(props) });
}

function showWarningDialog(props: IDialogProps) {
  return Modal.warning({ ...populateCommonProps(props) });
}

function showSuccessDialog(props: IDialogProps) {
  return Modal.success({
    ...populateCommonProps(props),
    icon: props.icon ?? <SvgIcon iconName="success" className="customicon--primary" />,
  });
}

function showErrorDialog(props: IDialogProps) {
  return Modal.error({ ...populateCommonProps(props) });
}

function confirmDataLoss(execute: (input: void) => Promise<void>) {
  showConfirmDialog({
    title: "Do you want to close?",
    content: "You will lose all unsaved data.",
    okText: "Close",
    onOk: () => execute(),
  });
}

export const DialogSupport = {
  showConfirmDialog,
  showInfoDialog,
  showWarningDialog,
  showSuccessDialog,
  showErrorDialog,
  confirmDataLoss,
};
