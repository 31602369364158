import { Layout } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { ContentView } from "react-mvvm";
import { AppLayout } from "../layout/AppLayout";
import { AppContent } from "../layout/appContent/AppContent";
import { Header } from "../layout/header/Header";
import { SidePanel } from "../layout/sidePanel/SidePanel";
import { ErrorView } from "../shared/errorView/ErrorView";
import { useHistory, useUrl } from "../useRouter";
import { App } from "./App";
import { RequestAccessView } from "../shared/requestAccess/requestAccess/RequestAccessView";
import { AddUsers } from "../shared/addUsers/AddUsers";

export const AppPage = observer(({ model }: { model: App }) => {
  const { errorService, navigationService } = model;

  // Temporary hack to achieve https://fortedigital.visualstudio.com/Idea.ly/_boards/board/t/Idea.ly%20Team/Stories/?workitem=10217
  // Remove when needed
  const history = useHistory();
  const url = useUrl();

  React.useEffect(() => {
    if (!history) {
      return;
    }

    if (location.pathname === "/") {
      window.location.href = url.toWorkspaces();
    }

    const historyListenerDisposer = history.listen(location => {
      if (location.pathname === "/") {
        window.location.href = url.toWorkspaces();
      }
    });

    return () => historyListenerDisposer();
  }, []);

  return (
    <AppLayout>
      <Header model={model} />
      <Layout>
        <SidePanel model={model} />

        <AppContent isDefaultUser={model.rootStore.userStore.isDefaultUser} forbidden={errorService.requestAccess}>
          {!errorService.errorCode && !errorService.requestAccess && <ContentView content={model.appChildPage} />}

          {errorService.errorCode && <ErrorView errorCode={errorService.errorCode} />}
          {errorService.requestAccess && (
            <RequestAccessView resourceId={errorService.accessResourceId} navigationService={navigationService} />
          )}
          <AddUsers model={model.addUsersViewModel} />
        </AppContent>
      </Layout>
    </AppLayout>
  );
});
