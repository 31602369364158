import { computed, reaction } from "mobx";
import { bindableForm } from "react-mvvm";
import { VoteMethodConfig } from "src/shared/stories/vote/VoteStore";
import { INT_32_MAX_VALUE } from "../../../../../../shared/Constants";
import { Step } from "../../../../../../shared/stepWizard/Step";
import { ModalsWalkTour } from "../../../../../../shared/walkTour/ModalsWalkTour";
import { CreateInitiativeModel } from "../../CreateInitiativeModel";
import { CreateInitiativeStep, CreateInitiativeStepName } from "../CreateInitiativeStep";

export function isCreateInitiativeVotingSystem(
  step: Step<CreateInitiativeStepName>
): step is CreateInitiativeVotingSystemModel {
  return step.name === "VotingSystem";
}

export class CreateInitiativeVotingSystemModel extends CreateInitiativeStep<
  "votingMethod" | "votesPerInitiative" | "maxVotesPerIdea"
> {
  readonly name = "VotingSystem";

  @computed get minMaxVotesPerInitiative() {
    const { value } = this.form.fields.votingMethod;
    if (value) {
      return this.votesConfig[value].votesPerPoll;
    }
    return { minValue: 0, maxValue: 1 };
  }

  @computed get minMaxVotesPerIdea() {
    const { value } = this.form.fields.votingMethod;
    if (value) {
      return this.votesConfig[value].votesPerPollOption;
    }
    return { minValue: 0, maxValue: 1 };
  }

  form = bindableForm<CreateInitiativeModel>()
    .addField("votingMethod")
    .addField("votesPerInitiative")
    .addField("maxVotesPerIdea")
    .bindTo(() => this.createInitiativeModel);

  steps = ModalsWalkTour.getCreateInitiativeVotingMethodSteps();

  private previousMaxVotesPerInitaitive?: number;

  constructor(
    protected createInitiativeModel: CreateInitiativeModel,
    protected readonly votesConfig: VoteMethodConfig
  ) {
    super();

    reaction(
      () => this.form.fields.votingMethod.value,
      () => {
        this.form.fields.votesPerInitiative.value = this.defaultValues.votesPerInitiative;
        this.form.fields.maxVotesPerIdea.value = this.defaultValues.votesPerIdea;
      }
    );
  }

  @computed get defaultValues() {
    const { value } = this.form.fields.votingMethod;
    if (value) {
      const votesPerInitiative: number = this.votesConfig[value].votesPerPoll.defaultValue;
      const votesPerIdea: number = this.votesConfig[value].votesPerPollOption.defaultValue;
      return { votesPerInitiative, votesPerIdea };
    }
    return { votesPerInitiative: 2, votesPerIdea: 1 };
  }

  @computed get maxVotesPerIdea() {
    const votesPerInitiative = this.form.fields.votesPerInitiative.value || 0;
    const maxVotesPerIdea = this.form.fields.maxVotesPerIdea.value || 0;
    return maxVotesPerIdea < votesPerInitiative && maxVotesPerIdea <= this.minMaxVotesPerIdea.maxValue
      ? this.minMaxVotesPerIdea.maxValue
      : votesPerInitiative;
  }

  @computed get minVotesPerInitiative() {
    return this.form.fields.maxVotesPerIdea.value || this.minMaxVotesPerInitiative.minValue;
  }

  @computed get hasInfiniteVotes() {
    return this.form.fields.votesPerInitiative.value === INT_32_MAX_VALUE;
  }

  setInfiniteVotes(value: boolean) {
    if (value) {
      this.previousMaxVotesPerInitaitive = this.form.fields.votesPerInitiative.value;
      this.form.fields.votesPerInitiative.value = INT_32_MAX_VALUE;
    } else
      this.form.fields.votesPerInitiative.value =
        this.previousMaxVotesPerInitaitive ?? this.defaultValues.votesPerInitiative;
  }

  canGotoNextStep() {
    return (
      this.form.isDirty ||
      !!(
        this.form.fields.maxVotesPerIdea.value &&
        this.form.fields.votesPerInitiative.value &&
        this.form.fields.maxVotesPerIdea.value <= this.form.fields.votesPerInitiative.value
      )
    );
  }
}
