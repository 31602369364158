import { computed, observable } from "mobx";
import { command, Command } from "react-mvvm";
import { Step } from "./Step";

export abstract class AbstractStepWizard<StepTypeParam> {
  @observable currentStepIndex = 0;

  goToNextStep: Command<void, Promise<void>>;

  goToPreviousStep: Command;

  submit: Command<void, Promise<void>>;

  protected abstract readonly steps: Step<StepTypeParam>[];

  @computed get currentStepOrder() {
    return this.currentStepIndex;
  }

  @computed get currentStep() {
    return this.steps[this.currentStepIndex];
  }

  @computed get stepCount() {
    return this.steps.length;
  }

  @computed get isGoToNextStepVisible() {
    return this.currentStepIndex < this.steps.length - 1;
  }

  @computed get isGoToPreviousStepVisible() {
    return this.currentStepIndex > 0;
  }

  @computed get isSubmitVisible() {
    return this.currentStepIndex === this.steps.length - 1;
  }

  constructor(onSubmit: () => Promise<void>) {
    this.goToNextStep = command(
      async () => {
        if (!(await this.currentStep.goToNextStep())) return;
        this.currentStepIndex += 1;
      },
      () => this.currentStepIndex < this.steps.length - 1 && this.currentStep.canGotoNextStep()
    );
    this.goToPreviousStep = command(
      () => {
        this.currentStepIndex -= 1;
      },
      () => this.currentStepIndex > 0
    );
    this.submit = command(
      async () => {
        await this.currentStep.commitStep?.();
        await onSubmit();
      },
      () => (this.currentStep.isValid !== undefined ? this.currentStep.isValid() : true)
    );
  }
}
