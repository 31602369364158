import { IRoute } from "react-mvvm";
import { App } from "../App";
import { UserProfileViewModel } from "./UserProfileViewModel";

export type InitiativeParams = { initiativeId: string };
export type IdeaParams = { ideaId: string };

export const addUserProfileRouter = (route: IRoute<App, never, never>) => {
  route.addRoute<UserProfileViewModel>("/profile", UserProfileViewModel, appPage => {
    const userProfile = new UserProfileViewModel(
      appPage.errorService,
      appPage.rootStore.userStore,
      appPage.rootStore.fileStore
    );
    return appPage.showChildPage(userProfile);
  });
};
