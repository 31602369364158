import { observable } from "mobx";
import { Command, command } from "react-mvvm";
import { DateFormatter } from "../../../../shared/DateFormatter";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { FileStore } from "../../../../shared/stories/file/FileStore";
import { InitiativeStore } from "../../../../shared/stories/initiative/InitiativeStore";
import { VoteStore } from "../../../../shared/stories/vote/VoteStore";
import { CreateInitiativeModel } from "./CreateInitiativeModel";
import { CreateInitiativeWizard } from "./createInitiativeWizard/CreateInitiativeWizard";

export class CreateInitiativeDialogViewModel extends DialogViewModel<never> {
  @observable.ref wizard?: CreateInitiativeWizard;

  cancel: Command;

  constructor(
    readonly fileStore: FileStore,
    private readonly initiativeStore: InitiativeStore,
    private readonly voteStore: VoteStore,
    private readonly workspaceId: string,
    private onSuccess: (newInitiativeId: string) => Promise<void>
  ) {
    super();

    this.cancel = command(() => {
      this.hide();
    });
  }

  async createInitiative(createInitiativeModel: CreateInitiativeModel) {
    const newInitiative = this.initiativeStore.createInitiative();

    newInitiative.description = createInitiativeModel.description;
    newInitiative.title = createInitiativeModel.title;
    newInitiative.submissionStartDate = DateFormatter.toStartTimeOfDayUTC(
      createInitiativeModel.submissionDateRange.startDate
    );
    newInitiative.submissionEndDate = DateFormatter.toEndTimeOfDayUTC(
      createInitiativeModel.submissionDateRange.endDate
    );
    newInitiative.votingStartDate = DateFormatter.toStartTimeOfDayUTC(createInitiativeModel.votingDateRange?.startDate);
    newInitiative.votingEndDate = DateFormatter.toEndTimeOfDayUTC(createInitiativeModel.votingDateRange?.endDate);
    newInitiative.votesPerPoll = createInitiativeModel.votesPerInitiative;
    newInitiative.maxVotesPerPollOption = createInitiativeModel.maxVotesPerIdea;
    newInitiative.votingMethod = createInitiativeModel.votingMethod;
    newInitiative.attachments = createInitiativeModel.attachments;
    newInitiative.hideIdeaSubmitter = createInitiativeModel.hideIdeaSubmitter;
    newInitiative.tags = createInitiativeModel.tags;
    newInitiative.workspaceId = this.workspaceId;

    try {
      await newInitiative.save();

      await this.onSuccess(newInitiative.id);
    } catch {
      this.initiativeStore.removeInitiative(newInitiative);
    }
  }

  protected async onShow() {
    const votingConfig = await this.voteStore.getVotingConfig();

    this.wizard = new CreateInitiativeWizard(
      async createInitiativeModel => await this.createInitiative(createInitiativeModel),
      votingConfig,
      this.fileStore,
      this.initiativeStore
    );
  }
}
