import { Layout } from "antd";
import classNames from "classnames";
import * as React from "react";

interface AppLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export const AppLayout: React.FC<AppLayoutProps> = ({ className, children }) => {
  return <Layout className={classNames("AppLayout", className)}>{children}</Layout>;
};
