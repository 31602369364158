/* eslint-disable jsx-a11y/label-has-associated-control */
import { Radio } from "antd";
import classNames from "classnames";
import * as React from "react";

export interface IRadioButtonProps<T> {
  value: T;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}
export const RadioButton = <T,>({ value, disabled, className, children }: IRadioButtonProps<T>) => {
  return (
    <Radio.Button value={value} className={classNames("RadioButton", className)} disabled={disabled}>
      {children}
    </Radio.Button>
  );
};
