import { action, observable } from "mobx";
import { Command, command } from "react-mvvm";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";

export class SuccessDialogViewModel extends DialogViewModel<never> {
  @observable initiativeId?: string;

  cancel: Command;

  constructor(public showInitiative: Command<string, void>) {
    super();

    this.cancel = command(() => {
      this.hide();
    });
  }

  @action
  setInitiativeId(newInitiativeId: string) {
    this.initiativeId = newInitiativeId;
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}
}
