import { Button } from "antd";
import classNames from "classnames";
import { DeltaStatic, Sources } from "quill";
import React, { FC, useEffect, useState } from "react";
import ReactQuill, { UnprivilegedEditor } from "react-quill";
import { Formats } from "./RichTextEditor"
const { htmlToText } = require('html-to-text');

// Quill editor docs
// See https://quilljs.com/

interface RichTextViewerProps {
  content?: string;
  height?: number;
  maxHeight?: number;
  maxLength?: number;
  maxLines?: number;
  className?: string;
  disableExpand?: boolean;
  hideExpandButton?: boolean;
  addPaddingBottom?: boolean;
}

export const RichTextViewer: FC<RichTextViewerProps> = ({
  content,
  height,
  maxHeight,
  maxLength = content?.length ?? 0,
  maxLines = -1,
  className,
  disableExpand,
  hideExpandButton,
  addPaddingBottom = false
}) => {
  const [editorHtml, setEditorHtml] = useState('');
  const [showExpand, setShowExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const quill = React.createRef<ReactQuill>();
  

  const modules = {
    toolbar: null,
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
    },

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };


  useEffect(() => {
    

    if (quill.current?.editor?.root && height) {
      quill.current.editor.root.style.height = `${height}px`
    }

    if (quill.current?.editor?.root && maxHeight) {
      quill.current.editor.root.style.maxHeight = `${maxHeight}px`
    }


  },[])


  useEffect(() => {

    quill.current?.editor?.on("text-change", e => { onTextChange(e) });
    quill.current?.editor?.disable();

    const plainText = getPlaintText(content);
    const isEmpty = isStringEmpty(plainText);
   
    if (isEmpty) {
      setEditorHtml('<p></p>');
    }
    else {
      setContent(content);
    }

  }, [content]);

  const onTextChange = (e: DeltaStatic) => {
    const html = quill.current?.editor?.root.innerHTML;
    if(html === undefined)
      return;

    const plainText = getPlaintText(html);
    const isEmpty = isStringEmpty(plainText);;
    const newLines = quill.current?.editor?.getLines().length ?? 0;
    const exceededAllowedMaxLines = maxLines === -1 ? false : newLines > maxLines;
    const clientHeight = (quill.current?.editor?.root.clientHeight) ? (quill.current?.editor?.root.clientHeight) + 5 : 0
    const hasScroll = clientHeight < (quill.current?.editor?.root.scrollHeight ?? 0)
    const isOverflow = (plainText.length > maxLength || hasScroll || exceededAllowedMaxLines) && !isEmpty;
    const showExpand = isOverflow && !disableExpand;

    setIsOverflow(isOverflow);
    setShowExpand(showExpand);

    if (showExpand) {
      if (quill.current?.editor?.root) {
        quill.current.editor.root.style.webkitLineClamp = `${maxLines}`;
      }
    }
  }

  const handleExpand = () => {
    setExpanded(prev => !prev);

    if (expanded) {
      setEditorHeight(`${maxHeight}px`);
    }
    else {
      setEditorHeight(`auto`);
    }

    scrollEditorToTop();
  };

  const setEditorHeight = (height: string) => {
    if (quill.current?.editor?.root) {
      quill.current.editor.root.style.height = `${height}`
    }
  }

  const scrollEditorToTop = () => {
    if (quill.current?.editor?.root) {
      quill.current.editor.root.scrollTo(0, 0);
    }
  }

  const setContent = (c?: string) => {
    var htmlContent = c ?? content ?? '';
    htmlContent = htmlContent.replace(/(?:\r\n|\r|\n)/g, '<br>');
    setEditorHtml(htmlContent);
  }

  const isStringEmpty = (val: string): boolean => {
    return val.replace("\n", "").trim() === "";
  }

  const getPlaintText = (html: string | undefined): string => {

    if (html === undefined)
      return '';

    return htmlToText(html, {
      wordwrap: false,
      preserveNewlines: true,
      formatters: {
        // Create a formatter.
        'iframeFormatter': function (elem: any, walk: any, builder: any, formatOptions: any) {
          builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
          walk(elem.children, builder);
          builder.addInline(`${elem.attribs.src}`);
          builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
        }
      },
      selectors: [
        {
          selector: 'iframe',
          format: 'iframeFormatter',
          options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
        }
      ]
    });
  }

  return (
    <div className={classNames(
      "RichTextViewer__root",
      showExpand ? (expanded ? "expanded" : "collapsed") : "",
      addPaddingBottom ? "padding-bottom" : "",
      className)}>
      <ReactQuill
        theme={''}
        value={editorHtml}
        modules={{ ...modules }}
        formats={Formats}
        bounds={'.RichTextEditor__root'}
        ref={quill}
      />

      {showExpand && !hideExpandButton && (
        <Button className="RichTextViewer__expandBtn" onClick={handleExpand} type="link">
          {expanded ? "Collapse" : "...Expand"}
        </Button>
      )}
    </div>
  );
};
