import { MouseEvent } from "react";
import { Command } from "./Command";
import { ContextualCommand } from "./ContextualCommand";

export * from "./Command";
export * from "./ContextualCommand";

export function asyncCommand<TIn, TOut>(execute : (input:TIn) => Promise<TOut>, enabled? : () => boolean){
    return new Command<TIn, TOut>(execute, enabled)
}

export function command<TIn, TOut>(execute : (input:TIn) => TOut, enabled? : () => boolean) {
    return new Command<TIn, TOut>(execute, enabled);
}

export function bindToCommand(command : Command<void, any>) {
    return {
        onClick: (e : MouseEvent) => { e.preventDefault(); e.stopPropagation(); return command.execute() },
        disabled: !command.isEnabled
    }
}

export function bindToContextualCommand<T>(command : ContextualCommand<T>, context : T) {
    return {
        onClick: (e : MouseEvent) => { e.preventDefault(); e.stopPropagation(); return command.execute(context) },
        disabled: !command.canExecute(context)
    }
}