import { Typography } from "antd";
import React from "react";
import { ReactourStep } from "reactour";
import { WalkTourPopover } from "./WalkTour";

const { Text } = Typography;

export enum CreateInitiativeVotingMethodWalkTourSelectors {
  None = "None",
  Dots = "Dots",
  Likes = "Likes",
  Invest = "Invest",
}

export class ModalsWalkTour {
  static getCreateInitiativeVotingMethodSteps() {
    const steps: ReactourStep[] = [
      {
        selector: CreateInitiativeVotingMethodWalkTourSelectors.None,
        content: () => (
          <WalkTourPopover icon="bulb" title="Voting Methods">
            <Text>
              IdeaMate offers three different voting methods to facilitate team decision-making. Learn about the
              advantages of each.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${CreateInitiativeVotingMethodWalkTourSelectors.Dots}]`,
        content: () => (
          <WalkTourPopover icon="dots" title="Dots Method">
            <Text>
              Give colleagues the opportunity to cast several votes for one idea. However, limit the maximum number of
              votes they can cast.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${CreateInitiativeVotingMethodWalkTourSelectors.Likes}]`,
        content: () => (
          <WalkTourPopover icon="thumbUp" title="Like Methods">
            <Text>
              Specify the maximum number of votes available to colleagues. They will only be able to allocate one vote
              for each idea.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${CreateInitiativeVotingMethodWalkTourSelectors.Invest}]`,
        content: () => (
          <WalkTourPopover icon="dollar" title="Invest Methods">
            <Text>
              Allow users to specify the funds they wish to allocate to the solution. Indicate the maximum amount of
              money they can allocate for the whole initiative and for each individual idea.
            </Text>
          </WalkTourPopover>
        ),
      },
    ];
    return steps;
  }

  static getCreateInitiativeDataSteps() {
    const steps: ReactourStep[] = [
      {
        selector: "none",
        content: () => (
          <WalkTourPopover icon="bulb" title="Initiative duration">
            <Text>
              IdeaMate allows you to accurately design your decision-making process over time. Choose the period during
              contributors can submit ideas and the time during you will decide which ideas to support.
            </Text>
          </WalkTourPopover>
        ),
      },
    ];
    return steps;
  }
}
