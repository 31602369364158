import { Typography } from "antd";
import React from "react";
import { ReactourStep } from "reactour";
import { InitialWalkTour } from "./InitialWalkTour";
import { WalkTourPopover } from "./WalkTour";

const { Text } = Typography;

export enum KanbanBoardWalkTourSelectors {
  None = "None",
  Board = "Board",
  Filter = "Filter",
  Initiatives = "Initiatives",
}

export class KanbanBoardWalkTour {
  static getSteps() {
    const initialSteps = InitialWalkTour.getSteps();
    const steps: ReactourStep[] = [
      {
        selector: `[data-tour=${KanbanBoardWalkTourSelectors.Board}]`,
        content: () => (
          <WalkTourPopover title="Kanban Board" icon="table">
            <Text>On this board you will find all the initiatives in your organization group by status.</Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${KanbanBoardWalkTourSelectors.Filter}]`,
        content: () => (
          <WalkTourPopover icon="barChart" title="Filters">
            <Text>
              Each initiative has its own author and publication date. Filter initiatives to find the one that interests
              you.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${KanbanBoardWalkTourSelectors.Initiatives}]`,
        content: () => (
          <WalkTourPopover icon="diamond" title="Initiatives">
            <Text>Click on the initiative card and look at its details.</Text>
          </WalkTourPopover>
        ),
      },
    ];
    return [...initialSteps, ...steps];
  }
}
