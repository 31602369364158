import { computed, observable } from "mobx";
import { Step } from "../../../../../shared/stepWizard/Step";
import { UserStore } from "../../../../../shared/stories/user/UserStore";
import { WorkspaceMember } from "../../../../../shared/stories/workspace/WorkspaceMember";
import { WorkspaceStore } from "../../../../../shared/stories/workspace/WorkspaceStore";
import { GetWorkspaceMemberListSortType } from "../../../../../types/initiatives/dto/GetWorkspaceMemberListSortType";
import { SortDirection } from "../../../../../types/shared/dto/SortDirection";
import { CreateWorkspaceMembersStepViewModel } from "../../createWorkspaceDialog/createWorkspaceWizard/createWorkspaceMembersStep/CreateWorkspaceMembersStepViewModel";
import { EditWorkspaceModel } from "../EditWorkspaceModel";
import { EditWorkspaceSteps } from "../EditWorkspaceSteps";

export function isEditWorkspaceMembersStep(step: Step<EditWorkspaceSteps>): step is EditWorkspaceMembersStepViewModel {
  return step.name === "Members";
}

export class EditWorkspaceMembersStepViewModel extends CreateWorkspaceMembersStepViewModel {
  @observable private _isLoading = false;

  readonly name = "Members";

  @computed get isLoading() {
    return this._isLoading;
  }

  constructor(
    private readonly editWorkspaceModel: EditWorkspaceModel,
    userStore: UserStore,
    private readonly workspaceStore: WorkspaceStore
  ) {
    super(editWorkspaceModel, userStore);

    this._isLoading = true;
    this.getWorkspaceMembers().then(x => {
      this.form.fields.members.value = x;
      this._isLoading = false;
    });
  }

  private readonly getWorkspaceMembers = async (): Promise<WorkspaceMember[]> => {
    let members: WorkspaceMember[] = [];

    let page = 1;
    let hasNextPage = false;
    do {
      // eslint-disable-next-line no-await-in-loop
      const result = await this.workspaceStore.getWorkspaceMembers(
        this.editWorkspaceModel.id,
        { page, results: 50 },
        { sortDirection: SortDirection.Descending, sortType: GetWorkspaceMemberListSortType.Role }
      );

      members = members.concat(result.members);
      page += 1;
      hasNextPage = result.hasNextPage;
    } while (hasNextPage);

    return members;
  };
}
