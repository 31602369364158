import { Url as url } from "../Url";

export interface BreadcrumbItem {
  title: string;
  path?: string;
}

export interface BreadcrumbViewProps {
  items: BreadcrumbItem[];
  appendHomeItem?: boolean;
  appendWorkspacesItem?: boolean;
}

const createIdeaItem = (ideaId: string, initiativeId: string, title: string): BreadcrumbItem => ({
  title,
  path: url.toIdeaPage({ ideaId, initiativeId }),
});

const createInitiativeItem = (initiativeId: string, title: string): BreadcrumbItem => ({
  title,
  path: url.toInitiativePage({ initiativeId }),
});

const createWorkspaceItem = (workspaceId: string, title: string): BreadcrumbItem => ({
  title,
  path: url.toWorkspace({ workspaceId }),
});

export const Breadcrumb = {
  createIdeaItem,
  createInitiativeItem,
  createWorkspaceItem,
};
