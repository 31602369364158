import { Button, Popover } from "antd";
import * as React from "react";
import { Command } from "react-mvvm/dist";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { IdeaDetail } from "../../../../shared/stories/idea/Idea";
import { IdeaDetailViewModel } from "../IdeaDetailViewModel";
import { IdeaListItemViewModel } from "../IdeaListItemViewModel";

interface IIdeaExtraActionsProps {
  idea: IdeaDetailViewModel | IdeaListItemViewModel;
  showEditIdeaDialog: Command<IdeaDetail, Promise<void>>;
  showMoveIdeaDialog: Command<IdeaDetail, Promise<void>>;
}

export const IdeaExtraActions: React.FunctionComponent<IIdeaExtraActionsProps> = ({
  idea,
  showEditIdeaDialog,
  showMoveIdeaDialog,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <Popover
      visible={isVisible}
      onVisibleChange={setIsVisible}
      placement="rightTop"
      trigger="click"
      content={() => (
        <ul className="IdeaExtraActions">
          {idea.canUserEdit && (
            <li>
              <Button
                className="IdeaExtraActions__extraActionsButton"
                icon={<SvgIcon iconName="edit" />}
                size="small"
                type="link"
                onClick={() => {
                  showEditIdeaDialog.execute(idea.idea);
                  setIsVisible(false);
                }}
              >
                Edit
              </Button>
            </li>
          )}
          {idea.canSetAsReleased && (
            <li>
              <Button
                className="IdeaExtraActions__extraActionsButton"
                onClick={() => {
                  DialogSupport.showConfirmDialog({
                    title: "Do you want to release this idea?",
                    content: "This action cannot be reversed",
                    onOk: async () => {
                      await idea.realizeIdea.execute();
                    },
                    onCancel: () => {},
                  });
                  setIsVisible(false);
                }}
                icon={<SvgIcon iconName="vote" />}
                size="small"
                type="link"
              >
                Set as released
              </Button>
            </li>
          )}
          {idea.canUserMove && (
            <li>
              <Button
                className="IdeaExtraActions__extraActionsButton"
                onClick={() => {
                  showMoveIdeaDialog.execute(idea.idea);
                  setIsVisible(false);
                }}
                icon={<SvgIcon iconName="move" className="IdeaExtraActions__moveIcon" />}
                size="small"
                type="link"
              >
                Move idea
              </Button>
            </li>
          )}

          {idea.canUserRemove && (
            <li>
              <Button
                className="IdeaExtraActions__extraActionsButton"
                onClick={() => {
                  DialogSupport.showConfirmDialog({
                    title: "Do you want remove this idea?",
                    content: "This action cannot be reversed",
                    onOk: async () => {
                      await idea.removeIdea.execute();
                    },
                    onCancel: () => {},
                  });
                  setIsVisible(false);
                }}
                icon={<SvgIcon iconName="delete" />}
                size="small"
                type="link"
              >
                Remove
              </Button>
            </li>
          )}
        </ul>
      )}
    >
      <Button
        className="IdeaExtraActions__extraActionsButton"
        icon={<SvgIcon iconName="ellipsisOutlined" />}
        size="small"
        type="link"
        onClick={() => setIsVisible(prev => !prev)}
      />
    </Popover>
  );
};
