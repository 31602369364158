export const TAG_MAX_LENGTH = 50;
export const TAG_MIN_LENGTH = 2;

export function TagsValidator<T>(value: any | undefined) {
  const tags = value as string[];

  if (tags.find(t => t.length > TAG_MAX_LENGTH)) return [`Tags cannot include more than ${TAG_MAX_LENGTH} characters`];
  if (tags.find(t => t.length < TAG_MIN_LENGTH)) return [`Each tag must include at least ${TAG_MIN_LENGTH} characters`];
  if (tags.find(t => t.includes("#"))) return [`Tags cannot include # symbol`];
  if (tags.find(t => /\s/.test(t))) return [`Tags cannot include whitespaces`];

  return true;
}
