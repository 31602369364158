import { v4 as uuid } from "uuid";
import { AbstractStepWizard } from "../../../../../shared/stepWizard/AbstractWizard";
import { Step } from "../../../../../shared/stepWizard/Step";
import { UserStore } from "../../../../../shared/stories/user/UserStore";
import { WorkspaceStore } from "../../../../../shared/stories/workspace/WorkspaceStore";
import { WorkspaceRole } from "../../../../../types/ly/application/WorkspaceRole";
import { CurrentUserDto } from "../../../../../types/shared/dto/CurrentUserDto";
import { CreateWorkspaceModel } from "../CreateWorkspaceModel";
import { CreateWorkspaceStepName } from "./CreateWorkspaceStep";
import { CreateWorkspaceBasicStepViewModel } from "./createWorkspaceBasicStep/CreateWorkspaceBasicStepViewModel";
import { CreateWorkspaceMembersStepViewModel } from "./createWorkspaceMembersStep/CreateWorkspaceMembersStepViewModel";
import { CreateWorkspaceSummaryStepViewModel } from "./createWorkspaceSummaryStep/CreateWorkspaceSummaryStepViewModel";

export class CreateWorkspaceWizard extends AbstractStepWizard<CreateWorkspaceStepName> {
  createWorkspaceModel: CreateWorkspaceModel = {
    id: uuid(),
    name: "",
    description: "",
    themeColor: "",
    isPrivate: false,
    members: [],
  };

  protected steps: Step<CreateWorkspaceStepName>[] = [
    new CreateWorkspaceBasicStepViewModel(this.createWorkspaceModel, this.workspaceStore),
    new CreateWorkspaceMembersStepViewModel(this.createWorkspaceModel, this.userStore),
    new CreateWorkspaceSummaryStepViewModel(this.createWorkspaceModel),
  ];

  constructor(
    onSubmit: (createWorkspaceModel: CreateWorkspaceModel) => Promise<void>,
    private readonly userStore: UserStore,
    private readonly currentUser: CurrentUserDto,
    private readonly workspaceStore: WorkspaceStore
  ) {
    super(() => onSubmit(this.createWorkspaceModel));

    this.createWorkspaceModel.members.unshift({
      ...currentUser,
      workspaceRole: WorkspaceRole.Owner,
    });
  }
}
