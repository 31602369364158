import { List } from "antd";
import classNames from "classnames";
import * as React from "react";
import { AttachmentInput } from "../../../../shared/AttachmentInput/AttachmentInput";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { FileAttachment, ThumbnailSizeEnum, UrlAttachment } from "../../../../shared/attachment/Attachment";
import { Button } from "../../../../shared/button/Button";
import { TextInputWithPasteSupport } from "../../../../shared/pasteSupport/PasteSupport";
import { convertHtmlToText } from "../../../../shared/richText/utils";
import { FileStore } from "../../../../shared/stories/file/FileStore";
import { MAX_COMMENT_LENGTH } from "../../../../shared/validators/LengthValidatorsConst";
import { AttachmentDto } from "../../../../types/initiatives/dto/AttachmentDto";
import { AttachmentType } from "../../../../types/ly/application/AttachmentType";
import { useUrl } from "../../../../useRouter";

interface ICreateComment {
  text?: string;
  attachments?: AttachmentDto[];
}

interface ICommentInputViewProps {
  onConfirm: (comment: ICreateComment) => void;
  placeholder?: string;
  fileStore: FileStore;
}

export const CommentInputView: React.FunctionComponent<ICommentInputViewProps> = props => {
  const { onConfirm, placeholder } = props;
  const [commentText, setCommentText] = React.useState("");
  const [attachments, setAttachments] = React.useState<AttachmentDto[]>([]);

  const uploadFileUrl = useUrl().toUpload();
  const { fileStore } = props;

  function handleRemove(item: AttachmentDto) {
    setAttachments(prev => prev.filter(i => i.id !== item.id));
  }

  const commentTextLength = React.useMemo(() => {
    const plainText = convertHtmlToText(commentText);
    const { length } = plainText;
    return length;
  }, [commentText]);

  const onConfirmHandler = () => {
    if ((commentTextLength === 0 && attachments.length === 0) || commentTextLength > MAX_COMMENT_LENGTH) return;
    onConfirm({
      text: commentText,
      attachments,
    });
    setAttachments([]);
    setCommentText("");
  };

  return (
    <>
      <div className="Comment">
        <TextInputWithPasteSupport
          className="CommentInput"
          placeholder={placeholder}
          value={commentText}
          onChange={setCommentText}
          onPressEnter={onConfirmHandler}
          fileStore={fileStore}
          onAttachmentAdded={result => setAttachments(prev => prev.concat(result))}
          suffix={
            <>
              <AttachmentInput
                url={uploadFileUrl}
                className="CommentUploadFile"
                renderList={false}
                attachmentList={attachments}
                onChange={newAttachments => setAttachments(newAttachments)}
              />
              <Button disabled={commentTextLength > MAX_COMMENT_LENGTH} type="text" onClick={onConfirmHandler}>
                <SvgIcon
                  iconName="send"
                  className={classNames(
                    "CommentInput__sendIcon",
                    commentTextLength > MAX_COMMENT_LENGTH && "CommentInput__sendIcon--disabled"
                  )}
                />
              </Button>
            </>
          }
          richTextEditor
        />
        {attachments.length !== 0 && (
          <List
            size="small"
            className="CommentAttachmentsList"
            dataSource={attachments}
            renderItem={item => (
              <List.Item key={item.id}>
                {item.type === AttachmentType.Url ? (
                  <UrlAttachment
                    attachment={item}
                    onRemove={() => handleRemove(item)}
                    thumbnailSize={ThumbnailSizeEnum.Small}
                  />
                ) : (
                  <FileAttachment
                    attachment={item}
                    onRemove={() => handleRemove(item)}
                    thumbnailSize={ThumbnailSizeEnum.Small}
                  />
                )}
              </List.Item>
            )}
          />
        )}
      </div>
      <span
        className={classNames(
          "CommentInputErrorMessage",
          commentTextLength > MAX_COMMENT_LENGTH && "CommentInputErrorMessage--displayed"
        )}
      >
        Comment to large: {MAX_COMMENT_LENGTH} characters max
      </span>
    </>
  );
};
