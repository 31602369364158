import { message } from "antd";
import { autorun, computed, observable, onBecomeObserved } from "mobx";
import { createViewModel } from "mobx-utils";
import { command, Command, Deferred, isLoaded } from "react-mvvm";
import { RootStore } from "src/shared/stories/RootStore";
import { Breadcrumb } from "../../../shared/breadcrumb/utils";
import { DialogSupport } from "../../../shared/dialogs/Dialogs";
import { IErrorService } from "../../../shared/services/ErrorService";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { Workspace } from "../../../shared/stories/workspace/Workspace";
import { WorkspaceStore } from "../../../shared/stories/workspace/WorkspaceStore";
import { CurrentUserDto } from "../../../types/shared/dto/CurrentUserDto";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { BasePage } from "../../BasePage";
import { InitiativeListViewModel } from "../../Initiatives/initiativeList/InitiativeListViewModel";
import { EditWorkspaceDialogViewModel } from "../workspaceList/editWorkspaceDialog/EditWorkspaceDialogViewModel";
import { EditWorkspaceSteps } from "../workspaceList/editWorkspaceDialog/EditWorkspaceSteps";
import { ManageWorkspaceMembersDialogViewModel } from "../workspaceList/manageWorkspaceMembersDialog/ManageWorkspaceMembersDialogViewModel";
import { WorkspaceMembersViewModel } from "./components/WorkspaceMembers/WorkspaceMembersViewModel";

class Loaded {
  @observable workspace: Workspace;

  @observable loggedUser: CurrentUserDto;

  showEditWorkspaceDialog: Command<EditWorkspaceSteps[]>;

  showManageWorkspaceMembersDialog: Command<"edit" | "read-only">;

  editWorkspaceDialogViewModel: EditWorkspaceDialogViewModel;

  manageWorkspaceMembersDialogViewModel: ManageWorkspaceMembersDialogViewModel;

  public membersViewModel: WorkspaceMembersViewModel;

  constructor(
    workspace: Workspace,
    public owner: Deferred<UserDto>,
    loggedUser: CurrentUserDto,
    private readonly workspaceStore: WorkspaceStore,
    private readonly userStore: UserStore
  ) {
    this.workspace = workspace;
    this.loggedUser = loggedUser;

    this.editWorkspaceDialogViewModel = new EditWorkspaceDialogViewModel(
      async editedWorkspace => {
        await editedWorkspace.save();
        editedWorkspace.submit();
        this.editWorkspaceDialogViewModel.hide();
        DialogSupport.showSuccessDialog({ title: "Your changes were saved successfully", okText: "Go to workspace" });
        await workspace.updateMembers();
      },
      createViewModel(this.workspace),
      this.workspaceStore,
      this.userStore
    );

    this.manageWorkspaceMembersDialogViewModel = new ManageWorkspaceMembersDialogViewModel(
      async editedWorkspace => {
        await editedWorkspace.save();
        editedWorkspace.submit();
        this.manageWorkspaceMembersDialogViewModel.hide();
        DialogSupport.showSuccessDialog({ title: "Your changes were saved successfully", okText: "Go to workspace" });
        await workspace.updateMembers();
      },
      createViewModel(this.workspace),
      this.workspaceStore,
      this.userStore
    );

    this.showEditWorkspaceDialog = command(
      type => {
        this.editWorkspaceDialogViewModel.show(createViewModel(this.workspace), type);
      },
      () => this.workspace.canUserEdit
    );

    this.showManageWorkspaceMembersDialog = command(type => {
      this.manageWorkspaceMembersDialogViewModel.show(createViewModel(this.workspace), type);
    });

    this.membersViewModel = new WorkspaceMembersViewModel(workspace, this.showManageWorkspaceMembersDialog);
  }

  @computed get breadcrumbItems() {
    return [Breadcrumb.createWorkspaceItem(this.workspace.id, this.workspace.name)];
  }

  @computed get currentUserIsOwner() {
    return this.loggedUser.id === this.workspace.ownerId;
  }

  @computed get hasExtraActions() {
    return this.canUserEdit;
  }

  @computed get canUserEdit() {
    return this.workspace.canUserEdit;
  }
}

export class WorkspaceViewModel extends BasePage {
  @observable state: Deferred<Loaded> = "Loading";

  showCreateInitiativeDialog: Command;

  shareWorkspace: Command;

  public initiativesListViewModel: InitiativeListViewModel;

  constructor(
    public workspaceId: string,
    public workspaceStore: WorkspaceStore,
    public userStore: UserStore,
    public errorService: IErrorService,
    public rootStore: RootStore
  ) {
    super(errorService, false, userStore);
    this.initiativesListViewModel = new InitiativeListViewModel(
      rootStore.initiativeStore,
      rootStore.voteStore,
      rootStore.userStore,
      rootStore.commentStore,
      errorService,
      rootStore.ideaStore,
      rootStore.fileStore,
      workspaceId
    );

    this.initiativesListViewModel.isTopActionButtonsVisible = false;
    this.initiativesListViewModel.titlePrefix = "Workspace initiatives";

    this.showCreateInitiativeDialog = command(() => {
      this.initiativesListViewModel.showCreateInitiativeDialog.execute();
    });

    this.shareWorkspace = command(() => {
      navigator.clipboard.writeText(window.location.href);
      message.info({
        content: "The workspace link has been copied to the clipboard.",
      });
    });

    onBecomeObserved(this, "state", async () => {
      await Promise.all([this.initiativesListViewModel.loadDataAsync(), this.loadData()]);
    });
  }

  protected async loadData() {
    const workspace = await this.workspaceStore.getWorkspace(this.workspaceId);
    autorun(() => {
      this.initiativesListViewModel.themeColor = workspace.themeColor;
    });
    this.userStore.updateUser();
    const owner = await this.userStore.getUser(workspace?.ownerId ?? "");

    if (isLoaded(owner)) {
      workspace.ownerName = owner.name;
      workspace.ownerEmail = owner.email;
    }

    this.state = new Loaded(workspace, owner, this.userStore.loggedUser, this.workspaceStore, this.userStore);
    super.loadData();
  }
}
