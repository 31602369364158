import { Card, Col, Divider, Row, Typography, Button } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { ContentView, isLoaded } from "react-mvvm";
import { DateFormatter } from "../../../shared/DateFormatter";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { VotingMethodIcon } from "../../../shared/VotingMethodIcon/VotingMethodIcon";
import { ActivityIndicator } from "../../../shared/activityIndicator/ActivityIndicator";
import { AttachmentList } from "../../../shared/attachment/AttachmentList";
import { BreadcrumbView } from "../../../shared/breadcrumb/BreadcrumbView";
import { RichTextViewer } from "../../../shared/richText/RichTextViewer";
import { UserAvatar } from "../../../shared/userAvatar/UserAvatar";
import { IdeaPageWalkTourSelectors } from "../../../shared/walkTour/IdeaPageWalkTour";
import { WalkTour } from "../../../shared/walkTour/WalkTour";
import { CommentInputView } from "../shared/comments/CommentInputView";
import { CommentListView } from "../shared/comments/CommentListView";
import { EditIdeaDialog } from "../shared/editIdeaDialog/EditIdeaDialog";
import { IdeaExtraActions } from "../shared/ideaExtraActions/IdeaExtraActions";
import { MoveIdeaDialog } from "../shared/moveIdeaDialog/MoveIdeaDialog";
import { SubscribeButton } from "../shared/subscription/subscribeButton/SubscribeButton";
import { Vote } from "../shared/vote/Vote";
import { IdeaViewModel } from "./IdeaViewModel";
import { VotingMethodDto } from "../../../types/shared/dto/VotingMethodDto";
import { INT_32_MAX_VALUE } from "../../../shared/Constants";

const { Title, Paragraph, Text } = Typography;
const { Meta } = Card;

export const IdeaView = observer(({ model }: { model: IdeaViewModel }) => {
  if (model.childPage) {
    return <ContentView content={model.childPage} />;
  }
  const { state, isLoading } = model;

  return (
    <div className="Idea">
      <ActivityIndicator isLoading={isLoading}>
        {isLoaded(state) && (
          <>
            <Row justify="space-between">
              <Col>
                <div className="Idea__topBanner">
                  <BreadcrumbView items={state.breadcrumbItems} appendWorkspacesItem />
                </div>
              </Col>
              <Col>
                <SubscribeButton
                  command={state.idea.editSubscription}
                  isSubscribed={state.idea.isCurrentUserSubscribed}
                />
              </Col>
            </Row>
            <Card className="HighlightedBorder--blue" 
                  data-tour={IdeaPageWalkTourSelectors.VotingMethod}
                  style={{ borderLeftColor: state.themeColor }}>
              <div className="Idea__header">
                <Text className="Idea__headerTitle Title--withIcon">
                  <SvgIcon iconName="diamond" /> {state.initiativeTitle}
                </Text>
                <div className="Idea__headerStatus">
                  {!state.hasVotingStarted && (
                    <div className="Idea__headerVotes">
                      <VotingMethodIcon isVoted={false} votingMethod={state.votingMethod} />
                      This initiative is not open for voting yet
                    </div>
                  )}
                  {state.hasVotingEnded && (
                    <div className="Idea__headerVotes">
                      <VotingMethodIcon isVoted={false} votingMethod={state.votingMethod} />
                      This initiative has already ended
                    </div>
                  )}
                  {state.canUserVote && (
                    <ul className="Idea__headerVotes">
                      <li>
                        <span className="Idea__headerVotesAmount">
                          {state.votingMethod === VotingMethodDto.Likes &&
                          state.maxVotesPerInitiative === INT_32_MAX_VALUE
                            ? "Unlimited"
                            : state.votesLeftForInitiative}
                        </span>
                        <VotingMethodIcon isVoted={false} votingMethod={state.votingMethod} />
                        left for initiative
                      </li>
                      <li>
                        <span className="Idea__headerVotesAmount">
                          {Math.min(state.votesLeftForInitiative, state.leftVotesToDistribute)}
                        </span>
                        <VotingMethodIcon isVoted={false} votingMethod={state.votingMethod} /> left for this idea
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </Card>
            <div className="Idea__body">
              <Row gutter={32}>
                <Col xs={15} lg={9}>
                  <Card className="Idea__content">
                    <div className="RichIdeaCard">
                      <div className="RichIdeaCard__voteInput" data-tour={IdeaPageWalkTourSelectors.Vote}>
                        <Vote idea={state.ideaWithPollOption} votingMethod={state.votingMethod} />
                      </div>
                      <div className="RichIdeaCard__body">
                        <Meta
                          avatar={
                            state.isAnonymous ? (
                              <></>
                            ) : (
                              <UserAvatar
                                userName={state.ideaOwnerName}
                                size="large"
                                avatarId={state.ideaOwnerAvatarId}
                              />
                            )
                          }
                          description={
                            <>
                              {!state.isAnonymous && (
                                <Title level={3} className="RichIdeaCard__authorInfo">
                                  Created by {state.ideaOwnerName}
                                </Title>
                              )}

                              <div className="RichIdeaCard__body__Metadata">
                                {DateFormatter.format(state.createdAt, "MMMM DD, YYYY h:mm A")}
                                {state.hasAnotherEditor && !state.isAnonymous && (
                                  <>
                                    <div className="RichIdeaCard__body__Metadata__Editor">
                                      Edited by {state.lastEditedByName}
                                    </div>
                                    <div className="RichIdeaCard__body__Metadata__Editor--small">
                                      Edited by{" "}
                                      <UserAvatar
                                        userName={state.lastEditedByName}
                                        size={16}
                                        avatarId={state.lastEditedByAvatarId}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          }
                        />
                        <div className="RichIdeaCard__description">
                          <Title level={1} className="Title--withIcon">
                            <SvgIcon iconName="bulb" />
                            {state.ideaTitle}
                            {state.isDeleted && "(Removed)"}
                          </Title>
                          <RichTextViewer content={state.ideaDescription} />
                        </div>
                        {state.hasAttachments && <AttachmentList attachments={state.attachments} />}
                      </div>
                      {state.hasExtraActions && (
                        <IdeaExtraActions
                          idea={state.ideaWithPollOption}
                          showEditIdeaDialog={state.showEditIdeaDialog}
                          showMoveIdeaDialog={state.showMoveIdeaDialog}
                        />
                      )}
                    </div>
                  </Card>
                </Col>
                <Col xs={15} lg={6}>
                  <Card
                    className="Idea__ideaComments"
                    data-tour={IdeaPageWalkTourSelectors.Comment}
                    title={
                      <Title level={2} className="Title--withIcon">
                        <SvgIcon iconName="chat" /> Comments
                      </Title>
                    }
                  >
                    <CommentListView comments={state.comments} idea={state.idea} />
                    <Divider />
                    {state.canUserAddComment ? (
                      <>
                        <CommentInputView
                          placeholder="Type your comment here"
                          onConfirm={comment => {
                            state.createComment.execute(comment);
                          }}
                          fileStore={model.fileStore}
                        />
                      </>
                    ) : (
                      <Row justify="center">
                        <Paragraph>Comments have been closed </Paragraph>
                      </Row>
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
            <EditIdeaDialog model={state.editIdeaDialogViewModel} fileStore={model.fileStore} />
            <MoveIdeaDialog model={state.moveIdeaViewModel} />
          </>
        )}
      </ActivityIndicator>
      <WalkTour
        fixed
        steps={model.steps}
        lastStepNextButton={<Button type="primary">Start discovering IdeaMate</Button>}
      />
    </div>
  );
});
