import { AbstractStepWizard } from "../../../../../shared/stepWizard/AbstractWizard";
import { Step } from "../../../../../shared/stepWizard/Step";
import { FileStore } from "../../../../../shared/stories/file/FileStore";
import { Initiative } from "../../../../../shared/stories/initiative/Initiative";
import { InitiativeStore } from "../../../../../shared/stories/initiative/InitiativeStore";
import { VoteMethodConfig } from "../../../../../shared/stories/vote/VoteStore";
import { EditInitiativeModel } from "../EditInitiativeModel";
import { EditInitiativeSteps } from "../EditInitiativeSteps";
import { EditInitiativeBasicStepViewModel } from "./EditInitiativeBasicStepViewModel";
import { EditInitiativeDateStepViewModel } from "./EditInitiativeDateStepViewModel";
import { EditInitiativeSummaryStepViewModel } from "./EditInitiativeSummaryStep";
import { EditInitiativeVotingStepViewModel } from "./EditInitiativeVotingStepViewModel";

export class EditInitiativeWizard extends AbstractStepWizard<EditInitiativeSteps> {
  protected readonly steps: Step<EditInitiativeSteps>[];

  private editInitiativeModel: EditInitiativeModel = {
    title: this.initiative.title,
    description: this.initiative.description,
    submissionDateRange: { startDate: this.initiative.submissionStartDate, endDate: this.initiative.submissionEndDate },
    votingDateRange: { startDate: this.initiative.votingStartDate, endDate: this.initiative.votingEndDate },
    votingMethod: this.initiative.votingMethod,
    votesPerInitiative: this.initiative.votesPerPoll,
    maxVotesPerIdea: this.initiative.maxVotesPerPollOption,
    attachments: this.initiative.attachments,
    fileStore: this.fileStore,
    hideIdeaSubmitter: this.initiative.hideIdeaSubmitter,
    tags: this.initiative.tags,
    edit: true,
  };

  constructor(
    onSubmit: (createInitiativeModel: EditInitiativeModel) => Promise<void>,
    private votesConfig: VoteMethodConfig,
    private fileStore: FileStore,
    private readonly initiativeStore: InitiativeStore,
    private initiative: Initiative,
    private type: EditInitiativeSteps = "Basic"
  ) {
    super(() => onSubmit(this.editInitiativeModel));

    this.steps =
      this.type === "Basic" || this.type === "VotingSystem"
        ? [
            new EditInitiativeBasicStepViewModel(this.editInitiativeModel, this.initiativeStore),
            new EditInitiativeVotingStepViewModel(this.editInitiativeModel, this.votesConfig),
            new EditInitiativeSummaryStepViewModel(this.editInitiativeModel),
          ]
        : [new EditInitiativeDateStepViewModel(this.editInitiativeModel)];
  }
}
