import { AbstractStepWizard } from "../../../../../shared/stepWizard/AbstractWizard";
import { Step } from "../../../../../shared/stepWizard/Step";
import { UserStore } from "../../../../../shared/stories/user/UserStore";
import { Workspace } from "../../../../../shared/stories/workspace/Workspace";
import { WorkspaceStore } from "../../../../../shared/stories/workspace/WorkspaceStore";
import { EditWorkspaceModel } from "../EditWorkspaceModel";
import { EditWorkspaceSteps } from "../EditWorkspaceSteps";
import { EditWorkspaceBasicStepViewModel } from "./EditWorkspaceBasicStepViewModel";
import { EditWorkspaceMembersStepViewModel } from "./EditWorkspaceMembersStepViewModel";
export class EditWorkspaceWizard extends AbstractStepWizard<EditWorkspaceSteps> {
  protected steps: Step<EditWorkspaceSteps>[] = [];

  private editWorkspaceModel: EditWorkspaceModel = {
    id: this.workspace.id,
    name: this.workspace.name,
    description: this.workspace.description,
    themeColor: this.workspace.themeColor,
    isPrivate: this.workspace.isPrivate,
    members: [],
  };

  constructor(
    onSubmit: (editWorkspaceModel: EditWorkspaceModel) => Promise<void>,
    private readonly workspaceStore: WorkspaceStore,
    private readonly userStore: UserStore,
    private workspace: Workspace,
    private editSteps: EditWorkspaceSteps[] = ["Basic", "Members"]
  ) {
    super(() => onSubmit(this.editWorkspaceModel));

    editSteps.forEach(step => this.steps.push(this.editStepNameToStep(step)));
  }

  private editStepNameToStep = (name: EditWorkspaceSteps) => {
    switch (name) {
      case "Members":
        return new EditWorkspaceMembersStepViewModel(this.editWorkspaceModel, this.userStore, this.workspaceStore);
      case "Basic":
      default:
        return new EditWorkspaceBasicStepViewModel(this.editWorkspaceModel, this.workspaceStore, this.workspace.name);
    }
  };
}
