// eslint-disable-next-line @typescript-eslint/no-var-requires
const { htmlToText } = require("html-to-text");

export const convertHtmlToText = (html: string): string => {
  const options = {
    wordwrap: null,
    whitespaceCharacters: "\t\r\n\f\u200b",
  };
  const plainText = htmlToText(html, options);
  return plainText;
};
