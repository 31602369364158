import { action, observable } from "mobx";
import { GetInitiativesListSortType } from "src/types/initiatives/dto/GetInitiativesListSortType";
import { InitiativeStateNameDto } from "src/types/initiatives/dto/InitiativeStateNameDto";
import { PeriodType } from "src/types/initiatives/dto/PeriodType";
import { SortDirection } from "src/types/shared/dto/SortDirection";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { INavigationService } from "../../../../shared/services/NavigationService";
import { IdeaDetail, IdeaListItem } from "../../../../shared/stories/idea/Idea";
import { InitiativeOverview } from "../../../../shared/stories/initiative/Initiative";
import { InitiativeStore } from "../../../../shared/stories/initiative/InitiativeStore";

const SEARCH_RESULTS = 10;

export class MoveIdeaDialogViewModel extends DialogViewModel<IdeaDetail | IdeaListItem> {
  @observable chosenInitiative?: InitiativeOverview;

  @observable initiatives: InitiativeOverview[] = [];

  @observable query = "";

  @observable idea?: IdeaDetail | IdeaListItem;

  constructor(
    private readonly initiativeStore: InitiativeStore,
    private readonly navigationService: INavigationService,
    private onIdeaMovedHandler?: (idea: IdeaDetail | IdeaListItem) => void
  ) {
    super();
  }

  @action setQuery = (value: string) => {
    this.query = value;
  };

  @action setChosenInitiative = (value: InitiativeOverview) => {
    this.chosenInitiative = value;
    this.query = value.title;
  };

  onSearch = async (query: string) => {
    const result = await this.initiativeStore.getInitiatives(
      this.idea?.workspaceId ?? "",
      { page: 0, results: SEARCH_RESULTS },
      { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.CreateAt },
      {
        createdByCurrentUser: false,
        expiredPeriodType: PeriodType.Ever,
        states: [
          InitiativeStateNameDto.Collecting,
          InitiativeStateNameDto.Evaluation,
          InitiativeStateNameDto.Implementation,
          InitiativeStateNameDto.Planning,
          InitiativeStateNameDto.PreVoting,
          InitiativeStateNameDto.Voting,
        ],
        tags: [],
        query,
      }
    );
    this.initiatives = result.initiatives.filter((i: InitiativeOverview) => i.id !== this.idea?.initiativeId);
  };

  moveIdea = async () => {
    if (!this.chosenInitiative) return;
    await this.idea?.move(this.chosenInitiative?.id);
  };

  onIdeaMoved = () => {
    this.idea && this.onIdeaMovedHandler?.(this.idea);
  };

  goToChosenInitiative = () => {
    this.hide();
    if (this.chosenInitiative) this.navigationService.goToInitiative(this.chosenInitiative?.id);
  };

  // eslint-disable-next-line class-methods-use-this
  protected onShow(idea?: IdeaDetail | IdeaListItem) {
    this.idea = idea;
  }
}
