import { History } from "history";
import { ConstructorOf } from "../ConstructorOf";
import { HomeRouteDefinition } from "./HomeRouteDefinition";
import { paths } from "./RouteDefinition";

export interface IRoutedPage {
    readonly childPage: IRoutedPage | undefined;
    removeChildPage(): void;
    handleInvalidRoute(): Promise<void>;
}

export interface IRoute<TModel, TParentParams, TParams> {
    addRoute<TNewModel extends IRoutedPage, TNewParams extends { [k: string]: string | undefined } = {}, TQuery = {}>(path: string, modelConstructor: ConstructorOf<TNewModel>, modelFactory: (currentModel: TModel, params: TNewParams, query: Partial<TQuery>) => Promise<TNewModel> | TNewModel, paramsSelector?: (page: TNewModel) => TNewParams, stateAccessor?: { get: (page: TNewModel) => string, set: (page: TNewModel, state: string) => void }): IRoute<TNewModel, TParentParams & TParams, TNewParams>
    getPath(params: TParentParams & TParams): string;
}

export interface IHomeRoute<TModel> extends IRoute<TModel, {}, {}> {
    bind(history: History): Promise<{ page: TModel, destroy(): void }>
}

export function route<TModel extends IRoutedPage & {activate: () => void} & {handleLocationChange: ()=>void}>(modelFactory: () => Promise<TModel> | TModel): IHomeRoute<TModel> {
    return new HomeRouteDefinition<TModel>(modelFactory);
}

export function getPath(page: ConstructorOf<IRoutedPage>, params?: { [k: string]: string | undefined }) {
    const path = paths.get(page);

    if (!path) {
        throw new Error("Invalid page");
    }

    return path(params);
}

export function isEmptyPath(path:string) { return !path || path === '/'; }