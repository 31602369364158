import { bindableForm, RequiredFieldValidator } from "react-mvvm";
import { Step } from "../../../../../../shared/stepWizard/Step";
import {
  MAX_WORKSPACE_DESCRIPTION_LENGTH,
  MAX_WORKSPACE_TITLE_LENGTH,
  MIN_WORKSPACE_TITLE_LENGTH,
} from "../../../../../../shared/validators/LengthValidatorsConst";
import { MaxLengthValidator } from "../../../../../../shared/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../../../shared/validators/MinLengthValidator";
import { RichTextLengthValidator } from "../../../../../../shared/validators/RichTextLengthValidator";
import { combineValidators } from "../../../../../../shared/validators/combineValidators";
import { CreateWorkspaceModel } from "../../CreateWorkspaceModel";
import { CreateWorkspaceStep, CreateWorkspaceStepName } from "../CreateWorkspaceStep";
import { WorkspaceNameValidator } from "../../../../../../shared/validators/WorkspaceNameValidator";
import { WorkspaceStore } from "../../../../../../shared/stories/workspace/WorkspaceStore";

export function isCreateWorkspaceBasicStep(
  step: Step<CreateWorkspaceStepName>
): step is CreateWorkspaceBasicStepViewModel {
  return step.name === "Basic";
}

export class CreateWorkspaceBasicStepViewModel extends CreateWorkspaceStep<
  "name" | "description" | "isPrivate" | "themeColor"
> {
  readonly name = "Basic";

  form = bindableForm<CreateWorkspaceModel>()
    .addField("name", {
      validator: v =>
        combineValidators([
          RequiredFieldValidator(v),
          MinLengthValidator(v, MIN_WORKSPACE_TITLE_LENGTH),
          MaxLengthValidator(v, MAX_WORKSPACE_TITLE_LENGTH),
          WorkspaceNameValidator(v, this.workspaceStore, this.initialWorkspaceName),
        ]),
    })
    .addField("description", {
      validator: v => combineValidators([RichTextLengthValidator(v, MAX_WORKSPACE_DESCRIPTION_LENGTH)]),
    })
    .addField("isPrivate")
    .addField("themeColor", {
      validator: v => RequiredFieldValidator(v),
    })
    .bindTo(() => this.createWorkspaceModel);

  constructor(
    private readonly createWorkspaceModel: CreateWorkspaceModel,
    private readonly workspaceStore: WorkspaceStore,
    private readonly initialWorkspaceName?: string
  ) {
    super();
  }
}
