import { action, observable, untracked, reaction, computed } from "mobx";
import { command, Command, Deferred, isLoaded } from "react-mvvm";
import { FileStore } from "../../../shared/stories/file/FileStore";
import { IdeaStore } from "../../../shared/stories/idea/IdeaStore";
import { InitiativeOverview } from "src/shared/stories/initiative/Initiative";
import { InitiativeStateNameDto } from "../../../types/initiatives/dto/InitiativeStateNameDto";
import { LoadMoreList } from "../../../shared/List/LoadMoreList";
import { IErrorService } from "../../../shared/services/ErrorService";
import { CommentStore } from "../../../shared/stories/comment/CommentStore";
import {
  GetIntiativesFilterStorageKey,
  GetIntiativesOrderByStorageKey,
  InitiativesFilter,
  InitiativeStore,
} from "../../../shared/stories/initiative/InitiativeStore";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { VoteStore } from "../../../shared/stories/vote/VoteStore";
import { InitiativeListWalkTour } from "../../../shared/walkTour/InitiativeListWalkTour";
import { GetInitiativesListSortType } from "../../../types/initiatives/dto/GetInitiativesListSortType";
import { PeriodType } from "../../../types/initiatives/dto/PeriodType";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { BasePage } from "../../BasePage";
import { LoadMoreTagList } from "../shared/tags/tagFilterList/LoadMoreTagList";
import { CreateInitiativeDialogViewModel } from "./createInitiativeDialog/CreateInitiativeDialogViewModel";
import { SuccessDialogViewModel } from "./successDialog/SuccessDialogViewModel";
import { navigateTo } from "../../../useRouter";
import { DEFAULT_WORKSPACE_COLOR } from "../../../shared/Constants";

export interface AddNewItem {
  id: "addNewItem";
  name: "addNewItem";
}

export function isAddNewItem<T>(item: T | AddNewItem): item is AddNewItem {
  const { id, name } = item as AddNewItem;

  return id === "addNewItem" && name === "addNewItem";
}

export enum InitiativesOrderBy {
  VotingDeadline,
  IdeaCollectionDeadline,
}

const defaultFilter: InitiativesFilter = {
  createdByCurrentUser: false,
  expiredPeriodType: PeriodType.Ever,
  states: [
    InitiativeStateNameDto.Planning,
    InitiativeStateNameDto.Collecting,
    InitiativeStateNameDto.PreVoting,
    InitiativeStateNameDto.Voting,
    InitiativeStateNameDto.Evaluation,
    InitiativeStateNameDto.Implementation,
    InitiativeStateNameDto.Closed,
  ],
  tags: [],
};

const defaultOrderBy = InitiativesOrderBy.VotingDeadline;

export class InitiativePaginatedList extends LoadMoreList<AddNewItem | InitiativeOverview> {
  @observable filter = defaultFilter;

  @observable orderBy = defaultOrderBy;

  constructor(
    private readonly initiativeStore: InitiativeStore,
    readonly workspaceId: string,
    public readonly loggedUser?: UserDto,
    readonly pageSize = 12
  ) {
    super(pageSize);
    this.setDefaultFilter();
  }

  @action addOrRemoveTag(tag: string) {
    if (this.filter.tags.includes(tag)) this.filter.tags = this.filter.tags.filter(t => t !== tag);
    else this.filter.tags = [...this.filter.tags, tag];
  }

  @action setDefaultFilter() {
    this.filter = defaultFilter;
  }

  protected async load(options: { page: number; results?: number }) {
    const currentPageNo = untracked(() => this.paginatedList.currentPageNo);
    if (currentPageNo === 1) {
      const result = await this.getInitiatives(this.workspaceId, { page: options.page, results: options.results });

      return {
        items: [
          {
            id: "addNewItem",
            name: "addNewItem",
          } as AddNewItem,
          ...result.items,
        ],
        hasNextPage: result.hasNextPage,
      };
    }

    const initiative = await this.getInitiatives(this.workspaceId, { ...options });

    return initiative;
  }

  private async getInitiatives(workspaceId: string, options: { page: number; results?: number }) {
    const { initiatives, totalCount, hasNextPage } = await this.initiativeStore.getInitiatives(
      workspaceId,
      { ...options },
      this.mapToSortOption(this.orderBy),
      this.filter
    );
    this.totalCount = totalCount ?? 0;
    return { items: initiatives, hasNextPage };
  }

  // eslint-disable-next-line class-methods-use-this
  private mapToSortOption(orderBy: InitiativesOrderBy) {
    switch (orderBy) {
      case InitiativesOrderBy.VotingDeadline:
        return { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.VotingEndDate };
      case InitiativesOrderBy.IdeaCollectionDeadline:
        return { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.SubmissionEndDate };
      default:
        return { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.VotingEndDate };
    }
  }
}

class Loaded {
  @observable initiativeList: InitiativePaginatedList;

  @observable tagList: LoadMoreTagList;

  @computed get title() {
    return `${this.titlePrefix} (${this.initiativeList.totalCount})`;
  }

  constructor(
    readonly initiativeStore: InitiativeStore,
    readonly voteStore: VoteStore,
    readonly workspaceId: string,
    public readonly loggedUser: UserDto,
    readonly titlePrefix: string = "Initiatives"
  ) {
    this.initiativeList = new InitiativePaginatedList(initiativeStore, workspaceId, loggedUser);
    this.tagList = new LoadMoreTagList(initiativeStore);

    this.initFilters();
    this.configureFilterReactions();
  }

  refreshLists() {
    this.initiativeList.paginatedList.loadFirstPage();
  }

  private initFilters() {
    const filter = localStorage.getItem(GetIntiativesFilterStorageKey(this.workspaceId));
    const orderBy = localStorage.getItem(GetIntiativesOrderByStorageKey(this.workspaceId));

    if (filter) this.initiativeList.filter = JSON.parse(filter) as InitiativesFilter;
    if (orderBy) this.initiativeList.orderBy = JSON.parse(orderBy);
  }

  private configureFilterReactions() {
    reaction(
      () => [JSON.stringify(this.initiativeList.filter), JSON.stringify(this.initiativeList.orderBy)],
      arr => {
        localStorage.setItem(GetIntiativesFilterStorageKey(this.workspaceId), arr[0]);
        localStorage.setItem(GetIntiativesOrderByStorageKey(this.workspaceId), arr[1]);
      }
    );
  }
}

export class InitiativeListViewModel extends BasePage {
  @observable.ref state: Deferred<Loaded> = "Loading";

  @observable isDrawerVisible = false;

  @observable isFilterVisible = false;

  @observable isTopActionButtonsVisible = true;

  @observable titlePrefix = "Initiatives";

  @observable themeColor = DEFAULT_WORKSPACE_COLOR;

  createInitiativeDialogViewModel: CreateInitiativeDialogViewModel;

  successDialogViewModel: SuccessDialogViewModel;

  showCreateInitiativeDialog: Command;

  showInitiative: Command<string, void>;

  resetFilters: Command;

  steps = InitiativeListWalkTour.getSteps();

  private showSuccessDialog: Command<string, void>;

  constructor(
    public initiativeStore: InitiativeStore,
    public voteStore: VoteStore,
    public userStore: UserStore,
    public commentStore: CommentStore,
    public errorService: IErrorService,
    public ideaStore: IdeaStore,
    public fileStore: FileStore,
    public workspaceId: string
  ) {
    super(errorService, false, userStore);

    this.createInitiativeDialogViewModel = new CreateInitiativeDialogViewModel(
      this.fileStore,
      this.initiativeStore,
      this.voteStore,
      this.workspaceId,
      async newInitiativeId => {
        this.createInitiativeDialogViewModel.hide();
        this.showSuccessDialog.execute(newInitiativeId);
        isLoaded(this.state) && this.state.refreshLists();
      }
    );

    this.showInitiative = command(id => {
      navigateTo(`/initiatives/${id}`);
    });

    this.successDialogViewModel = new SuccessDialogViewModel(this.showInitiative);

    this.showCreateInitiativeDialog = command(() => {
      this.createInitiativeDialogViewModel.show();
    });

    this.showSuccessDialog = command(id => {
      this.successDialogViewModel.setInitiativeId(id);
      this.successDialogViewModel.show();
    });

    this.resetFilters = command(() => {
      if (isLoaded(this.state)) {
        this.state.initiativeList.setDefaultFilter();
      }
    });
  }

  @action.bound toggleDrawer = () => {
    this.isDrawerVisible = !this.isDrawerVisible;
  };

  @action.bound toggleFilters = () => {
    this.isFilterVisible = !this.isFilterVisible;
  };

  @action.bound onTagClick = (tag: string) => {
    isLoaded(this.state) && this.state.initiativeList.addOrRemoveTag(tag);
  };

  protected async loadData() {
    await this.userStore.tryUpdateUser();
    this.state = new Loaded(
      this.initiativeStore,
      this.voteStore,
      this.workspaceId,
      this.userStore.loggedUser,
      this.titlePrefix
    );
    super.loadData();
  }

  public async loadDataAsync() {
    await this.loadData();
  }
}
