import { Col, Modal, Row, Steps } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { CreateWorkspaceDialogViewModel } from "./CreateWorkspaceDialogViewModel";
import { CreateWorkspaceStep } from "./createWorkspaceWizard/CreateWorkspaceStep";
import { CreateWorkspaceBasicStep } from "./createWorkspaceWizard/createWorkspaceBasicStep/CreateWorkspaceBasicStep";
import { isCreateWorkspaceBasicStep } from "./createWorkspaceWizard/createWorkspaceBasicStep/CreateWorkspaceBasicStepViewModel";
import { CreateWorkspaceMembersStep } from "./createWorkspaceWizard/createWorkspaceMembersStep/CreateWorkspaceMembersStep";
import { isCreateWorkspaceMembersStep } from "./createWorkspaceWizard/createWorkspaceMembersStep/CreateWorkspaceMembersStepViewModel";
import { CreateWorkspaceSummaryStep } from "./createWorkspaceWizard/createWorkspaceSummaryStep/CreateWorkspaceSummaryStep";
import { isCreateWorkspaceSummaryStep } from "./createWorkspaceWizard/createWorkspaceSummaryStep/CreateWorkspaceSummaryStepViewModel";

interface ICreateWorkspaceDialogViewProps {
  model: CreateWorkspaceDialogViewModel;
}

const { Step } = Steps;

const CreateWorkspaceSteps = ({
  currentStep,
  stepCount,
  hasSummary = false,
}: {
  currentStep: number;
  stepCount: number;
  hasSummary?: boolean;
}) => {
  return (
    <Steps
      progressDot={(iconDot, { index, status }) =>
        index < currentStep && status === "finish" ? <SvgIcon iconName="tickComplete" /> : iconDot
      }
      current={currentStep}
    >
      {[...Array(hasSummary ? stepCount - 1 : stepCount).keys()].map(key => (
        <Step key={key} />
      ))}
    </Steps>
  );
};

export const CreateWorkspaceDialog: React.FunctionComponent<ICreateWorkspaceDialogViewProps> = observer(
  ({ model }: ICreateWorkspaceDialogViewProps) => {
    const { isVisible, cancel, wizard } = model;

    if (!wizard) {
      return null;
    }

    const {
      currentStep,
      currentStepOrder,
      isGoToNextStepVisible,
      goToNextStep,
      isGoToPreviousStepVisible,
      goToPreviousStep,
      isSubmitVisible,
      submit,
      stepCount,
    } = wizard;

    return (
      <Modal
        visible={isVisible}
        title={
          <Row justify="space-between">
            <Col>
              <Title level={2} className="Title--withIcon">
                <SvgIcon iconName="diamond" /> New Workspace
              </Title>
            </Col>
            <Col className="CreateWorkspaceDialog__steps">
              <CreateWorkspaceSteps currentStep={currentStepOrder} stepCount={stepCount} hasSummary />
            </Col>
          </Row>
        }
        onCancel={() => {
          if (
            (currentStep instanceof CreateWorkspaceStep && currentStep.form.isDirty) ||
            !isCreateWorkspaceBasicStep(currentStep)
          )
            DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        width="100%"
        closeIcon={<SvgIcon iconName="close" />}
        footer={
          <div className="CreateWorkspaceDialog__footer">
            {isGoToNextStepVisible && (
              <CommandButton command={goToNextStep} type="primary" size="large">
                Next
              </CommandButton>
            )}
            {isSubmitVisible && (
              <CommandButton withLoader command={submit} type="primary" size="large">
                Create
              </CommandButton>
            )}
            {isGoToPreviousStepVisible && (
              <CommandButton command={goToPreviousStep} type="link" icon={<SvgIcon iconName="chevronLeft" />}>
                Back
              </CommandButton>
            )}
          </div>
        }
        className="CreateWorkspaceDialog"
      >
        {isCreateWorkspaceBasicStep(currentStep) && (
          <CreateWorkspaceBasicStep className="CreateWorkspaceDialog__form" model={currentStep} />
        )}
        {isCreateWorkspaceMembersStep(currentStep) && (
          <CreateWorkspaceMembersStep
            className="CreateWorkspaceDialog__form"
            model={currentStep}
            currentUser={model.userStore.loggedUser}
          />
        )}
        {isCreateWorkspaceSummaryStep(currentStep) && <CreateWorkspaceSummaryStep model={currentStep} />}
      </Modal>
    );
  }
);
