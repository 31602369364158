import _ from "lodash";
import { computed, observable } from "mobx";
import moment from "moment";
import { command, Command } from "../../../../react-mvvm/dist";
import { PollDto } from "../../../types/votes/dto/PollDto";
import { PollOptionDto } from "../../../types/votes/dto/PollOptionDto";
import { VoteDto } from "../../../types/votes/dto/VoteDto";
import { VotingConfigurationDto } from "../../../types/votes/dto/VotingConfigurationDto";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { VoteStore } from "./VoteStore";

export class Vote {
  @observable id = "";

  @observable amount = 0;

  @computed get canAddVote() {
    return (
      this.pollOption.poll.canAddVote &&
      this.pollOption.loggedUserVoteAmount < this.pollOption.poll.votingConfiguration.maxVotesPerPollOption
    );
  }

  @computed get canRemoveVote() {
    return this.pollOption.loggedUserVoteAmount > 0 && this.pollOption.poll.canRemoveVote;
  }

  addVote: Command<number, Promise<void>>;

  removeVote: Command<number, Promise<void>>;

  eraseVotes: Command<void, Promise<void>>;

  updateChanges = _.debounce(async () => {
    await this.pollOption.poll.store.updateVote(this.pollOption.targetId, this.amount);
  }, 800);

  constructor(public pollOption: PollOption) {
    this.addVote = command(
      async value => {
        this.amount += value;
        await this.updateChanges();
      },
      () => this.canAddVote
    );

    this.removeVote = command(
      async value => {
        this.amount -= value;
        await this.updateChanges();
      },
      () => this.canRemoveVote
    );

    this.eraseVotes = command(
      async () => {
        this.amount = 0;
      },
      () => this.canRemoveVote
    );
  }

  updateFromDto(dto: VoteDto) {
    this.id = dto.id;
    this.amount = dto.amount;
  }
}

export class PollOption {
  @observable targetId = "";

  @observable loggedUserVote: Vote;

  @computed get totalVoteAmount() {
    return this.voteCount + this.loggedUserVoteAmount;
  }

  @computed get loggedUserVoteAmount() {
    return this.loggedUserVote.amount;
  }

  @computed get isLoggedUserAddAnyVotes() {
    return this.loggedUserVoteAmount > 0;
  }

  @observable private voteCount = 0;

  constructor(public poll: Poll, public id: string) {
    this.loggedUserVote = new Vote(this);
  }

  updateFromDto(dto: PollOptionDto, voteDto?: VoteDto) {
    this.id = dto.id;
    this.targetId = dto.targetId;
    this.voteCount = dto.voteCount - (voteDto?.amount || 0);
    voteDto && this.loggedUserVote.updateFromDto(voteDto);
  }
}
export enum PollStatus {
  Planned,
  Open,
  Done,
  Closed,
}
export class Poll {
  @observable pollOptions: PollOption[] = [];

  @observable votingConfiguration = this.dto.votingConfigurationDto;

  id = this.dto.id;

  targetId = this.dto.targetId;

  @computed get hasStarted() {
    return moment(this.votingConfiguration.startDate).isSameOrBefore(moment());
  }

  @computed get hasEnded() {
    return moment(this.votingConfiguration.endDate).isSameOrBefore(moment());
  }

  @computed get isInProgress() {
    return this.hasStarted && !this.hasEnded;
  }

  @computed get canAddVote() {
    return this.loggedUserPollVoteAmount < this.votingConfiguration.votesPerPoll && this.isInProgress;
  }

  @computed get canRemoveVote() {
    return this.hasStarted && !this.hasEnded;
  }

  @computed get loggedUserPollVoteAmount() {
    return _.sumBy(this.pollOptions, po => po.loggedUserVoteAmount);
  }

  @computed get totalPollVoteAmount() {
    return _.sumBy(this.pollOptions, po => po.totalVoteAmount);
  }

  @computed get loggedUserLeftVotesToDistribute() {
    return this.votingConfiguration.votesPerPoll - this.loggedUserPollVoteAmount;
  }

  @computed get status() {
    if (!this.hasStarted) {
      return PollStatus.Planned;
    }

    if (this.hasEnded) {
      return PollStatus.Closed;
    }

    if (this.loggedUserLeftVotesToDistribute === 0) {
      return PollStatus.Done;
    }

    return PollStatus.Open;
  }

  constructor(
    public readonly store: VoteStore,
    public readonly userStore: UserStore,
    private dto: PollDto,
    userVotes: VoteDto[]
  ) {
    this.updateFromDto(dto, userVotes);
  }

  updateFromDto(dto: PollDto, userVotes: VoteDto[]) {
    this.id = dto.id;
    this.targetId = dto.targetId;
    this.votingConfiguration = dto.votingConfigurationDto;
    dto.pollOptionDtos.forEach(pollOptionDto =>
      this.updatePollOptionFromServer(
        pollOptionDto,
        userVotes.find(uv => uv.pollOptionId === pollOptionDto.id)
      )
    );
  }

  editVotingConfiguration(votingConfiguration: Pick<VotingConfigurationDto, "startDate" | "endDate">) {
    this.votingConfiguration.startDate = votingConfiguration.startDate;
    this.votingConfiguration.endDate = votingConfiguration.endDate;
  }

  getPollOption(pollOptionTargetId: string) {
    return this.pollOptions.find(po => po.targetId === pollOptionTargetId);
  }

  async reload() {
    await this.store.getPoll(this.targetId);
  }

  private updatePollOptionFromServer(dto: PollOptionDto, voteDto?: VoteDto) {
    let pollOption = this.pollOptions.find(b => b.id === dto.id);
    if (!pollOption) {
      pollOption = new PollOption(this, dto.id);
      this.pollOptions.push(pollOption);
    }
    pollOption.updateFromDto(dto, voteDto);

    return pollOption;
  }
}
