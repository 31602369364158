import { Button, Divider, Modal } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { AccessRequestStatusNameDto } from "src/types/users/dto/AccessRequestStatusNameDto";
import { DateFormatter } from "../../DateFormatter";
import { CommandButton } from "../../button/Button";
import { DialogSupport } from "../../dialogs/Dialogs";
import { AppLogo } from "../../logo/AppLogo";
import { ReviewAccessRequestViewModel } from "./ReviewAccessRequestViewModel";

export const ReviewAccessRequestView = observer(({ model }: { model: ReviewAccessRequestViewModel }) => {
  return (
    <Modal
      width={600}
      className="ReviewAccessRequestView"
      visible={model.isVisible}
      onCancel={() => {}}
      closable={false}
    >
      <AppLogo size="medium" variant="secondary" className="header" includeText />

      {model.accessRequest?.statusName === AccessRequestStatusNameDto.Pending && (
        <>
          <div>
            <p>
              The user{" "}
              <strong>
                <u>{model.accessRequest?.requesterEmail}</u>
              </strong>{" "}
              is requesting access to {model.accessRequest?.resourceType}. If you approve the request, the user will become a member of the {model.accessRequest?.resourceType}.
            </p>
          </div>

          <Button
            size="large"
            type="primary"
            onClick={() => {
              DialogSupport.showConfirmDialog({
                title: `Are you sure you want to give access to the ${model.accessRequest?.resourceType} for the user ${model.accessRequest?.requesterEmail}?`,
                okText: "Approve access request",
                onOk: () => model.approve.execute(),
              });
            }}
          >
            Approve
          </Button>

          <Divider />

          <p>Don't want the user to have access?</p>

          <Button
            size="large"
            type="ghost"
            onClick={() => {
              DialogSupport.showConfirmDialog({
                title: `Are you sure you want to deny access to the ${model.accessRequest?.resourceType} for the user ${model.accessRequest?.requesterEmail}?`,
                okText: "Deny access",
                onOk: () => model.deny.execute(),
              });
            }}
          >
            Deny access
          </Button>
        </>
      )}
      {model.accessRequest?.statusName !== AccessRequestStatusNameDto.Pending && (
        <>
          <p>
            Access request has been already <strong>{model.accessRequest?.statusName.toLowerCase()}</strong> by{" "}
            {model.reviewedByCurrentUser ? "you" : model.accessRequest?.editedBy.name} on{" "}
            {DateFormatter.formatLocal(model.accessRequest?.editedAt, "MMMM DD, YYYY h:mm A")}
          </p>

          <CommandButton size="large" type="primary" command={model.close}>
            Go to ${model.accessRequest?.resourceType.toLowerCase()}
          </CommandButton>
        </>
      )}
    </Modal>
  );
});
