/* eslint-disable react/require-default-props */
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import moment from "moment";
import React from "react";

export type RangePickerValue = { startDate: string; endDate: string };

type Props = {
  value?: RangePickerValue;
  onChange?: (value: Partial<RangePickerValue>) => void;
  onCommit?: () => void;
};

export const RangePicker = ({
  value,
  onChange,
  onCommit,
  ...props
}: Props & Omit<RangePickerProps<moment.Moment>, "value" | "onChange">) => (
  <DatePicker.RangePicker
    {...props}
    value={[moment(value?.startDate), moment(value?.endDate)]}
    onChange={values =>
      onChange && values && onChange({ startDate: values[0]?.toISOString(), endDate: values[1]?.toISOString() })
    }
    onBlur={() => onCommit && onCommit()}
    size="large"
  />
);
