import { Row, Col, Button, Switch } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { NumberInput } from "../../../../../../shared/NumberInput/NumberInput";
import { RadioButtonGroup } from "../../../../../../shared/RadioButtonGroup/RadioButtonGroup";
import { Field, Form } from "../../../../../../shared/form/Form";
import { CreateInitiativeVotingMethodWalkTourSelectors } from "../../../../../../shared/walkTour/ModalsWalkTour";
import { WalkTour } from "../../../../../../shared/walkTour/WalkTour";
import { VotingMethodDto } from "../../../../../../types/shared/dto/VotingMethodDto";
import { CreateInitiativeVotingSystemModel } from "./CreateInitiativeVotingSystemModel";
import { VotingTypeRadioButton } from "./VotingTypeRadioButton/VotingTypeRadioButton";

interface ICreateInitiativeVotingSystemProps {
  model: CreateInitiativeVotingSystemModel;
  className?: string;
  editInitiativeMode?: boolean;
  minVotesPerInitiative?: number;
  minVotesPerIdea?: number;
}

export const CreateInitiativeVotingSystem: React.FunctionComponent<ICreateInitiativeVotingSystemProps> = observer(
  ({ className, model, minVotesPerInitiative, minVotesPerIdea, editInitiativeMode = false }) => {
    const canNotEditVotingMessage = "You can't change the voting method.";

    const isVotingMethodDisabled = (fieldValue: VotingMethodDto | undefined, votingMethod: VotingMethodDto) =>
      editInitiativeMode && fieldValue !== votingMethod;

    return (
      <>
        <Form className={className} layout="vertical" form={model.form}>
          {fields => (
            <>
              <Field
                field={fields.votingMethod}
                label={
                  <>
                    Voting Method
                    <WalkTour
                      fixed={false}
                      steps={model.steps}
                      lastStepNextButton={<Button type="primary">Lets select voting method</Button>}
                    />
                  </>
                }
              >
                {field => (
                  <RadioButtonGroup<VotingMethodDto>
                    defaultValue={editInitiativeMode && field.value ? field.value : VotingMethodDto.Dots}
                    value={field.value}
                    onChange={field.onChange}
                    className="CreateInitiativeDialog__votingSystemOptions"
                  >
                    <VotingTypeRadioButton<VotingMethodDto>
                      className="CreateInitiativeDialog__votingSystemOptionsItem"
                      value={VotingMethodDto.Dots}
                      label="Vote with Dots"
                      icon="dots"
                      dataTour={CreateInitiativeVotingMethodWalkTourSelectors.Dots}
                      disabled={isVotingMethodDisabled(field.value, VotingMethodDto.Dots)}
                      tooltip={
                        isVotingMethodDisabled(field.value, VotingMethodDto.Dots) ? canNotEditVotingMessage : undefined
                      }
                    />
                    <VotingTypeRadioButton
                      className="CreateInitiativeDialog__votingSystemOptionsItem"
                      value={VotingMethodDto.Likes}
                      label="Vote with Likes"
                      icon="thumbUp"
                      dataTour={CreateInitiativeVotingMethodWalkTourSelectors.Likes}
                      disabled={isVotingMethodDisabled(field.value, VotingMethodDto.Likes)}
                      tooltip={
                        isVotingMethodDisabled(field.value, VotingMethodDto.Likes) ? canNotEditVotingMessage : undefined
                      }
                    />
                    <VotingTypeRadioButton
                      className="CreateInitiativeDialog__votingSystemOptionsItem"
                      value={VotingMethodDto.Investment}
                      label="Vote with Investment"
                      icon="dollar"
                      dataTour={CreateInitiativeVotingMethodWalkTourSelectors.Invest}
                      disabled={isVotingMethodDisabled(field.value, VotingMethodDto.Investment)}
                      tooltip={
                        isVotingMethodDisabled(field.value, VotingMethodDto.Investment)
                          ? canNotEditVotingMessage
                          : undefined
                      }
                    />
                  </RadioButtonGroup>
                )}
              </Field>
              {model.form.fields.votingMethod.value === VotingMethodDto.Likes && (
                <Field
                  field={fields.votesPerInitiative}
                  label={`Make ${model.form.fields.votingMethod.value} per Initiative unlimited`}
                  className="CreateInitiativeDialog__unlimitedVotes"
                >
                  {() => <Switch checked={model.hasInfiniteVotes} onChange={value => model.setInfiniteVotes(value)} />}
                </Field>
              )}
              <Row justify="space-between" gutter={[0, 25]}>
                <Col
                  sm={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? 20 : 6}
                  xs={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? 20 : 12}
                  className="CreateInitiativeDialog__votingSystemDeatails"
                >
                  <Field
                    field={fields.votesPerInitiative}
                    label={`${model.form.fields.votingMethod.value} per initiative`}
                  >
                    {field => (
                      <NumberInput
                        value={field.value ?? 0}
                        maxValue={model.minMaxVotesPerInitiative.maxValue}
                        minValue={
                          editInitiativeMode && minVotesPerInitiative
                            ? minVotesPerInitiative
                            : model.minVotesPerInitiative
                        }
                        onChange={field.onChange}
                        step={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? 20 : 1}
                        postfix={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? "$" : ""}
                        disabled={model.hasInfiniteVotes}
                      />
                    )}
                  </Field>
                </Col>
                <Col
                  sm={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? 20 : 6}
                  xs={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? 20 : 12}
                  className="CreateInitiativeDialog__votingSystemDeatails"
                >
                  {model.form.fields.votingMethod.value !== VotingMethodDto.Likes && (
                    <Field
                      field={fields.maxVotesPerIdea}
                      label={`Maximum ${model.form.fields.votingMethod.value} per idea`}
                    >
                      {field => (
                        <NumberInput
                          value={field.value ?? 0}
                          maxValue={model.maxVotesPerIdea}
                          minValue={
                            editInitiativeMode && minVotesPerIdea ? minVotesPerIdea : model.minMaxVotesPerIdea.minValue
                          }
                          onChange={field.onChange}
                          step={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? 20 : 1}
                          postfix={model.form.fields.votingMethod.value === VotingMethodDto.Investment ? "$" : ""}
                        />
                      )}
                    </Field>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Form>
      </>
    );
  }
);
