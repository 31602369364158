import classNames from "classnames";
import React, { FC, useEffect, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { SortDirection } from "src/types/shared/dto/SortDirection";
import { GetUsersSortType } from "src/types/users/dto/GetUsersSortType";
import { UserListDto } from "src/types/users/dto/UserListDto";
import { useStore } from "../stories/RootStore";

type ToolbarOption = "inline" | "fontSize" | "list" | "textAlign" | "link" | "emoji" | "blockType";
export const ToolbarModules = [
  // [{ 'font': [] }],
  [
    { size: ["small", false, "large", "huge"] },
    "bold",
    "italic",
    "underline",
    "strike",
    { color: [] },
    { background: [] },
    { list: "ordered" },
    { list: "bullet" },
    { align: [] },
    "link",
    "image",
    "video",
    "code-block",
    "clean",
  ],
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const Formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
  "mention",
];

interface RichTextEditorProps {
  value?: string;
  onContentChange?: (content: string) => void;
  onPaste?: React.ClipboardEventHandler<HTMLDivElement>;
  height?: number;
  toolbarOptions?: ToolbarOption[];
  suffix?: React.ReactNode;
  resizable?: boolean;
}

// Quill editor docs
// See https://quilljs.com/
export const RichTextEditor: FC<RichTextEditorProps> = ({
  value,
  onContentChange,
  onPaste,
  height,
  suffix,
  resizable = true,
}) => {
  const [theme, setTheme] = useState("snow");
  const quill = React.createRef<ReactQuill>();
  const store = useStore();
  const modules = {
    toolbar: ToolbarModules,
    mention: {
      allowedChars: /^.*$/,
      mentionDenotationChars: ["@"],
      dataAttributes: ["id", "value", "denotationChar", "link", "target", "disabled", "mentionName"],
      fixMentionsToQuill: false,
      defaultMenuOrientation: "top",
      source: useCallback(
        async (
          searchTerm: string,
          renderList: (
            arg0:
              | {
                  id: string;
                  value: string;
                  mentionName: string;
                  email: string;
                }[]
              | undefined,
            arg1: any
          ) => void,
          mentionChar: string
        ) => {
          const users = (await store.userStore.getUsers({
            ids: [],
            excludeIds: [],
            query: searchTerm,
            page: 0,
            limit: 20,
            sortType: GetUsersSortType.Name,
            sortDirection: SortDirection.Ascending,
          })) as UserListDto;

          const values = users.items.map(u => {
            return {
              id: u.id,
              value: `${u.name ?? u.email} ${u.jobTitle ? `| ${u.jobTitle}` : ""}`,
              mentionName: u.name ?? u.email,
              email: u.email,
            };
          });

          renderList(values, searchTerm);
        },
        []
      ),
      onSelect: useCallback(
        (
          item: { id: string; value: string; mentionName: string; email: string },
          insertItem: (selectedUser: { id: string; value: string; mentionName: string; email: string }) => void
        ) => {
          const selectedUser = item;
          selectedUser.value = item.mentionName;
          insertItem(selectedUser);
        },
        []
      ),
    },

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  useEffect(() => {
    if (quill.current?.editor?.root) {
      quill.current.editor.root.style.height = `${height ?? 180}px`;
    }
  }, [value]);

  const onChange = (html: any) => {
    if (onContentChange) {
      onContentChange(html);
    }
  };

  return (
    <div className={classNames("RichTextEditor__root", resizable ? "resizable" : "")} onPaste={onPaste}>
      <ReactQuill
        theme={theme}
        value={value}
        modules={{ ...modules }}
        formats={Formats}
        bounds=".RichTextEditor__root"
        ref={quill}
        onChange={onChange}
      />
      <div className="RichTextEditor__suffix">{suffix}</div>
    </div>
  );
};
