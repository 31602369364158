import { observable } from "mobx";
import { AppNotificationDto } from "../../../types/notifications/dto/AppNotificationDto";
import { AppNotificationListDto } from "../../../types/notifications/dto/AppNotificationListDto";
import { NotificationAreaDto } from "../../../types/notifications/dto/NotificationAreaDto";
import { AppNotification } from "./AppNotification";
import { NotificationStore } from "./NotificationStore";

const LOAD_NOTIFICATIONS_TAKE = 10;

export class AppNotificationList {
  @observable public totalUnread = 0;

  @observable public items: AppNotification[] = [];

  @observable public canLoadMore = true;

  @observable public isLoading = false;

  private pageLoaded: number;

  constructor(private readonly notificationStore: NotificationStore, public area: NotificationAreaDto) {
    this.pageLoaded = 0;
  }

  public initLoad = async () => await this.loadNotifications(true);

  public loadMore = async () => {
    if (!this.canLoadMore) return;
    await this.loadNotifications(false);
  };

  public pushFirst = (dto: AppNotificationDto) => {
    const appNotification = AppNotification.createFromDto(dto, this.notificationStore);
    this.items = [appNotification, ...this.items];
  };

  public markAllAsRead = () => {
    this.items.forEach(item => item.markAsRead());
  };

  private loadNotifications = async (reset: boolean) => {
    try {
      this.isLoading = true;
      const res = await this.getNotificationsFromApi(reset ? 1 : this.pageLoaded + 1, LOAD_NOTIFICATIONS_TAKE);
      this.pageLoaded = res.currentPage;
      const newNotifications = res.items.map(dto => AppNotification.createFromDto(dto, this.notificationStore));
      this.items = reset ? [...newNotifications] : [...this.items, ...newNotifications];
      this.isLoading = false;
      this.canLoadMore = newNotifications.length === LOAD_NOTIFICATIONS_TAKE;
    } catch {
      this.isLoading = false;
    }
  };

  private async getNotificationsFromApi(page: number, results?: number) {
    const areaGetters: {
      [key: string]: (page: number, results?: number) => Promise<AppNotificationListDto>;
    } = {
      [NotificationAreaDto.Initiative]: this.notificationStore.getInitiativeNotifications,
      [NotificationAreaDto.Idea]: this.notificationStore.getIdeaNotifications,
      [NotificationAreaDto.DirectMention]: this.notificationStore.getDirectMentionNotifications,
    };

    const func = areaGetters[this.area];
    if (!func) throw new Error("Bad area selected");

    return await func(page, results);
  }
}
