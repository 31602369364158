import { Modal, Typography } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { AttachmentInput } from "../../../../shared/AttachmentInput/AttachmentInput";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { Field, Form } from "../../../../shared/form/Form";
import { TextAreaWithPasteSupport } from "../../../../shared/pasteSupport/PasteSupport";
import { FileStore } from "../../../../shared/stories/file/FileStore";
import { TextInput } from "../../../../shared/textInput/TextInput";
import { useUrl } from "../../../../useRouter";
import { EditIdeaDialogViewModel } from "./EditIdeaDialogViewModel";

const { Title } = Typography;

interface IEditIdeaDialogViewProps {
  model: EditIdeaDialogViewModel;
  fileStore: FileStore;
}

export const EditIdeaDialog: React.FunctionComponent<IEditIdeaDialogViewProps> = observer(
  ({ model, fileStore }: IEditIdeaDialogViewProps) => {
    const { isVisible, cancel, submit, idea } = model;

    const uploadFileUrl = useUrl().toUpload();
    const [attachements, setAttachements] = React.useState(model.getIdeaAttachements);

    React.useEffect(() => {
      model.form.fields.attachments.value && setAttachements(model.form.fields.attachments.value);
    }, [model.form.fields.attachments.value]);

    return (
      <Modal
        destroyOnClose
        visible={isVisible}
        title={
          <>
            <Title level={2} className="Title--withIcon">
              <SvgIcon iconName="edit" /> Edit Idea
            </Title>
            {idea && <Title level={3} className="Title--withAccent" />}
          </>
        }
        onCancel={() => {
          if (model.form.isDirty) DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        closeIcon={<SvgIcon iconName="close" />}
        footer={
          <CommandButton command={submit} type="primary" size="large">
            Save changes
          </CommandButton>
        }
        className="EditIdeaDialog"
        width="100%"
      >
        <Form className="EditIdeaDialog__form" layout="vertical" form={model.form}>
          {fields => (
            <>
              <Field field={fields.title} label="Title">
                {props => <TextInput placeholder="Idea title" {...props} />}
              </Field>
              <Field field={fields.description} label="Description">
                {props => (
                  <TextAreaWithPasteSupport
                    placeholder="Idea description"
                    rows={10}
                    {...props}
                    fileStore={fileStore}
                    onAttachmentAdded={attachment => model.addToIdeaAttachments(attachment)}
                    richTextEditor
                    height={250}
                  />
                )}
              </Field>
              <Field
                field={fields.attachments}
                label={
                  <span className="AddAttachmentsInputLabel">
                    Attachments
                    <span className="AddAttachmentsInputLabelDescription">
                      Allowed files extension .pdf, .jpg, .jpeg, .png, .ppt, .zip
                    </span>
                  </span>
                }
              >
                {props => (
                  <AttachmentInput
                    text="Add attachment"
                    url={uploadFileUrl}
                    {...props}
                    onChange={props.onChange}
                    maxCount={3}
                    attachmentList={attachements}
                  />
                )}
              </Field>
            </>
          )}
        </Form>
      </Modal>
    );
  }
);
