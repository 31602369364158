import { Button, Modal } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { DateFormatter } from "../../../../shared/DateFormatter";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { SearchInput } from "../../../../shared/searchInput/SearchInput";
import { MoveIdeaDialogViewModel } from "./MoveIdeaDialogViewModel";

export const MoveIdeaDialog = observer(
  ({ model, afterIdeaMoved }: { model: MoveIdeaDialogViewModel; afterIdeaMoved?: () => void }) => {
    return (
      <Modal width={600} className="MoveIdeaDialog" destroyOnClose visible={model.isVisible} closable={false}>
        <div className="MoveIdeaDialog__icon">
          <SvgIcon iconName="move" />
        </div>
        <div className="MoveIdeaDialog__title">
          If you move this Idea all comments will move with it.
          <br />
          Any votes associated with this idea will not be saved.
        </div>
        <div className="MoveIdeaDialog__choose">
          <div className="MoveIdeaDialog__chooseTitle">Choose initiative</div>
          <SearchInput
            options={model.initiatives.map(i => ({ id: i.id, value: i, label: i.title }))}
            onSelect={model.setChosenInitiative}
            onSearch={model.onSearch}
            inputValue={model.query}
            onInputValueChange={model.setQuery}
            renderOption={v => (
              <div className="MoveIdeaDialog__InitiativeOption">
                <div className="InitiativeOption__title">{v.title}</div>
                <div className="InitiativeOption__content">
                  {`Created by ${v.ownerEmail}`}
                  <br />
                  {`Created on ${DateFormatter.format(v.createdAt, "MMMM DD, YYYY h:mm A")}`}
                </div>
              </div>
            )}
            getOptionInputValue={v => v.title}
          />
        </div>

        <Button
          size="large"
          type="primary"
          onClick={() =>
            DialogSupport.showConfirmDialog({
              title: "Are you sure you want to move idea?",
              content: "Idea will be moved to the chosen initiative. Votes will be lost.",
              okText: "Move idea",
              onOk: async () => {
                await model.moveIdea();
                DialogSupport.showSuccessDialog({
                  title: "Idea has been successfully moved",
                  content: "Idea will be now visible in the new initiative.",
                  okText: "Go to initiative",
                  onOk: model.goToChosenInitiative,
                  onCancel: () => model.hide(),
                  maskClosable: true,
                });
                model.onIdeaMoved();
              },
            })
          }
          disabled={!model.chosenInitiative}
        >
          Move idea
        </Button>
        <Button size="large" type="ghost" onClick={() => model.hide()}>
          Cancel
        </Button>
      </Modal>
    );
  }
);
