import { IRoute } from "react-mvvm";
import { App } from "../App";
import { LoginViewModel } from "./LoginViewModel";
import { SignInViewModel } from "./signIn/SigInViewModel";

export const addLoginRouter = (route: IRoute<App, never, never>) => {
  route.addRoute<LoginViewModel, { token: string }>("/signin/:token", LoginViewModel, (appPage, p) => {
    const register = new LoginViewModel(
      appPage.errorService,
      appPage.navigationService,
      appPage.rootStore.userStore,
      p.token
    );
    return appPage.showChildPage(register);
  });
};

export const addSignRouter = (route: IRoute<App, never, never>) => {
  route.addRoute<SignInViewModel, { token: string }>("/signin", SignInViewModel, appPage => {
    const register = new SignInViewModel(appPage.errorService, appPage.navigationService, appPage.rootStore.userStore);
    return appPage.showChildPage(register);
  });
};
