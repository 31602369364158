import { action, computed, observable } from "mobx";
import { IViewModel } from "mobx-utils";
import { bindableForm, Command, command, RequiredFieldValidator } from "react-mvvm";
import { IdeaStore } from "src/shared/stories/idea/IdeaStore";
import { AttachmentDto } from "src/types/initiatives/dto/AttachmentDto";
import { UserDto } from "src/types/shared/dto/UserDto";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { IdeaDetail, IdeaListItem } from "../../../../shared/stories/idea/Idea";
import { MAX_DESCRIPTION_LENGTH, MAX_IDEA_TITLE_LENGTH } from "../../../../shared/validators/LengthValidatorsConst";
import { MaxLengthValidator } from "../../../../shared/validators/MaxLengthValidator";
import { RichTextLengthValidator } from "../../../../shared/validators/RichTextLengthValidator";
import { combineValidators } from "../../../../shared/validators/combineValidators";
import { EditIdeaModel } from "./EditIdeaModel";

interface IEditIdeaModel {
  title: string;
  description: string;
  attachments: AttachmentDto[];
}

export class EditIdeaDialogViewModel extends DialogViewModel<IdeaListItem | IdeaDetail> {
  @observable editIdeaModel: IEditIdeaModel = {
    title: "",
    description: "",
    attachments: [],
  };

  form = bindableForm<EditIdeaModel>()
    .addField("title", {
      validator: v => combineValidators([RequiredFieldValidator(v), MaxLengthValidator(v, MAX_IDEA_TITLE_LENGTH)]),
    })
    .addField("description", {
      validator: v => combineValidators([RichTextLengthValidator(v, MAX_DESCRIPTION_LENGTH)]),
    })
    .addField("attachments")
    .bindTo(() => this.editIdeaModel);

  cancel: Command;

  submit: Command<void, Promise<void>>;

  idea?: (IdeaListItem & IViewModel<IdeaListItem>) | (IdeaDetail & IViewModel<IdeaDetail>);

  store: IdeaStore;

  constructor(
    private onSuccess: (
      idea: (IdeaListItem & IViewModel<IdeaListItem>) | (IdeaDetail & IViewModel<IdeaDetail>)
    ) => Promise<void>,
    ideaStore: IdeaStore,
    loggedUser: UserDto
  ) {
    super();

    this.store = ideaStore;

    this.cancel = command(() => {
      this.hide();
    });

    this.submit = command(
      async () => {
        if (!(await this.form.validateAndCommit())) {
          return;
        }

        if (!this.idea) {
          return;
        }

        this.idea.title = this.editIdeaModel.title;
        this.idea.description = this.editIdeaModel.description;
        this.idea.attachments = this.editIdeaModel.attachments;
        this.idea.lastEditedBy = {
          submittedBy: {
            name: loggedUser.name,
            email: loggedUser.email,
            id: loggedUser.id,
          },
          editedAt: new Date().toDateString(),
        };
        await this.onSuccess(this.idea);
      },
      () => this.form.isDirty
    );
  }

  @computed get getIdeaAttachements() {
    return this.editIdeaModel.attachments;
  }

  @action addToIdeaAttachments = (file: AttachmentDto) => {
    this.form.fields.attachments.value = this.form.fields.attachments.value?.concat(file);
  };

  async show(model: (IdeaListItem & IViewModel<IdeaListItem>) | (IdeaDetail & IViewModel<IdeaDetail>)) {
    this.form.reset();
    this.idea = model;
    this.editIdeaModel = { ...model };
    this.editIdeaModel.attachments = await this.store.updateAttachments(this.idea);
    await super.show();
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}
}
