import { action, computed, isAction, observable } from "mobx";
import { ContextualCommand } from "./ContextualCommand";

export interface ICommand<TOut> {
    readonly isEnabled : boolean;
    readonly isRunning : boolean;
    execute() : Promise<TOut>
}

export class Command<TIn = void, TOut = void> {

    @computed get isEnabled() : boolean {
        return (!this._enabledValueAccessor || !!this._enabledValueAccessor()) && !this._isRunning;
    }

    @observable private _isRunning: boolean = false;
    @computed get isRunning() {
        return this._isRunning;
    }
    
    private readonly _enabledValueAccessor?: () => boolean;
    private readonly _execute: ((input:TIn) => Promise<TOut>) | ((input:TIn) => TOut);
    
    constructor(execute: ((input:TIn) => Promise<TOut>) | ((input:TIn) => TOut), enabled?: () => boolean) {
        this._execute = isAction(execute) ? execute : action(execute);
        this._enabledValueAccessor = enabled;
    }

    @action.bound async execute(input: TIn): Promise<TOut> {
        const enabledValue = this._enabledValueAccessor && this._enabledValueAccessor();
        if (this._enabledValueAccessor && !enabledValue)
            return Promise.reject();

        this._isRunning = true;
        try {
            return await this._execute(input);
        }   
        finally {
            this._isRunning = false;
        }
    }

    asContextualCommand() {
        return new ContextualCommand<TIn, void>(c => this.execute(c), () => this.isEnabled);
    }
}
