import { Button, Col, Divider, Drawer, Radio, Row, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { bindTo, isLoaded, property } from "react-mvvm/dist";
import { PeriodType } from "src/types/initiatives/dto/PeriodType";
import { Dropdown } from "../../../shared/Dropdown/Dropdown";
import { RadioButtonGroup } from "../../../shared/RadioButtonGroup/RadioButtonGroup";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { ActivityIndicator } from "../../../shared/activityIndicator/ActivityIndicator";
import { KanbanBoardWalkTourSelectors } from "../../../shared/walkTour/KanbanBoardWalkTour";
import { WalkTour } from "../../../shared/walkTour/WalkTour";
import { InitiativesOrderBy } from "../initiativeList/InitiativeListViewModel";
import { TagFilterListView } from "../shared/tags/tagFilterList/TagFilterListView";
import { BoardViewModel } from "./BoardViewModel";
import { BoardCol } from "./boardCol/BoardCol";

const { Title, Text } = Typography;

export const BoardView = observer(({ model }: { model: BoardViewModel }) => {
  return (
    <ActivityIndicator isLoading={model.isLoading}>
      {isLoaded(model.state) && (
        <>
          <Row justify="space-between" className="KanbanBoardHeader">
            <Title level={2} className="Title--withIcon">
              <SvgIcon iconName="diamond" /> Initiatives
            </Title>
            <Row className="KanbanBoardHeader__Buttons">
              <Button type="text" onClick={model.toggleFilters} data-tour={KanbanBoardWalkTourSelectors.Filter}>
                <SvgIcon iconName="barChart" />
                Filters (2)
              </Button>
              <Button type="text" onClick={() => model.resetFilter.execute()}>
                <SvgIcon iconName="refresh" className="customicon--textPrimary" />
                Reset filters and tags
              </Button>
              <Dropdown
                defaultValue={model.state.orderBy}
                {...bindTo(property(model.state, "orderBy"))}
                options={[
                  { value: InitiativesOrderBy.VotingDeadline, text: "Voting end date" },
                  { value: InitiativesOrderBy.IdeaCollectionDeadline, text: "Idea collection end date" },
                ]}
                className="KanbanBoardHeader__Buttons__OrderBy"
                label={<SvgIcon iconName="vector" />}
              />
            </Row>
          </Row>
          <div className="KanbanBoardSider">
            <Button size="large" block onClick={model.toggleDrawer}>
              Filters and Sorts
            </Button>
            <Button block size="large" onClick={() => model.resetFilter.execute()}>
              Reset filters and tags
            </Button>
          </div>
          <Row
            className={classNames(
              "KanbanBoardFilterContainer",
              model.isFilterVisible && "KanbanBoardFilterContainer--active"
            )}
          >
            <Col className="KanbanBoardFilterContainer__Filter" span={4}>
              <Text>Created by:</Text>
              <RadioButtonGroup
                defaultValue={model.state.filter.createdByCurrentUser}
                {...bindTo(property(model.state.filter, "createdByCurrentUser"))}
              >
                {[
                  { value: false, text: "anyone" },
                  { value: true, text: "me" },
                ].map(item => (
                  <Radio value={item.value} key={item.text} className="KanbanBoardFilterContainer__FilterOption">
                    {item.text}
                  </Radio>
                ))}
              </RadioButtonGroup>
            </Col>
            <Col className="KanbanBoardFilterContainer__Filter" span={8}>
              <Text>Expired:</Text>
              <RadioButtonGroup
                defaultValue={model.state.filter.expiredPeriodType}
                {...bindTo(property(model.state.filter, "expiredPeriodType"))}
              >
                {[
                  { value: PeriodType.Day, text: "now" },
                  { value: PeriodType.Week, text: "last week" },
                  { value: PeriodType.Month, text: "last month" },
                  { value: PeriodType.Quarter, text: "last quarter" },
                  { value: PeriodType.Year, text: "last year" },
                  { value: PeriodType.Ever, text: "ever" },
                ].map(item => (
                  <Radio value={item.value} key={item.value} className="KanbanBoardFilterContainer__FilterOption">
                    {item.text}
                  </Radio>
                ))}
              </RadioButtonGroup>
            </Col>
          </Row>
          <Drawer
            title="Sort and filter"
            mask={false}
            onClose={model.toggleDrawer}
            visible={model.isDrawerVisible}
            placement="left"
            width={window.innerWidth}
            footer={
              <Button block type="primary" size="large" onClick={model.toggleDrawer}>
                Apply and Close
              </Button>
            }
          >
            <Col className="KanbanBoard__Drawer">
              <Col>
                <Title level={5}>Sort by</Title>
                <RadioButtonGroup
                  defaultValue={model.state.orderBy}
                  {...bindTo(property(model.state, "orderBy"))}
                  className="KanbanBoard__DrawerOptions"
                >
                  {[
                    { value: InitiativesOrderBy.VotingDeadline, text: "Voting end date" },
                    { value: InitiativesOrderBy.IdeaCollectionDeadline, text: "Idea collection end date" },
                  ].map(item => (
                    <Radio value={item.value} key={item.value} className="KanbanBoard__DrawerOptionItem">
                      {item.text}
                    </Radio>
                  ))}
                </RadioButtonGroup>
              </Col>
              <Divider />
              <Title level={5}>Filters</Title>
              <Col>
                <Text className="KanbanBoard__DrawerOptionTitle">Created by:</Text>
                <RadioButtonGroup
                  defaultValue={model.state.filter.createdByCurrentUser}
                  {...bindTo(property(model.state.filter, "createdByCurrentUser"))}
                  className="KanbanBoard__DrawerOptions"
                >
                  {[
                    { value: false, text: "anyone" },
                    { value: true, text: "me" },
                  ].map(item => (
                    <Radio value={item.value} key={item.text} className="KanbanBoard__DrawerOptionItem">
                      {item.text}
                    </Radio>
                  ))}
                </RadioButtonGroup>
              </Col>
              <Col>
                <Text className="KanbanBoard__DrawerOptionTitle">Expired:</Text>
                <RadioButtonGroup
                  defaultValue={model.state.filter.expiredPeriodType}
                  {...bindTo(property(model.state.filter, "expiredPeriodType"))}
                  className="KanbanBoard__DrawerOptions"
                >
                  {[
                    { value: PeriodType.Day, text: "now" },
                    { value: PeriodType.Week, text: "last week" },
                    { value: PeriodType.Month, text: "last month" },
                    { value: PeriodType.Quarter, text: "last quarter" },
                    { value: PeriodType.Year, text: "last year" },
                    { value: PeriodType.Ever, text: "ever" },
                  ].map(item => (
                    <Radio value={item.value} key={item.value} className="KanbanBoard__DrawerOptionItem">
                      {item.text}
                    </Radio>
                  ))}
                </RadioButtonGroup>
              </Col>
            </Col>
          </Drawer>
          <TagFilterListView
            className="KanbanBoard__TagsSection"
            list={model.state.tagList}
            selected={model.state.filter.tags}
            onClick={model.onTagClick}
            onReset={model.resetFilter}
          />
          <div className="KanbanBoard" data-tour={KanbanBoardWalkTourSelectors.Board}>
            <BoardCol list={model.state.planingInitiatives} showItem={model.showInitiative} rowTitle="Planing" />
            <BoardCol list={model.state.collectingInitiatives} showItem={model.showInitiative} rowTitle="Collecting" />
            <BoardCol list={model.state.preVotingInitiatives} showItem={model.showInitiative} rowTitle="PreVoting" />
            <BoardCol list={model.state.votingInitiatives} showItem={model.showInitiative} rowTitle="Voting" />
            <BoardCol list={model.state.evaluationInitiatives} showItem={model.showInitiative} rowTitle="Evaluation" />
            <BoardCol
              list={model.state.implementationInitiatives}
              showItem={model.showInitiative}
              rowTitle="Implementation"
            />
            <BoardCol list={model.state.closedInitiatives} showItem={model.showInitiative} rowTitle="Closed" />
          </div>
        </>
      )}
      <WalkTour
        fixed
        steps={model.steps}
        lastStepNextButton={<Button type="primary">Let s start using IdeaMate</Button>}
      />
    </ActivityIndicator>
  );
});
