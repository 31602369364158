import { observable } from "mobx";

export abstract class DialogViewModel<T> {
  @observable isVisible: Readonly<boolean> = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async show(model?: T) {
    this.isVisible = true;
    await this.onShow(model);
  }

  hide() {
    this.isVisible = false;
  }

  protected abstract onShow(model?: T): Promise<void> | void;
}
