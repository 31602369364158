import { Button, InputNumber, Slider } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { DateFormatter } from "../../../../shared/DateFormatter";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { VotingMethodIcon } from "../../../../shared/VotingMethodIcon/VotingMethodIcon";
import { VotingMethodDto } from "../../../../types/shared/dto/VotingMethodDto";
import { IdeaDetailViewModel } from "../IdeaDetailViewModel";
import { IdeaListItemViewModel } from "../IdeaListItemViewModel";

interface IVoteProps {
  idea: IdeaListItemViewModel | IdeaDetailViewModel;
  votingMethod?: VotingMethodDto;
}

export const Vote = observer(({ idea, votingMethod }: IVoteProps) => {
  if (idea.isReleased) {
    return (
      <div className="Vote">
        <div className="Vote__releasedInfo">
          <SvgIcon iconName="vote" className="Vote__releasedInfoIcon" />
          <span className="Vote__releasedInfoTotalVotes">{idea.totalVotes}</span>
          <span className="Vote__releasedInfoDate">{DateFormatter.format(idea.releasedDate, "DD MMM, YY")}</span>
        </div>
      </div>
    );
  }

  if (votingMethod !== VotingMethodDto.Investment) {
    return (
      <div className="Vote">
        <div className="Vote__votingInput">
          <Button size="small" type="link" onClick={() => idea.addVotes.execute(1)} disabled={!idea.addVotes.isEnabled}>
            <SvgIcon iconName="arrowUp" />
          </Button>
          <div className="Vote__votingInputResult">
            <span className="Vote__votingInputResultTotal">{idea.totalVotes}</span>
            <VotingMethodIcon isVoted={idea.isUserAddedAnyVotes && !idea.isDeleted} votingMethod={idea.votingMethod} />
            {idea.isUserAddedAnyVotes ? (
              <span className="VoteInput__userVotes">{!idea.isDeleted && `+${idea.loggedUserVoteAmount}`}</span>
            ) : null}
          </div>
          <Button
            size="small"
            type="link"
            onClick={() => idea.removeVotes.execute(1)}
            disabled={!idea.removeVotes.isEnabled}
          >
            <SvgIcon iconName="arrowDown" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="Vote">
      <div className="Vote__investmentVoting">
        <InputNumber
          min={0}
          max={Math.min(idea.maxVotesPerIdea, idea.loggedUserVoteAmount + idea.votesLeft)}
          value={idea.investment}
          onChange={value => idea.updateInvestment(value)}
          size="small"
          formatter={info => `${info} $`}
          step={20}
          className="Vote__investmentVotingInput"
          disabled={idea.isInvestAvailable}
        />
        <Button
          type="primary"
          shape="circle"
          danger={idea.investment < idea.loggedUserVoteAmount}
          disabled={idea.isInvestBtnAvailable}
          onClick={() => {
            if (idea.investment > idea.loggedUserVoteAmount) {
              idea.addVotes.execute(idea.investment - idea.loggedUserVoteAmount);
            } else {
              idea.removeVotes.execute(idea.loggedUserVoteAmount - idea.investment);
            }

            idea.updateInvestment(idea.loggedUserVoteAmount);
          }}
        >
          {idea.investmentButtonText(idea.investment)}
        </Button>
        <Slider
          vertical
          className="Vote__investmentVotingSlider"
          value={idea.investment}
          defaultValue={idea.loggedUserVoteAmount}
          onChange={(value: number) => {
            idea.updateInvestment(value);
          }}
          min={0}
          max={Math.min(idea.maxVotesPerIdea, idea.loggedUserVoteAmount + idea.votesLeft)}
          step={20}
          tipFormatter={info => `${info} $`}
          disabled={idea.isInvestAvailable}
        />
        <span
          className={classNames(
            ".Vote__investmentVotingTotalVotes",
            idea.idea.state.isFinal && ".Vote__investmentVotingTotalVotes--finale"
          )}
        >
          {idea.totalVotes}$
        </span>
      </div>
    </div>
  );
});
