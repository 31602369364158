import { Progress } from "antd";
import Typography from "antd/lib/typography";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";

interface DateProgressProps {
  title: string;
  startDate: string;
  endDate: string;
  className?: string;
}

const { Title } = Typography;

export const DateProgress: React.FC<DateProgressProps> = ({ title, startDate, endDate, className }) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const nowMoment = moment();

  const getProgress = () => {
    if (nowMoment.isAfter(endDateMoment)) {
      return 100;
    }

    if (nowMoment.isSameOrBefore(startDateMoment)) {
      return 0;
    }

    const totalDays = endDateMoment.diff(startDateMoment);
    const proceedDays = nowMoment.diff(startDateMoment);

    return (proceedDays * 100) / totalDays;
  };

  return (
    <div className={classNames("DateProgress", className)}>
      <Title level={3} className="DateProgress__title">
        {title}
      </Title>
      <div>
        <Progress percent={getProgress()} showInfo={false} />
        <div className="DateProgress__dates">
          <span>{startDateMoment.format("MMM DD, YYYY")}</span>
          <span>{endDateMoment.format("MMM DD, YYYY")}</span>
        </div>
      </div>
    </div>
  );
};
