import { Step } from "../../../../../shared/stepWizard/Step";
import { CreateInitiativeBasicStepViewModel } from "../../createInitiativeDialog/createInitiativeWizard/createInitiativeBasicStep/CreateInitiativeBasicStepViewModel";
import { EditInitiativeSteps } from "../EditInitiativeSteps";

export function isEditInitiativeBasicStep(step: Step<EditInitiativeSteps>): step is EditInitiativeBasicStepViewModel {
  return step.name === "Basic";
}

export class EditInitiativeBasicStepViewModel extends CreateInitiativeBasicStepViewModel {
  readonly name = "Basic";

  public canGotoNextStep = () => true;
}
