import { Col, Modal, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { CreateWorkspaceMembersStep } from "../createWorkspaceDialog/createWorkspaceWizard/createWorkspaceMembersStep/CreateWorkspaceMembersStep";
import { ManageWorkspaceMembersDialogViewModel } from "./ManageWorkspaceMembersDialogViewModel";

interface IManageWorkspaceMembersDialogViewProps {
  model: ManageWorkspaceMembersDialogViewModel;
}

export const ManageWorkspaceMembersDialog: React.FunctionComponent<IManageWorkspaceMembersDialogViewProps> = observer(
  ({ model }: IManageWorkspaceMembersDialogViewProps) => {
    const { isVisible, cancel, submit } = model;

    const [numberOfMembers, setNumberOfMembers] = React.useState(
      model.manageWorkspaceMembersStepViewModel.form.fields.members.value?.length ?? 0
    );
    React.useEffect(
      () => setNumberOfMembers(model.manageWorkspaceMembersStepViewModel.form.fields.members.value?.length ?? 0),
      [model.manageWorkspaceMembersStepViewModel.form.fields.members.value]
    );

    return (
      <Modal
        visible={isVisible}
        title={
          <Row justify="space-between">
            <Col>
              <Title level={2} className="Title--withIcon">
                <SvgIcon iconName="diamond" /> Workspace Members ({numberOfMembers})
              </Title>
            </Col>
          </Row>
        }
        onCancel={() => {
          if (model.canUserEdit && model.manageWorkspaceMembersStepViewModel.form.isDirty)
            DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        width="100%"
        closeIcon={<SvgIcon iconName="close" />}
        footer={
          model.canUserEdit && (
            <div className="ManageWorkspaceMembersDialog__footer">
              <CommandButton withLoader command={submit} type="primary" size="large">
                Save Changes
              </CommandButton>
            </div>
          )
        }
        className="ManageWorkspaceMembersDialog"
      >
        <CreateWorkspaceMembersStep
          className="ManageWorkspaceMembersDialog__form"
          model={model.manageWorkspaceMembersStepViewModel}
          currentUser={model.userStore.loggedUser}
          isReadOnly={!model.canUserEdit}
        />
      </Modal>
    );
  }
);
