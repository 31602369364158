import { Step } from "../../../../../shared/stepWizard/Step";
import { CreateInitiativeVotingSystemModel } from "../../createInitiativeDialog/createInitiativeWizard/createInitiativeVotingSystem/CreateInitiativeVotingSystemModel";
import { EditInitiativeSteps } from "../EditInitiativeSteps";

export function isEditInitiativeVotingStep(step: Step<EditInitiativeSteps>): step is EditInitiativeVotingStepViewModel {
  return step.name === "VotingSystem";
}

export class EditInitiativeVotingStepViewModel extends CreateInitiativeVotingSystemModel {
  readonly name = "VotingSystem";
}
