import { Result } from "antd";
import * as React from "react";
import { StatusErrorCode } from "../services/ErrorService";

interface ErrorViewProps {
  errorCode: StatusErrorCode;
}

export const ErrorView: React.FC<ErrorViewProps> = ({ errorCode }) => {
  const getErrorStatus = () => {
    switch (errorCode) {
      case 403:
        return "403";
      case 404:
        return "404";
      case "NetworkError":
        return "error";
      default:
        return "500";
    }
  };

  const getErrorTitle = () => {
    switch (errorCode) {
      case 403:
        return "403 Forbidden";
      case 404:
        return "404";
      case "NetworkError":
        return "Network Error";
      default:
        return "500";
    }
  };

  const getErrorMessage = () => {
    switch (errorCode) {
      case 403:
        return "Sorry, you are not authorized to access this page.";
      case 404:
        return "Sorry, the page you visited does not exist.";
      case "NetworkError":
        return "No internet";
      default:
        return "Sorry, something went wrong.";
    }
  };

  return (
    <article className="Error">
      <Result status={getErrorStatus()} title={getErrorTitle()} subTitle={getErrorMessage()} />
    </article>
  );
};
