import { Button, Tabs, Typography } from "antd";
import React from "react";
import { ReactourStep } from "reactour";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { InitialWalkTour } from "./InitialWalkTour";
import { WalkTourPopover } from "./WalkTour";

const { Text } = Typography;

export enum InitiativeWalkTourSelectors {
  None = "None",
  Share = "Share",
  AddUser = "AddUser",
  CreateIdea = "CreateIdea",
  VotingMethod = "VotingMethod",
  ExtraActions = "ExtraActions",
  Detail = "Detail",
  Vote = "Vote",
}

export class InitiativeWalkTour {
  static getSteps() {
    const initialSteps = InitialWalkTour.getSteps();
    const steps: ReactourStep[] = [
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.Share}]`,
        content: () => (
          <WalkTourPopover title="Submit Idea" icon="share">
            <Text>
              Would you like to share this initiative? Click on the
              <Button type="primary" className="WalkTourButton">
                <SvgIcon iconName="share" /> Share
              </Button>
              button and a link to this initiative will go to your clipboard
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.AddUser}]`,
        content: () => (
          <WalkTourPopover title="Invite Users" icon="addUser">
            <Text>
              Click on the
              <Button className="WalkTourButton InviteUser" icon={<SvgIcon iconName="addUser" />}>
                Invite users
              </Button>
              button to invite your colleagues to your IdeaMate instance by entering their e-mail addresses. They will
              receive instructions and an activation link to your IdeaMate.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.CreateIdea}]`,
        content: () => (
          <WalkTourPopover title="Submit Idea" icon="plusOutlined">
            <Text>
              Do you have an idea you want to add to this initiative? By clicking the
              <Button type="primary" className="WalkTourButton">
                <SvgIcon iconName="plusOutlined" /> Submit Idea
              </Button>
              button, describe Idea and propose it. Your colleagues will see it and support if they like it. Keep an eye
              on the time period setting to know until when you can contribute new ideas and vote.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.VotingMethod}]`,
        content: () => (
          <WalkTourPopover title="Voting Methods" icon="dots">
            <Text>
              Each initiative can have its own voting method, here you specify not only how you vote but also the
              maximum number of votes you can give on one idea and the maximum number of votes everyone has in this
              initiative.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.ExtraActions}]`,
        content: () => (
          <WalkTourPopover title="Extra actions" icon="ellipsisOutlined">
            <Text>
              Want to make a difference with your initiative or idea? Click the
              <Button type="text" className="WalkTourButton" icon={<SvgIcon iconName="ellipsisOutlined" />} />
              and choose one of the actions:
              <Tabs defaultActiveKey="edit" tabPosition="left">
                <Tabs.TabPane tab={<SvgIcon iconName="edit" />} key="edit">
                  <Text strong>Edit</Text>: Open edit panel.
                </Tabs.TabPane>
                <Tabs.TabPane tab={<SvgIcon iconName="addUser" />} key="addUser">
                  <Text strong>Add co-ovners</Text>: Define colleagues that will co-moderate this initiative with you.
                </Tabs.TabPane>
                <Tabs.TabPane tab={<SvgIcon iconName="favourites" />} key="favourites">
                  <Text strong>Start implementation</Text>: Inform your colleagues that the realization of this
                  initiative has started. The status of the initiative will change to “Implementation”, once you click
                  this button.
                </Tabs.TabPane>
                <Tabs.TabPane tab={<SvgIcon iconName="lock" />} key="lock">
                  <Text strong>Close</Text>: Inform your colleagues that this initiative has been closed. You can reopen
                  the initiative at any time. The status of the initiative will change to “Closed”, once you click this
                  button.
                </Tabs.TabPane>
                <Tabs.TabPane tab={<SvgIcon iconName="vote" />} key="vote">
                  <Text strong>Set as realised</Text>: Inform your colleagues that this idea has been awarded for
                  realization out proposed ideas. Voting for this idea will no longer be possible and the awarded votes
                  will return to the owners.
                </Tabs.TabPane>
                <Tabs.TabPane tab={<SvgIcon iconName="delete" />} key="delete">
                  <Text strong>Delete</Text>: Remove this idea and return their votes to users. Voting on these ideas
                  will no longer be possible. Please note that this action is not reversible.
                </Tabs.TabPane>
              </Tabs>
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.Detail}]`,
        content: () => (
          <WalkTourPopover title="Idea" icon="bulb">
            <Text>Click on the idea to see more details of this idea or leave a comment.</Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativeWalkTourSelectors.Vote}]`,
        content: () => (
          <WalkTourPopover title="Voting" icon="dots">
            <Text>
              Did you find an idea you wanted to support? Nothing simpler. Vote for it by showing your support. Remember
              that you can only vote for ideas that have not yet been implemented and deleted. You can withdraw your
              support at any time by revoking your vote.
            </Text>
          </WalkTourPopover>
        ),
      },
    ];
    return [...initialSteps, ...steps];
  }
}
