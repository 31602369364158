import { Col, Row, Switch, Typography } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { AttachmentInput } from "../../../../../../shared/AttachmentInput/AttachmentInput";
import { Field, Form } from "../../../../../../shared/form/Form";
import { TextAreaWithPasteSupport } from "../../../../../../shared/pasteSupport/PasteSupport";
import { FileStore } from "../../../../../../shared/stories/file/FileStore";
import { TextInput } from "../../../../../../shared/textInput/TextInput";
import { useUrl } from "../../../../../../useRouter";
import { TagEditableList } from "../../../../shared/tags/Tags";
import { CreateInitiativeBasicStepViewModel } from "./CreateInitiativeBasicStepViewModel";

interface ICreateInitiativeBasicStepProps {
  model: CreateInitiativeBasicStepViewModel;
  className?: string;
  fileStore: FileStore;
}

export const CreateInitiativeBasicStepA: React.FunctionComponent<ICreateInitiativeBasicStepProps> = ({
  model,
  className,
  fileStore,
}) => {
  const uploadFileUrl = useUrl().toUpload();
  const [attachements, setAttachements] = React.useState(model.getInitiativeAttachments);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);
  const [tagsLoading, setTagsLoading] = React.useState(false);

  const handleSearch = async (text: string) => {
    setTagsLoading(true);
    const tags = await model.searchTags(text);
    setTagOptions(tags);
    setTagsLoading(false);
  };

  React.useEffect(() => {
    model.form.fields.attachments.value && setAttachements(model.form.fields.attachments.value);
  }, [model.form.fields.attachments.value]);
  return (
    <Form className={className} layout="vertical" form={model.form}>
      {fields => (
        <>
          <Field field={fields.title} label="Title">
            {props => <TextInput placeholder="Initiative title" {...props} />}
          </Field>
          <Field field={fields.description} label="Description">
            {props => (
                <TextAreaWithPasteSupport
                  fileStore={fileStore}
                  placeholder="Initiative description"
                  rows={10}
                  onAttachmentAdded={attachment => model.addToInitiativeAttachments(attachment)}
                  {...props}
                  richTextEditor
                />
            )}
          </Field>
          <Field field={fields.tags} label="Tags">
            {props => (
              <TagEditableList
                {...props}
                selected={props.value ?? []}
                options={tagOptions}
                onSelectedChange={props.onChange}
                onSearch={handleSearch}
                tagOptionsLoading={tagsLoading}
              />
            )}
          </Field>
          <Field field={fields.attachments} className="AttachmentsInputTitle">
            {props => (
              <>
                <AttachmentInput
                  text="Add attachment"
                  url={uploadFileUrl}
                  {...props}
                  onChange={props.onChange}
                  maxCount={3}
                  attachmentList={attachements}
                />
              </>
            )}
          </Field>
          {!model.isEditMode && (
            <Field field={fields.hideIdeaSubmitter}>
              {({ onCommit, ...props }) => (
                <Row gutter={[12, 0]}>
                  <Col>
                    <Switch size="small" checked={props.value} {...props} />
                  </Col>
                  <Col>
                    <Typography.Text strong>Make all ideas anonymous</Typography.Text>
                  </Col>
                </Row>
              )}
            </Field>
          )}
        </>
      )}
    </Form>
  );
};

export const CreateInitiativeBasicStep = observer(CreateInitiativeBasicStepA);
