import { Upload, Button, message, List } from "antd";
import { UploadChangeParam, UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import * as React from "react";
import { AttachmentDto } from "../../types/initiatives/dto/AttachmentDto";
import { AttachmentType } from "../../types/ly/application/AttachmentType";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { FileAttachment } from "../attachment/Attachment";

export interface IFileInputProps {
  text?: string;
  url: string;
  onChange?: (value: AttachmentDto[]) => void;
  onRemove?: (file: UploadFile<AttachmentDto>) => void | boolean | Promise<void | boolean>;
  maxCount?: number;
  attachmentList?: AttachmentDto[];
  itemRender?: (
    rootNode: React.ReactElement,
    attachment: AttachmentDto,
    actions: {
      remove: () => void;
    }
  ) => React.ReactElement;
  className?: string;
  showButton?: boolean;
  renderList?: boolean;
}

interface UploadAttachment extends AttachmentDto {
  uploadId: string;
  uploadStatus: UploadFileStatus;
}

export const FileInput: React.FunctionComponent<IFileInputProps> = ({
  text,
  url,
  onChange,
  onRemove,
  maxCount,
  attachmentList,
  itemRender,
  className,
  showButton = true,
  renderList = true,
  children,
}) => {
  const [fileName, setFileName] = React.useState("");
  const [uploadAttachments, setUploadAttachments] = React.useState<(AttachmentDto | UploadAttachment)[]>(
    attachmentList ?? []
  );

  React.useEffect(() => {
    attachmentList && setUploadAttachments(attachmentList);
  }, [attachmentList?.length]);

  React.useEffect(() => {
    onChange && onChange(uploadAttachments);
  }, [uploadAttachments]);

  function handleChange(info: UploadChangeParam) {
    switch (info.file.status) {
      case "uploading":
        if (
          uploadAttachments.filter(attachment => "uploadId" in attachment && attachment.uploadId === info.file.uid)
            .length === 0
        ) {
          setUploadAttachments(
            uploadAttachments.concat({
              uploadId: info.file.uid,
              id: "",
              name: info.file.name,
              url: info.file.url,
              contentType: info.file.type ?? "application/octet-stream",
              type: AttachmentType.File,
              uploadStatus: "uploading",
            })
          );
        }

        break;
      case "done":
        setUploadAttachments(
          uploadAttachments.map(x =>
            "uploadId" in x && x.uploadId === info.file.uid && info.file.response
              ? { ...x, id: info.file.response.id, url: info.file.response.url }
              : x
          )
        );
        message.success(`${info.file.name} file uploaded successfully`);
        break;
      case "error":
        message.error(`${info.file.name} file upload failed.`);
        break;
      default:
        break;
    }
  }

  function download(info: UploadFile) {
    window.open(info.response.url);
  }

  function beforeUpload(file: File) {
    setFileName(file.name);
    return true;
  }

  function handleRemove(item: AttachmentDto | UploadAttachment) {
    setUploadAttachments(prev =>
      prev.filter(i => ("uploadId" in i && "uploadId" in item ? i.uploadId !== item.uploadId : i.id !== item.id))
    );
  }

  return (
    <>
      <Upload
        className={`fileUpload ${className}`}
        accept=".pdf, .jpg, .jpeg, .png, .ppt, .zip"
        onChange={handleChange}
        action={url}
        showUploadList={false}
        listType="picture"
        onDownload={download}
        onRemove={onRemove}
        name={fileName}
        beforeUpload={beforeUpload}
        maxCount={maxCount}
      >
        {showButton && (
          <Button shape="circle">
            <SvgIcon iconName="plusOutlined" />
          </Button>
        )}
        {text && <span className="fileUpload__text">{text}</span>}
        {children}
      </Upload>
      {renderList && uploadAttachments.length !== 0 && (
        <List
          size="small"
          className="FileInputAttachmentList"
          dataSource={uploadAttachments}
          renderItem={item => {
            const root = <></>;
            if (itemRender) {
              const result = itemRender(root, item, { remove: () => handleRemove(item) });
              if (result !== root) return result;
            }

            return (
              <List.Item key={item.id}>
                <FileAttachment attachment={item} onRemove={() => handleRemove(item)} />
              </List.Item>
            );
          }}
        />
      )}
    </>
  );
};
