import { convertHtmlToText } from "../richText/utils";

export function RichTextLengthValidator<T>(value: any | undefined, maxLength: number) {
  const text = convertHtmlToText(value);
  if (text.length === 0) {
    return [`Field must not be empty`];
  }
  if (text.length > maxLength) {
    return [`Field length is greater than ${maxLength}`];
  }
  return true;
}
