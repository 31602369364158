import { IRoute } from "react-mvvm";
import { App } from "../App";
import { WorkspaceViewModel } from "./workspace/WorkspaceViewModel";
import { WorkspaceListViewModel } from "./workspaceList/WorkspaceListViewModel";

export type WorkspaceParams = { workspaceId: string };
export type IdeaParams = { ideaId: string };

export const addWorkspaceRouter = (route: IRoute<App, never, never>) => {
  const workspaceRouter = route.addRoute<WorkspaceListViewModel>("/workspaces", WorkspaceListViewModel, appPage => {
    const workspaceList = new WorkspaceListViewModel(
      appPage.rootStore.userStore,
      appPage.rootStore.workspaceStore,
      appPage.errorService,
      appPage.rootStore
    );
    return appPage.showChildPage(workspaceList);
  });

  workspaceRouter.addRoute<WorkspaceViewModel, WorkspaceParams>(
    "/:workspaceId",
    WorkspaceViewModel,
    (appPage, p) => {
      const workspaceDetails = new WorkspaceViewModel(
        p.workspaceId,
        appPage.workspaceStore,
        appPage.userStore,
        appPage.errorService,
        appPage.rootStore
      );
      return appPage.showChildPage(workspaceDetails);
    },
    page => ({ workspaceId: page.workspaceId })
  );
};
