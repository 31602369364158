import { Input } from "antd";
import { InputProps } from "antd/lib/input/Input";
import * as React from "react";

// eslint-disable-next-line react/require-default-props
type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  onCommit?: () => void;
  showCount?: boolean;
  maxLength?: number;
};

export const TextInput = ({ value, onChange, onCommit, ...props }: Props & Omit<InputProps, "value" | "onChange">) => (
  <Input
    {...props}
    type="text"
    value={value ?? ""}
    onChange={e => onChange && onChange(e.target.value)}
    onBlur={() => onCommit && onCommit()}
  />
);
