import { FileDoneOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Image, Space } from "antd";
import Text from "antd/lib/typography/Text";
import * as React from "react";
import { AttachmentDto } from "../../types/initiatives/dto/AttachmentDto";

export enum ThumbnailSizeEnum {
  Small = "small",
  Normal = "normal",
}

interface IAttachmentProps {
  attachment: AttachmentDto;
  onRemove?: () => void;
  thumbnailSize?: ThumbnailSizeEnum;
}

export const UrlAttachment: React.FunctionComponent<IAttachmentProps> = ({
  attachment,
  onRemove,
  thumbnailSize = ThumbnailSizeEnum.Normal,
}) => (
  <Space className="Attachment">
    <Space>
      <a href={attachment.url} rel="noreferrer" target="_blank">
        <div className={`UrlAttachmentThumbnail AttachmentThumbnailSize-${thumbnailSize}`}>
          <LinkOutlined className="AttachmentIcon" />
        </div>
      </a>
      <Space direction="vertical">
        <Space>
          <Text className="AttachmentTitle">{attachment.name}</Text>
        </Space>
        <Space>
          {onRemove && (
            <Button type="link" block onClick={onRemove}>
              Remove
            </Button>
          )}
        </Space>
      </Space>
    </Space>
  </Space>
);

export const FileAttachment: React.FunctionComponent<IAttachmentProps> = ({
  attachment,
  onRemove,
  thumbnailSize = ThumbnailSizeEnum.Normal,
}) => (
  <Space className="Attachment">
    <Space>
      <div className={`FileAttachmentThumbnail AttachmentThumbnailSize-${thumbnailSize}`}>
        {attachment.contentType.startsWith("image") ? (
          <Image src={attachment.url} placeholder />
        ) : (
          <FileDoneOutlined className="AttachmentIcon" />
        )}
      </div>
      <Space direction="vertical">
        <Space>
          <Text className="AttachmentTitle">{attachment.name}</Text>
        </Space>
        <Space>
          <a href={attachment.url}>
            <Button type="link" block>
              Download
            </Button>
          </a>
          {onRemove && (
            <Button type="link" block onClick={onRemove}>
              Remove
            </Button>
          )}
        </Space>
      </Space>
    </Space>
  </Space>
);
