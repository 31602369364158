import { Badge, Card, Col, Radio, Row, Typography, Button, Drawer, Divider, Popover } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { bindTo, ContentView, isLoaded, property } from "react-mvvm";
import { NIL } from "uuid";
import { INT_32_MAX_VALUE } from "../../../shared/Constants";
import { DateFormatter } from "../../../shared/DateFormatter";
import { Dropdown } from "../../../shared/Dropdown/Dropdown";
import { RadioButtonGroup } from "../../../shared/RadioButtonGroup/RadioButtonGroup";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { VotingMethodIcon } from "../../../shared/VotingMethodIcon/VotingMethodIcon";
import { ActivityIndicator } from "../../../shared/activityIndicator/ActivityIndicator";
import { AttachmentList } from "../../../shared/attachment/AttachmentList";
import { BreadcrumbView } from "../../../shared/breadcrumb/BreadcrumbView";
import { CommandButton } from "../../../shared/button/Button";
import { RichTextViewer } from "../../../shared/richText/RichTextViewer";
import { UserAvatar } from "../../../shared/userAvatar/UserAvatar";
import { InitiativeWalkTourSelectors } from "../../../shared/walkTour/InitiativeWalkTour";
import { WalkTour } from "../../../shared/walkTour/WalkTour";
import { IdeaStateNameDto } from "../../../types/initiatives/dto/IdeaStateNameDto";
import { InitiativeStateNameDto } from "../../../types/initiatives/dto/InitiativeStateNameDto";
import { EditInitiativeDialog } from "../initiativeList/editInitiativeDialog/EditInitiativeDialog";
import { AddCoOwner } from "../shared/addCoOwner/AddCoOwner";
import { CreateIdeaDialog } from "../shared/createIdeaDialog/CreateIdeaDialog";
import { EditIdeaDialog } from "../shared/editIdeaDialog/EditIdeaDialog";
import { MoveIdeaDialog } from "../shared/moveIdeaDialog/MoveIdeaDialog";
import { SubscribeButton } from "../shared/subscription/subscribeButton/SubscribeButton";
import { TagList } from "../shared/tags/Tags";
import { IdeasOrderBy, InitiativeViewModel } from "./InitiativeViewModel";
import { DateProgress } from "./dateProgress/DateProgress";
import { IdeaListView } from "./ideaList/IdeaListView";

const { Title, Text } = Typography;
const { Meta } = Card;

export const InitiativeView = observer(({ model }: { model: InitiativeViewModel }) => {
  if (model.childPage) {
    return <ContentView content={model.childPage} />;
  }

  const {
    state,
    isLoading,
    showIdea,
    isFilterVisible,
    isDrawerVisible,
    isPopoverVisible,
    toggleDrawer,
    toggleFilters,
    togglePopover,
  } = model;

  return (
    <div className="Initiative">
      <ActivityIndicator isLoading={isLoading}>
        {isLoaded(state) && (
          <>
            <div className="Initiative__header">
              <BreadcrumbView items={state.breadcrumbItems} appendWorkspacesItem />
              <CommandButton
                icon={<SvgIcon iconName="share" />}
                command={state.shareInitiative}
                size="large"
                type="primary"
                dataTour={InitiativeWalkTourSelectors.Share}
              >
                Share
              </CommandButton>

              <CommandButton
                command={state.showCreateIdeaDialog}
                icon={<SvgIcon iconName="plusOutlined" />}
                size="large"
                type="primary"
                dataTour={InitiativeWalkTourSelectors.CreateIdea}
              >
                Submit Idea
              </CommandButton>
              <SubscribeButton
                command={state.editSubscription}
                isSubscribed={state.initiative.isCurrentUserSubscribed}
              />
              {state.isArchived && state.canUserUnarchive && (
                <CommandButton
                  command={state.unarchiveInitiative}
                  icon={<SvgIcon iconName="refresh" />}
                  size="large"
                  type="ghost"
                  className="Initiative__OutlinedBtn"
                >
                  Restore initiative
                </CommandButton>
              )}
            </div>
            <Row className="Initiative__body" gutter={32}>
              <Col xs={15} lg={9}>
                <Card
                  className="Initiative__basicDetailsCard HighlightedBorder--blue"
                  bordered={false}
                  data-tour={InitiativeWalkTourSelectors.ExtraActions}
                  style={{ borderLeftColor: state.initiative.themeColor }}
                  title={
                    <div className="Initiative__basicDetailsCardTitle">
                      <Meta
                        className="Initiative__basicDetailsCardTitleMeta"
                        avatar={<UserAvatar size="large" userName={state.ownerName} avatarId={state.ownerAvatarId} />}
                        title={<span className="Initiative__metaTitle">Created by {state.ownerName}</span>}
                        description={
                          <span className="Initiative__metaDate">
                            {DateFormatter.format(state.createdAt, "MMMM DD, YYYY h:mm A")}
                          </span>
                        }
                      />
                      <Title level={1} className="Title Title--withIcon">
                        <SvgIcon iconName="diamond" /> <span>{state.title}</span>
                      </Title>
                    </div>
                  }
                >
                  <RichTextViewer content={state.description} maxLines={10} maxLength={900} addPaddingBottom />
                  <TagList className="Initiative__tags" tags={state.initiative.tags} />
                  <Row
                    justify="space-between"
                    align="middle"
                    className="Initiative__basicDetailsCardFooter"
                    data-tour={InitiativeWalkTourSelectors.VotingMethod}
                  >
                    {state.isVotingPeriod && (
                      <Col className="Initiative__InitiativeUserVoteAmount">
                        <VotingMethodIcon isVoted={false} votingMethod={state.votingMethod} />
                        <span className="Initiative__InitiativeVoteAmount">
                          {state.maxVotesPerInitiative === INT_32_MAX_VALUE ? "∞" : state.votesLeftForInitiative}
                        </span>
                        left for this initiative
                      </Col>
                    )}
                    {state.initiative.hideIdeaSubmitter && (
                      <Col className="Initiative__AnonymousMarker">
                        <SvgIcon iconName="eyeOff" />
                        Anonymous ideas
                      </Col>
                    )}
                    <Col>
                      <div
                        className={classNames(
                          "Initiative__status",
                          `Initiative__status--${state.initiative.state.name?.toLowerCase()}`
                        )}
                      >
                        {state.initiative.state.name}
                      </div>
                    </Col>
                  </Row>
                  {state.hasAttachments && (
                    <>
                      <Divider />
                      <AttachmentList attachments={state.initiative.attachments} />
                    </>
                  )}
                  {state.hasExtraActions && (
                    <Popover
                      visible={isPopoverVisible}
                      onVisibleChange={() => togglePopover()}
                      placement="leftTop"
                      trigger="click"
                      content={() => (
                        <ul className="InitiativeExtraActions">
                          {state.canUserEdit && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="edit" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.showEditInitiativeDialog.execute("Basic");
                                  togglePopover();
                                }}
                              >
                                Edit
                              </Button>
                            </li>
                          )}
                          {state.canUserEdit && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="addUser" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.showAddCoOwnerDialog.execute();
                                  togglePopover();
                                }}
                              >
                                Set co-owners
                              </Button>
                            </li>
                          )}
                          {state.canUserSetImplementation && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="favourites" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.setImplementation.execute();
                                  togglePopover();
                                }}
                              >
                                Start Implementation
                              </Button>
                            </li>
                          )}
                          {state.canUserClose && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="lock" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.closeInitiative.execute();
                                  togglePopover();
                                }}
                              >
                                Close initiative
                              </Button>
                            </li>
                          )}
                          {state.canUserOpen && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="lockOpen" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.showEditInitiativeDialog.execute("Date");
                                  togglePopover();
                                }}
                              >
                                Open initiative
                              </Button>
                            </li>
                          )}
                          {state.canUserArchive && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="archive" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.archiveInitiative.execute();
                                  togglePopover();
                                }}
                              >
                                Archive initiative
                              </Button>
                            </li>
                          )}
                          {state.canUserUnarchive && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="unarchive" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.unarchiveInitiative.execute();
                                  togglePopover();
                                }}
                              >
                                Restore initiative
                              </Button>
                            </li>
                          )}
                          {state.canUserReset && (
                            <li>
                              <Button
                                className="InitiativeExtraActions__extraActionsButton"
                                icon={<SvgIcon iconName="refresh" />}
                                size="small"
                                type="link"
                                onClick={() => {
                                  state.resetInitiative.execute();
                                  togglePopover();
                                }}
                              >
                                Reset initiative
                              </Button>
                            </li>
                          )}
                        </ul>
                      )}
                    >
                      <Button
                        className="InitiativeExtraActionsPopover"
                        icon={<SvgIcon iconName="ellipsisOutlined" />}
                        size="small"
                        type="link"
                        onClick={() => togglePopover}
                      />
                    </Popover>
                  )}
                </Card>
              </Col>
              <Col xs={15} lg={6}>
                <Card
                  className="Initiative__timeframe"
                  title={
                    <Title level={2} className="Title--withIcon">
                      <SvgIcon iconName="clock" /> Timeframe
                    </Title>
                  }
                >
                  <div className="Initiative__timeframeBody">
                    <DateProgress
                      title="Collecting ideas"
                      className="Initiative__timeframeBodyDateProgress"
                      startDate={state.submissionStartDate}
                      endDate={state.submissionEndDate}
                    />
                    <DateProgress
                      title="Voting"
                      className="Initiative__timeframeBodyDateProgress"
                      startDate={state.votingStartDate}
                      endDate={state.votingEndDate}
                    />
                  </div>
                  {state.canUserEdit && (
                    <Button
                      onClick={() => state.showEditInitiativeDialog.execute("Date")}
                      icon={<SvgIcon iconName="edit" />}
                      size="small"
                      type="link"
                      className="Initiative__timeframeEditButton"
                    />
                  )}
                </Card>
              </Col>
              <Col xs={15} lg={9}>
                <Row align="middle" justify="space-between" className="Initiative__ideasHeader">
                  <Title level={2} className="Title--withIcon">
                    <SvgIcon iconName="bulb" /> Ideas ({state.ideasCount})
                  </Title>
                  <Col className="Initiative__ideasHeaderSortAndFilter">
                    <Dropdown
                      label={<SvgIcon iconName="vector" />}
                      className="Initiative__ideasHeaderOrderByVotes"
                      defaultValue={state.ideaList.orderBy}
                      {...bindTo(property(state.ideaList, "orderBy"))}
                      options={[
                        { value: IdeasOrderBy.DateDesc, text: "Most recent" },
                        { value: IdeasOrderBy.DateAsc, text: "Oldest" },
                        { value: IdeasOrderBy.VotesCountDesc, text: "Number of votes" },
                      ]}
                    />
                    <Button type="text" onClick={toggleFilters}>
                      <SvgIcon iconName="barChart" />
                      Filters (2)
                    </Button>
                  </Col>
                  <Col className="Initiative__ideasHeaderSortAndFilterSider">
                    <Button block size="large" onClick={toggleDrawer}>
                      Sort and Filters
                    </Button>
                  </Col>
                  <Row
                    className={classNames("Initiative__ideasHeaderSortAndFilterContainer", {
                      "Initiative__ideasHeaderSortAndFilterContainer--active": isFilterVisible,
                    })}
                  >
                    <Col className="InitiativeList__initiativesHeaderFilter" span={4}>
                      <Text>Created by:</Text>
                      <RadioButtonGroup
                        defaultValue={state.ideaList.filter.userId}
                        {...bindTo(property(state.ideaList.filter, "userId"))}
                      >
                        {[
                          { value: NIL, text: "anyone" },
                          { value: state.loggedUser.id, text: "me" },
                        ].map(item => (
                          <Radio
                            value={item.value}
                            key={item.value}
                            className="InitiativeList__initiativesHeaderFilterOption"
                          >
                            {item.text}
                          </Radio>
                        ))}
                      </RadioButtonGroup>
                    </Col>
                    <Col className="InitiativeList__initiativesHeaderFilter" span={8}>
                      <Text>Status</Text>
                      <RadioButtonGroup
                        defaultValue={state.ideaList.statusFilter.status}
                        {...bindTo(property(state.ideaList.statusFilter, "status"))}
                      >
                        {[
                          { value: "", text: "All" },
                          { value: IdeaStateNameDto.Submitted, text: "Open" },
                          { value: IdeaStateNameDto.Released, text: "Released" },
                          { value: IdeaStateNameDto.Deleted, text: "Deleted" },
                        ].map(item => (
                          <Radio
                            value={item.value}
                            key={item.text}
                            className="InitiativeList__initiativesHeaderFilterOption"
                          >
                            {item.text}
                          </Radio>
                        ))}
                      </RadioButtonGroup>
                    </Col>
                  </Row>
                </Row>
                <Drawer
                  title="Sort and filter"
                  mask={false}
                  onClose={toggleDrawer}
                  visible={isDrawerVisible}
                  placement="left"
                  width={window.innerWidth}
                  footer={
                    <Button block type="primary" size="large" onClick={toggleDrawer}>
                      Apply and Close
                    </Button>
                  }
                >
                  <Col className="Initiative__ideaListDrawer">
                    <Title level={5}>Sort by</Title>
                    <RadioButtonGroup
                      defaultValue={state.ideaList.orderBy}
                      {...bindTo(property(state.ideaList, "orderBy"))}
                      className="Initiative__ideaListDrawerOptions"
                    >
                      {[
                        { value: IdeasOrderBy.DateDesc, text: "Most recent" },
                        { value: IdeasOrderBy.DateAsc, text: "Oldest" },
                        { value: IdeasOrderBy.VotesCountDesc, text: "Number of votes" },
                      ].map(item => (
                        <Radio value={item.value} key={item.value} className="Initiative__ideaListDrawerOptionsItem">
                          {item.text}
                        </Radio>
                      ))}
                    </RadioButtonGroup>
                    <Divider />
                    <Title level={5}>Filters</Title>
                    <Text className="Initiative__ideaListDrawerOptionsTitle">Created by:</Text>
                    <RadioButtonGroup
                      defaultValue={state.ideaList.filter.userId}
                      {...bindTo(property(state.ideaList.filter, "userId"))}
                      className="Initiative__ideaListDrawerOptions"
                    >
                      {[
                        { value: NIL, text: "anyone" },
                        { value: state.loggedUser.id, text: "me" },
                      ].map(item => (
                        <Radio value={item.value} key={item.value} className="Initiative__ideaListDrawerOptionsItem">
                          {item.text}
                        </Radio>
                      ))}
                    </RadioButtonGroup>
                    <Text className="Initiative__ideaListDrawerOptionsTitle">Status</Text>
                    <RadioButtonGroup
                      defaultValue={state.ideaList.statusFilter.status}
                      {...bindTo(property(state.ideaList.statusFilter, "status"))}
                      className="Initiative__ideaListDrawerOptions"
                    >
                      {[
                        { value: "", text: "All" },
                        { value: IdeaStateNameDto.Submitted, text: "Open" },
                        { value: IdeaStateNameDto.Released, text: "Released" },
                        { value: IdeaStateNameDto.Deleted, text: "Deleted" },
                      ].map(item => (
                        <Radio value={item.value} key={item.text} className="Initiative__ideaListDrawerOptionsItem">
                          {item.text}
                        </Radio>
                      ))}
                    </RadioButtonGroup>
                  </Col>
                </Drawer>
                <Card className="Initiative__ideaList" data-tour={InitiativeWalkTourSelectors.Detail}>
                  <IdeaListView
                    ideaList={state.ideaList}
                    showIdea={showIdea}
                    showEditIdeaDialog={state.showEditIdeaDialog}
                    votingMethod={state.votingMethod}
                    showMoveIdeaDialog={state.showMoveIdeaDialog}
                  />
                </Card>
              </Col>
              <Col xs={15} lg={6}>
                {(state.initiative.state.name === InitiativeStateNameDto.Closed ||
                  state.initiative.state.name === InitiativeStateNameDto.Evaluation) && (
                  <Card
                    className="Initiative__summary"
                    title={
                      <Title level={2} className="Title--withIcon">
                        <SvgIcon iconName="chart" /> Summary
                      </Title>
                    }
                  >
                    <div className="Initiative__summaryBody">
                      <div className="participants">
                        <SvgIcon iconName="avatar" />
                        <span className="Initiative__summaryAmount">{state.totalContributorCount}</span> participants
                      </div>
                      <div className="votes">
                        <VotingMethodIcon isVoted={false} votingMethod={state.votingMethod} isSummaryIcon />
                        <span className="Initiative__summaryAmount">{state.totalVotesCount}</span> votes
                      </div>
                      <div className="ideas">
                        <SvgIcon iconName="bulb" />
                        <span className="Initiative__summaryAmount">{state.ideasCount}</span> ideas
                      </div>
                    </div>
                  </Card>
                )}
                <Row justify="space-between">
                  <Col>
                    {state.initiative.state.name !== InitiativeStateNameDto.Closed &&
                      state.initiative.state.name !== InitiativeStateNameDto.Evaluation && (
                        <div className="Initiative__participants">
                          <Title level={2}>
                            Participants <Badge showZero count={state.totalContributorCount} size="default" />
                          </Title>
                        </div>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <AddCoOwner model={state.addCoOwner} />
            <CreateIdeaDialog model={state.createIdeaDialogViewModel} fileStore={model.fileStore} />
            <EditInitiativeDialog model={state.editInitiativeDialogViewModel} />
            <EditIdeaDialog model={state.editIdeaDialogViewModel} fileStore={model.fileStore} />
            <MoveIdeaDialog model={state.moveIdeaDialogViewModel} />
          </>
        )}
      </ActivityIndicator>
      <>
        <WalkTour
          fixed
          steps={model.steps}
          lastStepNextButton={<Button type="primary">Start discovering IdeaMate</Button>}
        />
      </>
    </div>
  );
});
