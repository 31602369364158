import { computed, observable } from "mobx";
import { GetWorkspaceMemberListSortType } from "../../../types/initiatives/dto/GetWorkspaceMemberListSortType";
import { WorkspaceDto } from "../../../types/initiatives/dto/WorkspaceDto";
import { WorkspaceListItemDto } from "../../../types/initiatives/dto/WorkspaceListItemDto";
import { WorkspaceRole } from "../../../types/ly/application/WorkspaceRole";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { DEFAULT_WORKSPACE_COLOR } from "../../Constants";
import { UserStore } from "../user/UserStore";
import { WorkspaceMember } from "./WorkspaceMember";
import { WorkspaceStore } from "./WorkspaceStore";

export class WorkspaceOverview {
  @observable id = "";

  @observable ownerId? = "";

  @observable ownerEmail? = "";

  @observable ownerName? = "";

  @observable name = "";

  @observable description = "";

  @observable isPrivate = false;

  @observable themeColor = DEFAULT_WORKSPACE_COLOR;

  @observable numberOfMembers = 0;

  @observable numberOfInitiatives = 0;

  @observable private _isPersist = false;

  @computed get getOwnerName() {
    return this.ownerName ? this.ownerName : this.ownerEmail;
  }

  updateListFromServer(dto: WorkspaceListItemDto) {
    this.id = dto.id;
    this.ownerName = dto.owner?.name;
    this.ownerEmail = dto.owner?.email;
    this.ownerId = dto.owner?.id;
    this.name = dto.name;
    this.description = dto.description;
    this.themeColor = dto.themeColor ?? DEFAULT_WORKSPACE_COLOR;
    this.numberOfMembers = dto.numberOfMembers;
    this.numberOfInitiatives = dto.numberOfInitiatives;
    this.isPrivate = dto.isPrivate;
    this._isPersist = true;
  }
}

export class Workspace {
  @observable id: string;

  @observable ownerId? = "";

  @observable ownerEmail? = "";

  @observable ownerName? = "";

  @observable name = "";

  @observable description = "";

  @observable isPrivate = false;

  @observable themeColor = DEFAULT_WORKSPACE_COLOR;

  @observable numberOfMembers = 0;

  @observable coOwnerIds: string[] = [];

  @observable members: WorkspaceMember[] = [];

  @observable private _isPersist = false;

  constructor(private readonly workspaceStore: WorkspaceStore, private readonly userStore: UserStore, id: string) {
    this.id = id;
  }

  @computed get getOwnerName() {
    return this.ownerName ? this.ownerName : this.ownerEmail;
  }

  @computed get canUserEdit() {
    return this.ownerId === this.userStore.loggedUser.id || this.coOwnerIds.indexOf(this.userStore.loggedUser.id) > -1;
  }

  async updateWorkspace() {
    await this.workspaceStore.getWorkspace(this.id);
  }

  updateFromDto(dto: WorkspaceDto) {
    this.id = dto.id;
    this.ownerId = dto.ownerId;
    this.coOwnerIds = dto.coOwnerIds;
    this.name = dto.name;
    this.description = dto.description;
    this.themeColor = dto.themeColor ?? DEFAULT_WORKSPACE_COLOR;
    this.numberOfMembers = dto.numberOfMembers;
    this.isPrivate = dto.isPrivate;
    this._isPersist = true;
  }

  async updateMembers(limit: number | null = null) {
    let updatedMembers: WorkspaceMember[] = [];

    let page = 1;
    let hasNextPage = false;
    // eslint-disable-next-line no-param-reassign
    if (limit === null) limit = Infinity;
    let results = limit < 100 ? limit : 100;
    do {
      // eslint-disable-next-line no-await-in-loop
      const result = await this.workspaceStore.getWorkspaceMembers(
        this.id,
        { page, results },
        { sortDirection: SortDirection.Descending, sortType: GetWorkspaceMemberListSortType.Role }
      );

      updatedMembers = updatedMembers.concat(result.members);
      page += 1;
      hasNextPage = result.hasNextPage;
      if (limit - updatedMembers.length < results) results = limit - updatedMembers.length;
    } while (hasNextPage && updatedMembers.length < limit);

    this.members = updatedMembers;
  }

  async save() {
    if (this._isPersist) {
      await this.workspaceStore.workspaceService.editWorkspace({
        id: this.id,
        name: this.name,
        description: this.description,
        themeColor: this.themeColor,
        members: this.members
          .filter(x => x.workspaceRole !== WorkspaceRole.Owner)
          .map(x => ({
            email: x.email,
            type: x.workspaceRole,
          })),
      });
    } else {
      await this.workspaceStore.workspaceService.createWorkspace({
        id: this.id,
        name: this.name,
        description: this.description,
        themeColor: this.themeColor,
        isPrivate: this.isPrivate,
        members: this.members
          .filter(x => x.workspaceRole !== WorkspaceRole.Owner)
          .map(x => ({
            email: x.email,
            type: x.workspaceRole,
          })),
      });
      this._isPersist = true;
    }
  }
}
