import { IRoute } from "react-mvvm";
import { App } from "../App";
import { NotificationCenterViewModel } from "./NotificationCenterViewModel";

export type InitiativeParams = { initiativeId: string };
export type IdeaParams = { ideaId: string };

export const addNotificationsRouters = (route: IRoute<App, never, never>) => {
  route.addRoute<NotificationCenterViewModel>("/notifications", NotificationCenterViewModel, appPage => {
    const notificationCenter = new NotificationCenterViewModel(
      appPage.rootStore.notificationStore,
      appPage.rootStore.userStore,
      appPage.errorService,
      appPage.navigationService
    );
    return appPage.showChildPage(notificationCenter);
  });
};
