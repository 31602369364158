import { Col, Modal, Row, Steps } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { CreateWorkspaceStep } from "../createWorkspaceDialog/createWorkspaceWizard/CreateWorkspaceStep";
import { CreateWorkspaceBasicStep } from "../createWorkspaceDialog/createWorkspaceWizard/createWorkspaceBasicStep/CreateWorkspaceBasicStep";
import { CreateWorkspaceMembersStep } from "../createWorkspaceDialog/createWorkspaceWizard/createWorkspaceMembersStep/CreateWorkspaceMembersStep";
import { EditWorkspaceDialogViewModel } from "./EditWorkspaceDialogViewModel";
import { isEditWorkspaceBasicStep } from "./editWorkspaceWizard/EditWorkspaceBasicStepViewModel";
import { isEditWorkspaceMembersStep } from "./editWorkspaceWizard/EditWorkspaceMembersStepViewModel";

interface IEditWorkspaceDialogViewProps {
  model: EditWorkspaceDialogViewModel;
}

const { Step } = Steps;

const EditWorkspaceSteps = ({ currentStep, stepCount }: { currentStep: number; stepCount: number }) => {
  return (
    <Steps progressDot current={currentStep}>
      {[...Array(stepCount).keys()].map(key => (
        <Step key={key} />
      ))}
    </Steps>
  );
};

export const EditWorkspaceDialog: React.FunctionComponent<IEditWorkspaceDialogViewProps> = observer(
  ({ model }: IEditWorkspaceDialogViewProps) => {
    const { isVisible, cancel, wizard } = model;

    if (!wizard) {
      return null;
    }

    const {
      currentStep,
      currentStepOrder,
      isGoToNextStepVisible,
      goToNextStep,
      isGoToPreviousStepVisible,
      goToPreviousStep,
      isSubmitVisible,
      submit,
      stepCount,
    } = wizard;

    return (
      <Modal
        visible={isVisible}
        title={
          <Row justify="space-between">
            <Col>
              <Title level={2} className="Title--withIcon">
                <SvgIcon iconName="diamond" /> Edit Workspace
              </Title>
            </Col>
            <Col className="EditWorkspaceDialog__steps">
              <EditWorkspaceSteps currentStep={currentStepOrder} stepCount={stepCount} />
            </Col>
          </Row>
        }
        onCancel={() => {
          if (
            (currentStep instanceof CreateWorkspaceStep && currentStep.form.isDirty) ||
            !isEditWorkspaceBasicStep(currentStep)
          )
            DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        width="100%"
        closeIcon={<SvgIcon iconName="close" />}
        footer={
          <div className="EditWorkspaceDialog__footer">
            {isGoToNextStepVisible && (
              <CommandButton command={goToNextStep} type="primary" size="large">
                Next
              </CommandButton>
            )}
            {isSubmitVisible && (
              <CommandButton withLoader command={submit} type="primary" size="large">
                Save Changes
              </CommandButton>
            )}
            {isGoToPreviousStepVisible && (
              <CommandButton command={goToPreviousStep} type="link" icon={<SvgIcon iconName="chevronLeft" />}>
                Back
              </CommandButton>
            )}
          </div>
        }
        className="EditWorkspaceDialog"
      >
        {isEditWorkspaceBasicStep(currentStep) && (
          <CreateWorkspaceBasicStep className="EditWorkspaceDialog__form" model={currentStep} editMode />
        )}
        {isEditWorkspaceMembersStep(currentStep) && (
          <CreateWorkspaceMembersStep
            className="EditWorkspaceDialog__form"
            model={currentStep}
            currentUser={model.userStore.loggedUser}
          />
        )}
      </Modal>
    );
  }
);
