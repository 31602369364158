import { observable } from "mobx";
import { bindableForm, Command, command, RequiredFieldValidator } from "react-mvvm";
import { DialogSupport } from "../../../shared/dialogs/Dialogs";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { MAX_USER_NAME_LENGTH, MAX_USER_JOB_TITLE_LENGTH } from "../../../shared/validators/LengthValidatorsConst";
import { MaxLengthValidator } from "../../../shared/validators/MaxLengthValidator";
import { combineValidators } from "../../../shared/validators/combineValidators";

interface EditUserDataModel {
  name: string;
  jobTitle: string;
  email: string;
}

export class EditUserDataViewModel {
  @observable editUserDataModel: EditUserDataModel;

  form = bindableForm<EditUserDataModel>()
    .addField("name", {
      validator: v => combineValidators([RequiredFieldValidator(v), MaxLengthValidator(v, MAX_USER_NAME_LENGTH)]),
    })
    .addField("jobTitle", {
      validator: v => combineValidators([MaxLengthValidator(v, MAX_USER_JOB_TITLE_LENGTH)]),
    })
    .addField("email")
    .bindTo(() => this.editUserDataModel);

  submit: Command<void, Promise<void>>;

  constructor(private readonly userStore: UserStore) {
    this.editUserDataModel = {
      name: userStore.loggedUser.name,
      jobTitle: userStore.loggedUser.jobTitle ?? "",
      email: userStore.loggedUser.email,
    };

    this.submit = command(
      async () => {
        if (!(await this.form.validateAndCommit())) {
          return;
        }

        await this.userStore.updateCurrentUser({
          name: this.editUserDataModel.name,
          jobTitle: this.editUserDataModel.jobTitle,
        });
        await this.userStore.updateUser(true);
        DialogSupport.showSuccessDialog({
          title: "User general information successfully updated",
          okText: "Go to profile",
        });
        this.form.reset();
      },
      () => this.form.isDirty
    );
  }
}
