import { IErrorService } from "../../shared/services/ErrorService";
import { UserStore } from "../../shared/stories/user/UserStore";
import { NotificationStore } from "../../shared/stories/notification/NotificationStore";
import { BasePage } from "../BasePage";
import { Command } from "react-mvvm/dist";
import { AppNotification } from "../../shared/stories/notification/AppNotification";
import { navigateTo } from "../../useRouter";
import { INavigationService } from "../../shared/services/NavigationService";

export class NotificationCenterViewModel extends BasePage {
  readNotification: Command<AppNotification, void>;

  readAll: Command;

  constructor(
    public notificationStore: NotificationStore,
    public userStore: UserStore,
    public errorService: IErrorService,
    private readonly navigationService: INavigationService
  ) {
    super(errorService, true, userStore);

    this.readNotification = new Command(async notification => {
      await notification.read();
      if (notification.targetPath) this.navigationService.navigateTo(notification.targetPath);
    });

    this.readAll = new Command(async () => {
      await this.notificationStore.readAll();
    });
  }

  async onActivated() {
    super.onActivated();
    await this.loadData();
  }

  protected async loadData() {
    await this.notificationStore.initLoadAll();
    super.loadData();
  }
}
