import { Breadcrumb, Tooltip } from "antd";
import React from "react";
import { Link } from "../link/Link";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { Url as url } from "../Url";
import { BreadcrumbViewProps } from "./utils";

const MAX_ITEM_TITLE_LENGTH = 20;

const BreadcrumbItemTitle = ({ title, url }: { title: string, url: string | undefined }) => {
  let itemTitle = (<span>{title}</span>);
  if (title?.length > MAX_ITEM_TITLE_LENGTH + 3) {
    itemTitle =
      (
        <Tooltip placement="bottom" title={title} overlayClassName="BreadcrumbItemTitle__tooltip">
          <span>{title.substring(0, MAX_ITEM_TITLE_LENGTH)}...</span>
        </Tooltip>
      )
  }

  if (url) {
    itemTitle = (
      <Link to={url}>
        {itemTitle}
      </Link>)
  }

  return itemTitle;
};

export const BreadcrumbView: React.FC<BreadcrumbViewProps> = ({ items, appendHomeItem, appendWorkspacesItem }) => {
  return (
    <Breadcrumb separator={<SvgIcon iconName="chevronRight" />} className="BreadcrumbView">
      {(appendHomeItem ?? true) && (
        <Breadcrumb.Item>
          <Link to={url.toWorkspaces()}>
            <SvgIcon iconName="home" />
          </Link>
        </Breadcrumb.Item>
      )}
      {appendWorkspacesItem && (
        <Breadcrumb.Item>
          <BreadcrumbItemTitle title="Workspaces" url={url.toWorkspaces()} />
        </Breadcrumb.Item>
      )}
      {items.map((item, index) => (
        <Breadcrumb.Item key={item.path}>
          <BreadcrumbItemTitle title={item.title} url={index < items.length - 1 ? item.path : undefined} />
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
