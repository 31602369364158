import { Comment as CommentAntd, Button } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { DateFormatter } from "../../../../shared/DateFormatter";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { ThumbnailSizeEnum } from "../../../../shared/attachment/Attachment";
import { AttachmentList } from "../../../../shared/attachment/AttachmentList";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { RichTextViewer } from "../../../../shared/richText/RichTextViewer";
import { Comment } from "../../../../shared/stories/comment/Comment";
import { IdeaDetail } from "../../../../shared/stories/idea/Idea";
import { UserAvatar } from "../../../../shared/userAvatar/UserAvatar";

interface ICommentViewProps {
  comment: Comment;
  className?: string;
  idea: IdeaDetail;
}

export const CommentView: React.FunctionComponent<ICommentViewProps> = observer(props => {
  const {
    comment: {
      text,
      attachments,
      owner,
      createdAt,
      isDeleted,
      removeComment,
      canUserRemove,
      isLiked,
      likesCount,
      likeComment,
    },
    className,
    idea,
  } = props;

  return (
    <CommentAntd
      className={classNames(className, isDeleted && "DeletedComment")}
      datetime={DateFormatter.getDifferenceFromNow(createdAt)}
      author={owner.name ?? owner.email}
      actions={[
        <Button
          type="link"
          size="small"
          className={classNames("commentDeleteBtn", !canUserRemove && "commentDeleteBtn--Disabled")}
          onClick={() => {
            DialogSupport.showConfirmDialog({
              title: "Do you want delete this comment?",
              content: "",
              onOk: async () => {
                await removeComment.execute();
              },
              onCancel: () => {},
            });
          }}
        >
          <SvgIcon iconName="delete" /> Delete
        </Button>,
      ]}
      avatar={<UserAvatar userName={owner.name ?? owner.email} size="default" avatarId={owner.avatarId} />}
      content={
        <>
          {text && <RichTextViewer content={text} />}
          <div className="CommentView__likes-section">
            <div className="CommentView__likes-count">{likesCount}</div>
            <Button
              className="CommentView__like-btn"
              type="link"
              onClick={() => likeComment.execute()}
              disabled={idea.isArchived}
            >
              {isLiked ? <SvgIcon iconName="thumbUpClick" /> : <SvgIcon iconName="thumbUp" />}
            </Button>
          </div>
          {attachments.length !== 0 && (
            <AttachmentList attachments={attachments} thumbnailSize={ThumbnailSizeEnum.Small} />
          )}
        </>
      }
    />
  );
});
