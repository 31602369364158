import { computed, observable } from "mobx";
import { isLoaded } from "react-mvvm/dist";
import { NotificationAreaDto } from "src/types/notifications/dto/NotificationAreaDto";
import { AppNotificationDto } from "../../../types/notifications/dto/AppNotificationDto";
import { NotificationStore } from "./NotificationStore";

export class AppNotification {
  @observable public title = "";

  @observable public targetTitle = "";

  @observable public targetPath = "";

  @observable public triggeredById = "";

  @observable public triggeredByDefaultName = "";

  @observable public triggeredByAvatarId: string | undefined;

  @observable public content = "";

  @observable public notificationCategory = "";

  @observable public isRead = false;

  @observable public createdAt: string = new Date().toISOString();

  @observable public notificationArea?: NotificationAreaDto;

  constructor(private readonly notificationStore: NotificationStore, public id: string) { }

  static createFromDto(dto: AppNotificationDto, store: NotificationStore): AppNotification {
    const appNotification = new AppNotification(store, dto.id);
    appNotification.updateFromDto(dto);
    if (dto.triggeredBy && dto.notificationArea === NotificationAreaDto.DirectMention)
      appNotification.loadTriggeredBy();
    return appNotification;
  }

  @computed get triggeredBy() {
    const user = this.notificationStore.getUser(this.triggeredById);
    return isLoaded(user) ? user : null;
  }

  @computed get triggeredByName() {
    return this.triggeredBy?.name ?? this.triggeredByDefaultName;
  }

  async loadTriggeredBy() {
    this.notificationStore.loadUser(this.triggeredById);
  }

  async read() {
    await this.notificationStore.readNotification(this.id);
    this.markAsRead();
  }

  markAsRead() {
    this.isRead = true;
  }

  updateFromDto(dto: AppNotificationDto) {
    this.title = dto.title;
    this.targetTitle = dto.targetTitle;
    this.targetPath = dto.targetPath;
    this.triggeredById = dto.triggeredBy;
    this.triggeredByDefaultName = dto.triggeredByName;
    this.content = dto.content;
    this.notificationCategory = dto.notificationCategory;
    this.isRead = dto.read;
    this.createdAt = dto.createdAt;
    this.notificationArea = dto.notificationArea;
  }
}
