import { Layout } from "antd";
import classNames from "classnames";
import * as React from "react";

const { Content } = Layout;

interface AppContentProps {
  children: React.ReactNode;
  isDefaultUser: boolean;
  className?: string;
  forbidden?: boolean;
}

export const AppContent: React.FC<AppContentProps> = ({ className, children, isDefaultUser, forbidden }) => {
  return (
    <Content className={classNames("AppContent", className, (isDefaultUser || forbidden) && "nonAuthenticated")}>{children}</Content>
  );
};
