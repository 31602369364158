import { action, observable } from "mobx";
import { NIL } from "uuid";
import { INotificationService } from "./NotificationService";

export type StatusErrorCode = number | "NetworkError";

export interface IErrorService {
  errorCode?: StatusErrorCode;
  requestAccess?: boolean;
  accessResourceId: string;

  showErrorSplash(statusCode: StatusErrorCode): void;
  resetErrorSplash(): void;
  showErrorToast(statusCode: StatusErrorCode, code?: string, message?: string): void;
  showRequestAccess(accessResourceId: string): void;
}

export class ErrorService implements IErrorService {
  @observable errorCode?: StatusErrorCode;

  @observable requestAccess?: boolean;

  @observable accessResourceId = NIL;

  constructor(private readonly notificationService: INotificationService) {}

  @action
  showError(statusCode: StatusErrorCode) {
    this.errorCode = statusCode;
  }

  @action
  resetErrorSplash() {
    this.errorCode = undefined;
    this.requestAccess = false;
  }

  @action
  showErrorSplash(statusCode: StatusErrorCode) {
    this.errorCode = statusCode;
  }

  @action
  showErrorToast(statusCode: StatusErrorCode, code?: string, message?: string) {
    if (statusCode === "NetworkError") {
      this.showErrorSplash(statusCode);
    }
    this.notificationService.showNotification("error", this.mapExceptionResponseCode(code), message);
  }

  @action
  showRequestAccess(accessResourceId: string) {
    if (location.pathname !== "/signin") {
      this.errorCode = undefined;
      this.requestAccess = true;
      this.accessResourceId = accessResourceId;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private mapExceptionResponseCode(code?: string) {
    // TODO CreateExceptionResponseCodeMapper
    switch (code) {
      case "validation_failed":
        return "Validation failed";
      case "resource_not_found":
        return "Resource not found";
      default:
        return "Something went wrong";
    }
  }
}
