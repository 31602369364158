import { createBrowserHistory, History, LocationState } from "history";
import * as React from "react";
import { IHomeRoute } from "react-mvvm";
import { App } from "./pages/App";
import { DefaultUrl, IUrl, Url as url } from "./shared/Url";

export interface RouterContext {
  history?: History<LocationState>;
  url: IUrl;
}

export const history = createBrowserHistory();

export const routerContext = React.createContext<RouterContext>({
  history: undefined,
  url: DefaultUrl(),
});

export const useHistory = () => {
  const routerCtx = React.useContext(routerContext);
  return routerCtx.history;
};

export const useUrl = () => {
  const routerCtx = React.useContext(routerContext);
  return routerCtx.url;
};

export const navigateTo = (pathname: string, replace: boolean = false, state?: object) => {
  if (replace) {
    history.replace(pathname, state);
  }
  else {
    history.push(pathname, state);
  }
};

export const clearCurrentState = (pathname: string) => {
  history.replace(pathname, null);
}

export const goBack = () => {
  history.goBack();
};

export const goToSignIn = () => {
  navigateTo('/login', true);
};

export const goToSignOut = () => {
  navigateTo('/logout');
};



export const useRouter = async (homeRoute: IHomeRoute<App>) => {
  const { page } = await homeRoute.bind(history);
  const RouterProvider = ({ children }: { children: React.ReactNode }) => {
    const { Provider } = routerContext;
    return <Provider value={{ history, url }}>{children}</Provider>;
  };

  

  return { page, RouterProvider};
};
