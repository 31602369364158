import { Button, List, Typography } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { Command, isLoaded } from "react-mvvm";
import { DateFormatter } from "../../../../shared/DateFormatter";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { ThumbnailSizeEnum } from "../../../../shared/attachment/Attachment";
import { AttachmentList } from "../../../../shared/attachment/AttachmentList";
import { RichTextViewer } from "../../../../shared/richText/RichTextViewer";
import { IdeaListItem as IdeaListItemModel } from "../../../../shared/stories/idea/Idea";
import { UserAvatar } from "../../../../shared/userAvatar/UserAvatar";
import { InitiativeWalkTourSelectors } from "../../../../shared/walkTour/InitiativeWalkTour";
import { IdeaStateNameDto } from "../../../../types/initiatives/dto/IdeaStateNameDto";
import { VotingMethodDto } from "../../../../types/shared/dto/VotingMethodDto";
import { IdeaListItemViewModel } from "../../shared/IdeaListItemViewModel";
import { IdeaExtraActions } from "../../shared/ideaExtraActions/IdeaExtraActions";
import { SubscribeIconButton } from "../../shared/subscription/subscribeIconButton/SubscribeIconButton";
import { Vote } from "../../shared/vote/Vote";
import { Link } from "../../../../shared/link/Link";
import { Url } from "../../../../shared/Url";

const { Title, Paragraph } = Typography;

export const IdeaListItem = observer(
  ({
    item,
    showIdea,
    showEditIdeaDialog,
    votingMethod,
    showMoveIdeaDialog,
  }: {
    item: IdeaListItemViewModel;
    showIdea: Command<string>;
    showEditIdeaDialog: Command<IdeaListItemModel, Promise<void>>;
    showMoveIdeaDialog: Command<IdeaListItemModel, Promise<void>>;
    votingMethod?: VotingMethodDto;
  }) => {
    return (
      <List.Item className="IdeaList__ideasListItem">
        <div className="RichIdeaCard">
          <div className="RichIdeaCard__voteInput" data-tour={InitiativeWalkTourSelectors.Vote}>
            <Vote idea={item} votingMethod={votingMethod} />
          </div>
          <div className="RichIdeaCard__body">
            <List.Item.Meta
              description={
                <div className="RichIdeaCard__body__description">
                  <div className="RichIdeaCard__body__Metadata">
                    {DateFormatter.format(item.createdAt, "MMMM DD, YYYY h:mm A")}
                    {item.hasAnotherEditor && !item.isAnonymous && (
                      <>
                        <div className="RichIdeaCard__body__Metadata__Editor">Edited by {item.lastEditedByName}</div>
                        <div className="RichIdeaCard__body__Metadata__Editor--small">
                          Edited by <UserAvatar userName={item.lastEditedByName} size={16} />
                        </div>
                      </>
                    )}
                  </div>
                  <Title level={3}>
                    {item.title} {item.idea.state.name === IdeaStateNameDto.Deleted && "(Removed)"}
                  </Title>
                  <Link to={Url.toIdeaPage({ initiativeId: item.initiative.id, ideaId: item.id })}>
                    <Button type="link" className="RichIdeaCard__buttonOverlay">
                      {" "}
                    </Button>
                  </Link>

                </div>
              }
            />
            {isLoaded(item.submittedBy) && !item.isAnonymous && (
              <>
                <div className="RichIdeaCard__author">
                  <UserAvatar userName={item.submittedBy} size={32} avatarId={item.submittedByAvatarId} />
                  <div className="RichIdeaCard__authorName">
                    <span>{item.submittedBy}</span>
                  </div>
                </div>
              </>
            )}
            <div className="RichIdeaCard__description">
              <RichTextViewer content={item.description} maxLines={5} maxLength={450} />
            </div>
            {item.hasAttachments && (
              <AttachmentList
                attachments={item.attachments}
                thumbnailSize={ThumbnailSizeEnum.Small}
                numberOfColumns={2}
              />
            )}
            <div className="RichIdeaCard__footer">
              <span className="RichIdeaCard__comments">
                {!item.isArchived && (
                  <SubscribeIconButton
                    command={item.idea.editSubscription}
                    isSubscribed={item.isCurrentUserSubscribed}
                    tooltipPlacement="topRight"
                  />
                )}
              </span>
              <span className="RichIdeaCard__comments">
                <SvgIcon iconName="speechBubble" onClick={() => showIdea.execute(item.id)} /> {item.amountOfComment}
              </span>
            </div>
          </div>
          {item.hasExtraActions && (
            <IdeaExtraActions
              idea={item}
              showEditIdeaDialog={showEditIdeaDialog}
              showMoveIdeaDialog={showMoveIdeaDialog}
            />
          )}
        </div>
      </List.Item>
    );
  }
);
