import { Layout } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { App } from "../../pages/App";
import { InitialWalkTourSelectors } from "../../shared/walkTour/InitialWalkTour";
import { NavigationMenu } from "../Menu/NavigationMenu";

const { Sider } = Layout;

interface SidePanelProps {
  model: App;
  className?: string;
}

export const SidePanel: React.FC<SidePanelProps> = observer(({ model, className }) => {
  const [isCollapsed, setCollapsed] = React.useState<boolean>(true);
  return (
    <Sider
      collapsible
      collapsed={isCollapsed}
      onCollapse={setCollapsed}
      collapsedWidth="64px"
      className={classNames("SidePanel", className, model.rootStore.userStore.isDefaultUser && "hidden")}
      data-tour={InitialWalkTourSelectors.Navigation}
    >
      <NavigationMenu model={model} />
    </Sider>
  );
});
