import * as React from "react";
import * as ReactDOM from "react-dom";
import { ContentView, registerContentView } from "react-mvvm";
import { AccessRequestReviewPage } from "./pages/accessRequest/AccessRequestReviewPage";
import { AccessRequestReviewPageViewModel } from "./pages/accessRequest/AccessRequestReviewPageViewModel";
import { App } from "./pages/App";
import { AppPage } from "./pages/AppPage";
import { BoardView } from "./pages/Initiatives/board/BoardView";
import { BoardViewModel } from "./pages/Initiatives/board/BoardViewModel";
import { IdeaView } from "./pages/Initiatives/idea/IdeaView";
import { IdeaViewModel } from "./pages/Initiatives/idea/IdeaViewModel";
import { InitiativeView } from "./pages/Initiatives/initiative/InitiativeView";
import { InitiativeViewModel } from "./pages/Initiatives/initiative/InitiativeViewModel";
import { InitiativeListView } from "./pages/Initiatives/initiativeList/InitiativeListView";
import { InitiativeListViewModel } from "./pages/Initiatives/initiativeList/InitiativeListViewModel";
import { LoginView } from "./pages/login/LoginView";
import { LoginViewModel } from "./pages/login/LoginViewModel";
import { SignInViewModel } from "./pages/login/signIn/SigInViewModel";
import { SignIn } from "./pages/login/signIn/SignIn";
import { NotificationCenterView } from "./pages/notifications/NotificationCenterView";
import { NotificationCenterViewModel } from "./pages/notifications/NotificationCenterViewModel";
import { UserProfileView } from "./pages/userProfile/UserProfileView";
import { UserProfileViewModel } from "./pages/userProfile/UserProfileViewModel";
import { WorkspaceView } from "./pages/workspaces/workspace/WorkspaceView";
import { WorkspaceViewModel } from "./pages/workspaces/workspace/WorkspaceViewModel";
import { WorkspaceListView } from "./pages/workspaces/workspaceList/WorkspaceListView";
import { WorkspaceListViewModel } from "./pages/workspaces/workspaceList/WorkspaceListViewModel";
import { appRoute } from "./routes";
import { RootStoreContext } from "./shared/stories/RootStore";
import { useRouter } from "./useRouter";

registerContentView(App, p => <AppPage model={p} />);
// registerContentView(HomeViewModel, p => <HomeView model={p} />);
registerContentView(InitiativeListViewModel, p => <InitiativeListView model={p} />);
registerContentView(InitiativeViewModel, p => <InitiativeView model={p} />);
registerContentView(IdeaViewModel, p => <IdeaView model={p} />);
registerContentView(LoginViewModel, p => <LoginView model={p} />);
registerContentView(SignInViewModel, p => <SignIn model={p} />);
registerContentView(BoardViewModel, p => <BoardView model={p} />);
registerContentView(NotificationCenterViewModel, p => <NotificationCenterView model={p} />);
registerContentView(UserProfileViewModel, p => <UserProfileView model={p} />);
registerContentView(WorkspaceListViewModel, p => <WorkspaceListView model={p} />);
registerContentView(WorkspaceViewModel, p => <WorkspaceView model={p} />);
registerContentView(AccessRequestReviewPageViewModel, p => <AccessRequestReviewPage model={p} />);

(async () => {
  const { page, RouterProvider } = await useRouter(appRoute);

  ReactDOM.render(
    <RouterProvider>
      <RootStoreContext.Provider value={page.rootStore}>
        <ContentView content={page} />
      </RootStoreContext.Provider>
    </RouterProvider>,
    document.getElementById("app")
  );
})();
