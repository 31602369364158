import { Typography } from "antd";
import { LinkProps as AntdLinkProps } from "antd/lib/typography/Link";
import classNames from "classnames";
import { createLocation } from "history";
import * as React from "react";
import { useHistory } from "../../useRouter";

export enum HrefType {
  Autodetect,
  Internal,
  External,
  Anchor,
}

interface LinkProps extends AntdLinkProps {
  to: string;
  replace?: boolean;
  target?: "_blank" | "_self";
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  hrefType?: HrefType;
  tabIndex?: number;
}

const getHostname = (url: string) => {
  return new URL(url).hostname;
};

// https://stackoverflow.com/a/28054735
const isExternal = (url: string) => {
  return (url.indexOf(":") > -1 || url.indexOf("//") > -1) && getHostname(location.href) !== getHostname(url);
};

const isModifiedEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
};

export const Link: React.FC<LinkProps> = ({
  to,
  replace = false,
  children,
  target,
  type = "default",
  className,
  onClick,
  hrefType = HrefType.Autodetect,
  tabIndex,
}) => {
  const isExternalHref = hrefType === HrefType.External || (hrefType === HrefType.Autodetect && isExternal(to));
  if (isExternalHref || hrefType === HrefType.Anchor) {
    return (
      <Typography.Link
        href={to}
        target={target}
        tabIndex={tabIndex}
        className={classNames("Link", `Link--${type}`, className)}
        onClick={onClick}
      >
        {children}
      </Typography.Link>
    );
  }
  const history = useHistory();

  if (!history) {
    throw new Error("History is undefined");
  }
  const navigate = replace ? history.replace : history.push;

  const location = createLocation(to, null, undefined, history.location);
  const href = location ? history.createHref(location) : "";

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    try {
      if (onClick) onClick(event);
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate(location);
    }
  };

  return (
    <Typography.Link
      href={href}
      tabIndex={tabIndex}
      className={classNames("Link", `Link--${type}`, className)}
      onClick={handleOnClick}
    >
      {children}
    </Typography.Link>
  );
};
