import { Col, Modal, Row, Steps } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { AbstractFormStep } from "../../../../shared/stepWizard/Step";
import { CreateInitiativeBasicStep } from "../createInitiativeDialog/createInitiativeWizard/createInitiativeBasicStep/CreateInitiativeBasicStep";
import { CreateInitiativeDateStep } from "../createInitiativeDialog/createInitiativeWizard/createInitiativeDateStep/CreateInitiativeDateStep";
import { CreateInitiativeSummaryStep } from "../createInitiativeDialog/createInitiativeWizard/createInitiativeSummaryStep/CreateInitiativeSummaryStep";
import { CreateInitiativeVotingSystem } from "../createInitiativeDialog/createInitiativeWizard/createInitiativeVotingSystem/CreateInitiativeVotingSystem";
import { EditInitiativeDialogViewModel } from "./EditInitiativeDialogViewModel";
import { isEditInitiativeBasicStep } from "./editInitiativeWIzard/EditInitiativeBasicStepViewModel";
import { isEditInitiativeDateStep } from "./editInitiativeWIzard/EditInitiativeDateStepViewModel";
import { isEditInitiativeSummaryStep } from "./editInitiativeWIzard/EditInitiativeSummaryStep";
import { isEditInitiativeVotingStep } from "./editInitiativeWIzard/EditInitiativeVotingStepViewModel";

interface IEditIdeaDialogViewProps {
  model: EditInitiativeDialogViewModel;
}

const EditInitiativeSteps = ({ currentStep, stepCount }: { currentStep: number; stepCount: number }) => (
  <Steps progressDot current={currentStep}>
    {Array.from({ length: stepCount }, (_, index) => (
      <Steps.Step key={index} />
    ))}
  </Steps>
);

export const EditInitiativeDialog: React.FunctionComponent<IEditIdeaDialogViewProps> = observer(
  ({ model }: IEditIdeaDialogViewProps) => {
    const { isVisible, cancel, wizard, fileStore, initiative } = model;

    if (!wizard) {
      return null;
    }

    const {
      currentStep,
      currentStepOrder,
      stepCount,
      isGoToNextStepVisible,
      goToNextStep,
      isGoToPreviousStepVisible,
      goToPreviousStep,
      isSubmitVisible,
      submit,
    } = wizard;

    return (
      <Modal
        visible={isVisible}
        title={
          <Row justify="space-between">
            <Col>
              <Title level={2} className="Title--withIcon">
                <SvgIcon iconName="edit" /> Edit Initiative
              </Title>
              <Title level={3} className="Title--withAccent">
                {initiative && initiative.title}
              </Title>
            </Col>
            {stepCount > 1 && (
              <Col className="EditInitiativeDialog__steps">
                <EditInitiativeSteps currentStep={currentStepOrder} stepCount={stepCount} />
              </Col>
            )}
          </Row>
        }
        onCancel={() => {
          if (
            (currentStep instanceof AbstractFormStep && currentStep.form.isDirty) ||
            !isEditInitiativeBasicStep(currentStep)
          )
            DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        footer={
          <div className="EditInitiativeDialog__footer">
            {isGoToNextStepVisible && (
              <CommandButton command={goToNextStep} type="primary" size="large">
                Next
              </CommandButton>
            )}
            {isSubmitVisible && (
              <CommandButton
                withLoader={wizard.currentStep.isValid !== undefined ? wizard.currentStep.isValid() : true}
                command={submit}
                type="primary"
                size="large"
              >
                Save Changes
              </CommandButton>
            )}
            {isGoToPreviousStepVisible && (
              <CommandButton command={goToPreviousStep} type="link" icon={<SvgIcon iconName="chevronLeft" />}>
                Back
              </CommandButton>
            )}
          </div>
        }
        className="EditInitiativeDialog"
        width="100%"
        closeIcon={<SvgIcon iconName="close" />}
      >
        {isEditInitiativeBasicStep(currentStep) && (
          <CreateInitiativeBasicStep model={currentStep} fileStore={fileStore} />
        )}
        {isEditInitiativeVotingStep(currentStep) && (
          <CreateInitiativeVotingSystem
            className="EditInitiativeDialog__votingSystem"
            model={currentStep}
            editInitiativeMode
            minVotesPerInitiative={model.initiative.userMaxVotedForPoll}
            minVotesPerIdea={model.initiative.userMaxVotedForPollOption}
          />
        )}
        {isEditInitiativeDateStep(currentStep) && (
          <CreateInitiativeDateStep className="EditInitiativeDialog__dateStep" model={currentStep} />
        )}
        {isEditInitiativeSummaryStep(currentStep) && <CreateInitiativeSummaryStep model={currentStep} />}
      </Modal>
    );
  }
);
