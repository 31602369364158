import { computed, observable } from "mobx";
import { InviteUsersWithMagicLink } from "src/types/users/commands/InviteUsersWithMagicLink";
import { GetUsers } from "src/types/users/queries/GetUsers";
import { Deferred, isLoaded } from "../../../../react-mvvm/dist";
import { CurrentUserDto } from "../../../types/shared/dto/CurrentUserDto";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { IFileService, IUserService } from "../../api/BackendApi";

export class UserStore {
  @observable public loggedUser: CurrentUserDto;

  @observable public users: Map<string, Deferred<UserDto>>;

  @observable public avatars: Map<string, Deferred<string>>;

  defaultUser: CurrentUserDto = {
    id: "",
    name: "",
    email: "",
    hasMagicLinkSession: true,
  };

  private constructor(
    public readonly userService: IUserService,
    public readonly fileService: IFileService,
    loggedUser: CurrentUserDto
  ) {
    this.users = new Map<string, UserDto>();
    this.avatars = new Map<string, string>();
    this.loggedUser = loggedUser;
    this.users.set(this.loggedUser.id, this.loggedUser);
  }

  static async create(userService: IUserService, fileService: IFileService) {
    const defaultUser = {
      id: "",
      externalId: "",
      name: "",
      email: "",
      hasMagicLinkSession: true,
    };
    const store = new UserStore(userService, fileService, defaultUser);
    return store;
  }

  @computed get hasMagicLinkSession() {
    return this.loggedUser.hasMagicLinkSession === true;
  }

  @computed get isDefaultUser() {
    return Object.values(this.loggedUser).some((item: string) => item === "");
  }

  async tryUpdateUser() {
    if (this.isDefaultUser) {
      const currentUser = await this.userService.getCurrentUserRaw({});
      if (currentUser) {
        this.loggedUser = currentUser;
        return true;
      }
      return false;
    }
    return true;
  }

  async getIdentityProvider() {
    const idp = await this.userService.getIdentityProvider();
    return idp;
  }

  async resendInvitation(email: string, returnUrl?: string) {
    await this.userService.resendInvitation({ email, returnUrl });
  }

  async loginWithMagicLink(token: string) {
    await this.userService.loginWithMagicLink(token);
  }

  async setUserName(name: string) {
    await this.updateCurrentUser({ name });
  }

  async getUsers(req: GetUsers) {
    const response = await this.userService.getUsers(req);
    return response;
  }

  async inviteUsers(req: InviteUsersWithMagicLink) {
    const response = await this.userService.inviteUsers(req);
    return response;
  }

  async getCurrentUser() {
    const user = await this.userService.getCurrentUser({});
    return user;
  }

  async updateUser(forceUpdate?: boolean) {
    if (this.isDefaultUser || forceUpdate) {
      const loggedUser = await this.userService.getCurrentUser({});
      this.loggedUser = loggedUser;
    }
    this.loggedUser.name === null && (this.loggedUser.name = this.loggedUser.email);
  }

  async updateCurrentUser(req: { name?: string; jobTitle?: string }) {
    if (this.isDefaultUser) await this.updateUser();
    this.userService.updateCurrentUser({
      name: req.name ?? this.loggedUser.name,
      jobTitle: req.jobTitle ?? this.loggedUser.jobTitle,
    });
  }

  async getUser(id: string): Promise<Deferred<UserDto>> {
    const storedUser = this.users.get(id);

    if (!storedUser) {
      this.users.set(id, "Loading");

      const user = await this.userService.getUser({ id });

      this.users.set(user.id, user);

      return user;
    }

    if (!isLoaded(storedUser)) {
      return storedUser;
    }

    return storedUser;
  }

  async uploadUserPhoto(file: File) {
    await this.userService.uploadUserPhoto(file);
  }

  async removeUserPhoto() {
    await this.userService.removeUserPhoto();
  }

  async downloadUserAvatar(id: string) {
    const storedAvatar = this.avatars.get(id);

    if (!storedAvatar) {
      this.avatars.set(id, "Loading");
      const file = await this.fileService.downloadFile(id);
      const url = URL.createObjectURL(file);
      this.avatars.set(id, url);
      return file;
    }

    return storedAvatar;
  }

  async requestWorkspaceAccess(resourceId: string, email: string) {
    await this.userService.requestAccess({ resourceId, requesterEmail: email });
  }

  async requestWorkspaceAccessForCurrentUser(resourceId: string) {
    await this.userService.requestAccessForCurrentUser({ resourceId });
  }

  async approveAccessRequest(accessRequestId: string) {
    await this.userService.approveAccessRequest({ accessRequestId });
  }

  async denyAccessRequest(accessRequestId: string) {
    await this.userService.denyAccessRequest({ accessRequestId });
  }

  async getAccessRequest(id: string) {
    return await this.userService.getAccessRequest({ id });
  }
}
