import { Card, Col, Row, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Command } from "react-mvvm";
import { KanbanBoardWalkTourSelectors } from "src/shared/walkTour/KanbanBoardWalkTour";
import { DateFormat } from "../../../../shared/DateFormatter";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { VotingMethodIcon } from "../../../../shared/VotingMethodIcon/VotingMethodIcon";
import { InitiativeOverview } from "../../../../shared/stories/initiative/Initiative";
import { InitiativesListWalkTourSelectors } from "../../../../shared/walkTour/InitiativeListWalkTour";
import { SubscribeIconButton } from "../../shared/subscription/subscribeIconButton/SubscribeIconButton";

export interface IInitiativeCardProps {
  showInitiative: Command<string>;
  model: InitiativeOverview;
  dataTour?: KanbanBoardWalkTourSelectors | InitiativesListWalkTourSelectors;
  borderLeftColor?: string;
}

const { Title, Paragraph, Text } = Typography;

function initiativeDateRangeSimplified(start: string, end: string, format: DateFormat) {
  const today = moment();
  const startDate = moment(start);
  const endDate = moment(end);

  if (today.isBefore(startDate))
    return startDate.diff(today, "days") > 9 ? startDate.format(format) : startDate.fromNow();
  if (endDate.isBetween(moment().subtract(9, "day"), moment().add(9, "day"), undefined, "[]")) {
    return endDate.fromNow();
  }
  return endDate.format(format);
}

export const InitiativeCard = observer(({ showInitiative, model, dataTour, borderLeftColor }: IInitiativeCardProps) => {
  return (
    <Card
      hoverable
      className="InitiativeCard HighlightedBorder--primary"
      size="small"
      data-tour={dataTour}
      style={borderLeftColor ? ({ borderLeftColor: borderLeftColor }) : undefined}
    >
      <Row align="middle" justify="space-between" className="InitiativeCard__header">
        <Col>
          <Text className="InitiativeCard__owner">{model.getOwnerName}</Text>
        </Col>
        <Col className="InitiativeCard__header">
          {!model.isArchived && (
            <SubscribeIconButton
              command={model.editSubscription}
              isSubscribed={model.isCurrentUserSubscribed}
              tooltipPlacement="bottomRight"
            />
          )}
        </Col>
      </Row>

      <Title level={3} ellipsis={{ rows: 2 }} className="InitiativeCard__title">
        {model.title}
      </Title>
      <div className="InitiativeCard__body">
        <Paragraph ellipsis={{ rows: 2 }}>{model.descriptionPlainText}</Paragraph>
        <Row align="middle" justify="space-between" className="InitiativeCard__initiativeDates">
          <Col className="InitiativeCard__date">
            <SvgIcon iconName="bulb" />
            <Text className="InitiativeCard__dateTitle">Collecting</Text>
            <Text className="InitiativeCard__dateDates">
              {model.hasSubmissionStarted ? "End " : "Start "}
              {initiativeDateRangeSimplified(model.submissionStartDate, model.submissionEndDate, "DD MMM, YY")}
            </Text>
          </Col>
          <Col className="InitiativeCard__date">
            <VotingMethodIcon isVoted={false} votingMethod={model.votingMethod} />
            <Text className="InitiativeCard__dateTitle">Voting</Text>
            <Text className="InitiativeCard__dateDates">
              {model.hasVotingStarted ? "End " : "Start "}
              {initiativeDateRangeSimplified(model.votingStartDate, model.votingEndDate, "DD MMM, YY")}
            </Text>
          </Col>
        </Row>
      </div>
      <Row className="InitiativeCard__footer" justify="space-between" align="middle" gutter={[0, 8]}>
        <Row align="middle" justify="space-between" className="InitiativeCard__stats">
          <Row align="middle" justify="space-around">
            <SvgIcon iconName="bulb" />
            <Text>{model.ideasCount}</Text>
          </Row>
          <Row align="middle" justify="space-around">
            <SvgIcon iconName="avatar" className="avatar-small" />
            <Text>{model.participantsCount}</Text>
          </Row>
          <Row align="middle" justify="space-around">
            <SvgIcon iconName="chat" />
            <Text>{model.commentsCount}</Text>
          </Row>
        </Row>
        <Row
          align="middle"
          justify="center"
          className={classNames("InitiativeCard__status", `InitiativeCard__status--${model.state.name?.toLowerCase()}`)}
        >
          {model.state.name}
        </Row>
      </Row>
    </Card>
  );
});
