import { observable, onBecomeObserved } from "mobx";
import { Deferred, isLoaded } from "react-mvvm/dist";
import { VotingMethodDto } from "../../../types/shared/dto/VotingMethodDto";
import { PollDto } from "../../../types/votes/dto/PollDto";
import { VoteDto } from "../../../types/votes/dto/VoteDto";
import { VoteMethodConfigDto } from "../../../types/votes/dto/VoteMethodConfigDto";
import { IVoteService } from "../../api/BackendApi";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { Poll } from "./Poll";

export type VoteMethodConfig = { [key in VotingMethodDto]: VoteMethodConfigDto };

export class VoteStore {
  @observable polls: Poll[] = [];

  @observable votingConfig: Deferred<VoteMethodConfig> = "Loading";

  private constructor(private readonly voteService: IVoteService, private readonly userStore: UserStore) {
    onBecomeObserved(this, "votingConfig", async () => await this.getVotingConfig());
  }

  static async create(initiativeService: IVoteService, userStore: UserStore) {
    const store = new VoteStore(initiativeService, userStore);

    return store;
  }

  async getPolls(targetIds: string[]) {
    if (targetIds.length === 0) {
      return [];
    }

    const pollDtos = await this.voteService.getPollsRequest({ targetIds });
    const voteDtos = await this.voteService.getUserVotesRequest({ pollIds: pollDtos.map(p => p.id) });

    pollDtos.forEach(pollDto =>
      this.updatePollFromServer(
        pollDto,
        voteDtos.filter(voteDto => voteDto.pollId === pollDto.id)
      )
    );

    return this.polls.filter(p => targetIds.includes(p.targetId));
  }

  async getPoll(targetId: string) {
    await this.getPolls([targetId]);

    return this.polls.find(p => p.targetId === targetId);
  }

  async updateVote(pollOptionTargetId: string, amount: number) {
    await this.voteService.updateVote({ pollOptionTargetId, amount });
  }

  async getVotingConfig() {
    if (isLoaded(this.votingConfig)) {
      return this.votingConfig;
    }

    const result = await this.voteService.getVoteMethodConfigs();

    const dotsConfiguration = result.find(c => c.votingMethod === VotingMethodDto.Dots);
    const investmentConfiguration = result.find(c => c.votingMethod === VotingMethodDto.Investment);
    const likesConfiguration = result.find(c => c.votingMethod === VotingMethodDto.Likes);
    const kudosLikesConfiguration = result.find(c => c.votingMethod === VotingMethodDto.KudosLikes);

    if (!dotsConfiguration || !investmentConfiguration || !likesConfiguration || !kudosLikesConfiguration) {
      throw new Error("No voting configuration!");
    }

    this.votingConfig = {
      [VotingMethodDto.Dots]: dotsConfiguration,
      [VotingMethodDto.Investment]: investmentConfiguration,
      [VotingMethodDto.Likes]: likesConfiguration,
      [VotingMethodDto.KudosLikes]: kudosLikesConfiguration,
    };

    return this.votingConfig;
  }

  private updatePollFromServer(dto: PollDto, voteDtos: VoteDto[]) {
    let poll = this.polls.find(b => b.id === dto.id);
    if (!poll) {
      poll = new Poll(this, this.userStore, dto, voteDtos);
      this.polls.push(poll);
    }
    poll.updateFromDto(dto, voteDtos);

    return poll;
  }
}
