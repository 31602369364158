import { Step } from "../../../../../../shared/stepWizard/Step";
import { CreateWorkspaceModel } from "../../CreateWorkspaceModel";
import { CreateWorkspaceStepName } from "../CreateWorkspaceStep";

export function isCreateWorkspaceSummaryStep(
  step: Step<CreateWorkspaceStepName>
): step is CreateWorkspaceSummaryStepViewModel {
  return step.name === "Summary";
}

export class CreateWorkspaceSummaryStepViewModel implements Step<CreateWorkspaceStepName> {
  readonly name = "Summary";

  constructor(public readonly createWorkspaceModel: CreateWorkspaceModel) {}

  canGotoNextStep = () => false;

  goToNextStep = () => Promise.resolve(false);
}
