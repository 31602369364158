import { Modal, Slider } from "antd";
import { observer } from "mobx-react";
import React, { FC, useRef } from "react";
import ReactAvatarEditor from "react-avatar-editor";
import { command } from "react-mvvm/dist";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { CommandButton } from "../../../../shared/button/Button";
import { AvatarEditorDialogViewModel } from "./AvatarEditorDialogViewModel";

const IMG_SIZE = 300;

interface AvatarEditorProps {
  model: AvatarEditorDialogViewModel;
}

export const AvatarEditorDialog: FC<AvatarEditorProps> = observer(({ model }) => {
  const editor = useRef<ReactAvatarEditor>(null);

  const handleUpload = async () => {
    if (!editor.current) return;

    const canvas = editor.current.getImage();
    const blob = await new Promise<Blob | null>(resolve => canvas.toBlob(resolve, "image/jpeg"));
    if (blob && model.img) {
      await model.uploadPhoto(new File([blob], model.img.name, { type: "image/jpeg" }));
    }
  };

  return (
    <Modal
      destroyOnClose
      visible={model.isVisible}
      footer={
        <CommandButton type="primary" size="large" command={command(async () => await handleUpload())} withLoader>
          Upload
        </CommandButton>
      }
      onCancel={() => {
        model.hide();
      }}
      closable
      closeIcon={<SvgIcon iconName="close" />}
      className="AddCoOwner"
      width="100%"
    >
      <div className="AvatarEditorDialog__Content">
        <ReactAvatarEditor
          ref={editor}
          scale={model.scale}
          width={IMG_SIZE}
          height={IMG_SIZE}
          position={model.position}
          onPositionChange={pos => model.setPosition(pos)}
          rotate={0}
          borderRadius={IMG_SIZE}
          image={model.img ?? ""}
        />
        <Slider
          style={{ width: IMG_SIZE, margin: " 12px auto" }}
          value={model.scale}
          defaultValue={1}
          max={2}
          min={1}
          step={0.05}
          onChange={(val: number) => model.setScale(val)}
        />
      </div>
    </Modal>
  );
});
