import { Button as AntdButton } from "antd";
import { ButtonType } from "antd/lib/button";
import { ButtonHTMLType } from "antd/lib/button/button";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { MouseEventHandler } from "react";
import { Command } from "../../../react-mvvm/dist";

interface DefaultButtonProps {
  /** Button content */
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType | "outlined";
  icon?: React.ReactNode;
  htmltype?: ButtonHTMLType;
  size?: SizeType;
  className?: string;
  withLoader?: boolean;
  dataTour?: string;
  block?: boolean;
}

type ButtonProps = DefaultButtonProps &
  Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "aria-selected" | "aria-controls" | "aria-expanded" | "role">;

interface CommandButtonProps extends Omit<ButtonProps, "onClick" | "disabled"> {
  command: Command<void, unknown>;
  dataTour?: string;
}

export const Button: React.FC<ButtonProps> = props => {
  const {
    children,
    onClick,
    type = "default",
    htmltype = "button",
    size = "middle",
    className,
    disabled,
    icon,
    withLoader = false,
    dataTour = "",
    block = false,
  } = props;
  return (
    <AntdButton
      type={type === "outlined" ? "default" : type }
      htmlType={htmltype}
      size={size}
      icon={icon}
      onClick={onClick}
      className={classNames("Button", className, type === "outlined" ? "Button__Outlined" : "" )}
      disabled={disabled}
      data-tour={dataTour}
      loading={withLoader && disabled}
      block={block}
    >
      {children}
    </AntdButton>
  );
};

export const CommandButton = observer((props: CommandButtonProps) => {
  const { command } = props;
  return (
    <Button
      {...props}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        return command.execute();
      }}
      disabled={!command.isEnabled}
    />
  );
});
