import { action, observable } from "mobx";
import { Command, command } from "react-mvvm";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";

export class SuccessDialogViewModel extends DialogViewModel<never> {
  @observable workspaceId?: string;

  cancel: Command;

  constructor(public showWorkspace: Command<string, void>) {
    super();

    this.cancel = command(() => {
      this.hide();
    });
  }

  @action
  setWorkspaceId(newWorkspaceId: string) {
    this.workspaceId = newWorkspaceId;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  protected onShow(model?: undefined): void | Promise<void> {}
}
