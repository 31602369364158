import { VoteMethodConfig } from "src/shared/stories/vote/VoteStore";
import { AbstractStepWizard } from "../../../../../shared/stepWizard/AbstractWizard";
import { Step } from "../../../../../shared/stepWizard/Step";
import { FileStore } from "../../../../../shared/stories/file/FileStore";
import { InitiativeStore } from "../../../../../shared/stories/initiative/InitiativeStore";
import { VotingMethodDto } from "../../../../../types/shared/dto/VotingMethodDto";
import { CreateInitiativeModel } from "../CreateInitiativeModel";
import { CreateInitiativeStepName } from "./CreateInitiativeStep";
import { CreateInitiativeBasicStepViewModel } from "./createInitiativeBasicStep/CreateInitiativeBasicStepViewModel";
import { CreateInitiativeDateStepViewModel } from "./createInitiativeDateStep/CreateInitiativeDateStepViewModel";
import { CreateInitiativeSummaryStepViewModel } from "./createInitiativeSummaryStep/CreateInitiativeSummaryStepViewModel";
import { CreateInitiativeVotingSystemModel } from "./createInitiativeVotingSystem/CreateInitiativeVotingSystemModel";

export class CreateInitiativeWizard extends AbstractStepWizard<CreateInitiativeStepName> {
  createInitiativeModel: CreateInitiativeModel = {
    title: "",
    description: "",
    submissionDateRange: { startDate: new Date().toISOString(), endDate: new Date().toISOString() },
    votingDateRange: { startDate: new Date().toISOString(), endDate: new Date().toISOString() },
    votingMethod: VotingMethodDto.Dots,
    votesPerInitiative: this.votesConfig.Dots.votesPerPoll.defaultValue,
    maxVotesPerIdea: this.votesConfig.Dots.votesPerPollOption.defaultValue,
    attachments: [],
    fileStore: this.fileStore,
    hideIdeaSubmitter: false,
    tags: [],
    edit: false,
  };

  protected steps: Step<CreateInitiativeStepName>[] = [
    new CreateInitiativeBasicStepViewModel(this.createInitiativeModel, this.initiativeStore),
    new CreateInitiativeDateStepViewModel(this.createInitiativeModel),
    new CreateInitiativeVotingSystemModel(this.createInitiativeModel, this.votesConfig),
    new CreateInitiativeSummaryStepViewModel(this.createInitiativeModel),
  ];

  constructor(
    onSubmit: (createInitiativeModel: CreateInitiativeModel) => Promise<void>,
    private votesConfig: VoteMethodConfig,
    private fileStore: FileStore,
    private readonly initiativeStore: InitiativeStore
  ) {
    super(() => onSubmit(this.createInitiativeModel));
  }
}
