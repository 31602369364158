import { Button, Popover, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { observer } from "mobx-react";
import React from "react";
import { isLoaded } from "react-mvvm";
import { SvgIcon } from "../../../../../shared/SvgIcon/SvgIcon";
import { WorkspaceCard } from "../../../components/workspaceCard/WorkspaceCard";
import { WorkspaceViewModel } from "../../WorkspaceViewModel";

export interface IWorkspaceCardProps {
  viewModel: WorkspaceViewModel;
  currentUserIsOwner: boolean;
}

const { Title, Text } = Typography;

export const WorkspaceHeaderCard = observer(({ viewModel, currentUserIsOwner }: IWorkspaceCardProps) => {
  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const togglePopover = () => setPopoverVisible(!popoverVisible);

  const { state } = viewModel;
  if (!isLoaded(state)) throw new Error("Cannot handle not loaded view model.");
  const model = state.workspace;

  return (
    <WorkspaceCard themeColor={model.themeColor}>
      <div className="WorkspaceHeaderCard__Content">
        <div className="WorkspaceCard__Name">
          <div className="WorkspaceCard__Header">
            {model.isPrivate && <SvgIcon iconName="lock" />}
            <Text>{currentUserIsOwner ? "You" : model.getOwnerName}</Text>
          </div>
          <div className="WorkspaceCard__Title">
            <Title>{model.name}</Title>
          </div>
        </div>
        <div className="WorkspaceCard__Divider" />
        <div className="WorkspaceCard__Description">
          <Paragraph ellipsis={{ rows: 3 }}>{model.description}</Paragraph>
        </div>
        {state.hasExtraActions && (
          <Popover
            visible={popoverVisible}
            onVisibleChange={togglePopover}
            trigger="click"
            placement="right"
            content={() => (
              <ul className="WorkspaceCardExtraActions">
                {state.canUserEdit && (
                  <li>
                    <Button
                      className="WorkspaceCardExtraActions__ExtraActionsButton"
                      icon={<SvgIcon iconName="edit" />}
                      size="small"
                      type="link"
                      onClick={() => {
                        state.showEditWorkspaceDialog.execute(["Basic", "Members"]);
                        togglePopover();
                      }}
                    >
                      Edit
                    </Button>
                  </li>
                )}
              </ul>
            )}
          >
            <Button
              className="WorkspaceCardExtraActions__Popover"
              icon={<SvgIcon iconName="ellipsisOutlined" />}
              size="small"
              type="link"
              onClick={() => togglePopover}
            />
          </Popover>
        )}
      </div>
    </WorkspaceCard>
  );
});
