import moment from "moment";

export type DateFormat = "DD MMM, YY" | "MMMM DD, YYYY h:mm A" | "MMM DD, YYYY";

export class DateFormatter {
  static format(date: string, format: DateFormat) {
    return moment(date).format(format);
  }

  static formatLocal(date: string, format: DateFormat) {
    return moment.utc(date).local().format(format);
  }

  static getDifferenceFromNow(date: string) {
    return moment(date).fromNow();
  }

  static toEndTimeOfDayUTC(dateString: string) {
    return moment(dateString).endOf("day").toISOString(true);
  }

  static toStartTimeOfDayUTC(dateString: string) {
    return moment(dateString).startOf("day").toISOString(true);
  }
}
