import { action, computed, observable } from "mobx";
import { Command } from "react-mvvm";
import { Workspace } from "../../../../../shared/stories/workspace/Workspace";
import { ManageWorkspaceMembersType } from "../../../workspaceList/manageWorkspaceMembersDialog/ManageWorkspaceMembersDialogViewModel";

export class WorkspaceMembersViewModel {
  @observable workspace: Workspace;

  @computed get title() {
    return `${this.workspace.isPrivate ? "Members" : "Owner and Co-Owners"} (${this.workspace?.numberOfMembers ?? ""})`;
  }

  @observable isLoading = false;

  @computed get canUserEdit() {
    return this.workspace.canUserEdit ?? false;
  }

  @computed get members() {
    return this.workspace?.members.slice(0, this.membersLoadLimit) ?? [];
  }

  private showManageWorkspaceMembersDialog: Command<ManageWorkspaceMembersType>;

  private membersLoadLimit = 5;

  constructor(workspace: Workspace, showManageEditWorkspaceMembersDialog: Command<ManageWorkspaceMembersType>) {
    this.workspace = workspace;
    this.showManageWorkspaceMembersDialog = showManageEditWorkspaceMembersDialog;
  }

  @action
  showLoader() {
    this.isLoading = true;
  }

  @action
  hideLoader() {
    this.isLoading = false;
  }

  async loadData() {
    if (this.workspace.members.length > this.membersLoadLimit) return;

    try {
      this.showLoader();
      await this.workspace.updateMembers(this.membersLoadLimit);
    } finally {
      this.hideLoader();
    }
  }

  showManageWorkspaceMembersDialogClick = () =>
    this.showManageWorkspaceMembersDialog.execute(this.canUserEdit ? "edit" : "read-only");
}
