import { observable } from "mobx";
import { IdeaCommentDto } from "src/types/initiatives/dto/IdeaCommentDto";
import { v4 as uuid } from "uuid";
import { IdeaCommentListDto } from "../../../types/initiatives/dto/IdeaCommentListDto";
import { CommentType } from "../../../types/ly/application/CommentType";
import { ICommentService, IVoteService } from "../../api/BackendApi";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { Comment } from "./Comment";

export class CommentStore {
  @observable comments: Comment[] = [];

  @observable targetIdToAmountOfComments: Map<string, number> = new Map<string, number>();

  private constructor(
    public readonly commentService: ICommentService,
    private readonly userStore: UserStore,
    private readonly voteService: IVoteService
  ) {}

  static async create(commentService: ICommentService, userStore: UserStore, voteService: IVoteService) {
    const store = new CommentStore(commentService, userStore, voteService);

    return store;
  }

  async removeComment(id: string) {
    await this.commentService.removeComment(id);
  }

  async getComments(targetId: string) {
    let commentList: IdeaCommentListDto | null = null;
    const allComments = [];

    do {
      commentList = await this.commentService.getComments({
        ideaId: targetId,
        page: commentList ? commentList.currentPage + 1 : 1,
        results: 100,
      });
      allComments.push(...commentList.items);
    } while (commentList.currentPage < commentList.totalPages);

    allComments.forEach(dto => this.updateCommentFromServer(dto));
    return this.comments;
  }

  async getAmountOfComments(targetId: string) {
    const storedAmountOfComments = this.targetIdToAmountOfComments.get(targetId);

    if (storedAmountOfComments !== undefined) {
      return storedAmountOfComments;
    }

    const amountOfComments = await this.commentService.getAmountOfCommentsRequest({ ideaId: targetId });
    this.targetIdToAmountOfComments.set(targetId, amountOfComments);

    return amountOfComments;
  }

  async createComment(targetId: string, id: string = uuid()) {
    const comment = new Comment(
      this,
      this.userStore,
      id,
      this.userStore.loggedUser.id,
      targetId,
      CommentType.UserComment
    );
    this.comments.push(comment);

    this.targetIdToAmountOfComments.set(targetId, this.comments.filter(c => c.targetId === targetId).length);

    return comment;
  }

  async likeComment(id: string) {
    this.voteService.updateVote({ pollOptionTargetId: id, amount: 1 });
  }

  async unlikeComment(id: string) {
    this.voteService.updateVote({ pollOptionTargetId: id, amount: 0 });
  }

  private updateCommentFromServer(dto: IdeaCommentDto) {
    let comment = this.comments.find(b => b.id === dto.id);
    if (!comment) {
      comment = new Comment(this, this.userStore, dto.id, dto.owner.id, dto.ideaId, dto.commentType);
      this.comments.push(comment);
    }
    comment.updateFromDto(dto);

    return comment;
  }
}
