import axios from "axios";
import { IErrorService } from "../services/ErrorService";
import { INavigationService } from "../services/NavigationService";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNetworkError = (err: any) => {
  return !!err.isAxiosError && !err.response;
};

export const createAxiosInstance = () => {
  const axiosInstance = axios.create();
  return axiosInstance;
};

export const createAxiosInstanceWithRedirect = (navigation: INavigationService, errorService: IErrorService) => {
  const axiosInstance = createAxiosInstance();
  axiosInstance.interceptors.response.use(
    ok => ok,
    err => {
      if (isNetworkError(err)) {
        errorService.showErrorSplash("NetworkError");
      }

      const response = err?.response;
      if (response?.status === 401) {
        navigation.redirectTo("/signin");
      }

      return Promise.reject(err);
    }
  );
  return axiosInstance;
};
