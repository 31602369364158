import { Button, Card, Col, List, Radio, Row, Skeleton, Typography, Divider, Drawer, Checkbox } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { bindTo, isLoaded, property } from "react-mvvm";
import { InitiativeStateNameDto } from "src/types/initiatives/dto/InitiativeStateNameDto";
import { Dropdown } from "../../../shared/Dropdown/Dropdown";
import { LoadMoreListView } from "../../../shared/List/LoadMoreListView";
import { RadioButtonGroup } from "../../../shared/RadioButtonGroup/RadioButtonGroup";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { Url } from "../../../shared/Url";
import { ActivityIndicator } from "../../../shared/activityIndicator/ActivityIndicator";
import { CommandButton } from "../../../shared/button/Button";
import { Link } from "../../../shared/link/Link";
import { InitiativesListWalkTourSelectors } from "../../../shared/walkTour/InitiativeListWalkTour";
import { WalkTour } from "../../../shared/walkTour/WalkTour";
import { PeriodType } from "../../../types/initiatives/dto/PeriodType";
import { TagFilterListView } from "../shared/tags/tagFilterList/TagFilterListView";
import { InitiativeListViewModel, InitiativesOrderBy, isAddNewItem } from "./InitiativeListViewModel";
import { CreateInitiativeDialog } from "./createInitiativeDialog/CreateInitiativeDialog";
import { InitiativeCard } from "./initiativeCard/InitiativeCard";
import { SuccessDialog } from "./successDialog/SuccessDialog";

const { Title, Text } = Typography;

export const InitiativeListView = observer(({ model }: { model: InitiativeListViewModel }) => {
  const {
    showCreateInitiativeDialog,
    createInitiativeDialogViewModel,
    showInitiative,
    successDialogViewModel,
    state,
    isLoading,
    isFilterVisible,
    isDrawerVisible,
    toggleDrawer,
    toggleFilters,
    onTagClick,
  } = model;

  return (
    <div className="InitiativeList">
      <ActivityIndicator isLoading={isLoading}>
        {isLoaded(state) && (
          <>
            {model.isTopActionButtonsVisible && (
              <Row justify="end" className="InitiativeList__header" gutter={[0, 8]}>
                <CommandButton
                  command={showCreateInitiativeDialog}
                  size="large"
                  type="primary"
                  icon={<SvgIcon iconName="plusOutlined" />}
                  dataTour={InitiativesListWalkTourSelectors.CreateInitiative}
                >
                  Add Initiative
                </CommandButton>
              </Row>
            )}
            <Row>
              <Col>
                <Col className="InitiativeList__initiativeList">
                  <Row align="middle" justify="space-between" className="InitiativeList__initiativesHeader">
                    <Title level={2} className="Title--withIcon">
                      <SvgIcon iconName="diamond" /> {state.title}
                    </Title>
                    <Col className="InitiativeList__initiativesHeaderSortAndFilter">
                      <Button type="text" onClick={toggleFilters} data-tour={InitiativesListWalkTourSelectors.Filter}>
                        <SvgIcon iconName="barChart" />
                        Filters (2)
                      </Button>
                      <Button type="text" onClick={() => model.resetFilters.execute()}>
                        <SvgIcon iconName="refresh" className="customicon--textPrimary" />
                        Reset filters and tags
                      </Button>
                      <Dropdown
                        defaultValue={state.initiativeList.orderBy}
                        {...bindTo(property(state.initiativeList, "orderBy"))}
                        options={[
                          { value: InitiativesOrderBy.VotingDeadline, text: "Voting end date" },
                          { value: InitiativesOrderBy.IdeaCollectionDeadline, text: "Idea collection end date" },
                        ]}
                        className="InitiativeList__initiativesHeaderSortAndFilterOrderBy"
                        label={<SvgIcon iconName="vector" />}
                      />
                    </Col>
                    <Col className="InitiativeList__initiativesHeaderSortAndFilterSider">
                      <Button block size="large" onClick={toggleDrawer} className="filter-tour-step">
                        Sort and Filters
                      </Button>
                      <Button
                        block
                        size="large"
                        onClick={() => model.resetFilters.execute()}
                        className="filter-tour-step"
                      >
                        Reset filters and tags
                      </Button>
                    </Col>
                    <Row
                      className={classNames(
                        "InitiativeList__initiativesHeaderFilterContainer",
                        isFilterVisible && "InitiativeList__initiativesHeaderFilterContainer--active"
                      )}
                    >
                      <Col className="InitiativeList__initiativesHeaderFilter" span={4}>
                        <Text>Status:</Text>
                        <Checkbox.Group
                          options={[
                            InitiativeStateNameDto.Planning,
                            InitiativeStateNameDto.Collecting,
                            InitiativeStateNameDto.PreVoting,
                            InitiativeStateNameDto.Voting,
                            InitiativeStateNameDto.Evaluation,
                            InitiativeStateNameDto.Implementation,
                            InitiativeStateNameDto.Closed,
                            InitiativeStateNameDto.Archived,
                          ]}
                          value={state.initiativeList.filter.states}
                          onChange={list => {
                            list.length !== 0 &&
                              (state.initiativeList.filter.states = list as InitiativeStateNameDto[]);
                          }}
                        />
                      </Col>
                      <Col className="InitiativeList__initiativesHeaderFilter" span={4}>
                        <Text>Created by:</Text>
                        <RadioButtonGroup
                          defaultValue={state.initiativeList.filter.createdByCurrentUser}
                          {...bindTo(property(state.initiativeList.filter, "createdByCurrentUser"))}
                        >
                          {[
                            { value: false, text: "anyone" },
                            { value: true, text: "me" },
                          ].map(item => (
                            <Radio
                              value={item.value}
                              key={item.text}
                              className="InitiativeList__initiativesHeaderFilterOption"
                            >
                              {item.text}
                            </Radio>
                          ))}
                        </RadioButtonGroup>
                      </Col>
                      <Col className="InitiativeList__initiativesHeaderFilter" span={4}>
                        <Text>Expired:</Text>
                        <RadioButtonGroup
                          defaultValue={state.initiativeList.filter.expiredPeriodType}
                          {...bindTo(property(state.initiativeList.filter, "expiredPeriodType"))}
                        >
                          {[
                            { value: PeriodType.Day, text: "now" },
                            { value: PeriodType.Week, text: "last week" },
                            { value: PeriodType.Month, text: "last month" },
                            { value: PeriodType.Quarter, text: "last quarter" },
                            { value: PeriodType.Year, text: "last year" },
                            { value: PeriodType.Ever, text: "ever" },
                          ].map(item => (
                            <Radio
                              value={item.value}
                              key={item.value}
                              className="InitiativeList__initiativesHeaderFilterOption"
                            >
                              {item.text}
                            </Radio>
                          ))}
                        </RadioButtonGroup>
                      </Col>
                    </Row>
                  </Row>
                  <Drawer
                    title="Sort and filter"
                    mask={false}
                    onClose={toggleDrawer}
                    visible={isDrawerVisible}
                    placement="left"
                    width={window.innerWidth}
                    footer={
                      <Button block type="primary" size="large" onClick={toggleDrawer}>
                        Apply and Close
                      </Button>
                    }
                  >
                    <Col className="InitiativeList__initiativeListDrawer">
                      <Col>
                        <Title level={5}>Sort by</Title>
                        <RadioButtonGroup
                          defaultValue={state.initiativeList.orderBy}
                          {...bindTo(property(state.initiativeList, "orderBy"))}
                          className="InitiativeList__initiativeListDrawerOptions"
                        >
                          {[
                            { value: InitiativesOrderBy.VotingDeadline, text: "Voting end date" },
                            { value: InitiativesOrderBy.IdeaCollectionDeadline, text: "Idea collection end date" },
                          ].map(item => (
                            <Radio
                              value={item.value}
                              key={item.value}
                              className="InitiativeList__initiativeListDrawerOptionItem"
                            >
                              {item.text}
                            </Radio>
                          ))}
                        </RadioButtonGroup>
                      </Col>
                      <Divider />
                      <Title level={5}>Filters</Title>
                      <Col>
                        <Text className="InitiativeList__initiativeListDrawerOptionTitle">Status:</Text>
                        <Checkbox.Group
                          options={[
                            InitiativeStateNameDto.Planning,
                            InitiativeStateNameDto.Collecting,
                            InitiativeStateNameDto.PreVoting,
                            InitiativeStateNameDto.Voting,
                            InitiativeStateNameDto.Evaluation,
                            InitiativeStateNameDto.Implementation,
                            InitiativeStateNameDto.Closed,
                          ]}
                          value={state.initiativeList.filter.states}
                          onChange={list => {
                            list.length !== 0 &&
                              (state.initiativeList.filter.states = list as InitiativeStateNameDto[]);
                          }}
                        />
                      </Col>
                      <Col>
                        <Text className="InitiativeList__initiativeListDrawerOptionTitle">Created by:</Text>
                        <RadioButtonGroup
                          defaultValue={state.initiativeList.filter.createdByCurrentUser}
                          {...bindTo(property(state.initiativeList.filter, "createdByCurrentUser"))}
                          className="InitiativeList__initiativeListDrawerOptions"
                        >
                          {[
                            { value: false, text: "anyone" },
                            { value: true, text: "me" },
                          ].map(item => (
                            <Radio
                              value={item.value}
                              key={item.text}
                              className="InitiativeList__initiativeListDrawerOptionItem"
                            >
                              {item.text}
                            </Radio>
                          ))}
                        </RadioButtonGroup>
                      </Col>
                      <Col>
                        <Text className="InitiativeList__initiativeListDrawerOptionTitle">Expired:</Text>
                        <RadioButtonGroup
                          defaultValue={state.initiativeList.filter.expiredPeriodType}
                          {...bindTo(property(state.initiativeList.filter, "expiredPeriodType"))}
                          className="InitiativeList__initiativeListDrawerOptions"
                        >
                          {[
                            { value: PeriodType.Day, text: "now" },
                            { value: PeriodType.Week, text: "last week" },
                            { value: PeriodType.Month, text: "last month" },
                            { value: PeriodType.Quarter, text: "last quarter" },
                            { value: PeriodType.Year, text: "last year" },
                            { value: PeriodType.Ever, text: "ever" },
                          ].map(item => (
                            <Radio
                              value={item.value}
                              key={item.value}
                              className="InitiativeList__initiativeListDrawerOptionItem"
                            >
                              {item.text}
                            </Radio>
                          ))}
                        </RadioButtonGroup>
                      </Col>
                    </Col>
                  </Drawer>
                  <TagFilterListView
                    list={state.tagList}
                    selected={state.initiativeList.filter.tags}
                    onClick={onTagClick}
                    onReset={model.resetFilters}
                  />
                  <LoadMoreListView
                    dataTour={InitiativesListWalkTourSelectors.Initiatives}
                    grid={{
                      gutter: 32,
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 3,
                      xxl: 4,
                    }}
                    className="InitiativeList__initiativeList"
                    loadMoreList={state.initiativeList}
                    skeleton={
                      <List.Item>
                        <Card
                          className="InitiativeCard"
                          hoverable
                          size="small"
                          style={{ borderLeftColor: model.themeColor }}
                        >
                          <Skeleton title loading active />
                        </Card>
                      </List.Item>
                    }
                    renderItem={item => {
                      if (isAddNewItem(item)) {
                        return (
                          <List.Item onClick={() => showCreateInitiativeDialog.execute()}>
                            <Card
                              className="AddInitiativeCard HighlightedBorder--primary"
                              hoverable
                              onClick={() => showCreateInitiativeDialog.execute()}
                              style={{ borderLeftColor: model.themeColor }}
                            >
                              <SvgIcon iconName="plusCircle" />
                              <Title level={3}>Add initiative</Title>
                            </Card>
                          </List.Item>
                        );
                      }
                      return (
                        <List.Item>
                          <Link to={Url.toInitiativePage({ initiativeId: item.id })}>
                            <InitiativeCard
                              model={item}
                              showInitiative={showInitiative}
                              borderLeftColor={model.themeColor}
                            />
                          </Link>
                        </List.Item>
                      );
                    }}
                  />
                </Col>
              </Col>
            </Row>
            <CreateInitiativeDialog model={createInitiativeDialogViewModel} />
            <SuccessDialog model={successDialogViewModel} />
          </>
        )}
        <WalkTour
          fixed
          steps={model.steps}
          lastStepNextButton={<Button type="primary">Let s start using IdeaMate</Button>}
        />
      </ActivityIndicator>
    </div>
  );
});
