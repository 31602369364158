import { Step } from "../../../../../../shared/stepWizard/Step";
import { CreateInitiativeModel } from "../../CreateInitiativeModel";
import { CreateInitiativeStepName } from "../CreateInitiativeStep";

export function isCreateInitiativeSummaryStep(
  step: Step<CreateInitiativeStepName>
): step is CreateInitiativeSummaryStepViewModel {
  return step.name === "Summary";
}

export class CreateInitiativeSummaryStepViewModel implements Step<CreateInitiativeStepName> {
  readonly name = "Summary";

  constructor(public createInitiativeModel: CreateInitiativeModel) {}

  canGotoNextStep = () => {
    return false;
  };

  goToNextStep = async () => {
    return await false;
  };
}
