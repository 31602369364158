import { Step } from "../../../../../shared/stepWizard/Step";
import { CreateInitiativeSummaryStepViewModel } from "../../createInitiativeDialog/createInitiativeWizard/createInitiativeSummaryStep/CreateInitiativeSummaryStepViewModel";
import { EditInitiativeSteps } from "../EditInitiativeSteps";

export function isEditInitiativeSummaryStep(
  step: Step<EditInitiativeSteps>
): step is EditInitiativeSummaryStepViewModel {
  return step.name === "Summary";
}

export class EditInitiativeSummaryStepViewModel extends CreateInitiativeSummaryStepViewModel {
  readonly name = "Summary";
}
