import { Step } from "../../../../../shared/stepWizard/Step";
import { CreateInitiativeDateStepViewModel } from "../../createInitiativeDialog/createInitiativeWizard/createInitiativeDateStep/CreateInitiativeDateStepViewModel";
import { EditInitiativeSteps } from "../EditInitiativeSteps";

export function isEditInitiativeDateStep(step: Step<EditInitiativeSteps>): step is EditInitiativeDateStepViewModel {
  return step.name === "Date";
}

export class EditInitiativeDateStepViewModel extends CreateInitiativeDateStepViewModel {
  readonly name = "Date";
}
