import { HubConnection } from "@microsoft/signalr";
import React from "react";
import {
  ICommentService,
  IFileService,
  IInitiativeService,
  INotificationService,
  IUserService,
  IVoteService,
  IWorkspaceService,
} from "../api/BackendApi";
import { INotificationService as IShowNotificationService } from "../services/NotificationService";
import { CommentStore } from "./comment/CommentStore";
import { FileStore } from "./file/FileStore";
import { IdeaStore } from "./idea/IdeaStore";
import { InitiativeStore } from "./initiative/InitiativeStore";
import { NotificationStore } from "./notification/NotificationStore";
import { UserStore } from "./user/UserStore";
import { VoteStore } from "./vote/VoteStore";
import { WorkspaceStore } from "./workspace/WorkspaceStore";

export class RootStore {
  private constructor(
    public readonly userStore: UserStore,
    public readonly voteStore: VoteStore,
    public readonly initiativeStore: InitiativeStore,
    public readonly commentStore: CommentStore,
    public readonly ideaStore: IdeaStore,
    public readonly notificationStore: NotificationStore,
    public readonly fileStore: FileStore,
    public readonly workspaceStore: WorkspaceStore
  ) {}

  static async create(
    backendApi: IUserService &
      IInitiativeService &
      IVoteService &
      ICommentService &
      INotificationService &
      IFileService &
      IWorkspaceService,
    notificationHubConnection: HubConnection,
    showNotificationService: IShowNotificationService
  ) {
    const userStore = await UserStore.create(backendApi, backendApi);
    const voteStore = await VoteStore.create(backendApi, userStore);
    const initiativeStore = await InitiativeStore.create(backendApi, userStore, backendApi);
    const commentStore = await CommentStore.create(backendApi, userStore, backendApi);
    const ideaStore = await IdeaStore.create(backendApi, userStore, backendApi, backendApi);
    const notificationStore = await NotificationStore.create(
      notificationHubConnection,
      backendApi,
      showNotificationService,
      userStore
    );
    const fileStore = await FileStore.create(backendApi);
    const workspaceStore = await WorkspaceStore.create(backendApi, userStore);
    const store = new RootStore(
      userStore,
      voteStore,
      initiativeStore,
      commentStore,
      ideaStore,
      notificationStore,
      fileStore,
      workspaceStore
    );

    return store;
  }
}

export const RootStoreContext = React.createContext<RootStore | null>(null);

export const useStore = () => {
  const store = React.useContext(RootStoreContext);
  if (!store) throw new Error("No store initialized for RootStoreContext");
  return store;
};
