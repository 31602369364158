import { Button, Typography } from "antd";
import React from "react";
import { ReactourStep } from "reactour";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { WalkTourPopover } from "./WalkTour";

const { Text } = Typography;

export enum InitialWalkTourSelectors {
  Initial = "Initial",
  Navigation = "Navigation",
  Help = "Help",
}

export class InitialWalkTour {
  static getSteps() {
    const steps: ReactourStep[] = [
      {
        selector: `[data-tour=${InitialWalkTourSelectors.Initial}]`,
        content: () => (
          <WalkTourPopover icon="bulb" title="IdeaMate">
            <Text>This tutorial discovers with you the basic functions of IdeaMate.</Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitialWalkTourSelectors.Help}]`,
        content: () => (
          <WalkTourPopover icon="question" title="Helpers">
            <Text>
              If you ever get stuck on a page click the
              <Button
                shape="circle"
                size="small"
                className="WalkTourButton Question"
                icon={<SvgIcon iconName="question" />}
              />
              button to get specific guidance.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitialWalkTourSelectors.Navigation}]`,
        content: () => (
          <WalkTourPopover icon="home" title="Navigation - Initiatives List">
            <Text>
              Use navigation to move between different pages. Click on the
              <Button type="text" className="WalkTourButton Navigation" icon={<SvgIcon iconName="home" />} /> button to
              go to the home page.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitialWalkTourSelectors.Navigation}]`,
        content: () => (
          <WalkTourPopover icon="table" title="Navigation - KanbanBoard">
            <Text>
              Click on the
              <Button
                type="text"
                className="Navigation - WalkTourButton Navigation"
                icon={<SvgIcon iconName="table" />}
              />
              button to get to the kanbanboard view.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitialWalkTourSelectors.Navigation}]`,
        content: () => (
          <WalkTourPopover icon="logOut" title="Navigation - Logout">
            <Text>
              Click on the
              <Button type="text" className="WalkTourButton Navigation" icon={<SvgIcon iconName="logOut" />} />
              button to logout and end your session in IdeaMate.
            </Text>
          </WalkTourPopover>
        ),
      },
    ];
    return steps;
  }
}
