import { observable } from "mobx";
import { IViewModel } from "mobx-utils";
import { command, Command } from "react-mvvm";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { UserStore } from "../../../../shared/stories/user/UserStore";
import { Workspace } from "../../../../shared/stories/workspace/Workspace";
import { WorkspaceMember } from "../../../../shared/stories/workspace/WorkspaceMember";
import { WorkspaceStore } from "../../../../shared/stories/workspace/WorkspaceStore";
import { EditWorkspaceModel } from "./EditWorkspaceModel";
import { EditWorkspaceSteps } from "./EditWorkspaceSteps";
import { EditWorkspaceWizard } from "./editWorkspaceWizard/EditWorkspaceWizard";

export class EditWorkspaceDialogViewModel extends DialogViewModel<Workspace> {
  @observable.ref wizard?: EditWorkspaceWizard;

  cancel: Command;

  constructor(
    private onSuccess: (workspace: Workspace & IViewModel<Workspace>) => Promise<void>,
    readonly workspace: Workspace & IViewModel<Workspace>,
    private readonly workspaceStore: WorkspaceStore,
    readonly userStore: UserStore
  ) {
    super();

    this.cancel = command(() => {
      this.hide();
    });
  }

  async show(model: Workspace & IViewModel<Workspace>, editType: EditWorkspaceSteps[] = ["Basic", "Members"]) {
    this.wizard = new EditWorkspaceWizard(
      async editWorkspaceModel => await this.editWorkspace(editWorkspaceModel),
      this.workspaceStore,
      this.userStore,
      model,
      editType
    );

    await super.show();
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}

  private async editWorkspace(editWorkspaceModel: EditWorkspaceModel): Promise<void> {
    try {
      this.workspace.name = editWorkspaceModel.name;
      this.workspace.themeColor = editWorkspaceModel.themeColor;
      this.workspace.description = editWorkspaceModel.description;
      this.workspace.members = editWorkspaceModel.members.map(x => {
        const member = new WorkspaceMember();
        member.id = x.id;
        member.email = x.email;
        member.name = x.name;
        member.jobTitle = x.jobTitle;
        member.photoId = x.photoId;
        member.workspaceRole = x.workspaceRole;
        return member;
      });
      this.workspace.numberOfMembers = this.workspace.members.length;
      await this.onSuccess(this.workspace);
    } catch {
      await this.workspaceStore.getWorkspace(this.workspace.id);
    }
  }
}
