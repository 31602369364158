import { action, computed } from "mobx";
import { bindableForm, RequiredFieldValidator } from "react-mvvm";
import { Step } from "../../../../../../shared/stepWizard/Step";
import { InitiativeStore } from "../../../../../../shared/stories/initiative/InitiativeStore";
import {
  MAX_INITIATIVE_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from "../../../../../../shared/validators/LengthValidatorsConst";
import { MaxLengthValidator } from "../../../../../../shared/validators/MaxLengthValidator";
import { RichTextLengthValidator } from "../../../../../../shared/validators/RichTextLengthValidator";
import { TagsValidator, TAG_MIN_LENGTH } from "../../../../../../shared/validators/TagsValidator";
import { combineValidators } from "../../../../../../shared/validators/combineValidators";
import { AttachmentDto } from "../../../../../../types/initiatives/dto/AttachmentDto";
import { CreateInitiativeModel } from "../../CreateInitiativeModel";
import { CreateInitiativeStep, CreateInitiativeStepName } from "../CreateInitiativeStep";

export function isCreateInitiativeBasicStep(
  step: Step<CreateInitiativeStepName>
): step is CreateInitiativeBasicStepViewModel {
  return step.name === "Basic";
}

export class CreateInitiativeBasicStepViewModel extends CreateInitiativeStep<
  "title" | "description" | "attachments" | "tags"
> {
  readonly name = "Basic";

  form = bindableForm<CreateInitiativeModel>()
    .addField("title", {
      validator: v =>
        combineValidators([RequiredFieldValidator(v), MaxLengthValidator(v, MAX_INITIATIVE_TITLE_LENGTH)]),
    })
    .addField("description", {
      validator: v => combineValidators([RichTextLengthValidator(v, MAX_DESCRIPTION_LENGTH)]),
    })
    .addField("attachments")
    .addField("hideIdeaSubmitter")
    .addField("tags", {
      validator: v => TagsValidator(v),
    })
    .bindTo(() => this.createInitiativeModel);

  constructor(private createInitiativeModel: CreateInitiativeModel, private readonly initiativeStore: InitiativeStore) {
    super();
  }

  @computed get getInitiativeAttachments() {
    return this.createInitiativeModel.attachments;
  }

  @computed get isEditMode() {
    return this.createInitiativeModel.edit;
  }

  @computed get description() {
    return this.createInitiativeModel.description;
  }

  @action addToInitiativeAttachments = (file: AttachmentDto) => {
    this.form.fields.attachments.value = this.form.fields.attachments.value?.concat(file);
  };

  searchTags = async (text: string) => {
    if (text.length < TAG_MIN_LENGTH) return [];

    const { items: tags } = await this.initiativeStore.getTags(0, 10, text);
    return tags;
  };
}
