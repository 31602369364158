import { Button, Typography } from "antd";
import React from "react";
import { ReactourStep } from "reactour";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { InitialWalkTour } from "./InitialWalkTour";
import { WalkTourPopover } from "./WalkTour";

const { Text } = Typography;

export enum InitiativesListWalkTourSelectors {
  None = "None",
  Help = "Help",
  AddUser = "AddUser",
  CreateInitiative = "CreateInitiative",
  Filter = "Filter",
  Initiatives = "Initiatives",
}

export class InitiativeListWalkTour {
  static getSteps() {
    const initialSteps = InitialWalkTour.getSteps();
    const steps: ReactourStep[] = [
      {
        selector: `[data-tour=${InitiativesListWalkTourSelectors.AddUser}]`,
        content: () => (
          <WalkTourPopover icon="addUser" title="Add User">
            <Text>
              Click on the
              <Button className="WalkTourButton InviteUser" icon={<SvgIcon iconName="addUser" />}>
                Invite users
              </Button>
              button to invite your colleagues to IdeaMate by entering their e-mail addresses. They will receive
              instructions and an activation link to your IdeaMate.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativesListWalkTourSelectors.CreateInitiative}]`,
        content: () => (
          <WalkTourPopover icon="plusOutlined" title="Create Initiative">
            <Text>
              Click on the
              <Button type="primary" className="WalkTourButton">
                <SvgIcon iconName="plusOutlined" /> Add Initiative
              </Button>{" "}
              button, to launch a new initiative and start collecting ideas with your colleagues.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativesListWalkTourSelectors.Filter}]`,
        content: () => (
          <WalkTourPopover icon="barChart" title="Filters">
            <Text>
              Each initiative has its own state and author. Use the filter function to focus on the initiatives of your
              interest.
            </Text>
          </WalkTourPopover>
        ),
      },
      {
        selector: `[data-tour=${InitiativesListWalkTourSelectors.Initiatives}]`,
        content: () => (
          <WalkTourPopover icon="diamond" title="Initiatives">
            <Text>Click on the initiative tile to get to its content and ideas.</Text>
          </WalkTourPopover>
        ),
      },
    ];
    return [...initialSteps, ...steps];
  }
}
