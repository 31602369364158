import { computed } from "mobx";
import { useMemo } from "react";
import { isLoaded } from "react-mvvm/dist";
import { useStore } from "../stories/RootStore";
import { UserStore } from "../stories/user/UserStore";

class UserAvatarViewModel {
  displayName: string;

  constructor(private readonly userStore: UserStore, userName: string, private avatarId?: string | null) {
    this.displayName = userName ?? "";
    this.fetchAvatar();
  }

  @computed get avatarUrl() {
    if (!this.avatarId) return null;
    const url = this.userStore.avatars.get(this.avatarId);
    return url && isLoaded(url) ? url : null;
  }

  fetchAvatar = async () => {
    if (!this.avatarId) return;
    await this.userStore.downloadUserAvatar(this.avatarId);
  };
}

export const useViewModel = (userName: string, avatarId?: string | null) => {
  const store = useStore();
  const viewModel = useMemo(() => new UserAvatarViewModel(store.userStore, userName, avatarId), [userName, avatarId]);
  return viewModel;
};
