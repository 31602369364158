import { Col, Radio, Row, Switch, Typography } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { TextArea } from "../../../../../../shared/TextArea/TextArea";
import { Field, Form } from "../../../../../../shared/form/Form";
import { TextInput } from "../../../../../../shared/textInput/TextInput";
import {
  MAX_WORKSPACE_DESCRIPTION_LENGTH,
  MAX_WORKSPACE_TITLE_LENGTH,
} from "../../../../../../shared/validators/LengthValidatorsConst";
import { CreateWorkspaceBasicStepViewModel } from "./CreateWorkspaceBasicStepViewModel";

interface ICreateWorkspaceBasicStepProps {
  model: CreateWorkspaceBasicStepViewModel;
  className?: string;
  editMode?: boolean;
}

const themeColors = ["#E26D6D", "#F9AC64", "#FCCA46", "#A1C181", "#B4E7FC", "#4188BB", "#8B6DBC", "#CD8EE3"];

export const CreateWorkspaceBasicStep: React.FunctionComponent<ICreateWorkspaceBasicStepProps> = observer(
  ({ model, className, editMode = false }) => {
    return (
      <Form className={className} layout="vertical" form={model.form}>
        {fields => (
          <>
            <Field field={fields.name} label="Title">
              {props => (
                <TextInput placeholder="Workspace title" showCount maxLength={MAX_WORKSPACE_TITLE_LENGTH} {...props} />
              )}
            </Field>
            <Field field={fields.description} label="Description">
              {props => (
                <TextArea
                  placeholder="Description"
                  rows={10}
                  showCount
                  maxLength={MAX_WORKSPACE_DESCRIPTION_LENGTH}
                  {...props}
                />
              )}
            </Field>
            <Field field={fields.themeColor} label="Color theme">
              {props => (
                <Radio.Group
                  {...props}
                  onChange={event => props.onChange(event.target.value)}
                  className="CreateWorkspaceDialog__colorSelector"
                >
                  {themeColors.map(color => (
                    <Radio.Button key={color} value={color} style={{ backgroundColor: color }} />
                  ))}
                </Radio.Group>
              )}
            </Field>
            <Field field={fields.isPrivate} className="CreateWorkspaceDialog__makePrivateField">
              {({ onCommit, ...props }) => (
                <>
                  <Row gutter={[12, 0]}>
                    <Col>
                      <Switch size="small" checked={props.value} {...props} disabled={editMode} />
                    </Col>
                    <Col>
                      <Typography.Text strong>Make &quot;Private&quot;</Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Typography.Text>
                      {"When a workspace is set to private, it can only be viewed or joined by invitation. "}
                      <Typography.Text strong>This can&apos;t be undone.</Typography.Text>
                    </Typography.Text>
                  </Row>
                </>
              )}
            </Field>
          </>
        )}
      </Form>
    );
  }
);
