import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, List, Space, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { AttachmentDto } from "../../types/initiatives/dto/AttachmentDto";
import { AttachmentType } from "../../types/ly/application/AttachmentType";
import { FileAttachment, ThumbnailSizeEnum, UrlAttachment } from "./Attachment";

interface IAttachmentListProps {
  attachments: AttachmentDto[];
  thumbnailSize?: ThumbnailSizeEnum;
  attachmentPreviewLimit?: number;
  className?: string;
  numberOfColumns?: number;
}

export const AttachmentList: React.FunctionComponent<IAttachmentListProps> = observer(
  ({
    attachments,
    thumbnailSize = ThumbnailSizeEnum.Normal,
    attachmentPreviewLimit = 2,
    className,
    numberOfColumns,
  }) => {
    if (attachmentPreviewLimit < 0) throw new Error(`${JSON.stringify(attachmentPreviewLimit)} less than 0.`);

    const [isExpanded, setExpanded] = React.useState(false);

    return (
      <Space direction="vertical" className={classNames("AttachmentList", className)}>
        <List
          className={classNames(
            "AttachmentListPreview",
            attachments.length > attachmentPreviewLimit ? "ShowDivider" : ""
          )}
          grid={numberOfColumns ? { column: numberOfColumns, xs: 1, sm: 1, md: 1, lg: 1 } : undefined}
          dataSource={attachments.slice(0, attachmentPreviewLimit)}
          renderItem={item => (
            <List.Item key={item.id}>
              {item.type === AttachmentType.Url ? (
                <UrlAttachment attachment={item} thumbnailSize={thumbnailSize} />
              ) : (
                <FileAttachment attachment={item} thumbnailSize={thumbnailSize} />
              )}
            </List.Item>
          )}
        />
        {attachments.length > attachmentPreviewLimit && (
          <>
            <Collapse ghost onChange={() => setExpanded(!isExpanded)} className="AttachmentListCollapse">
              <Collapse.Panel
                key="attachments"
                header={
                  <>
                    <Typography.Text className="AttachmentListCollapseHeaderText">{`Show ${
                      isExpanded ? "less" : "more"
                    }`}</Typography.Text>
                    {isExpanded ? <UpOutlined /> : <DownOutlined />}
                  </>
                }
                showArrow={false}
              >
                <List
                  grid={numberOfColumns ? { column: numberOfColumns, xs: 1, sm: 1, md: 1, lg: 1 } : undefined}
                  dataSource={attachments.slice(attachmentPreviewLimit)}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      {item.type === AttachmentType.Url ? (
                        <UrlAttachment attachment={item} thumbnailSize={thumbnailSize} />
                      ) : (
                        <FileAttachment attachment={item} thumbnailSize={thumbnailSize} />
                      )}
                    </List.Item>
                  )}
                />
              </Collapse.Panel>
            </Collapse>
          </>
        )}
      </Space>
    );
  }
);
