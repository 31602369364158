import { Row, Typography } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { WorkspaceOverview } from "../../../../shared/stories/workspace/Workspace";
import { WorkspaceCard } from "../../components/workspaceCard/WorkspaceCard";

export interface IWorkspaceCardProps {
  model: WorkspaceOverview;
  currentUserIsOwner: boolean;
}

const { Title, Text } = Typography;

export const WorkspaceListCard = observer(({ model, currentUserIsOwner }: IWorkspaceCardProps) => {
  return (
    <WorkspaceCard themeColor={model.themeColor} hoverable>
      <Row className="WorkspaceCard__Header">
        {model.isPrivate && <SvgIcon iconName="lock" />}
        <Text>{currentUserIsOwner ? "You" : model.getOwnerName}</Text>
      </Row>
      <Row className="WorkspaceCard__Title">
        <Title>{model.name}</Title>
      </Row>
      <Row className="WorkspaceCard__Footer">
        <Text>{model.isPrivate && `${model.numberOfMembers} members`}</Text>
        <Text>{`${model.numberOfInitiatives} initiatives`}</Text>
      </Row>
    </WorkspaceCard>
  );
});
