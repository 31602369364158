import { action, computed, observable } from "mobx";
import { command } from "react-mvvm";
import { Initiative } from "src/shared/stories/initiative/Initiative";
import { IdeaListItem as IdeaListItemModel } from "../../../shared/stories/idea/Idea";
import { IdeaStateNameDto } from "../../../types/initiatives/dto/IdeaStateNameDto";

export class IdeaListItemViewModel {
  @observable investment = this.loggedUserVoteAmount;

  id = this.idea.id;

  submittedById = this.idea.submittedBy.id;

  createdAt = this.idea.createdAt;

  addVotes = command<number, Promise<void>>(
    async value => {
      this.initiative.votesLeftPerCurrentUserCount -= value;
      await this.idea.addVote.execute(value);
    },
    () => this.canAddVote
  );

  removeVotes = command<number, Promise<void>>(
    async value => {
      this.initiative.votesLeftPerCurrentUserCount += value;
      await this.idea.remoteVote.execute(value);
    },
    () => this.canRemoveVote
  );

  removeIdea = command<void, Promise<void>>(
    async () => {
      this.initiative.votesLeftPerCurrentUserCount += this.loggedUserVoteAmount;
      this.initiative.ideasCount -= 1;
      await this.idea.remove.execute();
    },
    () => this.idea.canUserRemove
  );

  realizeIdea = command<void, Promise<void>>(
    async () => {
      await this.idea.release.execute();
      this.initiative.votesLeftPerCurrentUserCount += this.loggedUserVoteAmount;
    },
    () => this.idea.canSetAsReleased
  );

  constructor(public idea: IdeaListItemModel, public initiative: Initiative) {}

  @computed get title() {
    return this.idea.title;
  }

  @computed get description() {
    return this.idea.description;
  }

  @computed get hasAttachments() {
    return this.idea.attachments.length > 0;
  }

  @computed get attachments() {
    return this.idea.attachments;
  }

  @computed get hasAnotherEditor() {
    return this.lastEditedByName !== "" && this.idea.submittedBy.name !== this.lastEditedByName;
  }

  @computed get lastEditedByName() {
    if (!this.idea.lastEditedBy) return "";
    return this.idea.lastEditedBy.submittedBy.name;
  }

  @computed get submittedBy() {
    return this.idea.submittedBy.name ? this.idea.submittedBy.name : this.idea.submittedBy.email;
  }

  @computed get submittedByAvatarId() {
    return this.idea.submittedBy.avatarId;
  }

  @computed get isDeleted() {
    return this.idea.state.name === IdeaStateNameDto.Deleted;
  }

  @computed get canRemoveVote() {
    return (
      this.idea.userVotesCount > 0 &&
      !this.idea.isDeleted &&
      !this.idea.isReleased &&
      this.initiative.isVotingPeriod &&
      !this.idea.isArchived
    );
  }

  @computed get canAddVote() {
    return (
      this.idea.userVotesCount < this.initiative.maxVotesPerPollOption &&
      this.initiative.votesLeftPerCurrentUserCount > 0 &&
      !this.idea.isDeleted &&
      !this.idea.isReleased &&
      this.initiative.isVotingPeriod &&
      !this.idea.isArchived
    );
  }

  @computed get isInvestAvailable() {
    return !this.addVotes.isEnabled && !this.removeVotes.isEnabled;
  }

  @computed get isInvestBtnAvailable() {
    return this.investment === this.loggedUserVoteAmount || (!this.addVotes.isEnabled && !this.removeVotes.isEnabled);
  }

  @computed get votesLeft() {
    return this.initiative.votesLeftPerCurrentUserCount;
  }

  @computed get maxVotesPerIdea() {
    return this.initiative.maxVotesPerPollOption;
  }

  @computed get votingMethod() {
    return this.initiative.votingMethod;
  }

  @computed get canUserEdit() {
    return this.idea.canUserEdit || this.initiative.canUserEdit;
  }

  @computed get canSetAsReleased() {
    return this.idea.canSetAsReleased;
  }

  @computed get totalVoteAmount() {
    return this.idea.votesCount;
  }

  @computed get loggedUserVoteAmount() {
    return this.idea.userVotesCount;
  }

  @computed get isUserAddedAnyVotes() {
    return this.idea.userVotesCount > 0;
  }

  @computed get canUserRemove() {
    return this.idea.canUserRemove;
  }

  @computed get canUserMove() {
    return this.initiative.canUseMoveIdeas;
  }

  @computed get hasExtraActions() {
    return this.idea.canUserEdit || this.idea.canUserRemove || this.idea.canSetAsReleased;
  }

  @computed get amountOfComment() {
    return this.idea.commentsCount;
  }

  @computed get totalVotes() {
    return this.idea.votesCount || 0;
  }

  @computed get getInvestment() {
    return this.investment;
  }

  @computed get isReleased() {
    return this.idea.isReleased;
  }

  @computed get isArchived() {
    return this.idea.isArchived;
  }

  @computed get releasedDate() {
    return this.idea.state.createdAt;
  }

  @computed get isCurrentUserSubscribed() {
    return this.idea.isCurrentUserSubscribed;
  }

  @computed get isAnonymous() {
    return this.idea.isAnonymous;
  }

  @action.bound updateInvestment = (newValue?: number | string | null) => {
    newValue && !isNaN(+newValue) ? this.setInvestment(+newValue) : this.setInvestment(0);
  };

  @action.bound setInvestment = (newValue: number) => {
    this.investment = newValue;
  };

  @action.bound investmentButtonText = (newValue: number) => {
    if (newValue > this.loggedUserVoteAmount) return "+$";
    if (newValue < this.loggedUserVoteAmount) return "-$";
    return " $";
  };
}
