import { Form as AntForm } from "antd";
import { FormProps } from "antd/lib/form";
import { FormItemProps } from "antd/lib/form/FormItem";
import { observer } from "mobx-react";
import * as React from "react";
import { bindTo, FormField, FormFields, FormState, IFormField, property } from "react-mvvm";

type FieldInputProps<T> = { value: T | undefined; onChange: (value: T) => void; onCommit: () => void };

interface AntFormParams<T> {
  form: { fields: FormFields<T> };
  children: (fields: FormFields<T>) => React.ReactNode | React.ReactNode[];
}

function antFormImpl<T>(props: AntFormParams<T> & Omit<FormProps, "form" | "children">) {
  const { form, children } = props;
  return (
    <AntForm {...props} form={undefined}>
      {children(form.fields)}
    </AntForm>
  );
}

export const Form = observer(antFormImpl);

function getValidationStatus(field: IFormField) {
  if (field.state === FormState.Invalid) return "error";
  if (field.isValidating) return "validating";
  if (field.state === FormState.Valid) return "success";
  return "";
}

type ChildrenType<T> = (inputProps: FieldInputProps<T>) => React.ReactElement;

function fieldImpl<T>(props: { field: FormField<T>; children: ChildrenType<T> } & Omit<FormItemProps, "children">) {
  const { field, children } = props;
  const inputProps = { ...bindTo(property(field, "value")), onCommit: () => field.commit() };

  return (
    <AntForm.Item
      {...props}
      validateStatus={getValidationStatus(field)}
      help={<span>{field.errors?.find(x => x !== undefined)}</span>}
      hasFeedback={field.state === FormState.Invalid}
    >
      {children(inputProps)}
    </AntForm.Item>
  );
}

export const Field = observer(fieldImpl);
