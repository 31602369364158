import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator } from "../../../../../../shared/activityIndicator/ActivityIndicator";
import { Field, Form } from "../../../../../../shared/form/Form";
import { IWorkspaceMember } from "../../../../../../shared/stories/workspace/WorkspaceMember";
import { WorkspaceRole } from "../../../../../../types/ly/application/WorkspaceRole";
import { UserDto } from "../../../../../../types/shared/dto/UserDto";
import { WorkspaceMemberList } from "../../../../components/workspaceMemberList/WorkspaceMemberList";
import { EditWorkspaceMembersStepViewModel } from "../../../editWorkspaceDialog/editWorkspaceWizard/EditWorkspaceMembersStepViewModel";
import { CreateWorkspaceMembersStepViewModel } from "./CreateWorkspaceMembersStepViewModel";

interface ICreateWorkspaceMembersStepProps {
  model: CreateWorkspaceMembersStepViewModel;
  className?: string;
  currentUser: UserDto;
  isReadOnly?: boolean;
}

export const CreateWorkspaceMembersStep: React.FunctionComponent<ICreateWorkspaceMembersStepProps> = observer(
  ({ model, className, currentUser, isReadOnly = false }) => {
    const editModel = model as EditWorkspaceMembersStepViewModel;
    const [isLoading, setIsLoading] = React.useState(editModel && editModel.isLoading);
    if (editModel)
      React.useEffect(() => setIsLoading(model instanceof EditWorkspaceMembersStepViewModel && model.isLoading), [
        editModel.isLoading,
      ]);

    const roleChanged = (value: WorkspaceRole, target: IWorkspaceMember) => {
      const m = model.form.fields.members.value?.find(x => x === target);
      if (m) m.workspaceRole = value;
    };

    return (
      <Form className={className} layout="vertical" form={model.form}>
        {fields => (
          <ActivityIndicator isLoading={isLoading}>
            <Field field={fields.members} label={isReadOnly ? "" : "Invite People"}>
              {props => (
                <WorkspaceMemberList
                  findUsers={model.findUsers}
                  isPrivate={model.isPrivate}
                  onChange={props.onChange}
                  roleChanged={roleChanged}
                  workspaceMembers={fields.members.value ?? []}
                  currentUser={currentUser}
                  isReadOnly={isReadOnly}
                />
              )}
            </Field>
          </ActivityIndicator>
        )}
      </Form>
    );
  }
);
