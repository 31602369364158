import { Button, Col, Divider, Result, Row, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { observer } from "mobx-react";
import * as React from "react";
import { SignInPage } from "../../../layout/SignInPage/SignInPage";
import { Field, Form } from "../../../shared/form/Form";
import { AppLogo } from "../../../shared/logo/AppLogo";
import { TextInput } from "../../../shared/textInput/TextInput";
import { SignInViewModel } from "./SigInViewModel";

export const SignIn = observer(({ model }: { model: SignInViewModel }) => {
  const renderInfo = (isLoading: boolean, isSuccess: boolean) => {
    if (isLoading) {
      return (
        <div className="SignInInfo">
          <p>Please wait, we are trying to log you in... </p>
          <div>
            <Spin size="large" />
          </div>
        </div>
      );
    }
    if (isSuccess) {
      return (
        <div className="SignInInfo">
          <Result
            status="success"
            title="Login url has been sent"
            subTitle="A login link has been provided to the email that was sent. Click on it to log in. "
          />
        </div>
      );
    }
    return (
      <>
        <Col>
          <AppLogo size="medium" variant="secondary" includeText className="SignInForm__Header" />
          <Title level={2}>Sign in to Ideamate</Title>
        </Col>
        <Row justify="space-between">
          {model.idp && (
            <Button block onClick={() => model.goToLogin.execute()}>
              Sign in with {model.idp}
            </Button>
          )}
        </Row>
        <Divider plain>or</Divider>
        <Form className="SignInForm__localUser" layout="vertical" form={model.form}>
          {fields => (
            <>
              <Field field={fields.email} label="Company email">
                {props => <TextInput type="email" placeholder="Company email" {...props} />}
              </Field>
            </>
          )}
        </Form>
        <Button size="large" type="primary" htmlType="submit" block onClick={() => model.submit.execute()}>
          Sign in
        </Button>
      </>
    );
  };

  return <SignInPage className="SignInView">{renderInfo(model.isLoading, model.isSuccess)}</SignInPage>;
});
