import { Card } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { DEFAULT_WORKSPACE_COLOR } from "../../../../shared/Constants";
import { WorkspaceShadow } from "./WorkspaceShadow";

export interface IWorkspaceCardProps {
  themeColor: string;
  onClick?: Promise<void>;
  children?: React.ReactNode;
  hoverable?: boolean;
}

export const WorkspaceCard = observer(({ themeColor, onClick, children, hoverable }: IWorkspaceCardProps) => (
  <Card hoverable={hoverable} className="WorkspaceCard" size="small" onClick={() => onClick}>
    <div className="WorkspaceCard__Content">{children}</div>
    <div className="WorkspaceCard__FooterIcon">
      <WorkspaceShadow color={themeColor ?? DEFAULT_WORKSPACE_COLOR} />
    </div>
  </Card>
));
