import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { ContentView } from "react-mvvm/dist";
import { SvgIcon } from "../../shared/SvgIcon/SvgIcon";
import { BreadcrumbView } from "../../shared/breadcrumb/BreadcrumbView";
import { CommandButton } from "../../shared/button/Button";
import { NotificationCenterViewModel } from "./NotificationCenterViewModel";
import { NotificationList } from "./notificationList/NotificationList";

export const NotificationCenterView = observer(({ model }: { model: NotificationCenterViewModel }) => {
  if (model.childPage) {
    return <ContentView content={model.childPage} />;
  }

  const { initiativeNotifications, ideaNotifications, directMentionNotifications } = model.notificationStore;

  return (
    <>
      <div className="NotificationCenter__header">
        <Row justify="space-between">
          <Col>
            <BreadcrumbView items={[{ title: "Notifications" }]} />
          </Col>
          <Col>
            <CommandButton command={model.readAll} size="large" type="ghost" className="NotificationCenter__headerBtn">
              Mark all as read
            </CommandButton>
          </Col>
        </Row>
      </div>
      <Row>
        <Col flex="1 0 60%">
          <NotificationList
            notifications={initiativeNotifications.items}
            isLoading={initiativeNotifications.isLoading}
            title={`Initiatives activity (${initiativeNotifications.totalUnread})`}
            icon={<SvgIcon iconName="diamond" />}
            onClickCommand={model.readNotification}
            listItemsClassName="NotificationCenter__notificationListItems"
            loadMore={initiativeNotifications.loadMore}
          />
          <NotificationList
            notifications={ideaNotifications.items}
            isLoading={ideaNotifications.isLoading}
            title={`Ideas activity (${ideaNotifications.totalUnread})`}
            icon={<SvgIcon iconName="bulb" />}
            onClickCommand={model.readNotification}
            listItemsClassName="NotificationCenter__notificationListItems"
            loadMore={ideaNotifications.loadMore}
          />
        </Col>
        <Col flex="1 0 40%">
          <NotificationList
            notifications={directMentionNotifications.items}
            isLoading={directMentionNotifications.isLoading}
            title={`Direct mentions (${directMentionNotifications.totalUnread})`}
            icon={<SvgIcon iconName="at" />}
            onClickCommand={model.readNotification}
            listItemsClassName="NotificationCenter__notificationListItems--long"
            loadMore={directMentionNotifications.loadMore}
          />
        </Col>
      </Row>
    </>
  );
});
