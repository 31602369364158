import { action, observable, onBecomeObserved, reaction, untracked } from "mobx";
import { command, Command, Deferred, isLoaded } from "react-mvvm/dist";
import { LoadMoreList } from "../../../shared/List/LoadMoreList";
import { IErrorService } from "../../../shared/services/ErrorService";
import { CommentStore } from "../../../shared/stories/comment/CommentStore";
import { IdeaStore } from "../../../shared/stories/idea/IdeaStore";
import { InitiativeOverview } from "../../../shared/stories/initiative/Initiative";
import {
  GetIntiativesFilterStorageKey,
  GetIntiativesOrderByStorageKey,
  InitiativesFilter,
  InitiativeStore,
} from "../../../shared/stories/initiative/InitiativeStore";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { VoteStore } from "../../../shared/stories/vote/VoteStore";
import { KanbanBoardWalkTour } from "../../../shared/walkTour/KanbanBoardWalkTour";
import { GetInitiativesListSortType } from "../../../types/initiatives/dto/GetInitiativesListSortType";
import { InitiativeStateNameDto } from "../../../types/initiatives/dto/InitiativeStateNameDto";
import { PeriodType } from "../../../types/initiatives/dto/PeriodType";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { BasePage } from "../../BasePage";
import { InitiativesOrderBy } from "../initiativeList/InitiativeListViewModel";
import { LoadMoreTagList } from "../shared/tags/tagFilterList/LoadMoreTagList";

const defaultFilter: Omit<InitiativesFilter, "states"> = {
  createdByCurrentUser: false,
  expiredPeriodType: PeriodType.Ever,
  tags: [],
};

type BoardFilter = Omit<InitiativesFilter, "states">;

export class FilteredInitiativePaginatedList extends LoadMoreList<InitiativeOverview> {
  @observable orderBy: InitiativesOrderBy;

  @observable filter: BoardFilter;

  constructor(
    private readonly initiativeStore: InitiativeStore,
    private readonly state: InitiativeStateNameDto,
    boardFilter: BoardFilter,
    order: InitiativesOrderBy,
    public readonly loggedUser: UserDto,
    readonly pageSize = 4
  ) {
    super(pageSize);
    this.orderBy = order;
    this.filter = boardFilter;
  }

  protected async load(options: { page: number; results?: number }) {
    const result = await this.getInitiatives({ ...options });

    return { items: result.initiatives, hasNextPage: result.hasNextPage };
  }

  private async getInitiatives(options: { page: number; results?: number }) {
    const { initiatives, hasNextPage } = await this.initiativeStore.getInitiatives(
      "",
      { ...options },
      this.mapToSortOption(this.orderBy),
      { ...this.filter, states: [this.state] }
    );
    return { initiatives, hasNextPage };
  }

  // eslint-disable-next-line class-methods-use-this
  private mapToSortOption(orderBy: InitiativesOrderBy) {
    switch (orderBy) {
      case InitiativesOrderBy.VotingDeadline:
        return { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.VotingEndDate };
      case InitiativesOrderBy.IdeaCollectionDeadline:
        return { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.SubmissionEndDate };
      default:
        return { sortDirection: SortDirection.Descending, sortType: GetInitiativesListSortType.VotingEndDate };
    }
  }
}

class Loaded {
  @observable planingInitiatives: FilteredInitiativePaginatedList;

  @observable collectingInitiatives: FilteredInitiativePaginatedList;

  @observable preVotingInitiatives: FilteredInitiativePaginatedList;

  @observable votingInitiatives: FilteredInitiativePaginatedList;

  @observable evaluationInitiatives: FilteredInitiativePaginatedList;

  @observable implementationInitiatives: FilteredInitiativePaginatedList;

  @observable closedInitiatives: FilteredInitiativePaginatedList;

  @observable orderBy = InitiativesOrderBy.VotingDeadline;

  @observable filter = defaultFilter;

  @observable tagList: LoadMoreTagList;

  constructor(
    readonly initiativeStore: InitiativeStore,
    readonly voteStore: VoteStore,
    readonly workspaceId: string,
    public readonly loggedUser: UserDto
  ) {
    this.initFilters();
    this.configureFilterReactions();

    this.preVotingInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.PreVoting,
      this.filter,
      this.orderBy,
      loggedUser
    );
    this.planingInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.Planning,
      this.filter,
      this.orderBy,
      loggedUser
    );
    this.collectingInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.Collecting,
      this.filter,
      this.orderBy,
      loggedUser
    );
    this.votingInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.Voting,
      this.filter,
      this.orderBy,
      loggedUser
    );
    this.evaluationInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.Evaluation,
      this.filter,
      this.orderBy,
      loggedUser
    );
    this.implementationInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.Implementation,
      this.filter,
      this.orderBy,
      loggedUser
    );
    this.closedInitiatives = new FilteredInitiativePaginatedList(
      initiativeStore,
      InitiativeStateNameDto.Closed,
      this.filter,
      this.orderBy,
      loggedUser
    );

    this.tagList = new LoadMoreTagList(this.initiativeStore);
  }

  @action addOrRemoveTag(tag: string) {
    if (this.filter.tags.includes(tag)) this.filter.tags = this.filter.tags.filter(t => t !== tag);
    else this.filter.tags = [...this.filter.tags, tag];
  }

  @action setDefaultFilter() {
    this.filter.createdByCurrentUser = defaultFilter.createdByCurrentUser;
    this.filter.expiredPeriodType = defaultFilter.expiredPeriodType;
    this.filter.tags = defaultFilter.tags;
  }

  private initFilters() {
    const filter = localStorage.getItem(GetIntiativesFilterStorageKey(this.workspaceId));
    const orderBy = localStorage.getItem(GetIntiativesOrderByStorageKey(this.workspaceId));

    if (filter) this.filter = JSON.parse(filter);
    if (orderBy) this.orderBy = JSON.parse(orderBy);
  }

  private configureFilterReactions() {
    reaction(
      () => [JSON.stringify(this.filter), JSON.stringify(this.orderBy)],
      arr => {
        localStorage.setItem(GetIntiativesFilterStorageKey(this.workspaceId), arr[0]);
        localStorage.setItem(GetIntiativesOrderByStorageKey(this.workspaceId), arr[1]);
      }
    );
  }
}

export class BoardViewModel extends BasePage {
  @observable.ref state: Deferred<Loaded> = "Loading";

  @observable isFilterVisible = false;

  @observable isDrawerVisible = false;

  resetFilter: Command;

  steps = KanbanBoardWalkTour.getSteps();

  constructor(
    public initiativeStore: InitiativeStore,
    public voteStore: VoteStore,
    public userStore: UserStore,
    public commentStore: CommentStore,
    public errorService: IErrorService,
    public ideaStore: IdeaStore,
    public showInitiative: Command<string, void>,
    public workspaceId: string
  ) {
    super(errorService, true, userStore);
    onBecomeObserved(this, "state", async () => {
      await this.loadData();
    });
    this.resetFilter = command(() => {
      if (isLoaded(this.state)) {
        this.state.setDefaultFilter();
      }
    });
  }

  @action.bound toggleFilters = () => {
    this.isFilterVisible = !this.isFilterVisible;
  };

  @action.bound toggleDrawer = () => {
    this.isDrawerVisible = !this.isDrawerVisible;
  };

  @action.bound onTagClick = (tag: string) => {
    isLoaded(this.state) && this.state.addOrRemoveTag(tag);
  };

  protected async loadData() {
    super.loadData();
    await this.userStore.updateUser();
    this.state = new Loaded(this.initiativeStore, this.voteStore, this.workspaceId, this.userStore.loggedUser);
  }
}
