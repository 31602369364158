import { Step } from "../../../../../shared/stepWizard/Step";
import { CreateWorkspaceBasicStepViewModel } from "../../createWorkspaceDialog/createWorkspaceWizard/createWorkspaceBasicStep/CreateWorkspaceBasicStepViewModel";
import { EditWorkspaceSteps } from "../EditWorkspaceSteps";

export function isEditWorkspaceBasicStep(step: Step<EditWorkspaceSteps>): step is EditWorkspaceBasicStepViewModel {
  return step.name === "Basic";
}

export class EditWorkspaceBasicStepViewModel extends CreateWorkspaceBasicStepViewModel {
  readonly name = "Basic";

  // eslint-disable-next-line class-methods-use-this
  canGotoNextStep() {
    return true;
  }
}
