import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { MoveIdeaToOtherInitiative } from "src/types/initiatives/commands/MoveIdeaToOtherInitiative";
import { TagListDto } from "src/types/initiatives/dto/TagListDto";
import { WorkspaceDto } from "src/types/initiatives/dto/WorkspaceDto";
import { WorkspaceMemberListDto } from "src/types/initiatives/dto/WorkspaceMemberListDto";
import { GetInitiativeMostUsedTags } from "src/types/initiatives/queries/GetInitiativeMostUsedTags";
import { GetWorkspace } from "src/types/initiatives/queries/GetWorkspace";
import { GetWorkspaceMembers } from "src/types/initiatives/queries/GetWorkspaceMembers";
import { MarkNotificationAsRead } from "src/types/notifications/commands/MarkNotificationAsRead";
import { SubscribeForNotifications } from "src/types/notifications/commands/SubscribeForNotifications";
import { UnsubscribeForNotifications } from "src/types/notifications/commands/UnsubscribeForNotifications";
import { UnreadNotificationTotalsDto } from "src/types/notifications/dto/UnreadNotificationTotalsDto";
import { GetDirectMentionAppNotifications } from "src/types/notifications/queries/GetDirectMentionAppNotifications";
import { GetIdeaAppNotifications } from "src/types/notifications/queries/GetIdeaAppNotifications";
import { GetInitiativeAppNotifications } from "src/types/notifications/queries/GetInitiativeAppNotifications";
import { CurrentUserDto } from "src/types/shared/dto/CurrentUserDto";
import { ApproveAccessRequest } from "src/types/users/commands/ApproveAccessRequest";
import { DenyAccessRequest } from "src/types/users/commands/DenyAccessRequest";
import { InviteUsersWithMagicLink } from "src/types/users/commands/InviteUsersWithMagicLink";
import { RequestAccess } from "src/types/users/commands/RequestAccess";
import { UpdateCurrentUser } from "src/types/users/commands/UpdateCurrentUser";
import { AccessRequestDto } from "src/types/users/dto/AccessRequestDto";
import { GetAccessRequest } from "src/types/users/queries/GetAccessRequest";
import { VoteMethodConfigDto } from "src/types/votes/dto/VoteMethodConfigDto";
import { ArchiveInitiative } from "../../types/initiatives/commands/ArchiveInitiative";
import { CreateIdea } from "../../types/initiatives/commands/CreateIdea";
import { CreateIdeaComment } from "../../types/initiatives/commands/CreateIdeaComment";
import { CreateInitiative } from "../../types/initiatives/commands/CreateInitiative";
import { CreateWorkspace } from "../../types/initiatives/commands/CreateWorkspace";
import { EditIdea } from "../../types/initiatives/commands/EditIdea";
import { EditInitiative } from "../../types/initiatives/commands/EditInitiative";
import { EditInitiativeCoOwners } from "../../types/initiatives/commands/EditInitiativeCoOwners";
import { EditWorkspace } from "../../types/initiatives/commands/EditWorkspace";
import { InviteUsersToInitiativeWithMagicLink } from "../../types/initiatives/commands/InviteUsersToInitiativeWithMagicLink";
import { ReleaseIdea } from "../../types/initiatives/commands/ReleaseIdea";
import { RemoveIdea } from "../../types/initiatives/commands/RemoveIdea";
import { ResetInitiative } from "../../types/initiatives/commands/ResetInitiative";
import { UnarchiveInitiative } from "../../types/initiatives/commands/UnarchiveInitiative";
import { AttachmentDto } from "../../types/initiatives/dto/AttachmentDto";
import { IdeaCommentListDto } from "../../types/initiatives/dto/IdeaCommentListDto";
import { IdeaDto } from "../../types/initiatives/dto/IdeaDto";
import { IdeaListDto } from "../../types/initiatives/dto/IdeaListDto";
import { InitiativeDto } from "../../types/initiatives/dto/InitiativeDto";
import { InitiativeListDto } from "../../types/initiatives/dto/InitiativeListDto";
import { WorkspaceListDto } from "../../types/initiatives/dto/WorkspaceListDto";
import { WorkspaceNameValidationResultDto } from "../../types/initiatives/dto/WorkspaceNameValidationResultDto";
import { GetAmountOfIdeaComments } from "../../types/initiatives/queries/GetAmountOfIdeaComments";
import { GetIdea } from "../../types/initiatives/queries/GetIdea";
import { GetIdeaComments } from "../../types/initiatives/queries/GetIdeaComments";
import { GetIdeas } from "../../types/initiatives/queries/GetIdeas";
import { GetInitiative } from "../../types/initiatives/queries/GetInitiative";
import { GetInitiatives } from "../../types/initiatives/queries/GetInitiatives";
import { GetWorkspaceNameValidation } from "../../types/initiatives/queries/GetWorkspaceNameValidation";
import { GetWorkspaces } from "../../types/initiatives/queries/GetWorkspaces";
import { AppNotificationListDto } from "../../types/notifications/dto/AppNotificationListDto";
import { InviteUsersWithMagicLinkDto } from "../../types/shared/dto/InviteUsersWithMagicLinkDto";
import { UserDto } from "../../types/shared/dto/UserDto";
import { ExceptionResponse } from "../../types/shared/exceptions/ExceptionResponse";
import { GetCurrentUser } from "../../types/shared/queries/GetCurrentUser";
import { GetUser } from "../../types/shared/queries/GetUser";
import { ReinviteExistingUserWithMagicLink } from "../../types/users/commands/ReinviteExistingUserWithMagicLink";
import { RequestAccessForCurrentUser } from "../../types/users/commands/RequestAccessForCurrentUser";
import { UserListDto } from "../../types/users/dto/UserListDto";
import { GetUsers } from "../../types/users/queries/GetUsers";
import { UpdateVote } from "../../types/votes/commands/UpdateVote";
import { PollDto } from "../../types/votes/dto/PollDto";
import { VoteDto } from "../../types/votes/dto/VoteDto";
import { GetPollsByTargetIds } from "../../types/votes/queries/GetPollsByTargetIds";
import { GetUserVotes } from "../../types/votes/queries/GetUserVotes";
import { GetVoteMethodConfigs } from "../../types/votes/queries/GetVoteMethodConfigs";
import { IErrorService } from "../services/ErrorService";
import { INavigationService } from "../services/NavigationService";
import { createAxiosInstance, createAxiosInstanceWithRedirect } from "./AxiosClient";

export interface IUserService {
  updateCurrentUser: (req: UpdateCurrentUser) => Promise<void>;
  getCurrentUser: (req: GetCurrentUser) => Promise<CurrentUserDto>;
  getCurrentUserRaw: (req: GetCurrentUser) => Promise<CurrentUserDto | null>;
  getUser: (req: GetUser) => Promise<UserDto>;
  getUsers: (req: GetUsers) => Promise<UserListDto>;
  inviteUsers: (req: InviteUsersWithMagicLink) => Promise<InviteUsersWithMagicLinkDto>;
  loginWithMagicLink: (req: string) => Promise<void>;
  resendInvitation: (req: ReinviteExistingUserWithMagicLink) => Promise<ReinviteExistingUserWithMagicLink>;
  getIdentityProvider: () => Promise<string[]>;
  uploadUserPhoto: (file: File) => Promise<void>;
  removeUserPhoto: () => Promise<void>;
  requestAccess: (req: RequestAccess) => Promise<void>;
  requestAccessForCurrentUser: (req: RequestAccessForCurrentUser) => Promise<void>;
  approveAccessRequest: (req: ApproveAccessRequest) => Promise<void>;
  denyAccessRequest: (req: DenyAccessRequest) => Promise<void>;
  getAccessRequest: (req: GetAccessRequest) => Promise<AccessRequestDto>;
}

export interface IInitiativeService {
  createInitiative: (req: CreateInitiative) => Promise<void>;
  getInitiativeList: (req: GetInitiatives) => Promise<InitiativeListDto>;
  getInitiative: (req: GetInitiative) => Promise<InitiativeDto>;
  editInitiative: (req: EditInitiative) => Promise<void>;
  getIdea: (req: GetIdea) => Promise<IdeaDto>;
  releaseIdea: (req: ReleaseIdea) => Promise<void>;
  getIdeaList: (req: GetIdeas) => Promise<IdeaListDto>;
  createIdea: (req: CreateIdea) => Promise<void>;
  removeIdea: (req: RemoveIdea) => Promise<void>;
  editIdea: (req: EditIdea) => Promise<void>;
  moveIdea: (req: MoveIdeaToOtherInitiative) => Promise<void>;
  setCoOwners: (req: EditInitiativeCoOwners) => Promise<void>;
  closeInitiative: (req: string) => Promise<void>;
  setImplementation: (req: string) => Promise<void>;
  inviteUserIntoInitiative: (req: InviteUsersToInitiativeWithMagicLink) => Promise<InviteUsersWithMagicLinkDto>;
  resetInitiative: (req: ResetInitiative) => Promise<void>;
  archiveInitiative: (req: ArchiveInitiative) => Promise<void>;
  unarchiveInitiative: (req: UnarchiveInitiative) => Promise<void>;
  getInitiativeMostUsedTags: (req: GetInitiativeMostUsedTags) => Promise<TagListDto>;
}

export interface IVoteService {
  getPollsRequest: (req: GetPollsByTargetIds) => Promise<PollDto[]>;
  getUserVotesRequest: (req: GetUserVotes) => Promise<VoteDto[]>;
  getVoteMethodConfigs: (req?: GetVoteMethodConfigs) => Promise<VoteMethodConfigDto[]>;
  updateVote: (req: UpdateVote) => Promise<void>;
}

export interface ICommentService {
  createComment: (req: CreateIdeaComment) => Promise<void>;
  getComments: (req: GetIdeaComments) => Promise<IdeaCommentListDto>;
  getAmountOfCommentsRequest: (req: GetAmountOfIdeaComments) => Promise<number>;
  removeComment: (req: string) => Promise<void>;
}

export interface IFileService {
  uploadFile: (file: File) => Promise<AxiosResponse<AttachmentDto>>;
  downloadFile: (id: string) => Promise<File>;
}

export interface INotificationService {
  subscribeForNotification: (req: SubscribeForNotifications) => Promise<void>;
  unsubscribeForNotification: (req: UnsubscribeForNotifications) => Promise<void>;
  markNotificationAsRead: (req: MarkNotificationAsRead) => Promise<void>;
  markAllNotificationsAsRead: () => Promise<void>;
  getInitiativeNotifications: (req: GetInitiativeAppNotifications) => Promise<AppNotificationListDto>;
  getIdeaNotifications: (req: GetIdeaAppNotifications) => Promise<AppNotificationListDto>;
  getDirectMentionNotifications: (req: GetDirectMentionAppNotifications) => Promise<AppNotificationListDto>;
  getUnreadNotificationsTotals: () => Promise<UnreadNotificationTotalsDto>;
}

export interface IWorkspaceService {
  createWorkspace: (req: CreateWorkspace) => Promise<void>;
  editWorkspace: (req: EditWorkspace) => Promise<void>;
  getWorkspaceList: (req: GetWorkspaces) => Promise<WorkspaceListDto>;
  getWorkspace: (req: GetWorkspace) => Promise<WorkspaceDto>;
  getWorkspaceMembers: (req: GetWorkspaceMembers) => Promise<WorkspaceMemberListDto>;
  validateWorkspaceName: (req: GetWorkspaceNameValidation) => Promise<WorkspaceNameValidationResultDto>;
}

export class BackendApi
  implements
    IUserService,
    IInitiativeService,
    IVoteService,
    ICommentService,
    IFileService,
    INotificationService,
    IWorkspaceService {
  private splashAxios: AxiosInstance;

  private toastAxios: AxiosInstance;

  private rawAxios: AxiosInstance;

  private errorService?: IErrorService;

  constructor(navigation: INavigationService, errorService: IErrorService) {
    this.toastAxios = createAxiosInstanceWithRedirect(navigation, errorService);
    this.toastAxios.interceptors.response.use(
      ok => ok,
      (err: AxiosError<ExceptionResponse>) => {
        const status = err?.response?.status;
        const exceptionResponseDto = err.response?.data;
        if (status) {
          this.ensureErrorServiceConfigured().showErrorToast(
            status,
            exceptionResponseDto?.code,
            exceptionResponseDto?.response
          );
        }
        return Promise.reject(err);
      }
    );

    this.splashAxios = createAxiosInstanceWithRedirect(navigation, errorService);
    this.splashAxios.interceptors.response.use(
      ok => ok,
      err => {
        const status = err?.response?.status;
        if (status) {
          this.ensureErrorServiceConfigured().showErrorSplash(status);
        }
        return Promise.reject(err);
      }
    );
    this.rawAxios = createAxiosInstance();
  }

  validateWorkspaceName = async (req: GetWorkspaceNameValidation) => {
    const response = await this.toastAxios.get<WorkspaceNameValidationResultDto>(`/api/workspaces/name/validate`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
      },
    });
    return response.data;
  };

  async editWorkspace(req: EditWorkspace) {
    await this.toastAxios.put<EditWorkspace>("/api/workspaces", req);
  }

  async getWorkspace(req: GetWorkspace) {
    const response = await this.createAxiosWithRequestAccess(req.id).get<WorkspaceDto>(`/api/workspaces/${req.id}`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
      },
    });

    return response.data;
  }

  async getWorkspaceList(req: GetWorkspaces) {
    return (
      await this.toastAxios.get<WorkspaceListDto>("/api/workspaces", {
        params: {
          ...req,
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
        },
      })
    ).data;
  }

  async getWorkspaceMembers(req: GetWorkspaceMembers) {
    return (
      await this.toastAxios.get<WorkspaceMemberListDto>(`/api/workspaces/${req.workspaceId}/members`, {
        params: {
          ...req,
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
        },
      })
    ).data;
  }

  async createWorkspace(req: CreateWorkspace) {
    await this.toastAxios.post<CreateWorkspace>("/api/workspaces", req);
  }

  async removeUserPhoto() {
    await this.toastAxios.delete("api/users/photo");
  }

  async downloadFile(id: string) {
    const response = await this.toastAxios.get<File>(`/api/files/${id}`, { responseType: "blob" });
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadUserPhoto(file: File) {
    const formData = new FormData();
    formData.append(file.name, file);
    await axios.post("/api/users/photo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async getInitiativeMostUsedTags(req: GetInitiativeMostUsedTags) {
    const response = await this.toastAxios.get<TagListDto>(`/api/initiatives/tags`, { params: req });
    return response.data;
  }

  async resetInitiative(req: ResetInitiative) {
    await this.toastAxios.put(`/api/initiatives/${req.id}/reset`);
  }

  async archiveInitiative(req: ArchiveInitiative) {
    await this.toastAxios.put(`/api/initiatives/${req.id}/archive`);
  }

  async unarchiveInitiative(req: UnarchiveInitiative) {
    await this.toastAxios.put(`/api/initiatives/${req.id}/unarchive`);
  }

  async getIdentityProvider() {
    const response = await this.toastAxios.get<string[]>("/identity/idp/externals");
    return response.data;
  }

  async resendInvitation(req: ReinviteExistingUserWithMagicLink) {
    const response = await this.toastAxios.post<ReinviteExistingUserWithMagicLink>(`/api/users/reinvite`, req);
    return response.data;
  }

  async inviteUserIntoInitiative(req: InviteUsersToInitiativeWithMagicLink) {
    const response = await this.toastAxios.post<InviteUsersWithMagicLinkDto>(
      `/api/initiatives/${req.id}/users/invite`,
      {
        Emails: req.emails,
      }
    );
    return response.data;
  }

  async updateCurrentUser(req: UpdateCurrentUser) {
    await this.toastAxios.put<UpdateCurrentUser>(`/api/users`, req);
  }

  async loginWithMagicLink(req: string) {
    await this.toastAxios.post<string>(`/Identity/MagicToken/${req}`);
  }

  async inviteUsers(req: InviteUsersWithMagicLink) {
    const response = await this.toastAxios.post<InviteUsersWithMagicLinkDto>(`/api/users/invite`, {
      Emails: req.emails,
    });
    return response.data;
  }

  async setCoOwners(req: EditInitiativeCoOwners) {
    await this.toastAxios.put<EditInitiativeCoOwners>(`/api/initiatives/${req.id}/coowners`, { userIds: req.userIds });
  }

  async getUsers(req: GetUsers) {
    const response = await this.toastAxios.get<UserListDto>("/api/users", {
      params: {
        ...req,
        excludeIds: JSON.stringify(req.excludeIds),
        ids: JSON.stringify(req.ids),
      },
    });
    return response.data;
  }

  async releaseIdea(req: ReleaseIdea) {
    await this.toastAxios.post<ReleaseIdea>(`/api/initiatives/ideas/${req.id}/release`, { data: req });
  }

  public initErrorInterceptors(errorService: IErrorService) {
    this.errorService = errorService;
  }

  async getIdeaList(req: GetIdeas) {
    const response = await this.splashAxios.get<IdeaListDto>(`/api/initiatives/${req.initiativeId}/ideas`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
      },
    });

    return response.data;
  }

  async getIdea(req: GetIdea) {
    const response = await this.createAxiosWithRequestAccess(req.id).get<IdeaDto>(`/api/initiatives/ideas/${req.id}`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
      },
    });

    return response.data;
  }

  async getInitiativeList(req: GetInitiatives) {
    const response = await this.createAxiosWithRequestAccess(req.workspaceId).get<InitiativeListDto>(
      `/api/initiatives`,
      {
        params: {
          ...req,
          states: JSON.stringify(req.states),
          tags: JSON.stringify(req.tags),
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
        },
      }
    );

    return response.data;
  }

  async getInitiative(req: GetInitiative) {
    const response = await this.createAxiosWithRequestAccess(req.id).get<InitiativeDto>(`/api/initiatives/${req.id}`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
      },
    });

    return response.data;
  }

  async closeInitiative(req: string) {
    await this.toastAxios.put<string>(`/api/initiatives/${req}/close`);
  }

  async setImplementation(req: string) {
    await this.toastAxios.put<string>(`/api/initiatives/${req}/implementation`);
  }

  async createInitiative(req: CreateInitiative) {
    await this.toastAxios.post<CreateInitiative>("/api/initiatives", req);
  }

  async editInitiative(req: EditInitiative) {
    await this.toastAxios.put<EditInitiative>(`/api/initiatives/${req.id}`, req);
  }

  async getCurrentUser() {
    const response = await this.splashAxios.get<CurrentUserDto>(`/api/users/current`);
    return response.data;
  }

  // intercept no response redirect etc
  async getCurrentUserRaw() {
    try {
      const response = await this.rawAxios.get<CurrentUserDto>(`/api/users/current`);
      return response.data;
    } catch {
      return null;
    }
  }

  async getUser(req: GetUser) {
    const response = await this.toastAxios.get<UserDto>(`/api/users/${req.id}`);
    return response.data;
  }

  async createIdea(req: CreateIdea) {
    await this.toastAxios.post<CreateIdea>("/api/initiatives/ideas", req);
  }

  async removeIdea(req: RemoveIdea) {
    await this.toastAxios.delete<RemoveIdea>(`/api/initiatives/ideas/${req.id}`, { data: req });
  }

  async editIdea(req: EditIdea) {
    await this.toastAxios.put<EditIdea>(`/api/initiatives/ideas/${req.id}`, req);
  }

  async moveIdea(req: MoveIdeaToOtherInitiative) {
    await this.toastAxios.put("/api/initiatives/ideas/move", req);
  }

  async getPollsRequest(req: GetPollsByTargetIds) {
    const response = await this.splashAxios.get<PollDto[]>(`/api/polls`, {
      params: {
        targetIds: JSON.stringify(req.targetIds),
      },
    });
    return response.data;
  }

  async getUserVotesRequest(req: GetUserVotes) {
    const response = await this.toastAxios.get<VoteDto[]>(`/api/polls/votes`, {
      params: {
        pollIds: JSON.stringify(req.pollIds),
      },
    });
    return response.data;
  }

  async getVoteMethodConfigs(req?: GetVoteMethodConfigs) {
    const response = await this.toastAxios.get<VoteMethodConfigDto[]>(`/api/polls/votes/methodconfigs`, {
      params: {
        ...req,
        votingMethods: JSON.stringify(req?.votingMethods),
      },
    });
    return response.data;
  }

  async updateVote(req: UpdateVote) {
    await this.toastAxios.put<UpdateVote>("/api/polls/votes", req);
  }

  async createComment(req: CreateIdeaComment) {
    await this.toastAxios.post<CreateIdeaComment>(`/api/initiatives/ideas/${req.ideaId}/comments`, req);
  }

  async getComments(req: GetIdeaComments) {
    const response = await this.toastAxios.get<IdeaCommentListDto>(`/api/initiatives/ideas/${req.ideaId}/comments`, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "comma", allowDots: true });
      },
    });
    return response.data;
  }

  async removeComment(req: string) {
    await this.toastAxios.delete<string>(`/api/initiatives/ideas/comments/${req}`, { data: req });
  }

  async getAmountOfCommentsRequest(req: GetAmountOfIdeaComments) {
    const response = await this.toastAxios.get<number>(`/api/initiatives/ideas/${req.ideaId}/comments/count`);
    return response.data;
  }

  async subscribeForNotification(req: SubscribeForNotifications) {
    await this.toastAxios.post<string>("/api/notifications/subscribe", req);
  }

  async unsubscribeForNotification(req: UnsubscribeForNotifications) {
    await this.toastAxios.put<string>("/api/notifications/unsubscribe", req);
  }

  async markNotificationAsRead(req: MarkNotificationAsRead) {
    await this.toastAxios.put(`/api/notifications/read/${req.notificationId}`);
  }

  async markAllNotificationsAsRead() {
    await this.toastAxios.put("/api/notifications/read/all");
  }

  async getInitiativeNotifications(req: GetInitiativeAppNotifications) {
    const response = await this.splashAxios.get<AppNotificationListDto>("/api/notifications/initiatives", {
      params: {
        ...req,
        timestamp: new Date().toISOString(),
      },
    });

    return response.data;
  }

  async getIdeaNotifications(req: GetIdeaAppNotifications) {
    const response = await this.splashAxios.get<AppNotificationListDto>("/api/notifications/ideas", {
      params: {
        ...req,
        timestamp: new Date().toISOString(),
      },
    });

    return response.data;
  }

  async getDirectMentionNotifications(req: GetDirectMentionAppNotifications) {
    const response = await this.splashAxios.get<AppNotificationListDto>("/api/notifications/mentions", {
      params: {
        ...req,
        timestamp: new Date().toISOString(),
      },
    });

    return response.data;
  }

  async getUnreadNotificationsTotals() {
    const response = await this.splashAxios.get<UnreadNotificationTotalsDto>(`/api/notifications/totals`);

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadFile(file: File): Promise<AxiosResponse<AttachmentDto>> {
    const formData = new FormData();
    formData.append(file.name, file);
    const promise = axios.post<AttachmentDto>("/api/files/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return await promise;
  }

  async requestAccess(req: RequestAccess) {
    await this.toastAxios.post("/api/users/access/request", req);
  }

  async requestAccessForCurrentUser(req: RequestAccessForCurrentUser) {
    await this.toastAxios.post("/api/users/current/access/request", req);
  }

  async approveAccessRequest(req: ApproveAccessRequest) {
    await this.toastAxios.put("/api/users/access/request/approve", req);
  }

  async denyAccessRequest(req: DenyAccessRequest) {
    await this.toastAxios.put("/api/users/access/request/deny", req);
  }

  async getAccessRequest(req: GetAccessRequest) {
    const res = await this.splashAxios.get<AccessRequestDto>(`/api/users/access/request/${req.id}`);
    return res.data;
  }

  private ensureErrorServiceConfigured() {
    if (!this.errorService) {
      throw new Error("errorService in BackendApi was not initialized. Did you forget to call initErrorInterceptor?");
    }
    return this.errorService;
  }

  private createAxiosWithRequestAccess(resourceId: string) {
    const axiosInstance = createAxiosInstance();
    axiosInstance.interceptors.request.use(config => {
      // prevent redirections, return 401 if unauthorized instaed of 302
      config.headers["X-Requested-With"] = "XMLHttpRequest";
      return config;
    });
    axiosInstance.interceptors.response.use(
      ok => ok,
      err => {
        const status = err?.response?.status;
        if (status === 401 || status === 403) {
          this.ensureErrorServiceConfigured().showRequestAccess(resourceId);
        }
        return Promise.reject(err);
      }
    );
    return axiosInstance;
  }
}
