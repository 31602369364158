import {
  PlusOutlined,
  CommentOutlined,
  EllipsisOutlined,
  MinusOutlined,
  FileOutlined,
  DownloadOutlined,
  UploadOutlined,
  PaperClipOutlined,
  TableOutlined,
  QuestionOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import * as React from "react";

import AddUser from "../../static/images/icons/24/add-user.svg";
import ArrowUp from "../../static/images/icons/24/arrow-up-bold.svg";
import At from "../../static/images/icons/24/at.svg";
import Avatar from "../../static/images/icons/24/avatar.svg";
import BellFilled from "../../static/images/icons/24/bell-filled.svg";
import Bell from "../../static/images/icons/24/bell.svg";
import Box from "../../static/images/icons/24/box.svg";
import Bulb from "../../static/images/icons/24/bulb.svg";
import Chart from "../../static/images/icons/24/chart-bold.svg";
import BarChart from "../../static/images/icons/24/chart.svg";
import Chat from "../../static/images/icons/24/chat.svg";
import ChevronLeft from "../../static/images/icons/24/chevron-left.svg";
import ChevronRight from "../../static/images/icons/24/chevron-right.svg";
import Clock from "../../static/images/icons/24/clock.svg";
import Delete from "../../static/images/icons/24/delete.svg";
import Diamond from "../../static/images/icons/24/diamond.svg";
import Dollar from "../../static/images/icons/24/dollar.svg";
import DotsVoted from "../../static/images/icons/24/dots-voted.svg";
import Dots from "../../static/images/icons/24/dots.svg";
import Edit from "../../static/images/icons/24/edit.svg";
import EyeOff from "../../static/images/icons/24/eye-off.svg";
import Favourites from "../../static/images/icons/24/favourites.svg";
import Home from "../../static/images/icons/24/home.svg";
import LockOpen from "../../static/images/icons/24/lock-open.svg";
import Lock from "../../static/images/icons/24/lock.svg";
import LogOut from "../../static/images/icons/24/log-out.svg";
import Minus from "../../static/images/icons/24/minus2p-bold.svg";
import Move from "../../static/images/icons/24/move.svg";
import Picture from "../../static/images/icons/24/picture.svg";
import Send from "../../static/images/icons/24/send2p-bold.svg";
import SpeechBubble from "../../static/images/icons/24/speech-bubble.svg";
import ThumbUpClick from "../../static/images/icons/24/thumbs-up-click.svg";
import ThumbUp from "../../static/images/icons/24/thumbs-up.svg";
import TickComplete from "../../static/images/icons/24/tick-complete.svg";
import Tick from "../../static/images/icons/24/tick2p-bold.svg";
import Unarchive from "../../static/images/icons/24/unarchive.svg";
import Vector from "../../static/images/icons/24/vector.svg";
import Vote from "../../static/images/icons/24/vote.svg";
import Close from "../../static/images/icons/32/close.svg";
import DotsLarge from "../../static/images/icons/32/dots-large.svg";
import MicrosoftLogo from "../../static/images/icons/32/logo-microsoft.svg";
import PlusCircle from "../../static/images/icons/32/plus2p.svg";
import Question from "../../static/images/icons/80/question.svg";
import Success from "../../static/images/icons/80/success.svg";
import Archive from "../../static/images/icons/archive.svg";
import Refresh from "../../static/images/icons/refresh.svg";
import X from "../../static/images/icons/x.svg";

const antIcons = {
  paperClip: PaperClipOutlined,
  minusOutlined: MinusOutlined,
  plusOutlined: PlusOutlined,
  commentOutlined: CommentOutlined,
  ellipsisOutlined: EllipsisOutlined,
  fileOutlined: FileOutlined,
  download: DownloadOutlined,
  upload: UploadOutlined,
  table: TableOutlined,
  question: QuestionOutlined,
  share: ShareAltOutlined,
};

const customIcons = {
  addUser: () => <AddUser />,
  barChart: () => <BarChart />,
  vector: () => <Vector />,
  minus: () => <Minus />,
  microsoftLogo: () => <MicrosoftLogo />,
  vote: () => <Vote />,
  dots: () => <Dots />,
  dotsLarge: () => <DotsLarge />,
  thumbUp: () => <ThumbUp />,
  thumbUpClick: () => <ThumbUpClick />,
  dollar: () => <Dollar />,
  dotsVoted: () => <DotsVoted />,
  diamond: () => <Diamond />,
  favourites: () => <Favourites />,
  clock: () => <Clock />,
  bulb: () => <Bulb />,
  home: () => <Home />,
  logOut: () => <LogOut />,
  lock: () => <Lock />,
  lockOpen: () => <LockOpen />,
  chevronRight: () => <ChevronRight />,
  chevronLeft: () => <ChevronLeft />,
  arrowUp: () => <ArrowUp />,
  arrowDown: () => <ArrowUp />,
  edit: () => <Edit />,
  chat: () => <Chat />,
  send: () => <Send />,
  close: () => <Close />,
  plusCircle: () => <PlusCircle />,
  success: () => <Success />,
  speechBubble: () => <SpeechBubble />,
  delete: () => <Delete />,
  chart: () => <Chart />,
  avatar: () => <Avatar />,
  bellOutlined: () => <Bell />,
  bellFilled: () => <BellFilled />,
  tick: () => <Tick />,
  at: () => <At />,
  eyeOff: () => <EyeOff />,
  refresh: () => <Refresh />,
  questionCircled: () => <Question />,
  archive: () => <Archive />,
  unarchive: () => <Unarchive />,
  picture: () => <Picture />,
  move: () => <Move />,
  x: () => <X />,
  box: () => <Box />,
  tickComplete: () => <TickComplete />,
};

const icons = { ...antIcons, ...customIcons };

export type IconType = keyof typeof antIcons | keyof typeof customIcons;

interface IIconProps {
  iconName: IconType;
  className?: string;
  onClick?: () => void;
}

export const SvgIcon: React.FC<IIconProps> = ({ iconName, className, onClick }) => {
  const Icon = icons[iconName];

  const classes = classNames(`anticon customicon-${iconName}`, className, onClick && "customicon--button");

  return iconName in antIcons ? (
    <Icon className={classes} onClick={onClick} />
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span className={classes} onClick={onClick}>
      <Icon />
    </span>
  );
};
