import { computed, observable } from "mobx";
import { bindableForm, Command, command, RequiredFieldValidator } from "react-mvvm/dist";
import { IErrorService } from "../../shared/services/ErrorService";
import { INavigationService } from "../../shared/services/NavigationService";
import { UserStore } from "../../shared/stories/user/UserStore";
import { combineValidators } from "../../shared/validators/combineValidators";
import { BasePage } from "../BasePage";

interface INewUser {
  name: string;
}

export class LoginViewModel extends BasePage {
  @observable newUser: INewUser = {
    name: "",
  };

  @observable userWithoutName = false;

  @observable id = "";

  @observable isLoading = true;

  @observable isSettingUserName = false;

  isError = false;

  magicLink;

  form;

  submit: Command<void, Promise<void>>;

  userStore;

  navigationService;

  constructor(errorService: IErrorService, navigationService: INavigationService, userStore: UserStore, token: string) {
    super(errorService, false, userStore);

    this.userStore = userStore;
    this.navigationService = navigationService;

    this.magicLink = token;

    this.form = bindableForm<INewUser>()
      .addField("name", {
        validator: v => combineValidators([RequiredFieldValidator(v)]),
      })
      .bindTo(() => this.newUser);

    this.submit = command(
      async () => {
        this.isSettingUserName = true;
        if (!(await this.form.validateAndCommit())) return;
        try {
          await userStore.setUserName(this.newUser.name);
          this.navigationService.returnToReturnUrlOrDefault();
        } catch {
          this.isSettingUserName = false;
        }
      },
      () => this.isValidUserName
    );
  }

  @computed get isValidUserName() {
    if (!this.form.fields.name.value?.length) {
      return false;
    }
    return this.form.fields.name.value?.length > 5;
  }

  async onActivated() {
    try {
      await this.userStore.loginWithMagicLink(this.magicLink);
      const user = await this.userStore.getCurrentUser();
      this.id = user.id;
      user.name === null ? (this.userWithoutName = true) : this.navigationService.returnToReturnUrlOrDefault();
      this.isLoading = false;
    } catch {
      this.isError = true;
      this.isLoading = false;
    }
    super.onActivated();
  }
}
