import { action, observable } from "mobx";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { DialogSupport } from "../../../../shared/dialogs/Dialogs";
import { UserStore } from "../../../../shared/stories/user/UserStore";

interface AvatarEditorDialogModel {
  img: File;
}

export class AvatarEditorDialogViewModel extends DialogViewModel<AvatarEditorDialogModel> {
  @observable img: File | undefined;

  @observable scale = 1;

  @observable position = { x: 0.5, y: 0.5 };

  constructor(private readonly userStore: UserStore) {
    super();
  }

  @action setPosition(pos: { x: number; y: number }) {
    this.position = pos;
  }

  @action setScale(scale: number) {
    this.scale = scale;
  }

  uploadPhoto = async (file: File) => {
    await this.userStore.uploadUserPhoto(file);
    await this.userStore.updateUser(true);
    this.hide();
    DialogSupport.showSuccessDialog({
      title: "New profile photo successfully uploaded",
      okText: "Go to profile",
    });
  };

  protected onShow(model: AvatarEditorDialogModel) {
    this.img = model.img;
  }
}
