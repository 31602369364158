import { Layout } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { App } from "../../pages/App";
import { NavigationMenu } from "../Menu/NavigationMenu";

interface SidePanelProps {
  model: App;
  className?: string;
}

export const Header: React.FC<SidePanelProps> = observer(({ model, className }) => {
  return (
    <Layout.Header className={classNames("Header", className, model.rootStore.userStore.isDefaultUser && "hidden")}>
      <NavigationMenu className="Header__menu" mode="horizontal" model={model} />
    </Layout.Header>
  );
});
