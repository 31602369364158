import { Button, Col, Divider, Result } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { SignInPage } from "../../../layout/SignInPage/SignInPage";
import { CommandButton } from "../../button/Button";
import { Field, Form } from "../../form/Form";
import { AppLogo } from "../../logo/AppLogo";
import { INavigationService } from "../../services/NavigationService";
import { TextInput } from "../../textInput/TextInput";
import { useViewModel } from "./RequestAccessViewModel";

export const RequestAccessView = observer(
  ({ resourceId, navigationService }: { resourceId: string; navigationService: INavigationService }) => {
    const model = useViewModel(resourceId, navigationService);

    return (
      <SignInPage className="RequestAccessView" hideImages={model.isAuthenticated}>
        {model.isSuccess ? (
          <div className="Result">
            {model.isAuthenticated ? (
              <Result
                status="success"
                title="Your request has been sent to the owner of the workspace."
                subTitle="You will receive a notification as soon as you request is approved or denied."
              />
            ) : (
              <Result
                status="success"
                title="Thank you! Your access request has been sent."
                subTitle="If you don't have an account, you will receive an email with a link to access the workspace as soon as your request is approved.
              If you already have an account, please sign in first."
              />
            )}
          </div>
        ) : (
          <>
            <Col>
              <AppLogo size="medium" variant="secondary" includeText />
              <div className="RequestAccessView__Title">
                Looks like you’re not a member of the workspace you’re trying to view. Please sign in or ask for access
                to the workspace.
              </div>
            </Col>
            {!model.isAuthenticated && (
              <Form layout="vertical" form={model.form}>
                {fields => (
                  <>
                    <Field field={fields.email} label="Your email">
                      {props => <TextInput type="email" placeholder="Email address" {...props} />}
                    </Field>
                  </>
                )}
              </Form>
            )}

            <CommandButton size="large" type="primary" command={model.submit} withLoader>
              Request access
            </CommandButton>
            {!model.isAuthenticated && (
              <>
                <Divider />
                <div className="RequestAccessView__Title">Already registered?</div>
                <Button size="large" type="primary" block onClick={() => model.goToLogin.execute()}>
                  Log in
                </Button>
              </>
            )}
          </>
        )}
      </SignInPage>
    );
  }
);
