import { computed, observable } from "mobx";
import { IViewModel } from "mobx-utils";
import { command, Command } from "react-mvvm";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { UserStore } from "../../../../shared/stories/user/UserStore";
import { Workspace } from "../../../../shared/stories/workspace/Workspace";
import { WorkspaceMember } from "../../../../shared/stories/workspace/WorkspaceMember";
import { WorkspaceStore } from "../../../../shared/stories/workspace/WorkspaceStore";
import { ManageWorkspaceMembersStepViewModel } from "./ManageWorkspaceMembersStepViewModel";

export type ManageWorkspaceMembersType = "edit" | "read-only";

export class ManageWorkspaceMembersDialogViewModel extends DialogViewModel<Workspace> {
  @observable editType: ManageWorkspaceMembersType = "read-only";

  @computed get canUserEdit() {
    return this.workspace.canUserEdit;
  }

  cancel: Command;

  submit: Command<void, Promise<void>>;

  manageWorkspaceMembersStepViewModel: ManageWorkspaceMembersStepViewModel;

  constructor(
    private onSuccess: (workspace: Workspace & IViewModel<Workspace>) => Promise<void>,
    readonly workspace: Workspace & IViewModel<Workspace>,
    private readonly workspaceStore: WorkspaceStore,
    readonly userStore: UserStore
  ) {
    super();

    this.cancel = command(() => {
      this.hide();
    });

    this.submit = command(
      () => this.editMembers(this.manageWorkspaceMembersStepViewModel),
      () => this.editType === "edit"
    );

    this.manageWorkspaceMembersStepViewModel = new ManageWorkspaceMembersStepViewModel(
      { ...workspace },
      this.userStore,
      this.workspaceStore
    );
  }

  async show(model: Workspace & IViewModel<Workspace>, editType: ManageWorkspaceMembersType = "read-only") {
    this.editType = editType;

    await this.workspace.updateMembers();
    this.manageWorkspaceMembersStepViewModel = new ManageWorkspaceMembersStepViewModel(
      { ...model },
      this.userStore,
      this.workspaceStore
    );

    await super.show();
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}

  private async editMembers(model: ManageWorkspaceMembersStepViewModel): Promise<void> {
    try {
      this.workspace.members = (model.form.fields.members.value ?? this.workspace.members).map(x => {
        const member = new WorkspaceMember();
        member.id = x.id;
        member.email = x.email;
        member.name = x.name;
        member.jobTitle = x.jobTitle;
        member.photoId = x.photoId;
        member.workspaceRole = x.workspaceRole;
        return member;
      });
      this.workspace.numberOfMembers = this.workspace.members.length;
      await this.onSuccess(this.workspace);
    } catch {
      await this.workspaceStore.getWorkspace(this.workspace.id);
    }
  }
}
