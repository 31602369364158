import { observable } from "mobx";
import { useMemo } from "react";
import { bindableForm, command, Command, RequiredFieldValidator } from "react-mvvm/dist";
import { INavigationService, NavigationService } from "../../services/NavigationService";
import { useStore } from "../../stories/RootStore";
import { UserStore } from "../../stories/user/UserStore";
import { combineValidators } from "../../validators/combineValidators";
import { EmailValidator } from "../../validators/emailValidator";

interface ExternalUser {
  email: string;
}

export class RequestAccessViewModel {
  @observable externalUser: ExternalUser = {
    email: "",
  };

  @observable isLoading = false;

  @observable isSuccess = false;

  @observable isAuthenticated = false;

  form;

  submit: Command<void, Promise<void>>;

  goToLogin: Command<void, Promise<void>>;

  userStore;

  constructor(navigationService: INavigationService, userStore: UserStore, private readonly resourceId: string) {
    this.userStore = userStore;
    userStore.tryUpdateUser().then(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
    this.goToLogin = command(async () => {
      navigationService.goToSignin(navigationService.getCurrentLocation());
    });

    this.form = bindableForm<ExternalUser>()
      .addField("email", {
        validator: v => combineValidators([RequiredFieldValidator(v), EmailValidator(v)]),
      })
      .bindTo(() => this.externalUser);

    this.submit = command(async () => {
      if (!this.isAuthenticated && !(await this.form.validateAndCommit())) return;
      this.isLoading = true;
      try {
        if (this.isAuthenticated) {
          await this.userStore.requestWorkspaceAccessForCurrentUser(this.resourceId);
        } else {
          await this.userStore.requestWorkspaceAccess(this.resourceId, this.externalUser.email);
        }

        this.isSuccess = true;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
        this.isSuccess = false;
      }
    });
  }
}

export const useViewModel = (resourceId: string, navigationService: INavigationService) => {
  const store = useStore();
  const viewModel = useMemo(() => new RequestAccessViewModel(navigationService, store.userStore, resourceId), [
    resourceId,
  ]);
  return viewModel;
};
