/* eslint-disable class-methods-use-this */
import { observable } from "mobx";
import { InviteUsersToInitiativeWithMagicLink } from "src/types/initiatives/commands/InviteUsersToInitiativeWithMagicLink";
import { InitiativeListItemDto } from "src/types/initiatives/dto/InitiativeListItemDto";
import { InitiativeStateNameDto } from "src/types/initiatives/dto/InitiativeStateNameDto";
import { SubscriptionTargetTypeDto } from "src/types/notifications/dto/SubscriptionTargetTypeDto";
import { GetUsersSortType } from "src/types/users/dto/GetUsersSortType";
import { NIL, v4 as uuid } from "uuid";
import { GetInitiativesListSortType } from "../../../types/initiatives/dto/GetInitiativesListSortType";
import { InitiativeDto } from "../../../types/initiatives/dto/InitiativeDto";
import { PeriodType } from "../../../types/initiatives/dto/PeriodType";
import { SortDirection } from "../../../types/shared/dto/SortDirection";
import { IInitiativeService, INotificationService } from "../../api/BackendApi";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { Initiative, InitiativeOverview } from "./Initiative";

const INITIATIVES_FILTER_STORAGE_KEY = "initiatives_filer_storage";
const INITIATIVES_ORDER_BY_STORAGE_KEY = "initiative_order_by_storage";

export const GetIntiativesFilterStorageKey = (workspaceId: string): string => `${workspaceId}_${INITIATIVES_FILTER_STORAGE_KEY}`
export const GetIntiativesOrderByStorageKey = (workspaceId: string): string => `${workspaceId}_${INITIATIVES_ORDER_BY_STORAGE_KEY}`

export interface InitiativesFilter {
  createdByCurrentUser: boolean;
  expiredPeriodType: PeriodType;
  states: InitiativeStateNameDto[];
  tags: string[];
  query?: string;
}

export class InitiativeStore {
  @observable initiatives: Initiative[] = [];

  private constructor(
    public readonly initiativeService: IInitiativeService,
    private readonly userStore: UserStore,
    public readonly notificationService: INotificationService
  ) {}

  static async create(
    initiativeService: IInitiativeService,
    userStore: UserStore,
    notificationService: INotificationService
  ) {
    const store = new InitiativeStore(initiativeService, userStore, notificationService);

    return store;
  }

  async getInitiative(id: string) {
    const initiativeDto = await this.initiativeService.getInitiative({ id });

    return this.updateInitiativeFromServer(initiativeDto);
  }

  async closeInitiative(id: string) {
    await this.initiativeService.closeInitiative(id);
  }

  async setImplementation(id: string) {
    await this.initiativeService.setImplementation(id);
  }

  async inviteUsers(req: InviteUsersToInitiativeWithMagicLink) {
    const response = this.initiativeService.inviteUserIntoInitiative(req);
    return response;
  }

  async editSubscription(initiativeId: string, subscribe: boolean) {
    if (subscribe) {
      return await this.notificationService.subscribeForNotification({
        targetId: initiativeId,
        targetType: SubscriptionTargetTypeDto.Initiative,
      });
    }
    return await this.notificationService.unsubscribeForNotification({
      targetId: initiativeId,
      targetType: SubscriptionTargetTypeDto.Initiative,
    });
  }

  async getInitiativeCoOwners(ids: string[], page: number) {
    const response = await this.userStore.getUsers({
      query: "",
      excludeIds: [],
      ids,
      page,
      limit: 12,
      sortType: GetUsersSortType.Name,
      sortDirection: SortDirection.Ascending,
    });
    return response;
  }

  async setInitiativeCoOwners(id: string, initiativeCoOwnersIdsList: string[]) {
    await this.initiativeService.setCoOwners({ id, userIds: initiativeCoOwnersIdsList });
  }

  async searchUsers(query: string, page: number, excludeIds: string[]) {
    const response = await this.userStore.getUsers({
      query: encodeURIComponent(query),
      page,
      excludeIds,
      ids: [],
      limit: 12,
      sortType: GetUsersSortType.Name,
      sortDirection: SortDirection.Ascending,
    });
    return response;
  }

  async getInitiatives(
    workspaceId: string,
    options: { page: number; results?: number },
    sortOption: { sortDirection: SortDirection; sortType: GetInitiativesListSortType },
    filter: InitiativesFilter
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const initiativesList = await this.initiativeService.getInitiativeList({
      workspaceId,
      ...options,
      ...sortOption,
      expiredPeriodType: filter.expiredPeriodType,
      states: filter.states,
      tags: filter.tags,
      userId: filter.createdByCurrentUser ? this.userStore.loggedUser.id : NIL,
      query: filter.query,
    });

    return {
      initiatives: initiativesList.items.map(initiativeOverview => this.updateInitiativeOverview(initiativeOverview)),
      totalCount: initiativesList.totalResults,
      hasNextPage: initiativesList.hasNextPage,
    };
  }

  createInitiative(id: string = uuid()) {
    const initiative = new Initiative(this, this.userStore, id);
    this.initiatives.push(initiative);
    return initiative;
  }

  removeInitiative(initiative: Initiative) {
    this.initiatives = this.initiatives.filter(i => i.id !== initiative.id);
  }

  async resetInitiative(initiative: Initiative) {
    await this.initiativeService.resetInitiative({ id: initiative.id });
  }

  async archiveInitiative(initiative: Initiative) {
    await this.initiativeService.archiveInitiative({ id: initiative.id });
  }

  async unarchiveInitiative(initiative: Initiative) {
    await this.initiativeService.unarchiveInitiative({ id: initiative.id });
  }

  async getTags(page: number, results: number, searchText = "") {
    const res = await this.initiativeService.getInitiativeMostUsedTags({
      page,
      results,
      searchText,
    });
    return {
      items: res.items,
      hasNextPage: res.hasNextPage,
    };
  }

  private updateInitiativeFromServer(dto: InitiativeDto) {
    let initiative = this.initiatives.find(b => b.id === dto.id);
    if (!initiative) {
      initiative = new Initiative(this, this.userStore, dto.id);
      this.initiatives.push(initiative);
    }
    initiative.updateFromDto(dto);

    return initiative;
  }

  private updateInitiativeOverview(dto: InitiativeListItemDto) {
    const initiativeOverview = new InitiativeOverview(this, this.userStore);
    initiativeOverview.updateListFromServer(dto);

    return initiativeOverview;
  }
}
