import { message } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { InputProps } from "antd/lib/input/Input";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { AttachmentDto } from "../../types/initiatives/dto/AttachmentDto";
import { AttachmentType } from "../../types/ly/application/AttachmentType";
import { TextArea } from "../TextArea/TextArea";
import { RichTextEditor } from "../richText/RichTextEditor";
import { FileStore } from "../stories/file/FileStore";
import { TextInput } from "../textInput/TextInput";

type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  onCommit?: () => void;
  onAttachmentAdded?: (value: AttachmentDto) => void;
  fileStore: FileStore;
  richTextEditor?: boolean;
  height?: number;
};

const acceptedFileTypes = ".pdf, .jpg, .jpeg, .png, .ppt, .zip";

function handlePaste(
  data: React.ClipboardEvent<HTMLElement>,
  fileStore: FileStore,
  onAttachmentAdded?: (value: AttachmentDto) => void
) {
  switch (true) {
    // Handle file paste
    case data.clipboardData.types.includes("Files"):
      Array.from(data.clipboardData.files)
        .filter(item =>
          acceptedFileTypes
            .split(",")
            .map(fileType => fileType.trim())
            .some(fileType => item.name.toLowerCase().endsWith(fileType))
        )
        .forEach(item => {
          fileStore.uploadFile(
            item,
            value => {
              onAttachmentAdded &&
                onAttachmentAdded({
                  id: value.id,
                  name: value.name,
                  type: AttachmentType.File,
                  contentType: value.type,
                  url: value.url,
                });
              message.success(`${item.name} file uploaded successfully.`);
            },
            () => message.error(`${item.name} upload failed.`)
          );
        });
      break;
    // Handle url paste
    case data.clipboardData.types.includes("text/plain"):
      {
        let url: URL;
        try {
          url = new URL(data.clipboardData.getData("Text"));
          onAttachmentAdded &&
            onAttachmentAdded({ id: uuid(), name: url.host, url, contentType: "text/plain", type: AttachmentType.Url });
        } catch (_) {
          // Not a URL - Ignore
        }
      }
      break;
    default:
      break;
  }
}

export const TextInputWithPasteSupport = ({
  value,
  onChange,
  onCommit,
  onAttachmentAdded,
  fileStore,
  richTextEditor,
  ...props
}: Props & Omit<InputProps, "value" | "onChange" | "showCount">) => {
  return richTextEditor ? (
    <RichTextEditor
      {...props}
      onContentChange={onChange}
      toolbarOptions={["inline", "list", "link", "emoji", "blockType"]}
      height={75}
      onPaste={data => handlePaste(data, fileStore, onAttachmentAdded)}
      value={value}
    />
  ) : (
    <TextInput
      {...props}
      value={value}
      onChange={onChange}
      onCommit={onCommit}
      onPaste={data => handlePaste(data, fileStore, onAttachmentAdded)}
    />
  );
};

export const TextAreaWithPasteSupport = ({
  value,
  onChange,
  onCommit,
  onAttachmentAdded,
  fileStore,
  richTextEditor,
  height,
  ...props
}: Props & Omit<TextAreaProps, "value" | "onChange" | "showCount">) => {
  return richTextEditor ? (
    <RichTextEditor
      onPaste={data => handlePaste(data, fileStore, onAttachmentAdded)}
      value={value}
      onContentChange={onChange}
      height={height}
    />
  ) : (
    <TextArea
      {...props}
      value={value}
      onChange={onChange}
      onPaste={data => handlePaste(data, fileStore, onAttachmentAdded)}
    />
  );
};
