import { LoadMoreList } from "../../../../../shared/List/LoadMoreList";
import { InitiativeStore } from "../../../../../shared/stories/initiative/InitiativeStore";

class Tag {
  id: string;

  constructor(public text: string) {
    this.id = text;
  }
}

export class LoadMoreTagList extends LoadMoreList<Tag> {
  constructor(private readonly initiativeStore: InitiativeStore, readonly pageSize = 5) {
    super(pageSize);
  }

  protected async load(options: { page: number; results: number }) {
    const result = await this.initiativeStore.getTags(options.page, options.results);

    return { items: result.items.map(t => new Tag(t)), hasNextPage: result.hasNextPage };
  }
}
