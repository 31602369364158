import { computed, observable } from "mobx";
import { Page } from "react-mvvm";
import { IErrorService } from "../shared/services/ErrorService";
import { INavigationService } from "../shared/services/NavigationService";
import { RootStore } from "../shared/stories/RootStore";
import { LoginViewModel } from "./login/LoginViewModel";
import { AddUsersViewModel } from "../shared/addUsers/AddUsersViewModel";
import notification from "antd/lib/notification";

export class App extends Page {
  @observable baseChildPage = new LoginViewModel(
    this.errorService,
    this.navigationService,
    this.rootStore.userStore,
    ""
  );

  addUsersViewModel: AddUsersViewModel;

  @computed
  get appChildPage() {
    return this.childPage || this.baseChildPage;
  }

  constructor(
    public navigationService: INavigationService,
    public rootStore: RootStore,
    public errorService: IErrorService
  ) {
    super();

    this.addUsersViewModel = new AddUsersViewModel(async (emailsList: string[]) => {
      const response = await this.rootStore.userStore.inviteUsers({ emails: emailsList });
      if (response.isAnyError) {
        response.errors.forEach(item =>
          notification.warning({
            message: `${item.errorMessage}`,
          })
        );
        const wrongEmailsList = response.errors.map(item => item.email);
        this.addUsersViewModel.showError(wrongEmailsList);
      } else {
        this.addUsersViewModel.hide();
      }
    });
  }

  showInviteUsersDialog() {
    this.addUsersViewModel.show();
  }

  logout() {
    this.navigationService.goToLogout();
  }

  async activate() {
    super.activate();

    this.rootStore.notificationStore.startNotificationHubConnection();
    await this.rootStore.notificationStore.updateUnreadTotals();
  }

  goToInitiatives() {
    this.errorService.resetErrorSplash();
    this.navigationService.goToInitiatives();
  }

  goToWorkspaces() {
    this.errorService.resetErrorSplash();
    this.navigationService.goToWorkspaces();
  }

  goToBoard() {
    this.navigationService.goToBoard();
  }

  goHomePage() {
    this.navigationService.goToHomePage();
  }

  goToNotificationCenter() {
    this.navigationService.goToNotificationCenter();
  }

  goToUserProfile() {
    this.navigationService.goToUserProfile();
  }

  async onInvalidRoute() {
    this.errorService.showErrorSplash(404);
  }

  async handleLocationChange() {
    this.errorService.resetErrorSplash();
  }
}
