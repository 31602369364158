import { Modal, Select, Tag, Typography } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { CommandButton } from "../button/Button";
import { DialogSupport } from "../dialogs/Dialogs";
import { Field, Form } from "../form/Form";
import { AddUsersViewModel } from "./AddUsersViewModel";

const { Title } = Typography;

interface IAddUsersDialogViewProps {
  model: AddUsersViewModel;
}

export const AddUsers: React.FunctionComponent<IAddUsersDialogViewProps> = observer(
  ({ model }: IAddUsersDialogViewProps) => {
    const { isVisible, cancel, submit } = model;
    const tagColor = React.useCallback(
      (email: string) => {
        if (!model.isValidEmail(email)) return "red";
        if (model.errorEmailList.indexOf(email) > -1) return "red";
        return "green";
      },
      [model.errorEmailList]
    );

    return (
      <Modal
        destroyOnClose
        visible={isVisible}
        title={
          <>
            <Title level={2} className="Title--withIcon">
              <SvgIcon iconName="addUser" /> Send invitations
            </Title>
          </>
        }
        footer={
          <CommandButton type="primary" size="large" command={submit} withLoader>
            Send invitations
          </CommandButton>
        }
        onCancel={() => {
          if (model.form.isDirty) DialogSupport.confirmDataLoss(cancel.execute);
          else cancel.execute();
        }}
        closable
        closeIcon={<SvgIcon iconName="close" />}
        className="AddCoOwner"
        width="100%"
      >
        <Form layout="vertical" form={model.form}>
          {fields => (
            <>
              <Field field={fields.emails} label="Invite users by email">
                {props => (
                  <Select
                    mode="tags"
                    size="middle"
                    dropdownStyle={{ display: "none" }}
                    placeholder="Type emails..."
                    className="SelectEmail"
                    onChange={e => {
                      if (e) {
                        props.onChange(e.toString());
                      }
                    }}
                    tokenSeparators={[",", " ", ";"]}
                    autoClearSearchValue
                    tagRender={p => (
                      <Tag color={tagColor(p.value.toString())} className="EmailTag" closable onClose={p.onClose}>
                        {p.value}
                      </Tag>
                    )}
                  />
                )}
              </Field>
            </>
          )}
        </Form>
      </Modal>
    );
  }
);
