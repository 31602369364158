import { action, observable } from "mobx";
import { Command } from "react-mvvm";
import { AttachmentDto } from "../../../types/initiatives/dto/AttachmentDto";
import { IdeaCommentDto } from "../../../types/initiatives/dto/IdeaCommentDto";
import { CommentType } from "../../../types/ly/application/CommentType";
import { UserDto } from "../../../types/shared/dto/UserDto";
import { UserStore } from "../user/UserStore";
// eslint-disable-next-line import/no-cycle
import { CommentStore } from "./CommentStore";

export class Comment {
  @observable targetId = "";

  @observable ownerId: string;

  @observable text = "";

  @observable createdAt = new Date().toISOString();

  @observable owner: UserDto = {
    id: "",
    name: "",
    email: "",
  };

  @observable attachments: AttachmentDto[] = [];

  @observable isDeleted = false;

  @observable canUserRemove = true;

  @observable isLiked = false;

  @observable likesCount = 0;

  removeComment: Command;

  likeComment: Command;

  constructor(
    public readonly store: CommentStore,
    private readonly userStore: UserStore,
    public id: string,
    ownerId: string,
    targetId: string,
    public readonly commentType: CommentType
  ) {
    this.ownerId = ownerId;
    this.targetId = targetId;

    this.removeComment = new Command(async () => {
      await this.store.removeComment(this.id);
      this.isDeleted = true;
    });

    this.likeComment = new Command(async () => {
      if (this.isLiked) {
        await this.store.unlikeComment(this.id);
        this.isLiked = false;
        this.likesCount -= 1;
      } else {
        await this.store.likeComment(this.id);
        this.isLiked = true;
        this.likesCount += 1;
      }
    });
  }

  @action
  updateText(newText: string) {
    this.text = newText;
  }

  @action
  updateAttachments(attachments: AttachmentDto[]) {
    this.attachments = attachments;
  }

  @action updateDeletePermissions(value: boolean) {
    this.canUserRemove = value;
  }

  async save() {
    await this.store.commentService.createComment({
      id: this.id,
      ideaId: this.targetId,
      text: this.text.trim(),
      attachments: this.attachments,
    });
  }

  updateFromDto(dto: IdeaCommentDto) {
    this.id = dto.id;
    this.targetId = dto.ideaId;
    this.ownerId = dto.owner.id;
    this.createdAt = dto.createdAt;
    this.text = dto.text;
    this.attachments = dto.attachments;
    this.canUserRemove = dto.isUserCanRemove;
    this.owner = dto.owner;
    this.isLiked = dto.isLiked;
    this.likesCount = dto.likesCount;
  }
}
