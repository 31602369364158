import { Card, List, Skeleton } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Command } from "react-mvvm/dist";
import { LoadMoreListView } from "../../../../shared/List/LoadMoreListView";
import { KanbanBoardWalkTourSelectors } from "../../../../shared/walkTour/KanbanBoardWalkTour";
import { InitiativeCard } from "../../initiativeList/initiativeCard/InitiativeCard";
import { FilteredInitiativePaginatedList } from "../BoardViewModel";

interface IBoardRow {
  list: FilteredInitiativePaginatedList;
  rowTitle: string;
  showItem: Command<string, void>;
}

export const BoardCol = observer(({ list, showItem, rowTitle }: IBoardRow) => {
  return (
    <div className="BoardRowContainer">
      <p className="BoardRowContainer__collTitle">{rowTitle}</p>
      <LoadMoreListView
        className="BoardRowContainer__list"
        loadMoreList={list}
        skeleton={
          <List.Item>
            <Card className="InitiativeCard" hoverable size="small">
              <Skeleton title loading active />
            </Card>
          </List.Item>
        }
        renderItem={item => {
          return (
            <List.Item>
              <InitiativeCard
                model={item}
                showInitiative={showItem}
                dataTour={KanbanBoardWalkTourSelectors.Initiatives}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
});
