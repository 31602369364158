import { computed, observable } from "mobx";
import { Command, command } from "react-mvvm";
import { DialogViewModel } from "../../dialog/DialogViewModel";
import { DialogSupport } from "../../dialogs/Dialogs";
import { UserStore } from "../../stories/user/UserStore";
import { AccessRequestDto } from "src/types/users/dto/AccessRequestDto";

export class ReviewAccessRequestViewModel extends DialogViewModel<string> {
  @observable accessRequest: AccessRequestDto | null = null;

  approve: Command<void, Promise<void>>;

  deny: Command<void, Promise<void>>;

  close: Command;


  constructor(private readonly userStore: UserStore, private readonly accessRequestId: string, onClose?: () => void) {
    super();

    this.approve = command(async () => {
      await this.userStore.approveAccessRequest(this.accessRequestId);
      DialogSupport.showSuccessDialog({
        title: `The user ${this.accessRequest?.requesterEmail} has been added successfully to the ${this.accessRequest?.resourceType.toLocaleLowerCase()}.`,
        content: `To exclude a user from the list of members in the ${this.accessRequest?.resourceType.toLocaleLowerCase()}, go to the list of members in the ${this.accessRequest?.resourceType.toLocaleLowerCase()}`,
        okText: `Go to ${this.accessRequest?.resourceType.toLocaleLowerCase()}`,
        closable: false,
        afterClose: () => this.close.execute()
      });
      this.isVisible = false;
    });
    this.deny = command(async () => {
      await this.userStore.denyAccessRequest(this.accessRequestId);
      DialogSupport.showSuccessDialog({
        title: `The user ${this.accessRequest?.requesterEmail} has been denied access to the ${this.accessRequest?.resourceType.toLocaleLowerCase()}.`,
        okText: "Close",
        afterClose: () => this.close.execute()
      });
      this.isVisible = false;
    });
    this.close = command(() => {
      this.isVisible = false;
      onClose && onClose();
    });
    this.isVisible = true;

    this.userStore.getAccessRequest(accessRequestId).then(res => {
      this.accessRequest = res;
    });
  }

  @computed get reviewedByCurrentUser() {
    return this.userStore.loggedUser.id === this.accessRequest?.editedBy.id;
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}
}
