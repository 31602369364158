import { action, observable } from "mobx";
import { Command, command } from "react-mvvm";
import { DialogViewModel } from "../../../../shared/dialog/DialogViewModel";
import { Initiative } from "../../../../shared/stories/initiative/Initiative";
import { UserDto } from "../../../../types/shared/dto/UserDto";

type AddCoOwnerOnSuccessMethod = (selectedUsers: string[]) => Promise<void>;

export class AddCoOwnerViewModel extends DialogViewModel<Initiative> {
  @observable searchValue = "";

  @observable searchResult: UserDto[] = [];

  @observable selectedUsers: UserDto[] = [];

  @observable selectedUsersIds: string[] = [];

  @observable currentPage = 1;

  @observable coOwnersCurrentPage = 0;

  @observable isNoMore = true;

  @observable isNoMoreCoOwners = true;

  @observable isEmptySearch = false;

  @observable.ref initiative: Initiative;

  cancel: Command;

  submit: Command<void, Promise<void>>;

  constructor(initiative: Initiative, private onSuccess: AddCoOwnerOnSuccessMethod) {
    super();
    this.initiative = initiative;

    this.cancel = command(() => {
      this.hide();
    });

    this.submit = command(
      async () => {
        await this.onSuccess(this.selectedUsersIds);
      },
      () => {
        return this.selectUser.length === 0;
      }
    );
  }

  @action.bound selectUser = (user: UserDto) => {
    if (!this.selectedUsers.includes(user)) {
      this.selectedUsers.push(user);
      this.selectedUsersIds.push(user.id);
      this.searchResult = this.searchResult.filter(item => item.id !== user.id);
    } else {
      this.initiative.coOwnersIds = this.initiative.coOwnersIds.filter(item => item !== user.id);
      this.selectedUsersIds = this.selectedUsersIds.filter(item => item !== user.id);
      this.selectedUsers = this.selectedUsers.filter(item => item.id !== user.id);
    }
  };

  @action.bound search = async () => {
    this.currentPage = 1;
    const { items, currentPage, totalPages } = await this.initiative.store.searchUsers(
      this.searchValue,
      this.currentPage,
      this.selectedUsersIds
    );
    this.searchResult = items.filter(this.isAlreadyCoOwner);
    this.isEmptySearch = this.searchResult.length === 0;
    this.isEmptySearch ? (this.isNoMore = true) : (this.isNoMore = currentPage === totalPages);
  };

  @action.bound loadMore = async () => {
    this.currentPage += 1;
    const { items, currentPage, totalPages } = await this.initiative.store.searchUsers(
      this.searchValue,
      this.currentPage,
      this.selectedUsersIds
    );
    const newItems = items.filter(this.isAlreadyCoOwner);
    newItems.length === 0 && this.search();
    this.searchResult = this.searchResult.concat(newItems);
    this.isNoMore = currentPage === totalPages;
  };

  @action.bound isAlreadyCoOwner = (item: UserDto) => {
    return (
      this.initiative?.coOwnersIds.indexOf(item.id) === -1 &&
      item.id !== this.initiative?.ownerId &&
      this.selectedUsersIds.indexOf(item.id) === -1
    );
  };

  @action.bound loadMoreCoOwners = async () => {
    this.coOwnersCurrentPage += 1;
    const { items, currentPage, totalPages } = await this.initiative.store.getInitiativeCoOwners(
      this.initiative.coOwnersIds,
      this.coOwnersCurrentPage
    );
    if (items.length === 0) {
      this.coOwnersCurrentPage = 0;
      this.loadMoreCoOwners();
    }
    this.selectedUsers = this.selectedUsers.concat(items);
    this.isNoMoreCoOwners = currentPage === totalPages;
  };

  async show() {
    this.selectedUsers = [];
    this.selectedUsersIds = this.initiative.coOwnersIds;
    this.searchValue = "";
    this.coOwnersCurrentPage = 0;
    this.currentPage = 0;
    this.initiative.coOwnersIds.length !== 0 && this.loadMoreCoOwners();
    this.search();
    await super.show();
  }

  // eslint-disable-next-line class-methods-use-this
  protected onShow() {}
}
