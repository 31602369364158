import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import React from "react";

// eslint-disable-next-line react/require-default-props
type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  onCommit?: () => void;
  showCount?: boolean;
  maxLength?: number;
};

export const TextArea = ({
  value,
  onChange,
  onCommit,
  ...props
}: Props & Omit<TextAreaProps, "value" | "onChange">) => (
  <Input.TextArea
    {...props}
    value={value ?? ""}
    onChange={e => onChange && onChange(e.target.value)}
    onBlur={() => onCommit && onCommit()}
  />
);
