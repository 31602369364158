import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { ContentView, isLoaded } from "react-mvvm/dist";
import { InitiativeListView } from "../../../pages/Initiatives/initiativeList/InitiativeListView";
import { SvgIcon } from "../../../shared/SvgIcon/SvgIcon";
import { ActivityIndicator } from "../../../shared/activityIndicator/ActivityIndicator";
import { BreadcrumbView } from "../../../shared/breadcrumb/BreadcrumbView";
import { CommandButton } from "../../../shared/button/Button";
import { EditWorkspaceDialog } from "../workspaceList/editWorkspaceDialog/EditWorkspaceDialog";
import { ManageWorkspaceMembersDialog } from "../workspaceList/manageWorkspaceMembersDialog/ManageWorkspaceMembersDialog";
import { WorkspaceViewModel } from "./WorkspaceViewModel";
import { WorkspaceMembers } from "./components/WorkspaceMembers/WorkspaceMembers";
import { WorkspaceHeaderCard } from "./components/workspaceHeaderCard/WorkspaceHeaderCard";

export const WorkspaceView = observer(({ model }: { model: WorkspaceViewModel }) => {
  if (model.childPage) return <ContentView content={model.childPage} />;

  const { isLoading, state, initiativesListViewModel } = model;

  return (
    <>
      <ActivityIndicator isLoading={isLoading}>
        {isLoaded(state) && (
          <>
            <div className="Workspace__header">
              <BreadcrumbView items={state.breadcrumbItems} appendWorkspacesItem />

              <CommandButton
                icon={<SvgIcon iconName="share" />}
                command={model.shareWorkspace}
                size="large"
                type="primary"
              >
                Share
              </CommandButton>

              <CommandButton
                command={model.showCreateInitiativeDialog}
                icon={<SvgIcon iconName="plusOutlined" />}
                size="large"
                type="primary"
              >
                Submit initiative
              </CommandButton>

              {/* <SubscribeButton
                                command={new Command(() => { })}
                                isSubscribed={false}
                            /> */}
            </div>
            <Row className="Workspace__body" gutter={32}>
              <Col xs={15} md={15} lg={11} xl={12}>
                <WorkspaceHeaderCard currentUserIsOwner={state.currentUserIsOwner} viewModel={model} />
              </Col>
              <Col xs={15} md={15} lg={4} xl={3}>
                <WorkspaceMembers model={state.membersViewModel} />
              </Col>
              <Col xs={15} md={15} lg={11} xl={12}>
                <InitiativeListView model={initiativesListViewModel} />
              </Col>
            </Row>
            <EditWorkspaceDialog model={state.editWorkspaceDialogViewModel} />
            <ManageWorkspaceMembersDialog model={state.manageWorkspaceMembersDialogViewModel} />
          </>
        )}
      </ActivityIndicator>
    </>
  );
});
