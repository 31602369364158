import { AbstractFormStep } from "../../../../../shared/stepWizard/Step";
import { CreateInitiativeModel } from "../CreateInitiativeModel";

export type CreateInitiativeStepName = "Basic" | "Date" | "VotingSystem" | "Summary";

export abstract class CreateInitiativeStep<T extends keyof CreateInitiativeModel> extends AbstractFormStep<
  T,
  CreateInitiativeModel,
  CreateInitiativeStepName
> {}
