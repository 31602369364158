import { observable } from "mobx";
import { bindableForm, command, Command, RequiredFieldValidator } from "react-mvvm/dist";
import { IErrorService } from "../../../shared/services/ErrorService";
import { INavigationService } from "../../../shared/services/NavigationService";
import { UrlService } from "../../../shared/services/UrlService";
import { UserStore } from "../../../shared/stories/user/UserStore";
import { combineValidators } from "../../../shared/validators/combineValidators";
import { EmailValidator } from "../../../shared/validators/emailValidator";
import { BasePage } from "../../BasePage";

interface ExternalUser {
  email: string;
}

export class SignInViewModel extends BasePage {
  @observable externalUser: ExternalUser = {
    email: "",
  };

  @observable isLoading = false;

  @observable isSuccess = false;

  @observable idp = "";

  form;

  submit: Command<void, Promise<void>>;

  goToLogin: Command<void, Promise<void>>;

  userStore;

  constructor(errorService: IErrorService, navigationService: INavigationService, userStore: UserStore) {
    super(errorService, false, userStore);

    this.userStore = userStore;
    this.goToLogin = command(async () => {
      navigationService.goToLogin();
    });

    this.form = bindableForm<ExternalUser>()
      .addField("email", {
        validator: v => combineValidators([RequiredFieldValidator(v), EmailValidator(v)]),
      })
      .bindTo(() => this.externalUser);

    this.submit = command(async () => {
      if (!(await this.form.validateAndCommit())) return;
      this.isLoading = true;
      try {
        await this.userStore.resendInvitation(this.externalUser.email, UrlService.getReturnUrlParam() ?? undefined);
        this.isSuccess = true;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
        this.isSuccess = true;
      }
    });
  }

  async onActivated() {
    const idp = await this.userStore.getIdentityProvider();
    // eslint-disable-next-line prefer-destructuring
    idp && (this.idp = idp[0]);
    super.onActivated();
  }
}
