import { Avatar } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { useViewModel } from "./UserAvatarViewModel";

interface UserAvatarProps {
  userName: string;
  avatarId?: string | null;
  className?: string;
  size?: AvatarSize;
}

export const UserAvatar: React.FC<UserAvatarProps> = observer(({ userName, className, size = "default", avatarId }) => {
  const viewModel = useViewModel(userName, avatarId);

  return viewModel.avatarUrl ? (
    <Avatar size={size} src={viewModel.avatarUrl} className={classNames("UserAvatar", className)} />
  ) : (
    <Avatar size={size} className={classNames("UserAvatar", className)}>
      {viewModel.displayName.length > 0 && viewModel.displayName[0].toUpperCase()}
    </Avatar>
  );
});
