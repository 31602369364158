import { Modal, Result, Space, Typography } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { SvgIcon } from "../../../../shared/SvgIcon/SvgIcon";
import { Button } from "../../../../shared/button/Button";
import { SuccessDialogViewModel } from "./SuccessWorkspaceDialogViewModel";

interface ISuccessWorkspaceDialogViewProps {
  model: SuccessDialogViewModel;
}

const { Paragraph } = Typography;

export const SuccessWorkspaceDialog: React.FunctionComponent<ISuccessWorkspaceDialogViewProps> = observer(
  ({ model }: ISuccessWorkspaceDialogViewProps) => {
    const { isVisible, cancel, showWorkspace, workspaceId } = model;

    return (
      <Modal
        visible={isVisible}
        onOk={() => workspaceId && showWorkspace.execute(workspaceId)}
        onCancel={() => cancel.execute()}
        closable={false}
        closeIcon={<SvgIcon iconName="close" />}
        className="SuccessWorkspaceDialog_root"
        footer={
          <Space direction="vertical" size="middle">
            <Button
              block
              key="submit"
              type="primary"
              size="large"
              onClick={() => workspaceId && showWorkspace.execute(workspaceId)}
            >
              Go to workspace
            </Button>
            <Button block key="submit" type="outlined" size="large" onClick={() => cancel.execute()}>
              Cancel
            </Button>
          </Space>
        }
        width="100%"
      >
        <Result
          className="SuccessWorkspaceDialog__message"
          title="Your workspace was successfully created"
          status="success"
          icon={<SvgIcon iconName="success" />}
          subTitle={
            <>
              <Paragraph>
                Now you can create initiatives and invite users.
                <br />
                It will be visible on your dashboard.
              </Paragraph>
            </>
          }
        />
      </Modal>
    );
  }
);
