import { Menu, Badge } from "antd";
import { MenuProps } from "antd/lib/menu";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { App } from "../../pages/App";
import { SvgIcon } from "../../shared/SvgIcon/SvgIcon";
import { InitialWalkTourSelectors } from "../../shared/walkTour/InitialWalkTour";
import { useHistory, useUrl } from "../../useRouter";

const getBasePagePath = (pathName: string) => {
  const basePage = pathName.split("/", 2)[1];

  return `/${basePage}`;
};

interface NavigationMenuProps extends MenuProps {
  model: App;
  className?: string;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = observer(({ model, className, mode }) => {
  const url = useUrl();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = React.useState<string>(
    !history ? url.toHomePage() : getBasePagePath(history.location.pathname)
  );

  React.useEffect(() => {
    if (!history) {
      return;
    }

    const historyListenerDisposer = history.listen(location => {
      setSelectedKey(getBasePagePath(location.pathname));
    });

    return () => historyListenerDisposer();
  }, []);

  return (
    <div
      className={classNames("NavigationMenu", className)}
      data-tour={window.innerWidth < 768 && InitialWalkTourSelectors.Navigation}
    >
      <div className="NavigationMenu__logo">
        <SvgIcon iconName="bulb" />
      </div>
      <Menu selectedKeys={[selectedKey]} theme="dark" mode={mode}>
        <Menu.Item
          onClick={() => model.goToWorkspaces()}
          icon={<SvgIcon iconName="home" className="customicon--white" />}
          key={url.toWorkspaces()}
        >
          Home Page
        </Menu.Item>

        {/* <Menu.Item
          onClick={() => model.goToBoard()}
          icon={<SvgIcon iconName="table" className="customicon--white" />}
          key="board"
        >
          Board
        </Menu.Item> */}

        <Menu.Item onClick={() => model.showInviteUsersDialog()} icon={<SvgIcon iconName="addUser" />} key="invite">
          Invite Users
        </Menu.Item>

        <Menu.Divider className="NavigationMenu__divider" />
        <Menu.Item
          onClick={() => model.goToUserProfile()}
          icon={<SvgIcon iconName="avatar" className="customicon--white" />}
          key={url.toUserProfile()}
        >
          My Profile
        </Menu.Item>
        <Menu.Item
          onClick={() => model.goToNotificationCenter()}
          icon={
            <div className="anticon">
              <Badge count={model.rootStore.notificationStore.totalUnread} size="small">
                <SvgIcon iconName="bellOutlined" className="customicon--white" />
              </Badge>
            </div>
          }
          key={url.toNotificationCenter()}
        >
          Notifications
        </Menu.Item>
        <Menu.Item
          onClick={() => model.logout()}
          icon={<SvgIcon iconName="logOut" className="customicon--white" />}
          key="logout"
        >
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
});
