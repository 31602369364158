import { getPath } from "react-mvvm";
import { IdeaViewModel } from "../pages/Initiatives/idea/IdeaViewModel";
import { WorkspaceViewModel } from "../pages/workspaces/workspace/WorkspaceViewModel";
import { getIdeaPath, IdeaParams, InitiativeParams } from "../pages/Initiatives/addInitiativeRouters";
import { InitiativeViewModel } from "../pages/Initiatives/initiative/InitiativeViewModel";
import { InitiativeListViewModel } from "../pages/Initiatives/initiativeList/InitiativeListViewModel";
import { NotificationCenterViewModel } from "../pages/notifications/NotificationCenterViewModel";
import { UserProfileViewModel } from "../pages/userProfile/UserProfileViewModel";
import { WorkspaceParams } from "../pages/workspaces/addWorkspaceRouters";
import { WorkspaceListViewModel } from "../pages/workspaces/workspaceList/WorkspaceListViewModel";

export interface IUrl {
  toHomePage: () => string;
  toInitiativePage: (params: InitiativeParams) => string;
  toIdeaPage: (params: IdeaParams & InitiativeParams) => string;
  toUpload: () => string;
  toNotificationCenter: () => string;
  toUserProfile: () => string;
  toWorkspace: (params: WorkspaceParams) => string;
  toWorkspaces: () => string;
}

export const Url: IUrl = {
  toHomePage: () => "/",
  toUpload: () => "/api/files/",
  toInitiativePage: (params: InitiativeParams) => getPath(InitiativeViewModel, params),
  toIdeaPage: (params: IdeaParams & InitiativeParams) => getPath(IdeaViewModel, params), 
  toNotificationCenter: () => getPath(NotificationCenterViewModel),
  toUserProfile: () => getPath(UserProfileViewModel),
  toWorkspace: (params: WorkspaceParams) => getPath(WorkspaceViewModel, params),
  toWorkspaces: () => getPath(WorkspaceListViewModel),
};

export const DefaultUrl = (): IUrl => {
  return {
    toHomePage: () => "",
    toInitiativePage: () => "",
    toIdeaPage: () => "",
    toUpload: () => "",
    toNotificationCenter: () => "",
    toUserProfile: () => "",
    toWorkspace: () => "",
    toWorkspaces: () => "",
  };
};
