import { ReviewAccessRequestViewModel } from "../../shared/requestAccess/reviewAccessRequest/ReviewAccessRequestViewModel";
import { IErrorService } from "../../shared/services/ErrorService";
import { INavigationService } from "../../shared/services/NavigationService";
import { UserStore } from "../../shared/stories/user/UserStore";
import { BasePage } from "../BasePage";

export class AccessRequestReviewPageViewModel extends BasePage {
  model: ReviewAccessRequestViewModel;

  constructor(
    errorService: IErrorService,
    userStore: UserStore,
    navigationService: INavigationService,
    resourceId: string
  ) {
    super(errorService, true, userStore);

    this.model = new ReviewAccessRequestViewModel(userStore, resourceId, () =>
      navigationService.returnToReturnUrlOrDefault()
    );
  }
}
