import { navigateTo } from "../../useRouter";
import { RETURN_URL_PARAM_NAME } from "../Constants";
import { IUrlService } from "./UrlService";

/* eslint-disable class-methods-use-this */
export interface INavigationService {
  goToLogin(): void;
  goToLogout(): void;
  goToHomePage(): void;
  goToBoard(): void;
  goToNotificationCenter(): void;
  goToUserProfile(): void;
  redirectTo(url: string): void;
  redirectToRedirectUriOrDefault(): void;
  returnToReturnUrlOrDefault(): void;
  goToSignin(returnUrl?: string): void;
  goToInitiative(id: string): void;
  goToInitiatives(): void;
  goToWorkspaces(): void;
  navigateTo(path: string): void;
  getCurrentLocation(): string;
}

// The idea is that this should be the only place where we access window.location functionalities.
// We should never refer to them directly from our viewmodels (and, preferably, services).
export class NavigationService implements INavigationService {
  constructor(private readonly urlService: IUrlService) {}

  redirectTo(url: string) {
    window.location.href = url;
  }

  redirectToRedirectUriOrDefault() {
    const redirectUri = this.urlService.getRedirectUriParam();
    if (redirectUri) this.redirectTo(redirectUri);
    else this.goToHomePage();
  }

  returnToReturnUrlOrDefault() {
    const returnUrl = this.urlService.getReturnUrlParam();
    if (returnUrl) this.navigateTo(returnUrl);
    else this.goToHomePage();
  }

  goToLogin() {
    this.redirectTo(this.urlService.getLoginUrl());
  }

  goToSignin(returnUrl?: string) {
    navigateTo(`/signin${returnUrl ? `?${RETURN_URL_PARAM_NAME}=${encodeURIComponent(returnUrl)}` : ""}`);
  }

  goToBoard() {
    navigateTo("/initiatives/board");
  }

  goToHomePage() {
    navigateTo("/");
  }

  goToLogout(): void {
    this.redirectTo("/logout");
  }

  goToNotificationCenter() {
    navigateTo("/notifications");
  }

  goToUserProfile(): void {
    navigateTo("/profile");
  }

  goToInitiative(id: string) {
    navigateTo(`/initiatives/${id}`);
  }

  goToInitiatives() {
    navigateTo("/initiatives");
  }

  goToWorkspaces(): void {
    navigateTo("/workspaces");
  }

  navigateTo(path: string): void {
    navigateTo(path);
  }

  getCurrentLocation() {
    return window.location.pathname;
  }
}
